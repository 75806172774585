import {
  CREATE_PLANT_SUCCESS,
  CREATE_PLANT_FAIL,
  SET_MESSAGE,
  READ_PLANT_SUCCESS,
  READ_PLANT_FAIL,
  EDIT_PLANT_SUCCESS,
  EDIT_PLANT_FAIL,
  DELETE_PLANT_SUCCESS,
  DELETE_PLANT_FAIL,
} from "./types";
import PlantService from "../services/plant.service";

export const addPlant = (payload) => (dispatch) => {
  return PlantService.create(payload).then(
    (response) => {
      dispatch({
        type: CREATE_PLANT_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_PLANT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editPlant = (payload) => (dispatch) => {
  return PlantService.edit(payload).then(
    (response) => {
      dispatch({
        type: EDIT_PLANT_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_PLANT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deletePlant = (payload) => (dispatch) => {
  return PlantService.delete(payload).then(
    (response) => {
      dispatch({
        type: DELETE_PLANT_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_PLANT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readPlant = () => (dispatch) => {
  return PlantService.readPlant().then(
    (response) => {
      dispatch({
        type: READ_PLANT_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_PLANT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
