import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class MATERIALService {

  create(payload) {
    return axios
      .post(API_URL_DEFAULT + "material", payload, { headers: authHeader() });
  }
  readMATERIAL(query) {
    return axios
      .get(API_URL_DEFAULT + "material/", { headers: authHeader(), params: query });
  }
  readMATERIALbyUOM(code) {
    return axios
      .get(API_URL_DEFAULT + "material/uom/?code=" + code, { headers: authHeader() });
  }
  edit(payload) {
    return axios
      .put(API_URL_DEFAULT + "material", payload, { headers: authHeader() });
  }

  delete(payload) {
    return axios
      .delete(API_URL_DEFAULT + "material", { data: payload, headers: authHeader() });
  }

}

export default new MATERIALService();