import {
  CREATE_GLACCOUNT_SUCCESS,
  CREATE_GLACCOUNT_FAIL,
  SET_MESSAGE,
  READ_GLACCOUNT_SUCCESS,
  READ_GLACCOUNT_FAIL,
  EDIT_GLACCOUNT_SUCCESS,
  EDIT_GLACCOUNT_FAIL,
  DELETE_GLACCOUNT_SUCCESS,
  DELETE_GLACCOUNT_FAIL,
} from "./types";
import GLAccountService from "../services/glaccount.service";

export const addGLAccount = (payload) => (dispatch) => {
  return GLAccountService.create(payload).then(
    (response) => {
      dispatch({
        type: CREATE_GLACCOUNT_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_GLACCOUNT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editGLAccount = (payload) => (dispatch) => {
  return GLAccountService.edit(payload).then(
    (response) => {
      dispatch({
        type: EDIT_GLACCOUNT_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_GLACCOUNT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deleteGLAccount = (payload) => (dispatch) => {
  return GLAccountService.delete(payload).then(
    (response) => {
      dispatch({
        type: DELETE_GLACCOUNT_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_GLACCOUNT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readGLAccount = (query) => (dispatch) => {
  return GLAccountService.readGLAccount(query).then(
    (response) => {
      dispatch({
        type: READ_GLACCOUNT_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_GLACCOUNT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readGLAccountByCostCenter = (cost_center) => (dispatch) => {
  return GLAccountService.readGLAccountByCostCenter(cost_center).then(
    (response) => {
      dispatch({
        type: READ_GLACCOUNT_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_GLACCOUNT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
