import React, { Component } from "react";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  readAdvance,
  upperApprovalAdvance,
  approvalSettlement,
} from "../actions/advance";
import { readCurrency } from "../actions/currency";
import { clearMessage } from "../actions/message";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import addSeparatorsNF from "../helpers/thousanddelimiter";
import dateFormat from "dateformat";

class ApprovalSettlementAdvance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      submitted: false,
      showModal: false,
      showModalAdvance: false,
      showModalAdvanceReject: false,
      showModalFlow: false,
      reason: null,
      readOnly: false,
      collection: [],
      select_items: [],
      advance: [],
      errors: {},
      id: null,
      selectedFile: null,
      requester: null,
      name_requester: null,
      email: null,
      department: null,
      keperluan_advance: null,
      nilai_pengajuan: null,
      nama_pemilik_rekening: null,
      nama_bank: null,
      nomor_rekening: null,
      g_l_account: null,
      currency: null,
      grand_total: null,
      exchange_rate_to_rp: "1",
      status: null,
      ready_for_approval: false,
      error_message_exchange_rate: null,
      created_at: null,
      cost_center: null,
      detail_realisasi: null,
      nominal_realisasi: null,
      columns: [
        {
          dataField: "requester",
          text: "Requester",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "departement",
          text: "Departement",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "keperluan_advance",
          text: "Purpose of Advance",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "currency",
          text: "Currency",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "nilai_pengajuan",
          text: "Proposal Nominal",
          filter: textFilter(),
          formatter: this.GetNominalFormat,
          sort: true,
        },
        {
          dataField: "grand_total",
          text: "Grand Total (Rp)",
          filter: textFilter(),
          formatter: this.GetNominalGrandTotalFormat,
          sort: true,
        },
        {
          dataField: "nama_pemilik_rekening",
          text: "Name of the Account Holder",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "nama_bank",
          text: "Bank Name",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "nomor_rekening",
          text: "Account Number",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "g_l_account",
          text: "GL Account",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "status",
          text: "Status",
          filter: textFilter(),
          sort: true,
          formatter: this.GetStatusFormat,
          classes: "text-center",
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      defaultSorted: [
        {
          dataField: "requester",
          order: "asc",
        },
      ],
      cellEditProps: {},
    };
  }
  componentDidMount() {
    const { dispatch, user } = this.props;
    dispatch(readAdvance(user.username, "approval_settlement")).then(
      (response) => {
        this.setState({
          advance: this.props.read_advance.data,
          requester: user.username,
          department: user.details.hris_org_tree.current_person.nama_department,
          email: user.details.hris_org_tree.current_person.email,
          name_requester: user.details.hris_org_tree.current_person.person_name,
        });
      }
    );

    dispatch(readCurrency()).then((response) => {
      this.setState({ select_items: this.props.read_currency.data });
    });
  }
  onModalAdvanceHide = () => {
    this.setState({
      id: null,
      submitted: false,
      selectedFile: null,
      keperluan_advance: null,
      nilai_pengajuan: null,
      nama_pemilik_rekening: null,
      nama_bank: null,
      nomor_rekening: null,
      g_l_account: null,
      currency: null,
      grand_total: null,
      exchange_rate_to_rp: "1",
      status: null,
      showModalAdvance: !this.state.showModalAdvance,
      readOnly: true,
      ready_for_approval: false,
      error_message_exchange_rate: null,
      created_at: null,
      cost_center: null,
      detail_realisasi: null,
      nominal_realisasi: null,
    });
  };
  onModalAdvanceRejectHide = () => {
    this.setState({
      id: null,
      submitted: false,
      showModalAdvanceReject: !this.state.showModalAdvanceReject,
      readOnly: true,
      error_message_exchange_rate: null,
      reason: null,
    });
  };

  onModalLoadingHide = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };
  onModalFlowHide = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };

  toggleFlowAdvance = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };

  GetNominalFormat = (cell, row) => {
    return <div>{addSeparatorsNF(row.nilai_pengajuan, ".", ".", ",")}</div>;
  };
  GetNominalGrandTotalFormat = (cell, row) => {
    return <div>{addSeparatorsNF(row.grand_total, ".", ".", ",")}</div>;
  };
  GetStatusFormat = (cell, row) => {
    return (
      <div>
        <button
          type="button"
          className="btn btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => this.toggleFlowAdvance()}
        >
          <i className="fas fa-search-location"></i>
          &nbsp;{row.status}
        </button>
      </div>
    );
  };
  toggleAdvance = () => {
    this.setState({
      showModalAdvance: !this.state.showModalAdvance,
    });
  };

  toDetailAdvance = (
    id,
    keperluan_advance,
    nilai_pengajuan,
    nama_pemilik_rekening,
    nama_bank,
    nomor_rekening,
    g_l_account,
    currency,
    exchange_rate_to_rp,
    grand_total,
    ready_for_approval,
    status,
    created_at,
    cost_center,
    detail_realisasi,
    nominal_realisasi
  ) => {
    return this.setState(
      {
        id: id,
        keperluan_advance: keperluan_advance,
        nilai_pengajuan: nilai_pengajuan,
        nama_pemilik_rekening: nama_pemilik_rekening,
        nama_bank: nama_bank,
        nomor_rekening: nomor_rekening,
        g_l_account: g_l_account,
        currency: currency,
        exchange_rate_to_rp: exchange_rate_to_rp,
        grand_total: grand_total,
        readOnly: true,
        ready_for_approval: ready_for_approval,
        status: status,
        created_at: created_at,
        cost_center: cost_center,
        detail_realisasi: detail_realisasi,
        nominal_realisasi: nominal_realisasi,
      },
      () => {
        this.toggleAdvance();
      }
    );
  };
  GetActionFormat = (cell, row) => {
    return (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetailAdvance(
              row._id,
              row.keperluan_advance,
              row.nilai_pengajuan,
              row.nama_pemilik_rekening,
              row.nama_bank,
              row.nomor_rekening,
              row.g_l_account,
              row.currency,
              row.exchange_rate_to_rp,
              row.grand_total,
              true,
              row.status,
              dateFormat(row.created_at, "yyyy-mm-dd HH:MM:ss"),
              row.cost_center,
              row.detail_realisasi,
              addSeparatorsNF(row.nominal_realisasi, ".", ".", ",")
            )
          }
        >
          <i className="fas fa-th"></i>
          &nbsp;Detail
        </button>
      </div>
    );
  };
  isEmptyObject = (obj) => {
    return obj == null || !Object.keys(obj).length;
  };
  approveData = (id) => {
    const { dispatch, history } = this.props;
    const { requester, name_requester, status, email, reason } = this.state;
    let collection = {
      _id: id,
      requester: requester,
      name_requester: name_requester,
      status: status,
      email: email,
      reason: "approval",
    };

    if (collection) {
      Swal.fire({
        title: "Are you sure to approve this?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, approve",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(approvalSettlement(collection))
            .then((response) => {
              toast.success("Data has been approved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-advance-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been ready approved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/approval/settlementadvance");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-advance-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  showinputReason = (id) => {
    this.setState({
      showModalAdvanceReject: !this.state.showModalAdvanceReject,
    });
  };
  rejectData = (id) => {
    this.setState({ submitted: true });
    const { dispatch, history } = this.props;
    let { requester, status, reason, email, name_requester } = this.state;
    let collection = {
      _id: id,
      requester: requester,
      email: email,
      name_requester: name_requester,
      status: status,
      reason: reason,
    };
    if (!this.validateRejectForm(reason)) {
      return;
    }

    if (reason && requester) {
      Swal.fire({
        title: "Are you sure to reject this?",
        text: "Please check your entries",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reject it",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(approvalSettlement(collection))
            .then((response) => {
              toast.success("Data has been rejected successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-advance-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been rejected successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/approval/advance");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-advance-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  validateRejectForm = (reason) => {
    let errors = this.state.errors;
    if (!reason) {
      errors.keperluan_advance = "This field is required";
    }
    return errors;
  };
  validateForm = (
    keperluan_advance,
    nilai_pengajuan,
    nama_pemilik_rekening,
    nama_bank,
    nomor_rekening,
    g_l_account,
    currency,
    exchange_rate_to_rp,
    created_at,
    cost_center
  ) => {
    let errors = this.state.errors;
    if (!keperluan_advance) {
      errors.keperluan_advance = "This field is required";
    }
    if (!nilai_pengajuan) {
      errors.nilai_pengajuan = "This field is required";
    }
    if (!nama_pemilik_rekening) {
      errors.nama_pemilik_rekening = "This field is required";
    }
    if (!nama_bank) {
      errors.nama_bank = "This field is required";
    }
    if (!nomor_rekening) {
      errors.nomor_rekening = "This field is required";
    }
    if (!g_l_account) {
      errors.g_l_account = "This field is required";
    }
    if (!currency) {
      errors.currency = "This field is required";
    }
    if (!exchange_rate_to_rp) {
      errors.exchange_rate_to_rp = "This field is required";
    }

    return errors;
  };
  render() {
    let {
      columns,
      defaultSorted,
      cellEditProps,
      select_items,
      advance,
      id,
      keperluan_advance,
      nilai_pengajuan,
      nama_pemilik_rekening,
      nama_bank,
      nomor_rekening,
      g_l_account,
      currency,
      exchange_rate_to_rp,
      readOnly,
      showModalAdvance,
      ready_for_approval,
      showModalAdvanceReject,
      grand_total,
      submitted,
      reason,
      errors,
      showModalFlow,
      loading,
      created_at,
      cost_center,
      detail_realisasi,
      nominal_realisasi,
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <ToastContainer />
          <Modal
            show={showModalFlow}
            size="xl"
            backdrop="static"
            onHide={this.onModalFlowHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Flow Advance</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={1}>Submitted</Col>
                    <Col xs={0}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={1}>Approval</Col>
                    <Col xs={0}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2}>Disbursement of Funds</Col>
                    <Col xs={0}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={1}>Confirm</Col>
                    <Col xs={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={1}>Settlement</Col>
                    <Col xs={0}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2}>Settlement Activity</Col>
                    <Col xs={0}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={1}>Done</Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <img src="./images/flow_advance.png" width="90%" alt="" />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </form>
          </Modal>
          <Modal
            show={showModalAdvance}
            size="xl"
            backdrop="static"
            onHide={this.onModalAdvanceHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Detail Advance</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="keperluan_advance">
                          Purpose of Advance *
                        </label>
                        <textarea
                          id="keperluan_advance"
                          className="form-control form-control-sm"
                          name="keperluan_advance"
                          placeholder="Purpose of Advance"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={keperluan_advance}
                          rows="5"
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="nominal_pengajuan">
                          Proposal Nominal *
                        </label>
                        <div className="input-group mb-3">
                          <div className="col-md-3">
                            <select
                              className="form-control form-control-sm "
                              name="currency"
                              onChange={this.onChangeValue}
                              style={{ padding: 0 }}
                              defaultValue={currency}
                              readOnly={readOnly}
                            >
                              <option value="">Choose</option>
                              {select_items &&
                                select_items.map(function (item, i) {
                                  return (
                                    <option value={item.value} key={i}>
                                      {item.value}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="col-md-9">
                            <div className="input-group-append">
                              <span
                                style={{
                                  backgroundColor: "rgb(233 236 239)",
                                  padding: ".25rem .5rem",
                                  fontSize: ".875rem",
                                  lineHeight: "1.5",
                                  borderRadius: ".2rem",
                                }}
                              >
                                {addSeparatorsNF(
                                  nilai_pengajuan,
                                  ".",
                                  ".",
                                  ","
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group input-group-sm mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text input-group-text"
                              id="exchange_rate_to_rp"
                            >
                              Exchange Rate to Rp.
                            </span>
                          </div>
                          <span
                            style={{
                              backgroundColor: "rgb(233 236 239)",
                              padding: ".25rem .5rem",
                              fontSize: ".875rem",
                              lineHeight: "1.5",
                              borderRadius: ".2rem",
                            }}
                          >
                            {addSeparatorsNF(
                              exchange_rate_to_rp,
                              ".",
                              ".",
                              ","
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="input-group input-group-sm mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text input-group-text"
                              id="grand_total"
                            >
                              Grand Total (Rp)
                            </span>
                          </div>
                          <span
                            style={{
                              backgroundColor: "rgb(233 236 239)",
                              padding: ".25rem .5rem",
                              fontSize: ".875rem",
                              lineHeight: "1.5",
                              borderRadius: ".2rem",
                            }}
                          >
                            {addSeparatorsNF(grand_total, ".", ".", ",")}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="nama_pemilik_rekening">
                          Name of Account Holder *
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="nama_pemilik_rekening"
                          placeholder="Name of Account Holder"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={nama_pemilik_rekening}
                          readOnly={readOnly}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="nama_bank">Bank *</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="nama_bank"
                          placeholder="Bank"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={nama_bank}
                          readOnly={readOnly}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="nomor_rekening">Account Number *</label>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          name="nomor_rekening"
                          placeholder="Account Number"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={nomor_rekening}
                          readOnly={readOnly}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="cost_center">Cost Center *</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="cost_center"
                          placeholder="Cost Center"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={cost_center}
                          readOnly={readOnly}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="g_l_account">GL Account *</label>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          name="g_l_account"
                          placeholder="GL Account"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={g_l_account}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="created_at">Created at</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="created_at"
                          placeholder="Created at"
                          defaultValue={created_at}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>

                  <h4>Realisasi Advance</h4>
                  <hr></hr>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="detail_realisasi">
                          Detail Realisasi
                        </label>
                        <textarea
                          type="text"
                          className="form-control form-control-sm"
                          name="detail_realisasi"
                          placeholder="Detail Realisasi"
                          onChange={this.onChangeValue}
                          rows="5"
                          defaultValue={detail_realisasi}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="nominal_realisasi">
                          Nominal Realisasi
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="nominal_realisasi"
                          placeholder="Nominal Realisasi"
                          defaultValue={nominal_realisasi}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter className="justify-content-between">
                <Button
                  variant="danger"
                  onClick={() => this.showinputReason(id)}
                  className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
                >
                  <i className="fas fa-times" /> Reject
                </Button>
                {ready_for_approval && (
                  <button
                    type="button"
                    className="btn btn-success btn-sm ml-2 mb-2 ts-buttom"
                    size="sm"
                    data-id={id}
                    onClick={() => this.approveData(id)}
                  >
                    <i className="fas fa-check-square"></i>
                    &nbsp;Approve
                  </button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Modal
            show={showModalAdvanceReject}
            size="md"
            backdrop="static"
            onHide={this.onModalAdvanceRejectHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              autoComplete="off"
            >
              <ModalHeader closeButton className="bg-warning text-white">
                <ModalTitle>Input Reason</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="form-group">
                        <label htmlFor="reason">Reason *</label>
                        <textarea
                          id="reason"
                          className="form-control form-control-sm"
                          name="reason"
                          placeholder="Reason"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={reason}
                          rows="5"
                        />

                        {errors.reason && (
                          <div className="invalid-feedback">
                            {errors.reason}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="danger"
                  onClick={() => this.rejectData(id)}
                  className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
                >
                  <i className="fas fa-times" /> Reject
                </Button>
              </ModalFooter>
            </form>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">
                          Approval Settlement Advance
                        </h3>
                      </div>
                      <div className="card-body">
                        <RemoteTable
                          data={advance}
                          columns={columns}
                          defaultSorted={defaultSorted}
                          cellEditProps={cellEditProps}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { read_advance } = state.advance;
  const { read_currency } = state.currency;
  const { read_current_exchange_rate } = state.exchange_rate;

  const { message } = state.message;
  return {
    message,
    read_advance,
    read_currency,
    read_current_exchange_rate,
    user,
  };
}

export default connect(mapStateToProps)(ApprovalSettlementAdvance);
