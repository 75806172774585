import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { create } from "../../actions/sodiq/complain_form";
import ProgressBar from "react-bootstrap/ProgressBar";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      description: null,
      loading: false,
    };
  }

  hideModal = () => {
    this.setState({ isOpen: false });
  };

  hideLoading = () => {
    this.setState({ loading: false });
  };

  handleDescription = (value) => {
    this.setState({ description: value });
  };

  handleSubmit = () => {
    const { description } = this.state;
    if (description) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, submit entries",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          const user = JSON.parse(localStorage.getItem("user"));
          const payload = {
            username: user.username,
            name: user?.details?.hris_org_tree?.current_person?.person_name,
            url: window.location.href,
            description,
          };
          create(payload)
            .then(() => {
              this.setState({
                loading: false,
                isOpen: false,
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              });
            })
            .catch((err) => {
              this.showToats(err);
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Information", "Your data is safe :)", "error");
        }
      });
    } else {
      this.showToats("Please entries Description");
    }
  };

  showToats = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: "toats",
      onClose: () => this.setState({ submitted: false }),
    });
  };

  handleOpenComplain = () => {
    this.setState({ isOpen: true, description: null });
  };

  render() {
    let { loading, isOpen, description } = this.state;
    const modules = {
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        ["clean"],
      ],
    };

    const formats = [
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
    ];

    return (
      <>
        <ToastContainer />
        <footer className="main-footer">
          <strong>
            Copyright © 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.
          </strong>{" "}
          All rights reserved.
          <span
            aria-hidden="true"
            style={{
              cursor: "pointer",
              color: "#4d69fa",
              marginLeft: "10px",
              textDecoration: "underline",
            }}
            onClick={this.handleOpenComplain}
          >
            <i className="fas fa-bug"></i> Report bug
          </span>
          <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> 3.1.0
          </div>
        </footer>
        <Modal
          show={loading}
          size="xl"
          backdrop="static"
          onHide={this.hideLoading}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Container
            style={{ backgroundColor: "#007bff", borderRadius: "5px" }}
          >
            <Row>
              <Col xs={12} md={12}>
                <ProgressBar animated now={100} />
              </Col>
            </Row>
          </Container>
        </Modal>
        <Modal
          show={isOpen}
          size="lg"
          backdrop="static"
          onHide={this.hideModal}
          scrollable={true}
          centered
        >
          <ModalHeader closeButton className="bg-info text-white">
            <ModalTitle>Report Bug Form</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col xs={12} md={12}>
                  <label>Description</label>
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    value={description}
                    onChange={(e) => this.handleDescription(e)}
                  />
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="danger"
              className="float-start"
              onClick={this.hideModal}
            >
              <i className="fas fa-times" /> Cancel
            </Button>
            <Button
              variant="success"
              className="float-right"
              onClick={this.handleSubmit}
            >
              <i className="fas fa-save" /> Submit
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
