import {
  CREATE_ACCOUNT_ASSIGNMENT_CAT_SUCCESS,
  CREATE_ACCOUNT_ASSIGNMENT_CAT_FAIL,
  SET_MESSAGE,
  READ_ACCOUNT_ASSIGNMENT_CAT_SUCCESS,
  READ_ACCOUNT_ASSIGNMENT_CAT_FAIL,
  EDIT_ACCOUNT_ASSIGNMENT_CAT_SUCCESS,
  EDIT_ACCOUNT_ASSIGNMENT_CAT_FAIL,
  DELETE_ACCOUNT_ASSIGNMENT_CAT_SUCCESS,
  DELETE_ACCOUNT_ASSIGNMENT_CAT_FAIL,
} from "./types";
import AccountAssignmentCatService from "../services/account_assignment_cat.service";

export const addAccountAssignmentCat = (payload) => (dispatch) => {
  return AccountAssignmentCatService.create(payload).then(
    (response) => {
      dispatch({
        type: CREATE_ACCOUNT_ASSIGNMENT_CAT_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_ACCOUNT_ASSIGNMENT_CAT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editAccountAssignmentCat = (payload) => (dispatch) => {
  return AccountAssignmentCatService.edit(payload).then(
    (response) => {
      dispatch({
        type: EDIT_ACCOUNT_ASSIGNMENT_CAT_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_ACCOUNT_ASSIGNMENT_CAT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deleteAccountAssignmentCat = (payload) => (dispatch) => {
  return AccountAssignmentCatService.delete(payload).then(
    (response) => {
      dispatch({
        type: DELETE_ACCOUNT_ASSIGNMENT_CAT_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_ACCOUNT_ASSIGNMENT_CAT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readAccountAssignmentCat = () => (dispatch) => {
  return AccountAssignmentCatService.readAccountAssignmentCat().then(
    (response) => {
      dispatch({
        type: READ_ACCOUNT_ASSIGNMENT_CAT_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_ACCOUNT_ASSIGNMENT_CAT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
