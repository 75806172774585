import {    
  READ_DEPARTMENT_SUCCESS,
  READ_DEPARTMENT_FAIL, 
  SET_MESSAGE
 } from "./types";
import DepartmentService from "../services/department.service";
   

export const readDepartment = () => (dispatch) => {
  return DepartmentService.readDepartment().then(
    (response) => { 
      dispatch({
        type: READ_DEPARTMENT_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_DEPARTMENT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};  