import { 
  CREATE_UOM_SUCCESS,
  CREATE_UOM_FAIL,
  READ_UOM_SUCCESS,
  READ_UOM_FAIL,
  EDIT_UOM_SUCCESS,
  EDIT_UOM_FAIL,
  DELETE_UOM_SUCCESS,
  DELETE_UOM_FAIL,
} from "../actions/types";
 
const initialState = { 
  read_uom: null,
};

export default function uom(state = initialState, action) {
  const { type, payload } = action;

  switch (type) { 

    case CREATE_UOM_SUCCESS:
      return {
        ...state,
      };
    case CREATE_UOM_FAIL:
      return {
        ...state,
      };

    case EDIT_UOM_SUCCESS:
      return {
        ...state,
      };
    case EDIT_UOM_FAIL:
      return {
        ...state,
      };
    case READ_UOM_SUCCESS:  
      return {
        ...state,
        read_uom: payload,
      };
    case READ_UOM_FAIL:
      return {
        ...state,
        read_uom: null,
      }; 
    case DELETE_UOM_SUCCESS:
      return {
        ...state,
      };
    case DELETE_UOM_FAIL:
      return {
        ...state,
      }; 
    default:
      return state;
  }
}
