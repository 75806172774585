import {
  CREATE_EXCHANGE_RATE_SUCCESS,
  CREATE_EXCHANGE_RATE_FAIL,
  READ_EXCHANGE_RATE_SUCCESS,
  READ_EXCHANGE_RATE_FAIL,
  EDIT_EXCHANGE_RATE_SUCCESS,
  EDIT_EXCHANGE_RATE_FAIL,
  DELETE_EXCHANGE_RATE_SUCCESS,
  DELETE_EXCHANGE_RATE_FAIL,
  READ_CURRENT_EXCHANGE_RATE_SUCCESS,
  READ_CURRENT_EXCHANGE_RATE_FAIL,
  READ_ACTIVE_EXCHANGE_RATE_SUCCESS,
  READ_ACTIVE_EXCHANGE_RATE_FAIL,
  READ_RECENT_EXCHANGE_RATE_SUCCESS,
  READ_RECENT_EXCHANGE_RATE_FAIL,
  CLEAR_CURRENT_EXCHANGE_RATE,
} from "../actions/types";

const initialState = {
  read_exchange_rate: null,
  read_current_exchange_rate: null,
  read_active_exchange_rate: null,
  read_recent_exchange_rate: null,
};

export default function exchange_rate(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
      };
    case CREATE_EXCHANGE_RATE_FAIL:
      return {
        ...state,
      };

    case EDIT_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
      };
    case EDIT_EXCHANGE_RATE_FAIL:
      return {
        ...state,
      };
    case READ_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        read_exchange_rate: payload,
      };
    case READ_EXCHANGE_RATE_FAIL:
      return {
        ...state,
        read_exchange_rate: null,
      };
    case READ_CURRENT_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        read_current_exchange_rate: payload,
      };
    case READ_CURRENT_EXCHANGE_RATE_FAIL:
      return {
        ...state,
        read_current_exchange_rate: null,
      };
    case READ_ACTIVE_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        read_active_exchange_rate: payload,
      };
    case READ_ACTIVE_EXCHANGE_RATE_FAIL:
      return {
        ...state,
        read_active_exchange_rate: null,
      };
    case READ_RECENT_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        read_recent_exchange_rate: payload,
      };
    case READ_RECENT_EXCHANGE_RATE_FAIL:
      return {
        ...state,
        read_recent_exchange_rate: null,
      };
    case CLEAR_CURRENT_EXCHANGE_RATE:
      return {
        ...state,
        read_current_exchange_rate: null,
      };
    case DELETE_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
      };
    case DELETE_EXCHANGE_RATE_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
