import React, { Component } from "react";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Modal from "../components/Modal";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { addMenu, readMenu, editMenu ,deleteMenu} from "../actions/menu";
import { clearMessage } from "../actions/message";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.onChangeValueParent = this.onChangeValueParent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this); 
    this.handleSubmitEdit = this.handleSubmitEdit.bind(this); 
    this.GetActionFormat = this.GetActionFormat.bind(this); 
    this.deleteData = this.deleteData.bind(this);   
    this.toDetail = this.toDetail.bind(this);   
    this.state = {
      titleModal: "Add Menu",
      showModal: "show",
      displayModal: "none",
      modalVisible:false,
      collection: [],
      bodyModal: [
        { name: "name", placeholder: "Name", required: true, type: "text" },
        { name: "icon", placeholder: "Icon", required: true, type: "text" },
        { name: "link", placeholder: "Link", required: true, type: "text" },
      ],
      menu: [],
      columns: [
        {
          dataField: "name",
          text: "Name",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "icon",
          text: "Icon",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "link",
          text: "Link",
          filter: textFilter(),
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "20%" },
        },
      ],
      defaultSorted: [
        {
          dataField: "name",
          order: "asc",
        },
      ], 
      cellEditProps: {
        mode: "dbclick",       
        beforeSaveCell: (oldValue, newValue, row, column) => { 
          this.handleSubmitEdit(row._id,column.dataField,newValue)  }, 
      },
    };
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(readMenu()).then((response) => {
      this.setState({ menu: this.props.read_menu.data });
    });
  } 

  toDetail = (id)=>{
    const {history} = this.props;
    history.push(`/setting/menu/${id}`);
  }
  deleteData = (id)=>{
    const { dispatch, history } = this.props; 
    let collection = {_id:id}
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, delete entries",
      }).then((result) => {
        if (result.value) {
          dispatch(deleteMenu(collection))
            .then((response) => {
              toast.success("Data has been deleted successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-menu-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been deleted successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/setting/menu");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-menu-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }
  GetActionFormat = (cell, row) => {
    return (
      <div> 
        <button
          type="button"
          className="btn btn-info btn-sm ml-2 ts-buttom"
          size="sm" 
          onClick={()=>this.toDetail(row._id)}
        >
          <i className="fas fa-th"></i>
          Submenu
        </button>
        <button
          type="button"
          className="btn btn-danger btn-sm ml-2 ts-buttom"
          size="sm"
          data-id={row._id}
          onClick={()=>this.deleteData(row._id)}
        >
          <i className="fas fa-trash"></i>
          Delete
        </button>
      </div>
    );
  };
  isEmptyObject = (obj) => {
    return obj == null || !Object.keys(obj).length;
  };
  onChangeValueParent = (any) => {
    if (!this.isEmptyObject(any)) {
      let object = any
        .filter(function (element) {
          return element !== undefined;
        })
        .reduce((obj, item) => {
          obj[item.key] = item.value;
          return obj;
        }, {});
      this.setState({ collection: object });
    }
  };
  handleSubmit = (e) => {
    const { dispatch, history } = this.props;
    if (this.state.collection) {
      Swal.fire({
        title: "Information",
        text: "Are you sure?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          dispatch(addMenu(this.state.collection))
            .then((response) => {
              window.$("#modal-menu").modal("hide");
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-menu-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/setting/menu");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-menu-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  handleSubmitEdit = (id,column,value) => {
    console.log(id,column,value)
    let collection= {_id:id};
    collection[column]=value;
    const { dispatch, history } = this.props; 
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          dispatch(editMenu(collection))
            .then((response) => { 
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-edit-menu-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/setting/menu");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-edit-menu-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }; 
  render() {
    let {
      columns,
      defaultSorted,
      titleModal,
      showModal,
      bodyModal,
      displayModal, 
      cellEditProps
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />

          <Header />
          <SideMenu />
          <Modal
            id="modal-menu"
            size="md"
            title={titleModal}
            show={showModal}
            body={bodyModal}
            onChangeValueParent={this.onChangeValueParent}
            handleSubmit={this.handleSubmit}
            display={displayModal} 
          /> 
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">Menu</h3>
                        <div className="card-tools float-right">
                          <a
                            href="#!"
                            type="button"
                            className="text-dark btn btn-sm  btn-warning"
                            data-toggle="modal"
                            data-target="#modal-menu"
                          >
                            <i className="fas fa-plus-square" /> Menu
                          </a>
                        </div>
                      </div>
                      <div className="card-body">
                        <RemoteTable
                          data={this.state.menu}
                          columns={columns}
                          defaultSorted={defaultSorted}
                          cellEditProps={cellEditProps} 
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { getMenu, read_menu } = state.menu;
  const { message } = state.message;
  return {
    getMenu,
    message,
    read_menu,
  };
}

export default connect(mapStateToProps)(Menu);
