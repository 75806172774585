import {
  CREATE_COSTCENTER_SUCCESS,
  CREATE_COSTCENTER_FAIL,
  READ_COSTCENTER_SUCCESS,
  READ_COSTCENTER_FAIL,
  EDIT_COSTCENTER_SUCCESS,
  EDIT_COSTCENTER_FAIL,
  DELETE_COSTCENTER_SUCCESS,
  DELETE_COSTCENTER_FAIL,
} from "../actions/types";

const initialState = {
  read_costcenter: null,
};

export default function costcenter(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_COSTCENTER_SUCCESS:
      return {
        ...state,
        read_costcenter: payload,
      };
    case READ_COSTCENTER_FAIL:
      return {
        ...state,
        read_costcenter: null,
      };
    default:
      return state;
  }
}
