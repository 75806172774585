import {
  CREATE_ADVANCE_SUCCESS,
  CREATE_ADVANCE_FAIL,
  READ_ADVANCE_SUCCESS,
  READ_ADVANCE_FAIL,
  READ_CLAIM_ADVANCE_SUCCESS,
  READ_CLAIM_ADVANCE_FAIL,
  EDIT_ADVANCE_SUCCESS,
  EDIT_ADVANCE_FAIL,
  DELETE_ADVANCE_SUCCESS,
  DELETE_ADVANCE_FAIL,
  READY_FOR_UPPER_APPROVAL_ADVANCE_SUCCESS,
  READY_FOR_UPPER_APPROVAL_ADVANCE_FAIL,
  CLAIM_ADVANCE_SUCCESS,
  CLAIM_ADVANCE_FAIL,
  FILE_ADVANCE_SUCCESS,
  FILE_ADVANCE_FAIL,
  CONFIRM_ADVANCE_SUCCESS,
  CONFIRM_ADVANCE_FAIL,
} from "../actions/types";

const initialState = {
  read_advance: null,
  read_claim_advance: null,
  read_file_advance: null,
};

export default function advance(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_ADVANCE_SUCCESS:
      return {
        ...state,
      };
    case CREATE_ADVANCE_FAIL:
      return {
        ...state,
      };
      case CLAIM_ADVANCE_SUCCESS:
        return {
          ...state,
        };
      case CLAIM_ADVANCE_FAIL:
        return {
          ...state,
        };
    case FILE_ADVANCE_SUCCESS:
      return {
        ...state,
        read_file_advance: payload,
      };
    case FILE_ADVANCE_FAIL:
      return {
        ...state,
      };
    case CONFIRM_ADVANCE_SUCCESS:
      return {
        ...state,
      };
    case CONFIRM_ADVANCE_FAIL:
      return {
        ...state,
      };

    case EDIT_ADVANCE_SUCCESS:
      return {
        ...state,
      };
    case EDIT_ADVANCE_FAIL:
      return {
        ...state,
      };
    case READ_ADVANCE_SUCCESS:
      return {
        ...state,
        read_advance: payload,
      };
    case READ_ADVANCE_FAIL:
      return {
        ...state,
        read_advance: null,
      };
    case READ_CLAIM_ADVANCE_SUCCESS: 
      return {
        ...state,
        read_claim_advance: payload,
      };
    case READ_CLAIM_ADVANCE_FAIL:
      return {
        ...state,
        read_claim_advance: null,
      };
    case DELETE_ADVANCE_SUCCESS:
      return {
        ...state,
      };
    case DELETE_ADVANCE_FAIL:
      return {
        ...state,
      };
    case READY_FOR_UPPER_APPROVAL_ADVANCE_SUCCESS:
      return {
        ...state,
      };
    case READY_FOR_UPPER_APPROVAL_ADVANCE_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
