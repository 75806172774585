import {
  CREATE_PURCHASE_REQUEST_SUCCESS,
  CREATE_PURCHASE_REQUEST_FAIL,
  READ_PURCHASE_REQUEST_SUCCESS,
  READ_PURCHASE_REQUEST_FAIL,
  EDIT_PURCHASE_REQUEST_SUCCESS,
  EDIT_PURCHASE_REQUEST_FAIL,
  DELETE_PURCHASE_REQUEST_SUCCESS,
  DELETE_PURCHASE_REQUEST_FAIL,
  READY_FOR_UPPER_APPROVAL_PURCHASE_REQUEST_SUCCESS,
  READY_FOR_UPPER_APPROVAL_PURCHASE_REQUEST_FAIL,
  NOMINAL_APPROVAL_PURCHASE_REQUEST_SUCCESS,
  NOMINAL_APPROVAL_PURCHASE_REQUEST_FAIL,
} from "../actions/types";

const initialState = {
  read_purchase_request: null,
};

export default function purchase_request(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_PURCHASE_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case CREATE_PURCHASE_REQUEST_FAIL:
      return {
        ...state,
      };
    case EDIT_PURCHASE_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case EDIT_PURCHASE_REQUEST_FAIL:
      return {
        ...state,
      };
    case READ_PURCHASE_REQUEST_SUCCESS:
      return {
        ...state,
        read_purchase_request: payload,
      };
    case READ_PURCHASE_REQUEST_FAIL:
      return {
        ...state,
        read_purchase_request: null,
      };
    case DELETE_PURCHASE_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case DELETE_PURCHASE_REQUEST_FAIL:
      return {
        ...state,
      };
    case READY_FOR_UPPER_APPROVAL_PURCHASE_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case READY_FOR_UPPER_APPROVAL_PURCHASE_REQUEST_FAIL:
      return {
        ...state,
      };
    case NOMINAL_APPROVAL_PURCHASE_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case NOMINAL_APPROVAL_PURCHASE_REQUEST_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
