import {
  SET_ACTIVE_MENU,
  CLEAR_ACTIVE_MENU,
  SET_ACTIVE_DASHBOARD,
  GET_MENU_SUCCESS,
  GET_MENU_FAIL,
  CREATE_MENU_SUCCESS,
  CREATE_MENU_FAIL,
  READ_MENU_SUCCESS,
  READ_MENU_FAIL,
  EDIT_MENU_SUCCESS,
  EDIT_MENU_FAIL,
  DELETE_MENU_SUCCESS,
  DELETE_MENU_FAIL,
  CREATE_SUBMENU_SUCCESS,
  CREATE_SUBMENU_FAIL,
  READ_SUBMENU_SUCCESS,
  READ_SUBMENU_FAIL,
  EDIT_SUBMENU_SUCCESS,
  EDIT_SUBMENU_FAIL,
  DELETE_SUBMENU_SUCCESS,
  DELETE_SUBMENU_FAIL,
} from "../actions/types";

const menuStorage = JSON.parse(localStorage.getItem("menu"));
const activeMenu = JSON.parse(localStorage.getItem("active_menu"));
const initialMenu = menuStorage ? menuStorage : null;
const initialActiveMenu = activeMenu ? activeMenu : null;
const initialState = {
  isDashboardOpen: true,
  active_menu: initialActiveMenu,
  menu: initialMenu,
  read_menu: null,
  read_submenu: null,
};

export default function menu(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ACTIVE_MENU:
      return {
        ...state,
        active_menu: payload,
        isDashboardOpen: false,
      };

    case SET_ACTIVE_DASHBOARD:
      return {
        ...state,
        active_menu: "",
        isDashboardOpen: true,
      };
    case CLEAR_ACTIVE_MENU:
      return {
        ...state,
        active_menu: "",
      };
    case GET_MENU_SUCCESS:
      return {
        ...state,
        menu: payload,
      };

    case GET_MENU_FAIL:
      return {
        ...state,
        menu: null,
      };

    case CREATE_MENU_SUCCESS:
      return {
        ...state,
      };
    case CREATE_MENU_FAIL:
      return {
        ...state,
      };

    case EDIT_MENU_SUCCESS:
      return {
        ...state,
      };
    case EDIT_MENU_FAIL:
      return {
        ...state,
      };
    case READ_MENU_SUCCESS:
      return {
        ...state,
        read_menu: payload,
      };
    case READ_MENU_FAIL:
      return {
        ...state,
        read_menu: null,
      };
    case DELETE_MENU_SUCCESS:
      return {
        ...state,
      };
    case DELETE_MENU_FAIL:
      return {
        ...state,
      };
    
    case CREATE_SUBMENU_SUCCESS:
      return {
        ...state,
      };
    case CREATE_SUBMENU_FAIL:
      return {
        ...state,
      };

    case EDIT_SUBMENU_SUCCESS:
      return {
        ...state,
      };
    case EDIT_SUBMENU_FAIL:
      return {
        ...state,
      };

    case READ_SUBMENU_SUCCESS:
      return {
        ...state,
        read_submenu: payload,
      };
    case READ_SUBMENU_FAIL:
      return {
        ...state,
        read_submenu: null,
      };
    
    case DELETE_SUBMENU_SUCCESS:
      return {
        ...state,
      };
    case DELETE_SUBMENU_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
