import {
  SET_MESSAGE,
  READ_SERVICE_NUMBER_SUCCESS,
  READ_SERVICE_NUMBER_FAIL,
} from "./types";
import ServiceNumberService from "../services/servicenumber.service";

export const readServiceNumber = (query) => (dispatch) => {
  return ServiceNumberService.readServiceNumber(query).then(
    (response) => {
      dispatch({
        type: READ_SERVICE_NUMBER_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_SERVICE_NUMBER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
