import React from "react";
import { Route } from "react-router-dom";
import NoMatch from "../views/NoMatch";
import Submenu from "../views/Submenu";

function createComponent(name) {
  const componentName = name;
  const importedComponentModule = tryRequire(componentName)
    ? tryRequire(componentName).default
    : NoMatch;

  return importedComponentModule;
}
const tryRequire = (path) => {
  try {
    return require(`../views/${path}`);
  } catch (err) {
    return null;
  }
};
const AllRoute = (data, ...rest) => (
  <div>
    {data.getMenu && data.getMenu.length > 0
      ? data.getMenu.map((item, a) => {
          const details = item.details.map((dtl, i) => {
            return (
              <Route
                exact
                path={dtl.link}
                key={i}
                component={createComponent(dtl.page)}
              />
            );
          });
          return (
            <Route path={`${item.link}/:next`} key={a} component={NoMatch}>
              {details}
            </Route>
          );
        })
      : ""}
    {/* routing tambahan */}
    <Route exact path="/setting/menu/:id" component={Submenu} />
  </div>
);
export default AllRoute;
