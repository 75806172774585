import React, { Component } from "react";
import { NavLink } from "react-router-dom";
export const setActiveMenu = (menu) => {
  localStorage.setItem("active_menu", JSON.stringify(menu));
  return menu;
};
class Submenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMenu: {},
    };
  }

  componentDidMount() {
    const { active_menu } = this.props;

    if (active_menu) {
      this.setState({
        currentMenu: active_menu,
      });
    }
  }
  handleSubmenu = (name, link) => {
    setActiveMenu(name);
    this.props.activeMenu(name, link);
  };
  render() {
    const { menu_name, menu_link, name, link } = this.props;
    return (
      <li className="nav-item">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={link}
          onClick={() => this.handleSubmenu(menu_link, link)}
        >
          <i className="far fa-circle nav-icon" />
          <p>{name}</p>
        </NavLink>
      </li>
    );
  }
}

export default Submenu;
