import React, { Component, useMemo } from "react";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import thousandformat from "../helpers/thousanddelimiter";
import Excel from "exceljs";
import {
  addExchangeRate,
  readExchangeRate,
  editExchangeRate,
} from "../actions/exchange_rate";
import { readCurrencyWithoutRP } from "../actions/currency";
import { clearMessage } from "../actions/message";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";
import XLSX from "xlsx";
import Worksheet from "../components/Worksheet";
import ReactTooltip from "react-tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Switch from "react-switch";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";

import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import { parse } from "date-fns/esm";
import moment from "moment";
import { saveAs } from "file-saver";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);
const TIME_TO_EXPORT = process.env.REACT_APP_EXCHANGE_RATE_TIME_TO_EXPORT;
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map((x) => `.${x}`)
  .join(",");

const TableCustom = ({
  data,
  totalRows,
  curPage,
  perPage,
  loading,
  fetchData,
  handleSearch,
  action,
}) => {
  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    return fetchData(page, newPerPage);
  };

  const handleChange = (e) => {
    handleSearch({
      [e.target.name]: e.target.value,
    });
  };
  const GetThousandDelimiterFormat = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        {cell || String(cell) === "0" || !isNaN(parseFloat(cell))
          ? parseFloat(cell) % 1 != 0
            ? thousandformat(Number(cell).toFixed(2), ".", ".", ",")
            : thousandformat(cell, ".", ".", ",")
          : ""}
      </div>
    );
  };
  const columns = useMemo(() => {
    return [
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Date"
                onChange={handleChange}
                name="date"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => row.date,
        sortable: true,
        center: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Currency"
                onChange={handleChange}
                name="currency"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => row.currency,
        sortable: true,
        center: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Value"
                onChange={handleChange}
                name="value"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => GetThousandDelimiterFormat(row.value),
        sortable: true,
        right: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Selling Rate"
                onChange={handleChange}
                name="kurs_jual"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        cell: (row) => GetThousandDelimiterFormat(row.kurs_jual),
        sortable: true,
        classNames: ["text-align-end"],
        right: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Buying Rate"
                onChange={handleChange}
                name="kurs_beli"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        cell: (row) => GetThousandDelimiterFormat(row.kurs_beli),
        sortable: true,
        right: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Middle Rate"
                onChange={handleChange}
                name="kurs_tengah"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        cell: (row) => GetThousandDelimiterFormat(row.kurs_tengah),
        sortable: true,
        right: true,
      },
      {
        name: "Action",
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: action,
      },
    ];
  }, [data]);

  return (
    <DataTable
      persistTableHead={true}
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationDefaultPage={curPage}
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
};

TableCustom.propTypes = {
  data: PropTypes.instanceOf(Array),
  totalRows: PropTypes.number,
  curPage: PropTypes.number,
  perPage: PropTypes.number,
  loading: PropTypes.bool,
  fetchData: PropTypes.func,
  handleSearch: PropTypes.func,
  toggleStatus: PropTypes.func,
};

TableCustom.defaultProps = {
  data: [],
  totalRows: 0,
  curPage: 1,
  perPage: 10,
  loading: false,
  fetchData: () => {},
};
class ExchangeRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      showModal: false,
      showModalExchangeRate: false,
      showModalExport: false,
      readOnly: false,
      currencyreadOnly: false,
      collection: [],
      select_items: [],
      data: [],
      errors: {},
      id: null,
      requester: null,
      currency: null,
      value: 1,
      kurs_jual: null,
      kurs_beli: null,
      date: null,
      error_message_filename: null,
      error_header: null,
      error_data: null,
      error_date: null,
      error_currency: null,
      error_value: null,
      loading: false,
      excel: null,
      worksheet: [],
      worksheet_name: true,
      isEnableTimeValid: false,
      columns: [
        {
          dataField: "date",
          text: "Date",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "currency",
          text: "Currency",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "value",
          text: "Value",
          filter: textFilter(),
          sort: true,
          style: {
            textAlign: "end",
          },
          formatter: this.GetThousandDelimiterFormat,
        },
        {
          dataField: "kurs_jual",
          text: "Selling Rate",
          filter: textFilter(),
          sort: true,
          style: {
            textAlign: "end",
          },
          formatter: this.GetThousandDelimiterFormat,
        },
        {
          dataField: "kurs_beli",
          text: "Buying Rate",
          filter: textFilter(),
          sort: true,
          style: {
            textAlign: "end",
          },
          formatter: this.GetThousandDelimiterFormat,
        },
        {
          dataField: "kurs_tengah",
          text: "Middle Rate",
          filter: textFilter(),
          sort: true,
          style: {
            textAlign: "end",
          },
          formatter: this.GetThousandDelimiterFormat,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      defaultSorted: [
        {
          dataField: "date",
          order: "desc",
        },
      ],

      count_list: 0,
      cellEditProps: {},
      sizePerPage: 10,
      page: 1,
      search: "",
      loading_fetch_data: false,
    };
  }
  componentDidMount() {
    this.reloadDataBackend();
  }
  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      currency: null,
      value: 1,
      kurs_jual: null,
      kurs_beli: null,
      date: null,
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };
  onModalExportHide = () => {
    this.setState({
      showModalExport: !this.state.showModalExport,
      worksheet: [],
      error_header: null,
      error_data: null,
      error_currency: null,
      error_date: null,
      error_value: null,
    });
  };
  onModalLoadingHide = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };
  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };

  toDetail = (
    id,
    currency,
    value,
    kurs_jual,
    kurs_beli,
    date,
    currencyreadOnly
  ) => {
    this.setState(
      {
        id: id,
        currency: currency,
        value: value,
        kurs_jual: kurs_jual,
        kurs_beli: kurs_beli,
        date: date,
        currencyreadOnly: currencyreadOnly,
      },
      () => {
        this.toggle();
      }
    );
  };

  GetActionFormat = (row) => {
    let datenow = new Date();
    let is_noneditable = true;
    let date = row.date.split("-");
    let date_baru = new Date(date[0], date[1] - 1, date[2]);
    let currencyreadOnly = true;
    if (dateFormat(datenow, "yyyy-mm-dd") === row.date) {
      is_noneditable = false;
    }
    return !is_noneditable ? (
      <div>
        <button
          key={row._id}
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetail(
              row._id,
              row.currency,
              row.value,
              row.kurs_jual,
              row.kurs_beli,
              date_baru,
              is_noneditable,
              currencyreadOnly
            )
          }
        >
          <i className="fas fa-edit" key={row._id}></i>
          &nbsp;Edit
        </button>
      </div>
    ) : (
      ""
    );
  };
  GetThousandDelimiterFormat = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        {cell || String(cell) === "0" || !isNaN(parseFloat(cell))
          ? parseFloat(cell) % 1 != 0
            ? thousandformat(Number(cell).toFixed(2), ".", ".", ",")
            : thousandformat(cell, ".", ".", ",")
          : ""}
      </div>
    );
  };
  isEmptyObject = (obj) => {
    return obj == null || !Object.keys(obj).length;
  };
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  onChangeHandlerFile = (event) => {
    this.setState({
      selectedFile: event.target.files,
      loading: true,
      error_message_filename: null,
    });
    const files = event.target.files;
    if (files && files[0]) {
      this.handleFile(files[0]);
    }
    this.setState({
      loading: false,
      error_header: null,
      error_data: null,
      error_currency: null,
      error_date: null,
      error_value: null,
    });
  };

  reloadDataBackend = (page = 1, sizePerPage = 10) => {
    this.setState({
      loading_fetch_data: true,
    });
    const { dispatch, user } = this.props;
    const { search } = this.state;
    const query = {
      page: page,
      sizePerPage: sizePerPage,
      search: search,
    };
    dispatch(readExchangeRate(query))
      .then((response) => {
        this.setState({
          data: this.props.read_exchange_rate.data.foundData,
          count_list: this.props.read_exchange_rate.data.countData,
          loading_fetch_data: false,
        });
      })
      .finally(() => {
        this.setState({
          loading_fetch_data: false,
        });
      });

    dispatch(readCurrencyWithoutRP()).then((response) => {
      this.setState({ select_items: this.props.read_currency_without_rp.data });
    });
    this.setState({
      requester: user.username,
    });
  };

  handleFile = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        cellDates: true,
        cellText: false,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */

      let worksheet = [];
      wb.SheetNames.map((e, i) => {
        const wsname = wb.SheetNames[i];
        const result_worksheet = this.setting_worksheet(
          wb.Sheets[wsname],
          wsname
        );
        if (result_worksheet) worksheet.push(result_worksheet);
      });
      let header_check = [
        "date",
        "currency",
        "value",
        "selling_rate",
        "buying_rate",
        "middle_rate",
      ];
      //check header format
      worksheet.forEach((item) => {
        if (item.cols.length > 0 && item.cols.length === header_check.length) {
          for (let i = 0; i < item.cols.length; i++) {
            let header = item.cols[i];
            if (header.name != header_check[i]) {
              this.setState({
                error_header:
                  "Please upload excel based on the given template (Click Button Download Template)",
              });
            }
          }
        } else {
          this.setState({
            error_header:
              "Please upload excel based on the given template. (Click Button Download Template)",
          });
        }

        //check data
        if (item.data.length > 0) {
          for (let i = 0; i < item.data.length; i++) {
            let row = item.data[i];

            //check data filled
            for (let y = 0; y < row.length; y++) {
              let item = row[y];
              if (item == "" || item == null || item == undefined) {
                this.setState({
                  error_data: "Please fill all the columns",
                });
                break;
              }
            }

            //check date format
            let regex_date =
              /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
            if (row[0] && !row[0].match(regex_date)) {
              this.setState({
                error_date:
                  "Date format should be YYYY-MM-DD ! (ex. 2021-12-31)",
              });
              break;
            }

            //check currency
            let regex_alphabet = /^[A-Za-z]+$/;
            if (row[1] && !row[1].match(regex_alphabet)) {
              this.setState({
                error_currency:
                  "Currency should be alphabetic characters (A-Z)",
              });
              break;
            }

            //check value
            let regex_numerical = /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/;
            if (row[2] && !row[2].match(regex_numerical)) {
              this.setState({
                error_value:
                  "Value/Selling Rate/Buying Rate/Middle Rate/ should be numeric or decimal characters (0-1)",
              });
              break;
            }
            if (row[3] && !row[3].match(regex_numerical)) {
              this.setState({
                error_value:
                  "Value/Selling Rate/Buying Rate/Middle Rate/ should be numeric or decimal characters (0-1)",
              });
              break;
            }
            if (row[4] && !row[4].match(regex_numerical)) {
              this.setState({
                error_value:
                  "Value/Selling Rate/Buying Rate/Middle Rate/ should be numeric or decimal characters (0-1)",
              });
              break;
            }
            if (row[5] && !row[5].match(regex_numerical)) {
              this.setState({
                error_value:
                  "Value/Selling Rate/Buying Rate/Middle Rate/ should be numeric or decimal characters (0-1)",
              });
              break;
            }
          }
        } else {
          this.setState({
            error_data: "Please fill all the columns",
          });
        }
      });

      const excel = {
        worksheet: worksheet,
        filename: file.name,
      };
      this.setState({
        worksheet: worksheet,
        loading: false,
        excel: excel,
      });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };
  //setting worksheet
  setting_worksheet = (ws, wsname) => {
    /* Convert array of arrays */

    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
      raw: false,
      dateNF: "YYYY-MM-DD",
    });
    if (!data) return;
    /* Update state */
    if (!ws["!ref"]) return;
    const cols = this.make_cols(ws["!ref"]);
    const result = this.exists_only(data, cols);
    const custom_cols = this.make_custom_cols(result.header);

    return {
      worksheet: wsname,
      data: result.detail,
      cols: custom_cols,
    };
  };
  exists_only = (data, cols) => {
    let header = [];
    let detail = [];
    let clone_data = [...data];

    if (clone_data) {
      clone_data.map((r, i) => {
        let z = [...r];
        let splice = z.splice(0, 2);
        if (i === 0) {
          let header_concat = splice.concat(z);
          header.push(header_concat);
        }
        //
        let cleanArray = z.filter(function (el) {
          return true && el != null && el != "";
        });

        if (i !== 0 && cleanArray.length > 2) {
          let array_concat = splice.concat(z);
          detail.push(array_concat);
        }
      });
    }

    return { header: header, detail: detail };
  };
  /* generate an array of column objects */
  make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i)
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  };
  make_custom_cols = (obj) => {
    let o = [];
    if (obj) {
      obj[0].map((b, d) => {
        o.push({ name: b, key: d });
      });
    }
    return o;
  };
  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { dispatch, history } = this.props;
    const { requester, worksheet } = this.state;

    let collection = {
      requester: requester,
      worksheet: worksheet,
    };
    if (requester && worksheet) {
      if (!this.checkTimeToExport()) {
        Swal.fire({
          title: "Overtime, want to save ?",
          text: "Scheduled time is at " + TIME_TO_EXPORT + ".",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            collection["is_overtime"] = true;
            this.saveExchangeRate(collection);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Your data is safe :)", "error");
          }
        });
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "Please check your entries !",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            collection["is_overtime"] = false;
            this.saveExchangeRate(collection);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Your data is safe :)", "error");
          }
        });
      }
    } else {
      this.toggleLoading();
    }
  };
  saveExchangeRate = (collection) => {
    const { dispatch, history } = this.props;
    this.toggleLoading();
    dispatch(addExchangeRate(collection))
      .then((response) => {
        this.toggleLoading();
        this.onModalExportHide();
        toast.success("Data has been saved successfully", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "customId-add-exchange-rate-success",
          onClose: () => dispatch(clearMessage()),
        });
        Swal.fire({
          title: "Information",
          icon: "success",
          text: "Data has been saved successfully",
          showConfirmButton: false,
          timer: 2000,
        }).then((result) => {
          history.push("/input/exchange-rate");
          this.reloadDataBackend();
        });
      })
      .catch((err) => {
        this.toggleLoading();
        toast.error(this.props.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "customId-add-exchange-rate-failed",
          onClose: () => dispatch(clearMessage()),
        });
      });
  };
  handleSubmitEdit = () => {
    this.setState({ submitted: true });
    const { id, currency, value, kurs_jual, kurs_beli, date } = this.state;

    if (!this.validateForm(currency, value, kurs_jual, kurs_beli, date)) {
      return;
    }
    const { dispatch, history } = this.props;
    let collection = {
      _id: id,
      currency: currency,
      value: value,
      kurs_jual: kurs_jual,
      kurs_beli: kurs_beli,
      date: date,
    };
    if (id && currency && value && kurs_jual && kurs_beli && date) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          dispatch(editExchangeRate(collection))
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-edit-exchange-rate-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/exchange-rate");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-edit-exchange-rate-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  validateForm = (currency, value, kurs_jual, kurs_beli, date) => {
    let errors = this.state.errors;
    if (!currency || currency == null) {
      errors.currency = "This is field required";
    }
    if (!value) {
      errors.value = "This is field required";
    }
    if (!kurs_jual) {
      errors.kurs_jual = "This is field required";
    }
    if (!kurs_beli) {
      errors.kurs_beli = "This is field required";
    }
    if (!date) {
      errors.date = "This is field required";
    }

    return errors;
  };

  checkTimeToExport = () => {
    //get from env, convert to int
    const splittedTime = TIME_TO_EXPORT.split(":");
    const hourStr = splittedTime[0];
    const minuteStr = splittedTime[1];
    const hour = parseInt(hourStr);
    const minute = parseInt(minuteStr);

    //start at midnight => 0 minutes
    const start = 0;

    //end at 08:30 => convert to minutes
    const end = hour * 60 + minute;
    const date = new Date();
    const now = date.getHours() * 60 + date.getMinutes();
    return start <= now && now <= end;
  };

  handleDate = (date) => {
    this.setState({
      date: date,
    });
  };
  DownloadTemplate = async () => {
    const fileName = `exchange-rates`;
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(moment().format("YYYY"));
    worksheet.addRow([
      "date",
      "currency",
      "value",
      "selling_rate",
      "buying_rate",
      "middle_rate",
    ]);

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  };

  changeFilter = (data) => {
    this.setState((prevState) => ({
      search: { ...prevState.search, ...data },
    }));

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.reloadDataBackend();
    }, 250);
  };
  render() {
    let {
      columns,
      defaultSorted,
      showModal,
      showModalExport,
      cellEditProps,
      select_items,
      data,
      id,
      currency,
      value,
      kurs_jual,
      kurs_beli,
      date,
      errors,
      submitted,
      currencyreadOnly,
      error_message_filename,
      error_header,
      error_data,
      error_currency,
      error_date,
      error_value,
      loading,
      excel,
      worksheet,
      isEnableTimeValid,
      is,
      count_list,
      sizePerPage,
      page,
      loading_fetch_data,
    } = this.state;

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <ReactTooltip place="top" type="dark" effect="float" />
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Modal
            show={showModalExport}
            size="xl"
            backdrop="static"
            onHide={this.onModalExportHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader closeButton className="bg-info text-white">
              Import Exchange Rate
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="card-body">
                      <label htmlFor="file">Upload Excel * </label>
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        name="file"
                        placeholder="File"
                        multiple
                        onChange={this.onChangeHandlerFile}
                        accept={SheetJSFT}
                        required
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="px-5 card-body">
                      {error_header && (
                        <div className="p-1 bg-danger text-white text-center rounded font-weight-bold">
                          {error_header}
                        </div>
                      )}
                      &nbsp;
                      {error_data && (
                        <div className="p-1 bg-danger text-white text-center rounded font-weight-bold">
                          {error_data}
                        </div>
                      )}
                      &nbsp;
                      {error_currency && (
                        <div className="p-1 bg-danger text-white text-center rounded font-weight-bold">
                          {error_currency}
                        </div>
                      )}
                      &nbsp;
                      {error_date && (
                        <div className="p-1 bg-danger text-white text-center rounded font-weight-bold">
                          {error_date}
                        </div>
                      )}
                      &nbsp;
                      {error_value && (
                        <div className="p-1 bg-danger text-white text-center rounded font-weight-bold">
                          {error_value}
                        </div>
                      )}
                      {worksheet.length > 0 &&
                        !error_header &&
                        !error_data &&
                        !error_currency &&
                        !error_value &&
                        !error_date && (
                          <div className="bg-success text-white text-center rounded font-weight-bold">
                            <i className="fas fa-check"> Ready to export</i>
                          </div>
                        )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <Worksheet worksheet={worksheet} />
                    {worksheet.length > 0 &&
                      !error_header &&
                      !error_data &&
                      !error_currency &&
                      !error_value &&
                      !error_date && (
                        <div className="float-right">
                          <Button
                            variant="danger"
                            onClick={this.onModalExportHide}
                          >
                            {" "}
                            <i className="fas fa-times" /> Cancel
                          </Button>
                          &nbsp;
                          <Button variant="success" onClick={this.handleSubmit}>
                            {" "}
                            <i className="fas fa-save" /> Submit
                          </Button>
                        </div>
                      )}
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>
                  {id ? "Update" : "Create"} Exchange Rate to Rp
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="date">Date *</label>
                        <DatePicker
                          selected={date}
                          startDate={date}
                          maxDate={
                            date
                              ? subDays(new Date(date), 0)
                              : subDays(new Date(), 0)
                          }
                          minDate={
                            date
                              ? subDays(new Date(date), 0)
                              : subDays(new Date(), 0)
                          }
                          disabledKeyboardNavigation
                          onChange={this.handleDate}
                          name="date"
                          dateFormat="yyyy-MM-dd"
                          className="form-control form-control-sm"
                          required
                        />
                        {errors.date && (
                          <div className="invalid-feedback">{errors.date}</div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="value">Value *</label>
                        <input
                          type="number"
                          step="0.001"
                          className="form-control form-control-sm"
                          name="value"
                          placeholder="Value"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={value}
                          min="0"
                        />
                        {errors.value && (
                          <div className="invalid-feedback">{errors.value}</div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div
                        className={
                          errors.currency != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="currency">Currency *</label>

                        <select
                          className={
                            errors.currency != "This field is required"
                              ? "form-control form-control-sm "
                              : "form-control form-control-sm  has-error has-feedback"
                          }
                          name="currency"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={currency}
                          readOnly={currencyreadOnly}
                          required
                        >
                          <option value="">Choose</option>
                          {select_items &&
                            select_items.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.currency && (
                          <div className="invalid-feedback">
                            {errors.currency}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="kurs_jual">Selling Rate *</label>
                        <input
                          type="number"
                          step="0.001"
                          className="form-control form-control-sm"
                          name="kurs_jual"
                          placeholder="Selling Rate"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={kurs_jual}
                          min="0"
                        />
                        {errors.kurs_jual && (
                          <div className="invalid-feedback">
                            {errors.kurs_jual}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="kurs_beli">Buying Rate *</label>
                        <input
                          type="number"
                          step="0.001"
                          className="form-control form-control-sm"
                          name="kurs_beli"
                          placeholder="Buying Rate"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={kurs_beli}
                          min="0"
                        />
                        {errors.kurs_beli && (
                          <div className="invalid-feedback">
                            {errors.kurs_beli}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter className="justify-content-between">
                <Button variant="danger" onClick={this.toggle}>
                  <i className="fas fa-times" /> Cancel
                </Button>
                {id ? (
                  <Button variant="success" onClick={this.handleSubmitEdit}>
                    <i className="fas fa-save" /> Update
                  </Button>
                ) : (
                  <Button variant="success" onClick={this.handleSubmit}>
                    <i className="fas fa-save" /> Submit
                  </Button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">Exchange Rate</h3>
                        <div className="card-tools float-right">
                          <span
                            data-tip={
                              "You should export before " +
                              TIME_TO_EXPORT +
                              " WIB"
                            }
                            data-tip-disable={this.checkTimeToExport()}
                          >
                            <Button
                              variant={
                                this.checkTimeToExport()
                                  ? "success"
                                  : "secondary"
                              }
                              onClick={() => {
                                this.onModalExportHide();
                              }}
                            >
                              <span className="mr-2">
                                <i className="fas fa-plus-square" />
                              </span>
                              Import Exchange Rate
                            </Button>
                          </span>
                          &nbsp;
                          <Button
                            color="warning"
                            data-tip-disable={true}
                            onClick={() => {
                              this.DownloadTemplate();
                            }}
                          >
                            {" "}
                            <span className="mr-2">
                              <i className="fas fa-download" />
                            </span>
                            Download Template
                          </Button>
                        </div>
                      </div>
                      <div className="card-body">
                        <TableCustom
                          data={data}
                          totalRows={count_list}
                          perPage={sizePerPage}
                          curPage={page}
                          fetchData={this.reloadDataBackend}
                          loading={loading_fetch_data}
                          action={this.GetActionFormat}
                          handleSearch={this.changeFilter}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { read_exchange_rate } = state.exchange_rate;
  const { read_currency_without_rp } = state.currency;
  const { message } = state.message;
  return {
    message,
    read_exchange_rate,
    read_currency_without_rp,
    user,
  };
}

export default connect(mapStateToProps)(ExchangeRate);
