import React, { Component, useMemo, useState } from "react";

import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  addBudget,
  readBudget,
  editBudget,
  deleteBudget,
  upperApprovalBudget,
  readRemainingBudgetAllMonth,
} from "../actions/budget";
import { readCurrency } from "../actions/currency";
import { readDepartment } from "../actions/department";
import {
  readCurrentExchangeRate,
  readActiveExchangeRate,
} from "../actions/exchange_rate";
import { clearMessage } from "../actions/message";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import Tooltip from "react-bootstrap/Tooltip";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import XLSX from "xlsx";
import OutTable from "../components/OutTable";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import PropTypes from "prop-types";

/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map((x) => `.${x}`)
  .join(",");

const TableCustom = ({
  data,
  totalRows,
  curPage,
  perPage,
  loading,
  fetchData,
  handleSearch,
  toggleStatus,
  action,
}) => {
  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    return fetchData(page, newPerPage);
  };

  const handleChange = (e) => {
    handleSearch({
      [e.target.name]: e.target.value,
    });
  };

  const columns = useMemo(() => {
    return [
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Creator"
                onChange={handleChange}
                name="creator"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => row.creator,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Year"
                onChange={handleChange}
                name="year"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => row.year,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Department"
                onChange={handleChange}
                name="department"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => row.department,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Excel"
                onChange={handleChange}
                name="excel"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        cell: (row) => row.excel.filename,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Currency"
                onChange={handleChange}
                name="currency"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        cell: (row) => row.currency,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Status"
                onChange={handleChange}
                name="status"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        cell: (row) => {
          return (
            <div>
              <button
                type="button"
                className="btn btn-sm ml-2 mb-2 ts-buttom"
                size="sm"
                onClick={toggleStatus}
              >
                <i className="fas fa-search-location"></i>
                &nbsp;
                {row.status === "Need Approval Finance Staff"
                  ? "Need Approval FAC Controller"
                  : row.status}
              </button>
            </div>
          );
        },
        sortable: true,
      },
      {
        name: "Action",
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: action,
      },
    ];
  }, [data]);

  return (
    <DataTable
      persistTableHead={true}
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationDefaultPage={curPage}
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
};

TableCustom.propTypes = {
  data: PropTypes.instanceOf(Array),
  totalRows: PropTypes.number,
  curPage: PropTypes.number,
  perPage: PropTypes.number,
  loading: PropTypes.bool,
  fetchData: PropTypes.func,
  handleSearch: PropTypes.func,
  toggleStatus: PropTypes.func,
};

TableCustom.defaultProps = {
  data: [],
  totalRows: 0,
  curPage: 1,
  perPage: 10,
  loading: false,
  fetchData: () => {},
};

class Budget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_detail: null,
      search_detail_remain: null,
      search_by_remain: null,
      department: null,
      id_department: null,
      id_department_submit: null,
      show_department: false,
      list_department: [],
      loading: false,
      submitted: false,
      showModal: false,
      showModalBudget: false,
      showModalFlow: false,
      readOnly: false,
      collection: [],
      select_items: [],
      select_items_search_by: [
        { value: "GL Account" },
        { value: "GL Description" },
        { value: "Category" },
      ],
      data: [],
      remaining_budget: [],
      remaining_budget_origin: [],
      data_formatted: [],
      filename: null,
      cols: [],
      list_data: [],
      list_data_table: [],
      errors: {},
      id: null,
      selectedFile: null,
      creator: null,
      name_creator: null,
      currency: null,
      excel: null,
      exchange_rate_to_rp: "1",
      ready_for_approval: false,
      active_exchange_rate: null,
      error_message_exchange_rate: null,
      error_message_filename: null,
      cellEditProps: {},
      creatorFilter: "",
      yearFilter: "",
      departmentFilter: "",
      excelFilter: "",
      currencyFilter: "",
      statusFilter: "",
      search: {},

      count_list: 0,
      sizePerPage: 10,
      loading_fetch_data: false,
      page: 1,
    };
    this.onGetDetail = this.onGetDetail.bind(this);
    this.timer = null;
  }

  componentDidMount() {
    const { dispatch, user } = this.props;

    dispatch(readDepartment()).then((response) => {
      this.setState({
        list_department: this.props.read_department.data,
      });
    });

    dispatch(readActiveExchangeRate()).then((response) => {
      this.setState({
        active_exchange_rate: this.props.read_active_exchange_rate.data,
      });
    });
    dispatch(readCurrency()).then((response) => {
      this.setState({ select_items: this.props.read_currency.data });
    });

    this.setState(
      {
        creator: user.username,
        department: user.details.hris_org_tree.current_person
          ? user.details.hris_org_tree.current_person.nama_department
          : null,
        email_creator: user.details.hris_org_tree.current_person
          ? user.details.hris_org_tree.current_person.email
          : null,
        name_creator: user.details.hris_org_tree.current_person
          ? user.details.hris_org_tree.current_person.person_name
          : null,
        id_department: user.details.hris_org_tree.current_person
          ? user.details.hris_org_tree.current_person.department_id
          : null,
      },
      () => {
        this.reloadData();
      }
    );
  }
  onModalFlowHide = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };
  toggleFlowBudget = (row) => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
      department: row.department,
    });
  };

  GetCreatorFormat = (cell, row) => {
    return (
      <div>
        {row.name_creator} ({row.creator})
      </div>
    );
  };
  GetStatusFormat = (cell, row) => {
    return (
      <div>
        <button
          type="button"
          className="btn btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => this.toggleFlowBudget(row)}
        >
          <i className="fas fa-search-location"></i>
          &nbsp;
          {row.status === "Need Approval Finance Staff"
            ? "Need Approval FAC Controller"
            : row.status}
        </button>
      </div>
    );
  };
  GetActionFormat = (row) => {
    let ready_for_approval = false;
    if (
      row.status === "Submitted" &&
      row.currency !== "Rp" &&
      row.exchange_rate_to_rp === "1"
    ) {
      ready_for_approval = false;
    }
    if (
      row.status === "Submitted" &&
      row.currency === "Rp" &&
      row.exchange_rate_to_rp === "1"
    ) {
      ready_for_approval = true;
    }
    if (
      row.status === "Submitted" &&
      row.currency !== "Rp" &&
      row.exchange_rate_to_rp !== "1"
    ) {
      ready_for_approval = true;
    }
    if (row.isRejected) {
      ready_for_approval = true;
    }
    return row.status === "Submitted" || row.isRejected ? (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetailBudget(
              row._id,
              row.excel,
              row.currency,
              row.exchange_rate_to_rp,
              ready_for_approval,
              row.status,
              row.year
            )
          }
        >
          <i className="fas fa-th"></i>
          &nbsp;Detail
        </button>
        <button
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetail(
              row._id,
              row.excel,
              row.currency,
              row.exchange_rate_to_rp
            )
          }
        >
          <i className="fas fa-edit"></i>
          &nbsp;Edit
        </button>
        <button
          type="button"
          className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          data-id={row._id}
          onClick={() => this.deleteData(row._id)}
        >
          <i className="fas fa-trash"></i>
          &nbsp;Delete
        </button>
      </div>
    ) : (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetailBudget(
              row._id,
              row.excel,
              row.currency,
              row.exchange_rate_to_rp,
              ready_for_approval,
              row.status,
              row.year
            )
          }
        >
          <i className="fas fa-th"></i>
          &nbsp;Detail
        </button>
      </div>
    );
  };
  onModalHide = () => {
    const { user } = this.props;
    this.setState({
      department: user.details.hris_org_tree.current_person.nama_department,
      id: null,
      submitted: false,
      selectedFile: null,
      excel: null,
      data: null,
      cols: null,
      currency: null,
      exchange_rate_to_rp: "1",
      showModal: !this.state.showModal,
      readOnly: false,
    });
  };
  onModalBudgetHide = () => {
    const { user } = this.props;
    this.setState({
      department: user.details.hris_org_tree.current_person.nama_department,
      id: null,
      submitted: false,
      excel: null,
      data: null,
      cols: null,
      currency: null,
      exchange_rate_to_rp: "1",
      showModalBudget: !this.state.showModalBudget,
      readOnly: false,
      ready_for_approval: false,
      error_message_exchange_rate: null,
      search_by: null,
      search_by_remain: null,
      search_detail_remain: null,
      search_detail: null,
    });
  };

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  toggleBudget = () => {
    this.setState({
      showModalBudget: !this.state.showModalBudget,
    });
  };

  toDetail = (id, excel, currency, exchange_rate_to_rp) => {
    this.setState(
      {
        id: id,
        data: excel.data,
        cols: excel.cols,
        currency: currency,
        exchange_rate_to_rp: exchange_rate_to_rp,
      },
      () => {
        this.toggle();
      }
    );
  };

  toDetailBudget = (
    id,
    excel,
    currency,
    exchange_rate_to_rp,
    ready_for_approval,
    status,
    year
  ) => {
    const { dispatch } = this.props;
    const { active_exchange_rate, creator, department } = this.state;

    let arrayHeader = [];
    excel.cols.map((item) => {
      arrayHeader.push(item["name"]);
    });
    let dataFormatted = [arrayHeader, ...excel.data];
    this.setState({
      data_formatted: dataFormatted,
      filename: excel.filename.split(".")[0],
    });

    if (status === "Submitted" && currency !== "Rp") {
      return dispatch(readCurrentExchangeRate(currency, "last=true"))
        .then((response) => {
          let current_rate = this.props.read_current_exchange_rate.data[
            active_exchange_rate.value
          ]
            ? this.props.read_current_exchange_rate.data[
                active_exchange_rate.value
              ]
            : exchange_rate_to_rp;

          let cek_ready = false;
          if (currency !== "Rp" && current_rate !== "1") {
            cek_ready = true;
          }

          if (currency === "Rp") {
            cek_ready = true;
          }

          this.setState(
            {
              id: id,
              excel: excel,
              data: excel.data,
              cols: excel.cols,
              currency: currency,
              exchange_rate_to_rp: current_rate,
              readOnly: true,
              ready_for_approval: cek_ready,
            },
            () => {
              this.toggleBudget();
            }
          );
        })
        .catch((err) => {
          this.setState(
            {
              id: id,
              excel: excel,
              data: excel.data,
              cols: excel.cols,
              currency: currency,
              exchange_rate_to_rp: exchange_rate_to_rp,
              readOnly: true,
              ready_for_approval: false,
              error_message_exchange_rate: this.props.message,
            },
            () => {
              this.toggleBudget();
            }
          );
          toast.error(this.props.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: "customId-read-current-exchange-rate-failed",
            onClose: () => dispatch(clearMessage()),
          });
          this.setState({
            loading: false,
          });
        });
    }
    const queryString = `year=${year}&creator=${creator}&department=${department}`;
    dispatch(readRemainingBudgetAllMonth(queryString)).then(() => {
      this.setState({
        remaining_budget_origin: this.props.read_remaining_budget.data,
        remaining_budget: this.props.read_remaining_budget.data,
      });
    });

    return this.setState(
      {
        id: id,
        excel: excel,
        data: excel.data,
        cols: excel.cols,
        currency: currency,
        exchange_rate_to_rp: exchange_rate_to_rp,
        readOnly: true,
        ready_for_approval: ready_for_approval,
      },
      () => {
        this.toggleBudget();
      }
    );
  };
  onChangeHandlerFile = (event) => {
    this.setState({
      selectedFile: event.target.files,
      loading: true,
      error_message_filename: null,
    });
    const files = event.target.files;
    if (files && files[0]) {
      this.handleFile(files[0]);
    }
    this.setState({
      loading: false,
    });
  };

  handleFile = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      const cols = this.make_cols(ws["!ref"]);
      const result = this.exists_only(data, cols);
      const custom_cols = this.make_custom_cols(result.header);

      const { id_department, department } = this.state;
      if (!file.name.match("^[A-Z]+_[0-9]+.[A-Za-z]*$")) {
        this.setState({
          error_message_filename: "Format filename is wrong!",
        });
      }
      const year_now = new Date();
      const excel = {
        data: result.detail,
        cols: custom_cols,
        filename: file.name,
      };
      let filename_dept = file.name.split("_");
      let filename_year =
        filename_dept.length > 1 ? filename_dept[1].split(".") : null;
      let dept_name =
        id_department === 12 || id_department === 24
          ? filename_dept[0]
          : department;

      this.setState(
        {
          data: result.detail,
          cols: custom_cols,
        },
        () => {
          !file.name.match("^[A-Z]+_[0-9]+.[A-Za-z]*$")
            ? id_department === 12 || id_department === 24
              ? this.setState({
                  loading: false,
                  excel: excel,
                  department: null,
                  show_department: true,
                  year: year_now.getFullYear(),
                })
              : this.setState(
                  {
                    loading: false,
                    excel: excel,
                    show_department: false,
                    year: year_now.getFullYear(),
                  },
                  () => {
                    this.setDept(dept_name);
                  }
                )
            : this.setState(
                {
                  loading: false,
                  excel: excel,
                  show_department: false,
                  department: dept_name,
                  year: filename_year[0],
                },
                () => {
                  this.setDept(dept_name);
                }
              );
        }
      );
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };
  setDept = (value) => {
    let list_department = [...this.state.list_department];
    let filter = list_department
      .filter((item) => {
        return item.name === value;
      })
      .reduce((obj, item) => {
        obj.name = item.name;
        obj.id = item.id;
        return obj;
      }, {});
    this.setState({
      id_department_submit: filter.id,
    });
  };
  exists_only = (data, cols) => {
    let header = [];
    let detail = [];
    let clone_data = [...data];

    if (clone_data) {
      clone_data.map((r, i) => {
        let z = [...r];
        let splice = z.splice(0, 2);
        if (i === 0) {
          let header_concat = splice.concat(z);
          header.push(header_concat);
        }
        //
        let cleanArray = z.filter(function (el) {
          return true && el !== null && el !== "";
        });

        if (i !== 0 && cleanArray.length > 2) {
          let array_concat = splice.concat(z);
          detail.push(array_concat);
        }
      });
    }

    return { header: header, detail: detail };
  };
  /* generate an array of column objects */
  make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i)
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  };
  make_custom_cols = (obj) => {
    let o = [];
    if (obj) {
      obj[0].map((b, d) => {
        o.push({ name: b, key: d });
      });
    }
    return o;
  };
  onSearchDetail = (any, type) => {
    const { excel, search_by, search_by_remain, remaining_budget_origin } =
      this.state;
    let name = any.target.name;
    let value = any.target.value;
    let obj = {};
    obj[name] = value;
    const typeSearch = type === "search_detail" ? search_by : search_by_remain;
    const excelData =
      type === "search_detail" ? [...excel.data] : [...remaining_budget_origin];
    this.setState(obj, () => {
      this.onGetDetail(excelData, typeSearch, value, type);
    });
  };
  onGetDetail = (excelData, search_by, value, type) => {
    try {
      const cloneXLData = excelData;

      let obj = {};
      const typeName =
        type === "search_detail_remain" ? "remaining_budget" : "data";
      obj[typeName] = cloneXLData;

      if (!value || !search_by) return this.setState(obj);

      const cloneData = cloneXLData.filter((a, i) => {
        if (search_by === "GL Account") {
          const baseD = typeof a[0] === "number" ? `${a[0]}` : a[0];
          return baseD.includes(value);
        }
        if (search_by === "GL Description")
          return a[1].toLowerCase().includes(value.toLowerCase());
        if (search_by === "Category")
          return a[15].toLowerCase().includes(value.toLowerCase());
      });
      obj[typeName] = cloneData;
      return this.setState(obj);
    } catch (err) {
      return {};
    }
  };
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data, () => {
      const {
        excel,
        search_detail,
        remaining_budget_origin,
        search_detail_remain,
      } = this.state;

      if (name === "search_by" && search_detail) {
        const excelData = [...excel.data];
        const type = "search_detail";
        return this.onGetDetail(excelData, value, search_detail, type);
      }
      if (name === "search_by_remain" && search_detail_remain) {
        const excelData = [...remaining_budget_origin];
        const type = "search_detail_remain";
        return this.onGetDetail(excelData, value, search_detail_remain, type);
      }
    });
  };
  onChangeValueDept = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
    let list_department = [...this.state.list_department];
    let filter = list_department
      .filter((item) => {
        return item.name === value;
      })
      .reduce((obj, item) => {
        obj.name = item.name;
        obj.id = item.id;
        return obj;
      }, {});

    this.setState({
      id_department_submit: filter.id,
    });
  };
  isEmptyObject = (obj) => {
    return obj === null || !Object.keys(obj).length;
  };
  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { dispatch, history } = this.props;
    const {
      creator,
      name_creator,
      department,
      excel,
      currency,
      exchange_rate_to_rp,
      selectedFile,
      email_creator,
      year,
      id_department,
    } = this.state;
    if (!this.validateForm(selectedFile, currency)) {
      return;
    }

    let collection = {
      creator: creator,
      name_creator: name_creator,
      email_creator: email_creator,
      department: department,
      year: year,
      excel: excel,
      currency: currency,
      exchange_rate_to_rp: exchange_rate_to_rp,
      id_department: id_department,
    };
    if (
      creator &&
      email_creator &&
      department &&
      excel &&
      currency &&
      exchange_rate_to_rp
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          dispatch(addBudget(collection))
            .then((response) => {
              this.toggle();
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-budget-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/budget");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-budget-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  reloadData = async (page = 1, sizePerPage = 10) => {
    const { dispatch, user } = this.props;
    const { search, department } = this.state;
    this.setState({
      loading_fetch_data: true,
    });
    const query = {
      page: page,
      sizePerPage: sizePerPage,
      department: department,
      search: search,
    };
    dispatch(readBudget(query))
      .then((response) => {
        this.setState({
          list_data: this.props.read_budget.data.foundData,
          list_data_table: this.props.read_budget.data.foundData,
          count_list: this.props.read_budget.data.countData,
          creator: user.username,
        });
      })
      .finally((f) => {
        this.setState({
          loading_fetch_data: false,
        });
      });
  };
  handleSubmitEdit = () => {
    this.setState({ submitted: true });
    const { id, creator, excel, currency, exchange_rate_to_rp, name_creator } =
      this.state;

    if (!this.validateEditForm(excel, currency, exchange_rate_to_rp)) {
      return;
    }
    const { dispatch, history } = this.props;
    let collection = {
      _id: id,
      creator: creator,
      name_creator: name_creator,
      excel: excel,
      currency: currency,
      exchange_rate_to_rp: exchange_rate_to_rp,
    };
    if (id && creator && excel && currency && exchange_rate_to_rp) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          dispatch(editBudget(collection))
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-edit-budget-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/budget");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-edit-budget-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  deleteData = (id) => {
    const { dispatch, history } = this.props;
    const { creator } = this.state;
    let collection = { _id: id, creator: creator };
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, delete entries",
      }).then((result) => {
        if (result.value) {
          dispatch(deleteBudget(collection))
            .then((response) => {
              toast.success("Data has been deleted successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-budget-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been deleted successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/budget");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-budget-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  validateForm = (file, currency, department) => {
    let errors = this.state.errors;
    if (!file) {
      errors.file = "This field is required";
    }
    if (!currency) {
      errors.currency = "This field is required";
    }
    if (!department) {
      errors.currency = "This field is required";
    }

    return errors;
  };
  validateEditForm = (currency) => {
    let errors = this.state.errors;
    if (!currency) {
      errors.currency = "This field is required";
    }

    return errors;
  };

  approveData = (id) => {
    const { dispatch, history } = this.props;
    const {
      exchange_rate_to_rp,
      email_creator,
      creator,
      name_creator,
      id_department,
    } = this.state;
    let collection = {
      _id: id,
      exchange_rate_to_rp: exchange_rate_to_rp,
      email: email_creator,
      creator: creator,
      name_creator: name_creator,
      id_department: id_department,
    };

    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, ready for upper approval",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(upperApprovalBudget(collection, "need_upper_approval"))
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-budget-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been ready for upper approval successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/budget");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-budget-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  changeFilter = (data) => {
    this.setState((prevState) => ({
      search: { ...prevState.search, ...data },
    }));

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.reloadData();
    }, 250);
  };
  render() {
    let {
      showModal,
      showModalBudget,
      showModalFlow,
      cellEditProps,
      data,
      cols,
      list_data,
      list_data_table,
      id,
      currency,
      errors,
      submitted,
      readOnly,
      ready_for_approval,
      exchange_rate_to_rp,
      error_message_exchange_rate,
      error_message_filename,
      select_items,
      loading,
      list_department,
      show_department,
      department,
      search_detail,
      select_items_search_by,
      search_by,
      remaining_budget,
      search_detail_remain,
      search_by_remain,

      count_list,
      sizePerPage,
      loading_fetch_data,
      page,
    } = this.state;

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <ToastContainer />
          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
              autoComplete="off"
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>{id ? "Update" : "Create"} Budget</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={4}>
                      <div>
                        <label htmlFor="file">Upload Excel * </label>
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          name="file"
                          placeholder="File"
                          multiple
                          onChange={this.onChangeHandlerFile}
                          accept={SheetJSFT}
                          required
                        />

                        {errors.selectedFile && (
                          <div className="invalid-feedback">
                            {errors.selectedFile}
                          </div>
                        )}

                        {error_message_filename && (
                          <em className="error-message">
                            {error_message_filename}
                          </em>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div
                        className={
                          errors.currency
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="currency">Currency *</label>
                        <div className="input-group mb-3">
                          <div className="col-md-12">
                            <select
                              className="form-control form-control-sm "
                              name="currency"
                              onChange={this.onChangeValue}
                              style={{ padding: 0 }}
                              defaultValue={currency}
                              required
                            >
                              <option value="">Choose</option>
                              {select_items &&
                                select_items.map(function (item, i) {
                                  return (
                                    <option value={item.value} key={i}>
                                      {item.value}
                                    </option>
                                  );
                                })}
                            </select>

                            {errors.currency && (
                              <div className="invalid-feedback">
                                {errors.currency}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                    {show_department ? (
                      <Col xs={12} md={4}>
                        <div
                          className={
                            errors.department
                              ? "form-group"
                              : "form-group has-error has-feedback"
                          }
                        >
                          <label htmlFor="department">Department *</label>
                          <div className="input-group mb-3">
                            <div className="col-md-12">
                              <select
                                className="form-control form-control-sm "
                                name="department"
                                onChange={this.onChangeValueDept}
                                style={{ padding: 0 }}
                                defaultValue={department}
                                required
                              >
                                <option value="">Choose</option>
                                {list_department &&
                                  list_department.map(function (item, i) {
                                    return (
                                      <option value={item.name} key={i}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                              </select>

                              {errors.department && (
                                <div className="invalid-feedback">
                                  {errors.department}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    ) : (
                      <div></div>
                    )}
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <OutTable data={data} cols={cols} />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter className="justify-content-between">
                <Button variant="danger" onClick={this.toggle}>
                  <i className="fas fa-times" /> Cancel
                </Button>
                {id ? (
                  <Button variant="success" onClick={this.handleSubmitEdit}>
                    <i className="fas fa-save" /> Update
                  </Button>
                ) : (
                  <Button variant="success" onClick={this.handleSubmit}>
                    <i className="fas fa-save" /> Submit
                  </Button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Modal
            show={showModalBudget}
            size="xl"
            backdrop="static"
            onHide={this.onModalBudgetHide}
            dialogClassName="full-modal-dialog"
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>Detail Budget</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={12} md={12}>
                  <ul className="nav nav-pills p-2">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        href="#tab_1"
                        data-toggle="tab"
                      >
                        Target
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#tab_2" data-toggle="tab">
                        Remaining
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane active" id="tab_1">
                      <Col xs={12} md={12}>
                        <div className="form-row align-items-center">
                          <div className="col-auto my-1">
                            <label htmlFor="currency">Currency</label>
                          </div>
                          <div className="col-auto my-1">
                            <select
                              className="form-control form-control-sm "
                              name="currency"
                              onChange={this.onChangeValue}
                              style={{ padding: 0 }}
                              defaultValue={currency}
                              required
                              readOnly={readOnly}
                            >
                              <option value="">Choose</option>
                              {select_items &&
                                select_items.map(function (item, i) {
                                  return (
                                    <option value={item.value} key={i}>
                                      {item.value}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="col-auto my-1">
                            <div className="input-group input-group-sm">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text input-group-text"
                                  id="exchange_rate_to_rp"
                                >
                                  Exchange Rate to Rp.
                                </span>
                              </div>
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                name="exchange_rate_to_rp"
                                min="0"
                                step="0.01"
                                required="required"
                                onChange={this.onChangeValue}
                                defaultValue={exchange_rate_to_rp}
                                readOnly={readOnly}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col xs={12} md={6}>
                        <form>
                          <div className="form-row align-items-center">
                            <div className="col-auto my-1">
                              <label
                                className="mr-sm-2 sr-only"
                                htmlFor="inlineFormCustomSelect"
                              >
                                Preference
                              </label>

                              <select
                                className="form-control form-control-sm "
                                name="search_by"
                                onChange={this.onChangeValue}
                                style={{ padding: 0 }}
                                defaultValue={search_by}
                                required
                              >
                                <option value="">Search By</option>
                                {select_items_search_by &&
                                  select_items_search_by.map(function (
                                    item,
                                    i
                                  ) {
                                    return (
                                      <option value={item.value} key={i}>
                                        {item.value}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <div className="col-auto my-2">
                              <input
                                type="search"
                                name="search_detail"
                                value={search_detail || ""}
                                onChange={(event) => {
                                  this.onSearchDetail(event, "search_detail");
                                }}
                                className="form-control form-control-sm"
                                placeholder="G/L Account / Description"
                                autoComplete="off"
                                id="search_detail"
                              />
                            </div>
                          </div>
                        </form>
                      </Col>
                      <hr />
                      <OutTable data={data} cols={cols} />
                      <hr />

                      <CSVLink
                        data={this.state.data_formatted}
                        filename={this.state.filename + ".csv"}
                        className="btn btn-info float-right"
                      >
                        <i className="fas fa-file-download" /> Download
                      </CSVLink>
                    </div>
                    <div className="tab-pane" id="tab_2">
                      <Col xs={12} md={6}>
                        <form>
                          <div className="form-row align-items-center">
                            <div className="col-auto my-1">
                              <label
                                className="mr-sm-2 sr-only"
                                htmlFor="inlineFormCustomSelect"
                              >
                                Preference
                              </label>

                              <select
                                className="form-control form-control-sm "
                                name="search_by_remain"
                                onChange={this.onChangeValue}
                                style={{ padding: 0 }}
                                defaultValue={search_by_remain}
                                required
                              >
                                <option value="">Search By</option>
                                {select_items_search_by &&
                                  select_items_search_by.map(function (
                                    item,
                                    i
                                  ) {
                                    return (
                                      <option value={item.value} key={i}>
                                        {item.value}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <div className="col-auto my-2">
                              <input
                                type="search"
                                name="search_detail_remain"
                                value={search_detail_remain || ""}
                                onChange={(event) => {
                                  this.onSearchDetail(
                                    event,
                                    "search_detail_remain"
                                  );
                                }}
                                className="form-control form-control-sm"
                                placeholder="G/L Account / Description"
                                autoComplete="off"
                                id="search_detail_remain"
                              />
                            </div>
                          </div>
                        </form>
                      </Col>
                      <hr />
                      <hr />
                      <OutTable data={remaining_budget} cols={cols} />
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="justify-content-between">
              <Button variant="danger" onClick={this.toggleBudget}>
                <i className="fas fa-times" /> Close
              </Button>
              {ready_for_approval && (
                <button
                  type="button"
                  className="btn btn-success btn-sm ml-2 mb-2 ts-buttom"
                  size="sm"
                  data-id={id}
                  onClick={() => this.approveData(id)}
                >
                  <i className="fas fa-check-square"></i>
                  &nbsp;Submit for Approval
                </button>
              )}
            </ModalFooter>
          </Modal>
          <Modal
            show={showModalFlow}
            size="xl"
            backdrop="static"
            onHide={this.onModalFlowHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Flow Budget</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={2} md={2}>
                      Creator Submitted
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      Approval Manager {this.state.department}
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      Approval FAC Controller
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      Approval FAC Manager
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </form>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">Budget</h3>
                        <div className="card-tools float-right">
                          <Button color="warning" onClick={this.toggle}>
                            <i className="fas fa-plus-square" /> Budget
                          </Button>
                        </div>
                      </div>
                      <div className="card-body">
                        <TableCustom
                          data={list_data_table}
                          totalRows={count_list}
                          perPage={sizePerPage}
                          curPage={page}
                          fetchData={this.reloadData}
                          loading={loading_fetch_data}
                          action={this.GetActionFormat}
                          handleSearch={this.changeFilter}
                          toggleStatus={this.toggleFlowBudget}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { read_budget, read_remaining_budget } = state.budget;
  const { read_currency } = state.currency;
  const { read_department } = state.department;
  const { read_current_exchange_rate, read_active_exchange_rate } =
    state.exchange_rate;

  const { message } = state.message;
  return {
    message,
    read_budget,
    read_currency,
    read_current_exchange_rate,
    read_active_exchange_rate,
    user,
    read_department,
    read_remaining_budget,
  };
}

export default connect(mapStateToProps)(Budget);
