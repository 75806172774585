import React, { Component } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import AllRoute from "./routes/AllRoute";
import Dashboard from "./views/Dashboard";
import Login from "./views/Login";
import { PrivateRoute } from "./components/PrivateRoute";

import { connect } from "react-redux";

import {
  logout,
  goONELNKHomapage,
  getMenu,
  login,
  loginSSO,
} from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from "./helpers/history";
import { Cookies } from "react-cookie";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";

const cookies = new Cookies();

const accessToken = localStorage.getItem("accessToken") || null;
const IS_SSO = process.env.REACT_APP_SSO == "true";
const SSO_KEY = process.env.REACT_APP_SSO_KEY || null;
const SSO_HOMEPAGE = process.env.REACT_APP_SSO_HOMEPAGE || null;

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined,
      cookie: cookies.get("ssocookies"),
      cookieAcc: cookies.get("ssoaccount"),
      loadingPage: false,
    };

    history.listen((location) => {
      this.props.dispatch(clearMessage()); // clear message when changing location
    });
  }
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    const menu = JSON.parse(localStorage.getItem("menu"));
    const { cookie, cookieAcc } = this.state;
    if (user) {
      this.setState({
        currentUser: user,
        menu: menu,
      });
    }
    const setLoadingSubmit = (load) => {
      this.setState({
        loadingPage: load,
      });
    };
    const check = this.isSSOLogin(setLoadingSubmit, cookie, cookieAcc);
    if (check) {
      history.push("/home");
    }
  }
  logOut() {
    this.props.dispatch(logout());
  }
  onModalLoadingHide = () => {
    this.setState({
      loadingPage: false,
    });
  };

  testJSON(text) {
    if (typeof text !== "string") {
      return false;
    }
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  }

  isSSOLogin(setLoadingSubmit, cookie, cookieAcc) {
    try {
      const isEmailSSO = cookieAcc ? window.atob(cookieAcc) : null;
      if (IS_SSO) {
        if (!cookie) {
          this.props.dispatch(goONELNKHomapage());
          window.location.href = SSO_HOMEPAGE;
          return SSO_HOMEPAGE;
        }
        const decryptCookieSSO = window.atob(cookie);
        if (!decryptCookieSSO) {
          this.props.dispatch(goONELNKHomapage());
          window.location.href = SSO_HOMEPAGE;
          return SSO_HOMEPAGE;
        }
        const tokenAccSSO = this.testJSON(decryptCookieSSO)
          ? JSON.parse(decryptCookieSSO)
          : null;
        if (!tokenAccSSO?.sso_key) {
          this.props.dispatch(goONELNKHomapage());
          window.location.href = SSO_HOMEPAGE;
          return SSO_HOMEPAGE;
        }
        if (tokenAccSSO?.sso_key && tokenAccSSO?.sso_key !== SSO_KEY) {
          this.props.dispatch(goONELNKHomapage());
          window.location.href = SSO_HOMEPAGE;
          return SSO_HOMEPAGE;
        }

        const decodeToken = tokenAccSSO
          ? jwtDecode(tokenAccSSO?.accessToken)
          : null;
        //  isEmailSSo then get accessToken
        if (isEmailSSO && decodeToken && !accessToken) {
          setLoadingSubmit(true);
          const parseEmail = JSON.parse(isEmailSSO);
          const newPayload = {
            name: parseEmail?.name,
            email: parseEmail?.idTokenClaims?.preferred_username,
            account_id: parseEmail?.localAccountId,
            username: parseEmail?.localAccountId,
          };
          this.props
            .dispatch(loginSSO(newPayload))
            .then(async (res) => {
              const user = JSON.parse(localStorage.getItem("user"));
              let role = "guest";
              if (user.details.hris_org_tree.current_person) {
                role = `${user.details.hris_org_tree.current_person.nama_department} ${user.details.hris_org_tree.current_person.nama_posisi}`;
              }
              //getmenu from backend
              this.props.dispatch(getMenu(role)).then((response) => {
                history.push("/home");
                window.location.reload();
              });

              Swal.fire({
                icon: "success",
                title: "Information",
                text: "Login Success",
                showConfirmButton: false,
                timer: 3000,
              });
            })
            .finally(() => {
              setLoadingSubmit(false);
            });
        }

        //  !isEmailSSo then get accessToken
        if (
          !isEmailSSO &&
          decodeToken &&
          !accessToken &&
          tokenAccSSO?.sso_key
        ) {
          setLoadingSubmit(true);
          const accTokenParse = jwtDecode(tokenAccSSO.accessToken);
          this.props
            .dispatch(
              login(
                accTokenParse.username.toUpperCase(),
                accTokenParse.password
              )
            )
            .then(async (res) => {
              const user = JSON.parse(localStorage.getItem("user"));
              let role = "guest";
              if (user.details.hris_org_tree.current_person) {
                role = `${user.details.hris_org_tree.current_person.nama_department} ${user.details.hris_org_tree.current_person.nama_posisi}`;
              }
              //getmenu from backend
              this.props.dispatch(getMenu(role)).then((response) => {
                history.push("/home");
                window.location.reload();
              });

              Swal.fire({
                icon: "success",
                title: "Information",
                text: "Login Success",
                showConfirmButton: false,
                timer: 3000,
              });
            })
            .finally(() => {
              setLoadingSubmit(false);
            });
        }
      }
      return IS_SSO;
    } catch (err) {
      return Swal.fire({
        heightAuto: false,
        title: "Warning!",
        text: err,
        icon: "info",
      });
    }
  }

  render() {
    const { menu } = this.props;
    const { loadingPage } = this.state;

    return (
      <Router history={history} basename={process.env.PUBLIC_URL}>
        <div>
          <Modal
            show={loadingPage}
            size="sm"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalBody
              style={{ backgroundColor: "#007bff", color: "white" }}
              className="text-center"
            >
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;
                <span className="sr-only">Loading...</span>
              </button>
            </ModalBody>
          </Modal>
          <Switch>
            <PrivateRoute exact path={["/", "/home"]} component={Dashboard} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute component={AllRoute} getMenu={menu} />
            <Redirect from="*" to="/" />
          </Switch>
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { menu } = state.menu;
  return {
    user,
    menu,
  };
}

export default connect(mapStateToProps)(App);
