import { 
  CREATE_SUPPLIER_SUCCESS,
  CREATE_SUPPLIER_FAIL,
  READ_SUPPLIER_SUCCESS,
  READ_SUPPLIER_FAIL,
  EDIT_SUPPLIER_SUCCESS,
  EDIT_SUPPLIER_FAIL,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_FAIL,
} from "../actions/types";
 
const initialState = { 
  read_supplier: null,
};

export default function supplier(state = initialState, action) {
  const { type, payload } = action;

  switch (type) { 

    case CREATE_SUPPLIER_SUCCESS:
      return {
        ...state,
      };
    case CREATE_SUPPLIER_FAIL:
      return {
        ...state,
      };

    case EDIT_SUPPLIER_SUCCESS:
      return {
        ...state,
      };
    case EDIT_SUPPLIER_FAIL:
      return {
        ...state,
      };
    case READ_SUPPLIER_SUCCESS:  
      return {
        ...state,
        read_supplier: payload,
      };
    case READ_SUPPLIER_FAIL:
      return {
        ...state,
        read_supplier: null,
      }; 
    case DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
      };
    case DELETE_SUPPLIER_FAIL:
      return {
        ...state,
      }; 
    default:
      return state;
  }
}
