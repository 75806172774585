import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import addSeparatorsNF from "../helpers/thousanddelimiter";
import dateFormat from "dateformat";

const BudgetRemaining = ({ itemData, key }) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const message = () => {
    let message = {};

    if (itemData.remaining) {
      for (const [index, value] of itemData.budget_interval.entries()) {
        const month =
          monthNames[Object.keys(itemData.budget_interval[index])[0] - 1];
        message[month] = value[Object.keys(value)[0]];
      }
    }
    if (itemData.nominal) {
      const month =
        monthNames[parseFloat(dateFormat(itemData.delivery_date, "m")) - 1];
      message[month] = itemData.nominal;
    }
    const jsx = Object.entries(message).map(([month, val]) => {
      return (
        <div className="row text-center">
          <div className="col-6">{month}</div>
          <div className="col-6">{val ?? ''}</div>
        </div>
      );
    });

    return jsx;
  };

  if (itemData.remaining || itemData.remaining == 0) {
    return (
      <div
        key={key}
        className="d-flex flex-row align-items-center justify-content-center"
      >
        <div>
          {itemData.currency} {itemData?.remaining ?? 0}
        </div>

      </div>
    );
  }

  if (itemData.nominal) {
    return (
      <div
        key={key}
        className="d-flex flex-row align-items-center justify-content-center"
      >
        <div>
          {itemData.currency} {itemData?.nominal ?? 0}
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default BudgetRemaining;
