import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class PurchaseRequestService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "purchase_request", payload, {
      headers: authHeader(),
    });
  }

  readInputNominal(query) {
    return axios.get(API_URL_DEFAULT + "purchase_request", {
      headers: authHeader(),
      params: {
        ...query,
        input_nominal_pr: true
      }
    });
  }

  readPurchaseRequest(username, type, additionalParams = {}) {
    return axios.get(
      API_URL_DEFAULT + "purchase_request/",
      {
        headers: authHeader(),
        params: {
          [type]: username,
          ...additionalParams
        }
      }
    );
  }
  readAllPurchaseRequest(username, pr_type) {
    return axios.get(
      API_URL_DEFAULT +
      "purchase_request/?username=" +
      username +
      "&pr_type=" +
      pr_type,
      { headers: authHeader() }
    );
  }
  edit(payload) {
    return axios.put(API_URL_DEFAULT + "purchase_request", payload, {
      headers: authHeader(),
    });
  }

  transfer(payload) {
    return axios.put(API_URL_DEFAULT + "purchase_request/transfer", payload, {
      headers: authHeader(),
    });
  }

  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "purchase_request", {
      data: payload,
      headers: authHeader(),
    });
  }
  upperApproval(payload, status, approval, reason) {
    if (status === "need_upper_approval") {
      return axios.put(
        API_URL_DEFAULT + "purchase_request/?status=" + status,
        payload,
        { headers: authHeader() }
      );
    }
    return axios.put(
      API_URL_DEFAULT +
      "purchase_request/?status=" +
      status +
      "&approval=" +
      approval +
      "&reason=" +
      reason,
      payload,
      { headers: authHeader() }
    );
  }
  nominalApproval(payload, queryString) {
    return axios.put(
      API_URL_DEFAULT + "purchase_request/nominal-approval/?" + queryString,
      payload,
      { headers: authHeader() }
    );
  }

  checkBudget(payload) {
    return axios.post(
      API_URL_DEFAULT + "purchase_request/checkBudget",
      payload,
      {
        headers: authHeader(),
      }
    );
  }
  updateOverbudget(payload) {
    return axios.post(
      API_URL_DEFAULT + "purchase_request/update-over-budget",
      payload,
      {
        headers: authHeader(),
      }
    );
  }
}

export default new PurchaseRequestService();
