import {
  CREATE_SUPPLIER_SUCCESS,
  CREATE_SUPPLIER_FAIL,
  SET_MESSAGE,
  READ_SUPPLIER_SUCCESS,
  READ_SUPPLIER_FAIL,
  EDIT_SUPPLIER_SUCCESS,
  EDIT_SUPPLIER_FAIL,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_FAIL,
} from "./types";
import SUPPLIERService from "../services/supplier.service";

export const addSUPPLIER = (payload) => (dispatch) => {
  return SUPPLIERService.create(payload).then(
    (response) => {
      dispatch({
        type: CREATE_SUPPLIER_SUCCESS,
        payload: response.data
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_SUPPLIER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editSUPPLIER = (payload) => (dispatch) => {
  return SUPPLIERService.edit(payload).then(
    (response) => {
      dispatch({
        type: EDIT_SUPPLIER_SUCCESS,
        payload: response.data
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_SUPPLIER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deleteSUPPLIER = (payload) => (dispatch) => {
  return SUPPLIERService.delete(payload).then(
    (response) => {
      dispatch({
        type: DELETE_SUPPLIER_SUCCESS,
        payload: response.data
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_SUPPLIER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readSUPPLIER = (query) => (dispatch) => {
  return SUPPLIERService.readSUPPLIER(query).then(
    (response) => {
      dispatch({
        type: READ_SUPPLIER_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_SUPPLIER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
