import React, { Component } from 'react'
import DefaultContent from '../components/templates/DefaultContent';
import Footer from '../components/templates/Footer';
import NavSideLayout from '../components/NavSideLayout';

export default class Customer extends Component {
  render() {
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <NavSideLayout/>
          
          <DefaultContent/>
          <Footer/>
        </div>
      </div>
    )
  }
}
