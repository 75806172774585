import axios from "axios";
import authHeader from "./auth-header"; 

const API_URL_DEFAULT = process.env.REACT_APP_API;

class AccountAssignmentCatService { 

  create(payload) {  
    return axios
      .post(API_URL_DEFAULT + "account_assignment_cat", payload,{ headers: authHeader()});
  }
  readAccountAssignmentCat() {   
    return axios
      .get(API_URL_DEFAULT + "account_assignment_cat/",{ headers: authHeader()});
  } 
  edit(payload) {  
    return axios
      .put(API_URL_DEFAULT + "account_assignment_cat", payload,{ headers: authHeader()});
  }

  delete(payload) {  
    return axios
      .delete(API_URL_DEFAULT + "account_assignment_cat", {data:payload, headers: authHeader()});
  } 
  
}

export default new AccountAssignmentCatService();