import React, { Component } from "react";
import Menu from "./Menu";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { setActiveDashboard, setActiveMenu } from "../../actions/menu";

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.handleDashboardOpen = this.handleDashboardOpen.bind(this);
    this.state = {
      user: {
        photo: "./images/user.png",
        name: "",
      },
      menu: this.props.menu,
      currentUser: {},
    };
  }
  componentDidMount() {
    const user = this.props.user;

    if (user) {
      this.setState({
        currentUser: user,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const user = nextProps.user;

    if (user) {
      this.setState({
        currentUser: user,
      });
    }
  }
  handleDashboardOpen() {
    const { dispatch } = this.props;
    // dispatch(setActiveDashboard());
    dispatch(setActiveMenu("/home"));
  }
  render() {
    let { menu, user, currentUser } = this.state;
    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <a href="#!" className="brand-link">
          <img
            src="dist/img/AdminLTELogo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light">Apps</span>
        </a>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src={
                  currentUser && currentUser.photo
                    ? currentUser.photo
                    : user.photo
                }
                className="img-circle elevation-2"
                alt=""
              />
            </div>
            <div className="info">
              <a href="#!" className="d-block">
                {currentUser && currentUser.details
                  ? currentUser.details.hris_org_tree.current_person.person_name
                  : ""}
              </a>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}

              <li
                className={
                  this.props.currentDashboard
                    ? "nav-item menu-open"
                    : "nav-item"
                }
              >
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/home"
                  onClick={this.handleDashboardOpen}
                >
                  <i className="nav-icon fas fa-home" />
                  <p>Dashboard</p>
                </NavLink>
              </li>
              {menu && menu.length > 0
                ? menu.map((item, i) => {
                    return (
                      <Menu
                        name={item.name}
                        icon={item.icon}
                        link={item.link}
                        details={item.details}
                        key={i}
                      />
                    );
                  })
                : ""}
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}
function mapStateToProps(state) {
  const { isDashboardOpen, menu } = state.menu;
  const { user } = state.auth;
  return {
    isDashboardOpen,
    menu,
    user,
  };
}

export default connect(mapStateToProps)(SideMenu);
