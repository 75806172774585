import {
  CREATE_REQUISITIONER_SUCCESS,
  CREATE_REQUISITIONER_FAIL,
  READ_REQUISITIONER_SUCCESS,
  READ_REQUISITIONER_FAIL,
  EDIT_REQUISITIONER_SUCCESS,
  EDIT_REQUISITIONER_FAIL,
  DELETE_REQUISITIONER_SUCCESS,
  DELETE_REQUISITIONER_FAIL,
} from "../actions/types";

const initialState = {
  read_requisitioner: null,
};

export default function requisitioner(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_REQUISITIONER_SUCCESS:
      return {
        ...state,
      };
    case CREATE_REQUISITIONER_FAIL:
      return {
        ...state,
      };

    case EDIT_REQUISITIONER_SUCCESS:
      return {
        ...state,
      };
    case EDIT_REQUISITIONER_FAIL:
      return {
        ...state,
      };
    case READ_REQUISITIONER_SUCCESS:
      return {
        ...state,
        read_requisitioner: payload,
      };
    case READ_REQUISITIONER_FAIL:
      return {
        ...state,
        read_requisitioner: null,
      };
    case DELETE_REQUISITIONER_SUCCESS:
      return {
        ...state,
      };
    case DELETE_REQUISITIONER_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
