import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ServiceNumberService {

  readServiceNumber(query) {
    return axios
      .get(API_URL_DEFAULT + "service_number/getOption/", { headers: authHeader(), params: query });
  }

}

export default new ServiceNumberService();