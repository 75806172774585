import {
  CREATE_PURCH_GROUP_SUCCESS,
  CREATE_PURCH_GROUP_FAIL,
  SET_MESSAGE,
  READ_PURCH_GROUP_SUCCESS,
  READ_PURCH_GROUP_FAIL,
  EDIT_PURCH_GROUP_SUCCESS,
  EDIT_PURCH_GROUP_FAIL,
  DELETE_PURCH_GROUP_SUCCESS,
  DELETE_PURCH_GROUP_FAIL,
} from "./types";
import PurchGroupService from "../services/purch_group.service";

export const addPurchGroup = (payload) => (dispatch) => {
  return PurchGroupService.create(payload).then(
    (response) => {
      dispatch({
        type: CREATE_PURCH_GROUP_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_PURCH_GROUP_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editPurchGroup = (payload) => (dispatch) => {
  return PurchGroupService.edit(payload).then(
    (response) => {
      dispatch({
        type: EDIT_PURCH_GROUP_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_PURCH_GROUP_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deletePurchGroup = (payload) => (dispatch) => {
  return PurchGroupService.delete(payload).then(
    (response) => {
      dispatch({
        type: DELETE_PURCH_GROUP_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_PURCH_GROUP_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readPurchGroup = () => (dispatch) => {
  return PurchGroupService.readPurchGroup().then(
    (response) => {
      dispatch({
        type: READ_PURCH_GROUP_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_PURCH_GROUP_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
