import {
  CREATE_REQUISITIONER_SUCCESS,
  CREATE_REQUISITIONER_FAIL,
  SET_MESSAGE,
  READ_REQUISITIONER_SUCCESS,
  READ_REQUISITIONER_FAIL,
  EDIT_REQUISITIONER_SUCCESS,
  EDIT_REQUISITIONER_FAIL,
  DELETE_REQUISITIONER_SUCCESS,
  DELETE_REQUISITIONER_FAIL,
} from "./types";
import RequisitionerService from "../services/requisitioner.service";

export const addRequisitioner = (payload) => (dispatch) => {
  return RequisitionerService.create(payload).then(
    (response) => {
      dispatch({
        type: CREATE_REQUISITIONER_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_REQUISITIONER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editRequisitioner = (payload) => (dispatch) => {
  return RequisitionerService.edit(payload).then(
    (response) => {
      dispatch({
        type: EDIT_REQUISITIONER_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_REQUISITIONER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deleteRequisitioner = (payload) => (dispatch) => {
  return RequisitionerService.delete(payload).then(
    (response) => {
      dispatch({
        type: DELETE_REQUISITIONER_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_REQUISITIONER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readRequisitioner = () => (dispatch) => {
  return RequisitionerService.readRequisitioner().then(
    (response) => {
      dispatch({
        type: READ_REQUISITIONER_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_REQUISITIONER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
