import React, { Component } from "react"; 

class Modal extends Component {
  constructor(props) {
    super(props);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.state = {
      collection:[],
      display: this.props.displayModal
    }
  }  
  onChangeValue = (e)=>{ 
      let collection =[...this.state.collection]; 
      let data ={};
      data['key']=e.target.name; 
      data['value']=e.target.value; 
      collection[e.target.dataset.id] = data;  
      this.setState({collection},()=> this.props.onChangeValueParent(collection)) 
  }  
  render() {
    let{id,size,title,show,body,modalVisible}=this.props; 
    let styles = modalVisible
    ? { display: "block" }
    : { display: "none" };
    let showClass = show?"modal fade in show":"modal fade in";
    return (
      <div>
        <div 
         className={showClass}
         id={id} 
         style={styles}
         >
          <div className={size?`modal-dialog modal-${size}`:"modal-dialog"}>
            <form autoComplete="off">
            <div className="modal-content"> 
              <div className="modal-header">
                <h4 className="modal-title">{title?title:""}</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-md-12 col-xs-12">
                  {body && body.length > 0 ?body.map((item,i)=>{
                    return (
                      <div className="form-group" key={i}> 
                      <label htmlFor={item.name}>{item.placeholder}</label>
                      <input 
                      id={i}
                      data-id={i}
                      type={item.type} className="form-control" 
                      name={item.name} 
                      placeholder={item.placeholder} 
                      required="required" 
                      defaultValue={item.value}
                      onChange={this.onChangeValue}  
                      /> 
                      </div> 
                    )
                  })
                  :
                  ""
                  }
                </div>
              </div>
              <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-danger " data-dismiss="modal" ><i className="fas fa-times"></i> Cancel </button> <button type="button" className="btn btn-success" onClick={this.props.handleSubmit}><i className="fas fa-save"></i> Submit </button>
              </div> 
            </div>
            </form>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>
      </div>
    );
  }
}
export default Modal;