import React, { Component, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import PropTypes from "prop-types";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import addSeparatorsNF from "../helpers/thousanddelimiter";
import Select from "react-select";
import { connect } from "react-redux";
import {
  addPurchaseRequest,
  readPurchaseRequest,
  editPurchaseRequest,
  deletePurchaseRequest,
  upperApprovalPurchaseRequest,
  transferPurchaseRequest,
  nominalApprovalPurchaseRequest,
} from "../actions/purchase_request";
import { readBudgetByGlAccount } from "../actions/budget";
import { readRequisitioner } from "../actions/requisitioner";
import { readServiceNumber } from "../actions/servicenumber";
import { clearMessage } from "../actions/message";
import { readCostCenter } from "../actions/costcenter";
import { readDocumentType } from "../actions/document_type";
import { readPlant } from "../actions/plant";
import { readPurchGroup } from "../actions/purch_group";
import { readAccountAssignmentCat } from "../actions/account_assignment_cat";
import { readUOM } from "../actions/uom";
import DynamicTable from "../components/DynamicTable";
import { Table } from "react-bootstrap";
import moment from "moment";

import {
  readCurrentExchangeRate,
  readActiveExchangeRate,
} from "../actions/exchange_rate";

const TableCustom = ({
  data,
  totalRows,
  action,
  toggleStatus,
  fetchData,
  handleSearch,
  loading,
}) => {
  const [perPage, setPerPage] = useState(10);
  const handleChange = (e) => {
    handleSearch({
      [e.target.name]: e.target.value,
    });
  };

  const columns = useMemo(() => {
    return [
      {
        name: (
          <div className="form-row align-items-center px-2">
            <input
              placeholder="Requester"
              onChange={handleChange}
              name="requester"
              type="search"
              className="form-control"
              autoComplete="off"
            />
          </div>
        ),
        selector: (row) => `${row.name_requester} (${row.requester})`,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Document Number"
                onChange={handleChange}
                name="document_number"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => row.document_number,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Summary"
                onChange={handleChange}
                name="summary"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        cell: (row) => {
          const summary = (row?.details || [])
            .map((e) => e?.item_text)
            .join(", ");
          return summary;
        },
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Status"
                onChange={handleChange}
                name="status"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        cell: (row) => {
          let message_reject = "";
          if (row.isRejected === true) {
            message_reject = row.input_approval_l1.reason
              ? row.input_approval_l1.reason
              : row.input_approval_l2.reason;
          }
          return (
            <div>
              <button
                type="button"
                className="btn btn-sm ml-2 mb-2 ts-buttom"
                size="sm"
                onClick={toggleStatus}
              >
                <i className="fas fa-search-location"></i>
                &nbsp;{row.status}
                <br></br>
                {message_reject ? `Reason : ${message_reject}` : ""}
              </button>
            </div>
          );
        },
        sortable: true,
      },
      {
        name: "Action",
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: action,
      },
    ];
  }, []);

  const handlePageChange = async (page) => {
    await fetchData({
      page,
      sizePerPage: perPage,
    });
  };

  const handlePerPageChange = async (newPerPage, page) => {
    await fetchData({
      page,
      sizePerPage: newPerPage,
    });

    setPerPage(newPerPage);
  };

  return (
    <DataTable
      persistTableHead={true}
      columns={columns}
      data={data}
      pagination
      paginationServer
      paginationPerPage={perPage}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      paginationTotalRows={totalRows}
      progressPending={loading}
    />
  );
};

TableCustom.propTypes = {
  data: PropTypes.instanceOf(Array),
  totalRows: PropTypes.number,
  action: PropTypes.func,
  toggleStatus: PropTypes.func,
  fetchData: PropTypes.func,
  handleSearch: PropTypes.func,
  loading: PropTypes.bool,
};

TableCustom.defaultProps = {
  data: [],
  totalRows: 0,
  loading: false,
};

class PurchaseRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClearable: true,
      isValid: true,
      isValidGLAccount: true,
      isValidDate: true,
      isValidMaterial: true,
      isValidSupplier: true,
      isValidCostCenter: true,
      isValidSourceDetermination: true,
      is_nominal_approval: false,
      query_get_remaining_by_month: false,
      fetchLoading: false,
      loading: false,
      extend: false,
      submitted: false,
      showModal: false,
      showModalTransfer: false,
      showModalPurchaseRequest: false,
      showModalPurchaseRequestReject: false,
      showModalFlow: false,
      readOnly: false,
      collection: [],
      select_items_cost_center: [],
      select_items_source_determination: [],
      select_items_requisitioner: [],
      select_items_uom: [],
      select_items_document_type: [],
      select_items_plant: [],
      select_items_purch_group: [],
      select_items_account_assignment_cat: [
        { value: "K", label: "(K) Cost Center" },
      ],
      select_items_item_cat: [{ value: "D", label: "(D) Service" }],
      select_items_service_number: [],
      list_data: [],
      list_data_table: [],
      count_data_list: 0,
      errors: {},
      active_exchange_rate: null,
      exchange_rate_to_rp: "1",
      id: null,
      reason: null,

      requester: null,
      email_requester: null,
      department: null,
      id_department: null,

      document_type: null,
      source_determination: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      requisitioner_label: null,
      remark: null,
      account_assignment_cat: "K",
      item_cat: "D",
      account_assignment_cat_default: "K",
      item_cat_default: "D",
      service_number: null,
      document_number: null,
      cost_center: null,
      ready_for_approval: false,
      pr_type: "service",
      data_service: [
        {
          short_text: "",
          item_text: "",
          service_number: "",
          qty: "",
          uom: "",
          delivery_date: "",
          remaining: "",
        },
      ],
      details: [
        {
          short_text: "",
          item_text: "",
          service_number: "",
          qty: "",
          uom: "",
          delivery_date: "",
          remaining: "",
        },
      ],
      columns_service: [
        {
          text: "Short Text",
          type: "text",
        },
        {
          text: "Item Text",
          type: "item_text",
        },
        {
          text: "Service Number",
          type: "service_number",
        },
        {
          text: "Qty",
          type: "numeric",
        },
        {
          text: "UOM",
          type: "uom",
        },
        {
          text: "Delivery Date",
          type: "date",
        },
        {
          text: "Budget Remaining",
          type: "remaining",
        },
      ],
      columns_service_transfer: [
        {
          text: "Short Text",
          type: "text",
        },
        {
          text: "Item Text",
          type: "item_text",
        },
        {
          text: "Qty",
          type: "numeric",
        },
        {
          text: "UOM",
          type: "uom",
        },
        {
          text: "Delivery Date",
          type: "date",
        },
        {
          text: "Prev Service Number",
          type: "prev_service_number",
          width: "10%",
        },
        {
          text: "Nominal",
          type: "after_input_nominal",
          width: "10%",
        },
        {
          text: "Exchange Rate",
          type: "exchange_rate",
          width: "10%",
        },
        {
          text: "Prev Budget Remaining",
          type: "prev_remaining",
          width: "10%",
        },
        {
          text: "Over Budget",
          type: "over",
          width: "10%",
        },
        {
          text: "Transfer Service Number",
          type: "service_number",
          width: "10%",
        },
        {
          text: "Transfer Budget Remaining",
          type: "remaining",
          width: "10%",
        },
      ],
      defaultSorted: [
        {
          dataField: "requester",
          order: "asc",
        },
      ],
      columns_service_nominal_approval: [
        {
          text: "Short Text",
          type: "text",
        },
        {
          text: "Item Text",
          type: "item_text",
        },
        {
          text: "Qty",
          type: "numeric",
        },
        {
          text: "UOM",
          type: "uom",
        },
        {
          text: "Delivery Date",
          type: "date",
        },
        {
          text: "Service Number",
          type: "prev_service_number",
          width: "10%",
        },
        {
          text: "Nominal",
          type: "after_input_nominal",
          width: "10%",
        },
        {
          text: "Exchange Rate",
          type: "exchange_rate",
          width: "10%",
        },
        {
          text: "Budget Remaining",
          type: "prev_remaining",
          width: "10%",
        },
      ],
      cellEditProps: {},
      search: {},
      created_at: null,
    };
    this.timer = null;
  }

  fetchPurchaseRequest(additionalParams = { page: 1, sizePerPage: 10 }) {
    const { dispatch, user } = this.props;
    this.setState({
      fetchLoading: true,
    });

    dispatch(
      readPurchaseRequest(user.username, "requester", {
        ...additionalParams,
        search: this.state.search,
        pr_type: "service",
      })
    ).then((response) => {
      this.setState({
        list_data: this.props.read_purchase_request.data.foundData,
        list_data_table: this.props.read_purchase_request.data.foundData,
        count_data_list: this.props.read_purchase_request.data.countData,
        fetchLoading: false,
      });
    });
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.reloadDataBackend();

    dispatch(readUOM()).then((response) => {
      this.setState({ select_items_uom: this.props.read_uom.data });
    });

    dispatch(readPurchGroup()).then((response) => {
      this.setState({
        select_items_purch_group: this.props.read_purch_group.data,
      });
    });

    dispatch(readActiveExchangeRate()).then((response) => {
      this.setState({
        active_exchange_rate: this.props.read_active_exchange_rate.data,
      });
    });
  }

  reloadDataBackend = () => {
    const { dispatch, user } = this.props;
    this.fetchPurchaseRequest();

    dispatch(readRequisitioner()).then((response) => {
      this.setState({
        select_items_requisitioner: this.props.read_requisitioner.data,
      });
    });

    dispatch(
      readCostCenter(user.details.hris_org_tree.current_person.nama_department)
    ).then((response) => {
      this.setState({
        select_items_cost_center: this.props.read_costcenter.data,
      });
    });

    dispatch(readDocumentType()).then((response) => {
      this.setState({
        select_items_document_type: this.props.read_document_type.data,
      });
    });
    dispatch(readPlant()).then((response) => {
      this.setState({ select_items_plant: this.props.read_plant.data });
    });
    dispatch(readPurchGroup()).then((response) => {
      this.setState({
        select_items_purch_group: this.props.read_purch_group.data,
      });
    });
    dispatch(readAccountAssignmentCat()).then((response) => {
      this.setState({
        select_items_account_assignment_cat:
          this.props.read_account_assignment_cat.data,
      });
    });
    dispatch(readServiceNumber({ size: 1, sizePerPage: 10 })).then(
      (response) => {
        this.setState({
          select_items_service_number: this.props.read_service_number.data,
        });
      }
    );

    this.setState({
      requester: user.username,
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      email_requester: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.email
        : null,
      name_requester: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.person_name
        : null,
      id_department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.department_id
        : null,

      is_nominal_approval: false,
      ready_for_approval: false,
      extend: false,
      readOnly: false,
      isValid: true,
      isValidDate: true,
      isValidCostCenter: true,
      isValidSourceDetermination: true,
      document_type: null,
      source_determination: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      cost_center: null,
      reason: null,
      account_assignment_cat: null,
      type: null,
    });
  };

  handleSearch = (data) => {
    this.setState((prevState) => ({
      search: { ...prevState.search, ...data },
    }));

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fetchPurchaseRequest();
    }, 250);
  };

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      showModal: !this.state.showModal,
      document_type: null,
      source_determination: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      cost_center: null,
      readOnly: false,
      isValid: true,
      isValidDate: true,
      isValidCostCenter: true,
      isValidSourceDetermination: true,
      reason: null,
      account_assignment_cat: null,
      type: null,
      loading: false,
      data_service: [
        {
          short_text: "",
          item_text: "",
          service_number: "",
          qty: "",
          uom: "",
          delivery_date: "",
          currency: "",
          nominal: "",
        },
      ],
      details: [
        {
          short_text: "",
          item_text: "",
          service_number: "",
          qty: "",
          uom: "",
          delivery_date: "",
          currency: "",
          nominal: "",
        },
      ],
    });
  };
  onModalPurchaseRequestHide = () => {
    this.setState({
      showModalPurchaseRequest: false,
      id: null,
      submitted: false,
      document_type: null,
      requisitioner: null,
      plant: null,
      cost_center: null,
      account_assignment_cat: null,
      item_cat: this.state.item_cat_default,
      purchase_group: null,
      source_determination: null,
      data_service: [
        {
          short_text: "",
          item_text: "",
          service_number: "",
          qty: "",
          uom: "",
          delivery_date: "",
          currency: "",
          nominal: "",
        },
      ],
      details: [
        {
          short_text: "",
          item_text: "",
          service_number: "",
          qty: "",
          uom: "",
          delivery_date: "",
          currency: "",
          nominal: "",
        },
      ],
    });
  };

  onModalPurchaseRequestRejectHide = () => {
    this.setState({
      id: null,
      submitted: false,
      showModalPurchaseRequestReject:
        !this.state.showModalPurchaseRequestReject,
      readOnly: true,
      reason: null,
    });
  };
  toggle = (type) => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  togglePurchaseRequest = () => {
    this.setState({
      showModalPurchaseRequest: !this.state.showModalPurchaseRequest,
    });
  };

  toggleFlowPurchaseRequest = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };
  onModalFlowHide = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };
  toDetail = (
    id,
    document_type,
    requisitioner,
    plant,
    cost_center,
    account_assignment_cat,
    item_cat,
    purchase_group,
    source_determination,
    details,
    doc_number,
    status,
    created_at
  ) => {
    this.setState(
      {
        id: id,
        document_type: document_type,
        requisitioner: requisitioner,
        plant: plant,
        cost_center: cost_center,
        account_assignment_cat: account_assignment_cat,
        item_cat: item_cat,
        purchase_group: purchase_group,
        source_determination: source_determination,
        data_service: details,
        doc_number: doc_number,
        status: status,
        details: details,
        created_at: created_at,
      },
      () => {
        this.toggle();
      }
    );
  };
  toDetailPurchaseRequest = (
    id,
    document_type,
    requisitioner,
    plant,
    cost_center,
    account_assignment_cat,
    item_cat,
    purchase_group,
    source_determination,
    details,
    status,
    ready_for_approval,
    extend,
    document_number,
    is_nominal_approval = false,
    created_at
  ) => {
    const { dispatch } = this.props;
    if (status === "Submitted") {
      return this.setState(
        {
          document_type_label: this.state.select_items_document_type.find(
            (e) => e.value === document_type
          ).label,
          requisitioner_label: this.state.select_items_requisitioner.find(
            (e) => e.value === requisitioner
          ).label,
          plant_label: this.state.select_items_plant.find(
            (e) => e.value === plant
          ).label,
          cost_center_label: this.state.select_items_cost_center.find(
            (e) => e.value === cost_center
          ).label,
          account_assignment_cat_label:
            this.state.select_items_account_assignment_cat.find(
              (e) => e.value === account_assignment_cat
            ).label,
          item_cat_label: this.state.select_items_item_cat.find(
            (e) => e.value === item_cat
          ).label,
          purchase_group_label: this.state.select_items_purch_group.find(
            (e) => e.value === purchase_group
          ).label,
          source_determination_label:
            this.state.select_items_source_determination?.find(
              (e) => e.value === source_determination
            )?.label,
          details: details,
          status: status,
          readOnly: true,
          ready_for_approval: ready_for_approval,
          extend: extend,
          document_number: document_number,
          id: id,
          created_at: created_at,
        },
        () => {
          this.togglePurchaseRequest();
        }
      );
    }

    return this.setState(
      {
        document_type_label: this.state.select_items_document_type.find(
          (e) => e.value === document_type
        ).label,
        requisitioner_label: this.state.select_items_requisitioner.find(
          (e) => e.value === requisitioner
        ).label,
        plant_label: this.state.select_items_plant.find(
          (e) => e.value === plant
        ).label,
        cost_center_label: this.state.select_items_cost_center.find(
          (e) => e.value === cost_center
        ).label,
        account_assignment_cat_label:
          this.state.select_items_account_assignment_cat.find(
            (e) => e.value === account_assignment_cat
          ).label,
        item_cat_label: this.state.select_items_item_cat.find(
          (e) => e.value === item_cat
        ).label,
        purchase_group_label: this.state.select_items_purch_group.find(
          (e) => e.value === purchase_group
        ).label,
        source_determination_label:
          this.state.select_items_source_determination?.find(
            (e) => e.value === source_determination
          )?.label,
        status: status,
        readOnly: true,
        ready_for_approval: ready_for_approval,
        extend: extend,
        document_number: document_number,
        id: id,
        details: details,
        is_nominal_approval: is_nominal_approval,
        created_at: created_at,
      },
      () => {
        if (is_nominal_approval && status === "Need Nominal Approval") {
          return this.showTransfer(id);
        }
        this.togglePurchaseRequest();
      }
    );
  };

  GetRequesterFormat = (cell, row) => {
    return (
      <div>
        {row.name_requester} ({row.requester})
      </div>
    );
  };
  GetQTYFormat = (cell, row) => {
    return (
      <div>
        {addSeparatorsNF(row.quantity, ".", ".", ",")} &nbsp;
        {row.unit_of_measure}
      </div>
    );
  };
  GetActionFormat = (row) => {
    let date = row.delivery_date.split("-");
    let date_baru = new Date(date[0], date[1] - 1, date[2]);
    let extend = false;
    if (row.overbudget) {
      if (row.overbudget.isOverBudget) {
        extend = true;
      }
    }
    if (row.status === "Submitted") {
      return (
        <div>
          <button
            type="button"
            className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() =>
              this.toDetailPurchaseRequest(
                row._id,
                row.document_type,
                row.requisitioner,
                row.plant,
                row.cost_center,
                row.account_assignment_cat,
                row.item_cat,
                row.purchase_group,
                row?.source_determination,
                row.details,
                row.status,
                true,
                extend,
                row.document_number,
                false,
                row.created_at
              )
            }
          >
            <i className="fas fa-th"></i>
            &nbsp;Detail
          </button>
          <button
            type="button"
            className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() =>
              this.toDetail(
                row._id,
                row.document_type,
                row.requisitioner,
                row.plant,
                row.cost_center,
                row.account_assignment_cat,
                row.item_cat,
                row.purchase_group,
                row.source_determination,
                row.details,
                row.document_number,
                row.status,
                row.created_at
              )
            }
          >
            <i className="fas fa-edit"></i>
            &nbsp;Edit
          </button>

          <button
            type="button"
            className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            data-id={row._id}
            onClick={() => this.deleteData(row._id)}
          >
            <i className="fas fa-trash"></i>
            &nbsp;Delete
          </button>
        </div>
      );
    }
    if (row.status.includes("Rejected")) {
      return (
        <div>
          <button
            type="button"
            className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() =>
              this.toDetailPurchaseRequest(
                row.id,
                row.document_type,
                row.requisitioner,
                row.plant,
                row.cost_center,
                row.account_assignment_cat,
                row.item_cat,
                row.purchase_group,
                row.source_determination,
                row.details,
                row.status,
                false,
                extend,
                row.document_number,
                false,
                row.created_at
              )
            }
          >
            <i className="fas fa-th"></i>
            &nbsp;Detail
          </button>
          <button
            type="button"
            className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() =>
              this.toDetail(
                row._id,
                row.document_type,
                row.requisitioner,
                row.plant,
                row.cost_center,
                row.account_assignment_cat,
                row.item_cat,
                row.purchase_group,
                row.source_determination,
                row.details,
                row.document_number,
                row.status,
                row.created_at
              )
            }
          >
            <i className="fas fa-edit"></i>
            &nbsp;Edit
          </button>
        </div>
      );
    }
    return (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetailPurchaseRequest(
              row._id,
              row.document_type,
              row.requisitioner,
              row.plant,
              row.cost_center,
              row.account_assignment_cat,
              row.item_cat,
              row.purchase_group,
              row.source_determination,
              row.details,
              row.status,
              false,
              extend,
              row.document_number,
              row.is_nominal_approval,
              row.created_at
            )
          }
        >
          <i className="fas fa-th"></i>
          &nbsp;Detail
        </button>
      </div>
    );
  };
  isEmptyObject = (obj) => {
    return obj === null || !Object.keys(obj).length;
  };
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { dispatch, history } = this.props;
    const {
      id,
      requester,
      name_requester,
      department,
      id_department,
      email_requester,
      document_type,
      requisitioner,
      plant,
      cost_center,
      account_assignment_cat,
      item_cat,
      purchase_group,
      source_determination,
      details,
      pr_type,
    } = this.state;
    if (
      !this.validateForm(
        document_type,
        requisitioner,
        plant,
        cost_center,
        account_assignment_cat,
        item_cat,
        purchase_group
      )
    ) {
      return;
    }
    let collection = {
      _id: id,
      requester: requester,
      name_requester: name_requester,
      department: department,
      id_department: id_department,
      email_requester: email_requester,
      document_type: document_type,
      requisitioner: requisitioner,
      plant: plant,
      cost_center: cost_center,
      account_assignment_cat:
        account_assignment_cat !== null ? account_assignment_cat : "K",
      item_cat: item_cat,
      purchase_group: purchase_group,
      source_determination: source_determination,
      details: details,
      pr_type: pr_type,
    };
    for (let obj = 0; obj < details.length; obj++) {
      if (parseInt(details[obj].qty) < 0) {
        Swal.fire({
          title: "Warning?",
          text: "Qty can't minus",
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }
    }
    if (
      requester &&
      name_requester &&
      email_requester &&
      department &&
      id_department &&
      document_type &&
      requisitioner &&
      plant &&
      cost_center &&
      item_cat &&
      purchase_group &&
      details &&
      pr_type
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          dispatch(addPurchaseRequest(collection))
            .then((response) => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.onModalHide();
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  handleSubmitEdit = () => {
    this.setState({ submitted: true });
    const {
      id,
      requester,
      document_type,
      requisitioner,
      plant,
      cost_center,
      account_assignment_cat,
      item_cat,
      purchase_group,
      source_determination,
      details,
      pr_type,
    } = this.state;
    if (
      !this.validateForm(
        document_type,
        requisitioner,
        plant,
        cost_center,
        account_assignment_cat,
        item_cat,
        purchase_group
      )
    ) {
      return;
    }
    const { dispatch, history } = this.props;
    let collection = {
      _id: id,
      requester: requester,
      document_type: document_type,
      requisitioner: requisitioner,
      plant: plant,
      cost_center: cost_center,
      account_assignment_cat: account_assignment_cat,
      item_cat: item_cat,
      purchase_group: purchase_group,
      source_determination: source_determination,
      details: details,
      pr_type: pr_type,
    };
    for (let obj = 0; obj < details.length; obj++) {
      if (parseInt(details[obj].qty) < 0) {
        Swal.fire({
          title: "Warning?",
          text: "Qty can't minus",
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }
    }
    if (
      requester &&
      document_type &&
      requisitioner &&
      plant &&
      cost_center &&
      account_assignment_cat &&
      item_cat &&
      purchase_group &&
      details &&
      pr_type
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          dispatch(editPurchaseRequest(collection))
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-edit-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.onModalHide();
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-edit-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  deleteData = (id) => {
    const { dispatch, history } = this.props;
    const { requester } = this.state;
    let collection = { _id: id, requester: requester };
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, delete entries",
      }).then((result) => {
        if (result.value) {
          dispatch(deletePurchaseRequest(collection))
            .then((response) => {
              toast.success("Data has been deleted successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been deleted successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  approveData = (id) => {
    const { dispatch, history } = this.props;
    const { email_requester, requester, name_requester } = this.state;
    let collection = {
      _id: id,
      email: email_requester,
      requester: requester,
      name_requester: name_requester,
    };

    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, ready for input nominal",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(upperApprovalPurchaseRequest(collection, "Approval L1"))
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been ready for upper approval successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.setState({
                  loading: false,
                });
                this.togglePurchaseRequest();
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  extendBudget = (id) => {
    const { dispatch, history } = this.props;
    const { email_requester, requester, name_requester, status, reason } =
      this.state;
    let collection = {
      _id: id,
      email: email_requester,
      requester: requester,
      name_requester: name_requester,
      status: status,
    };

    if (!this.validateRejectForm(reason)) {
      return;
    }
    if (reason && requester) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, extend budget",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(
            upperApprovalPurchaseRequest(collection, status, "approved", reason)
          )
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-extend-budget-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been ready for extend budget successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.setState({
                  loading: false,
                  showModalPurchaseRequestReject: false,
                  showModalPurchaseRequest: false,
                });

                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-extend-budget-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  donotextendBudget = (id) => {
    const { dispatch, history } = this.props;
    const { email_requester, requester, name_requester, status } = this.state;
    let collection = {
      _id: id,
      email: email_requester,
      requester: requester,
      name_requester: name_requester,
      status: status,
    };

    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "No, Don't extend budget",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(upperApprovalPurchaseRequest(collection, status, "rejected"))
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId:
                  "customId-donot-extend-budget-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/purchase-service");

                this.setState({
                  loading: false,
                  showModalPurchaseRequest: false,
                });
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-donot-extend-budget-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.value,
        [name + "_label"]: newValue.label,
      });
      if (name === "requisitioner") {
        this.setState({
          isValid: true,
        });
      }

      if (name === "cost_center") {
        this.setState({
          isValidCostCenter: true,
        });
      }
      if (name === "source_determination") {
        this.setState({
          isValidSourceDetermination: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
      });
      if (name === "requisitioner") {
        this.setState({
          isValid: false,
        });
      }
      if (name === "cost_center") {
        this.setState({
          isValidCostCenter: false,
        });
      }
      if (name === "source_determination") {
        this.setState({
          isValidSourceDetermination: false,
        });
      }
    }
  };

  handleDate = (date) => {
    this.setState({
      delivery_date: date,
      isValidDate: true,
    });
  };

  showinputReason = (id) => {
    this.setState({
      showModalPurchaseRequestReject:
        !this.state.showModalPurchaseRequestReject,
    });
  };

  handleSubmitNominalApproval = () => {
    this.setState({ submittedTransfer: true });
    const {
      id,
      requester,
      department,
      email_requester,
      details,
      status,
      name_requester,
    } = this.state;

    const { dispatch, history } = this.props;
    let collection = {
      _id: id,
      requester: requester,
      name_requester: name_requester,
      email: email_requester,
      details: details,
      status: status,
      approval: {
        result: "approved",
      },
    };

    const checkErate = [...details]
      .map((item) => {
        return item.exchange_rate && item.exchange_rate.error ? 1 : 0;
      })
      .reduce((acc, cur) => {
        return acc + cur;
      }, 0);
    if (requester && email_requester && department && checkErate === 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, ready for nominal approval",
      }).then((result) => {
        if (result.value) {
          dispatch(nominalApprovalPurchaseRequest(collection, status))
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-nominal-approval-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.setState({
                  loading: false,
                });
                this.onModalTransferHide();
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-nominal-approval-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  validateForm = (
    document_type,
    requisitioner,
    plant,
    cost_center,
    account_assignment_cat,
    item_cat,
    purchase_group
  ) => {
    let errors = this.state.errors;
    if (!document_type) errors.document_type = "This field is required";
    if (!requisitioner) {
      this.setState({ isValid: false });
      errors.requisitioner = "This field is required";
    }
    if (!plant) errors.plant = "This field is required";
    if (!cost_center) {
      errors.cost_center = "This field is required";
      this.setState({
        isValidCostCenter: false,
      });
    }
    if (!account_assignment_cat)
      errors.account_assignment_cat = "This field is required";
    if (!item_cat) errors.item_cat = "This field is required";
    if (!purchase_group) errors.purchase_group = "This field is required";

    return errors;
  };

  validateRejectForm = (reason) => {
    let errors = this.state.errors;
    if (!reason) {
      errors.reason = "This field is required";
    }
    return errors;
  };

  onModalTransferHide = () => {
    this.setState({
      submittedTransfer: false,
      showModalTransfer: false,
      details: null,
      query_get_remaining_by_month: false,
      data_service: [
        {
          short_text: "",
          item_text: "",
          service_number: "",
          qty: "",
          uom: "",
          delivery_date: "",
          currency: "",
          nominal: "",
          exchange_rate: "",
        },
      ],
      details: [
        {
          short_text: "",
          item_text: "",
          service_number: "",
          qty: "",
          uom: "",
          delivery_date: "",
          currency: "",
          nominal: "",
          exchange_rate: "",
        },
      ],
    });
  };
  getRemaining = () => {
    const { dispatch } = this.props;
    const { details, department } = this.state;
    details.map((obj, idx) => {
      let query = `department=${department}&service_number=${obj.service_number}&delivery_date=${obj.delivery_date}`;

      if (obj.service_number !== "" && obj.delivery_date !== "") {
        dispatch(readBudgetByGlAccount(query)).then((response) => {
          details[idx] = {
            ...details[idx],
            remaining: this.props.read_budget_by_gl_account.data
              ? this.props.read_budget_by_gl_account.data.budget_per_quartal
              : "0",
          };
          details[idx] = {
            ...details[idx],
            budget_interval:
              this.props.read_budget_by_gl_account.data.budget_interval ?? [],
          };
          this.setState({
            details: details,
            budget_interval:
              this.props.read_budget_by_gl_account.data.budget_interval ?? [],
          });
        });
      }
    });
  };

  handleSubmitTransfer = () => {
    this.setState({ submittedTransfer: true });
    const {
      id,
      requester,
      department,
      email_requester,
      details,
      name_requester,
      status,
    } = this.state;
    const { dispatch, history } = this.props;
    let collection = {
      _id: id,
      requester: requester,
      department: department,
      name_requester: name_requester,
      email: email_requester,
      source_determination: null,
      details: details,
      status: status,
    };

    const checkErate = [...details]
      .map((item) => {
        return item.exchange_rate && item.exchange_rate.error ? 1 : 0;
      })
      .reduce((acc, cur) => {
        return acc + cur;
      }, 0);
    const checkDetail = [...details]
      .map((item) => {
        return parseFloat(item.remaining) - parseFloat(item.over) < 0 ? 1 : 0;
      })
      .reduce((acc, val) => {
        return acc + val;
      }, 0);
    if (
      requester &&
      email_requester &&
      department &&
      checkDetail === 0 &&
      checkErate === 0
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, submit entries",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(transferPurchaseRequest(collection))
            .then((response) => {
              toast.success("Data has been submitted successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-trasnfer-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been submitted successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                this.setState({
                  loading: false,
                });
                this.onModalTransferHide();
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-trasnfer-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  showTransfer = async () => {
    const { details, exchange_rate_to_rp, active_exchange_rate } = this.state;
    const { dispatch } = this.props;
    const cloneDetail = [...details];
    const mapDetail = await Promise.all(
      cloneDetail.map(async (item) => {
        item.over =
          parseFloat(item.remain_payment) < 0
            ? Math.abs(item.remain_payment)
            : 0;
        item.prev_remaining = item.remaining;
        item.prev_service_number = item.service_number;
        item.prev_service_number_label = item.service_number_label;
        const result = await dispatch(
          readCurrentExchangeRate(item.currency, "last=true")
        )
          .then((res) => {
            const a = this.props.read_current_exchange_rate?.data[
              active_exchange_rate?.value
            ]
              ? this.props.read_current_exchange_rate?.data[
                  active_exchange_rate?.value
                ]
              : exchange_rate_to_rp;
            let obj = {
              error: false,
              data: a,
              number: a,
              created_at:
                this.props.read_current_exchange_rate?.data?.created_at,
            };
            return obj;
          })
          .catch((err) => {
            let obj = { error: true, data: err, number: 0, created_at: null };
            return obj;
          });
        item.exchange_rate = result;
        return item;
      })
    );

    this.setState(
      {
        details: mapDetail,
        isTransfer: true,
      },
      () => {
        this.getRemaining();
        this.setState({
          showModalTransfer: !this.state.showModalTransfer,
          showModalPurchaseRequest: false,
        });
      }
    );
  };
  render() {
    let {
      columns_service,
      showModal,
      showModalFlow,
      showModalPurchaseRequestReject,
      select_items_requisitioner,
      select_items_uom,
      select_items_cost_center,
      select_items_document_type,
      select_items_plant,
      select_items_purch_group,
      select_items_account_assignment_cat,
      select_items_item_cat,
      select_items_service_number,
      list_data,
      count_list_data,
      id,
      document_type,
      requisitioner,
      plant,
      cost_center,
      account_assignment_cat,
      item_cat,
      purchase_group,
      source_determination,
      document_type_label,
      requisitioner_label,
      plant_label,
      cost_center_label,
      account_assignment_cat_label,
      item_cat_label,
      purchase_group_label,
      errors,
      submitted,
      readOnly,
      showModalPurchaseRequest,
      ready_for_approval,
      isClearable,
      loading,
      fetchLoading,
      isValid,
      isValidCostCenter,
      extend,
      reason,
      status,
      document_number,
      showModalTransfer,
      columns_service_transfer,
      active_exchange_rate,
      details,
      select_items_material,
      select_items_glaccount,
      select_items_currency,
      is_nominal_approval,
      columns_service_nominal_approval,
      created_at,
    } = this.state;

    const customStyles = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "green",
        },
      }),
    };
    const customStylesCostCenter = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidCostCenter
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidCostCenter
            ? "#ddd"
            : "green",
        },
      }),
    };

    const { dispatch } = this.props;
    const loadServiceNumber = (value) => {
      return new Promise((resolve, reject) => {
        const query = {
          search: value,
          size: 1,
          sizePerPage: 10,
        };
        dispatch(readServiceNumber(query)).then(() => {
          resolve(this.props.read_service_number.data);
        });
      });
    };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <ToastContainer />
          {/* MODAL FORM */}
          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
            dialogClassName="my-modal"
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
              autoComplete="off"
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>
                  {id ? "Update" : "Create"} Purchase Request Service
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <div>
                  <Row>
                    <Col xs={12} md={6}>
                      <div
                        className={
                          errors.document_type
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="document_type">Document Type *</label>

                        <select
                          className={
                            errors.document_type !== "This field is required"
                              ? "form-control form-control-md "
                              : "form-control form-control-md  has-error has-feedback"
                          }
                          name="document_type"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={document_type}
                          required
                        >
                          <option value="">Choose</option>
                          {select_items_document_type &&
                            select_items_document_type.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.label}
                                </option>
                              );
                            })}
                        </select>

                        {errors.document_type === "This field is required" && (
                          <div className="invalid-feedback">
                            {errors.document_type}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="requisitioner">Requisitioner *</label>
                        <Select
                          styles={customStyles}
                          placeholder="Choose"
                          onChange={this.handleChange.bind(
                            this,
                            "requisitioner"
                          )}
                          options={select_items_requisitioner}
                          defaultValue={select_items_requisitioner.filter(
                            (e) => e.value === requisitioner
                          )}
                          isClearable={isClearable}
                        />

                        {!isValid && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.requisitioner}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div
                        className={
                          errors.plant !== "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="plant">Plant *</label>
                        <select
                          className={
                            errors.plant !== "This field is required"
                              ? "form-control form-control-md "
                              : "form-control form-control-md  has-error has-feedback"
                          }
                          name="plant"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={plant}
                          required
                        >
                          <option value="">Choose</option>
                          {select_items_plant &&
                            select_items_plant.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.label}
                                </option>
                              );
                            })}
                        </select>

                        {errors.plant === "This field is required" && (
                          <div className="invalid-feedback">{errors.plant}</div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div
                        className={
                          errors.cost_center !== "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="cost_center">Cost Center *</label>
                        <Select
                          styles={customStylesCostCenter}
                          placeholder="Choose"
                          onChange={this.handleChange.bind(this, "cost_center")}
                          options={select_items_cost_center}
                          defaultValue={select_items_cost_center.filter(
                            (e) => e.value === cost_center
                          )}
                          isClearable={isClearable}
                        />
                        {!isValidCostCenter && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.cost_center}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div
                        className={
                          errors.account_assignment_cat !=
                          "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="account_assignment_cat">
                          Account Assignment Cat. *
                        </label>
                        <select
                          className={
                            errors.account_assignment_cat !=
                            "This field is required"
                              ? "form-control form-control-md "
                              : "form-control form-control-md  has-error has-feedback"
                          }
                          name="account_assignment_cat"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={account_assignment_cat}
                          required
                        >
                          {/* <option value="">Choose</option> */}
                          {select_items_account_assignment_cat &&
                            select_items_account_assignment_cat.map(function (
                              item,
                              i
                            ) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.label}
                                </option>
                              );
                            })}
                        </select>

                        {errors.account_assignment_cat ==
                          "This field is required" && (
                          <div className="invalid-feedback">
                            {errors.account_assignment_cat}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="item_cat">Item Cat. *</label>
                        <select
                          className={
                            errors.account_assignment_cat !=
                            "This field is required"
                              ? "form-control form-control-md "
                              : "form-control form-control-md  has-error has-feedback"
                          }
                          name="item_cat"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={item_cat}
                          required
                        >
                          {/* <option value="">Choose</option> */}
                          {select_items_item_cat &&
                            select_items_item_cat.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.label}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div
                        className={
                          errors.purchase_group !== "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="purchase_group">Purchase Group *</label>
                        <select
                          className={
                            errors.purchase_group !== "This field is required"
                              ? "form-control form-control-md "
                              : "form-control form-control-md  has-error has-feedback"
                          }
                          name="purchase_group"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={purchase_group}
                          required
                        >
                          <option value="">Choose</option>
                          {select_items_purch_group &&
                            select_items_purch_group.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.label}
                                </option>
                              );
                            })}
                        </select>

                        {errors.purchase_group === "This field is required" && (
                          <div className="invalid-feedback">
                            {errors.purchase_group}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <DynamicTable
                    data={details}
                    columns={columns_service}
                    uom={select_items_uom}
                    loadServiceNumber={loadServiceNumber}
                    service_number={select_items_service_number}
                    onChange={(data) => {
                      this.setState({
                        details: [...data],
                      });
                    }}
                    onChangeRemaining={(data) => {
                      this.setState(
                        {
                          details: [...data],
                        },
                        () => {
                          this.getRemaining();
                        }
                      );
                    }}
                  />
                </div>
              </ModalBody>
              <ModalFooter className="justify-content-between">
                <Button variant="danger" onClick={this.toggle}>
                  <i className="fas fa-times" /> Cancel
                </Button>
                {id ? (
                  status.includes("Rejected") ? (
                    <Button variant="success" onClick={this.handleSubmit}>
                      <i className="fas fa-save" /> Update and Submit
                    </Button>
                  ) : (
                    <Button variant="success" onClick={this.handleSubmitEdit}>
                      <i className="fas fa-save" /> Update
                    </Button>
                  )
                ) : (
                  <Button variant="success" onClick={this.handleSubmit}>
                    <i className="fas fa-save" /> Submit
                  </Button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          {/* MODAL DETAIL */}
          <Modal
            show={showModalPurchaseRequest}
            size="xl"
            backdrop="static"
            onHide={this.onModalPurchaseRequestHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Detail Purchase Request</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="document_type">Document Type *</label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="document_type"
                          defaultValue={document_type_label}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="requisitioner">Requisitioner *</label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="requisitioner"
                          defaultValue={requisitioner_label}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="plant">Plant *</label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="plant"
                          defaultValue={plant_label}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="cost_center">Cost Center *</label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="cost_center"
                          defaultValue={cost_center_label}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="account_assignment_cat">
                          Account Assignment Cat. *
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="account_assignment_cat"
                          defaultValue={account_assignment_cat_label}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="item_cat">Item Cat. *</label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="item_cat"
                          defaultValue={item_cat_label}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="purchase_group">Purchase Group *</label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="purchase_group"
                          defaultValue={purchase_group_label}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="document_number">
                          Document Number *
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="Document Number"
                          defaultValue={document_number}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                    {created_at && (
                      <Col xs={12} md={6}>
                        <div className="form-group">
                          <label>Created At</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            defaultValue={moment(created_at).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}
                            readOnly={true}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Col xs={12} md={12}>
                    <Table
                      bordered
                      hover
                      size="sm"
                      style={{ textAlign: "center", justifyContent: "center" }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              backgroundColor: "#17A2B8",
                              color: "white",
                            }}
                          >
                            {" "}
                            No.{" "}
                          </th>
                          <th
                            style={{
                              backgroundColor: "#17A2B8",
                              color: "white",
                            }}
                          >
                            {" "}
                            Short Text{" "}
                          </th>
                          <th
                            style={{
                              backgroundColor: "#17A2B8",
                              color: "white",
                            }}
                          >
                            {" "}
                            Item Text{" "}
                          </th>
                          <th
                            style={{
                              backgroundColor: "#17A2B8",
                              color: "white",
                            }}
                          >
                            {" "}
                            Service Number{" "}
                          </th>
                          <th
                            style={{
                              backgroundColor: "#17A2B8",
                              color: "white",
                            }}
                          >
                            {" "}
                            Qty{" "}
                          </th>
                          <th
                            style={{
                              backgroundColor: "#17A2B8",
                              color: "white",
                            }}
                          >
                            {" "}
                            UOM{" "}
                          </th>
                          <th
                            style={{
                              backgroundColor: "#17A2B8",
                              color: "white",
                            }}
                          >
                            {" "}
                            Delivery Date{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {details &&
                          details.map((x, i) => (
                            <tr key={`tr-service-${i}`}>
                              <td key={`td-service1-${i}`}>{i + 1}.</td>
                              <td key={`td-service2-${i}`}>{x.short_text}</td>
                              <td key={`td-service3-${i}`}>{x.item_text}</td>
                              <td key={`td-service4-${i}`}>
                                {x.service_number_label}
                              </td>
                              <td key={`td-service5-${i}`}>{x.qty}</td>
                              <td key={`td-service6-${i}`}>{x.uom}</td>
                              <td key={`td-service7-${i}`}>
                                {x.delivery_date}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Container>
              </ModalBody>
              <ModalFooter className={extend ? "justify-content-between" : ""}>
                {ready_for_approval && status !== "Done" && (
                  <button
                    type="button"
                    className="btn btn-success btn-sm ml-2 mb-2 ts-bottom float-right"
                    size="sm"
                    data-id={id}
                    onClick={() => this.approveData(id)}
                  >
                    <i className="fas fa-check-square"></i>
                    &nbsp;Submit for Approval
                  </button>
                )}
                {extend && status !== "Done" && (
                  <button
                    type="button"
                    className="btn btn-warning btn-sm ml-2 mb-2 ts-bottom"
                    size="sm"
                    data-id={id}
                    onClick={() => this.donotextendBudget(id)}
                  >
                    <i className="fas fa-times"></i>
                    &nbsp;Dont Extend Budget
                  </button>
                )}
                {extend && status !== "Done" && (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm ml-2 mb-2 ts-bottom "
                      size="sm"
                      data-id={id}
                      onClick={() => this.showinputReason(id)}
                    >
                      <i className="fas fa-check-square"></i>
                      &nbsp;Extend Budget
                    </button>
                    <button
                      type="button"
                      className="btn btn-success btn-sm ml-2 mb-2 ts-bottom float-right"
                      size="sm"
                      data-id={id}
                      onClick={() => this.showTransfer(id)}
                    >
                      <i className="fas fa-check-square"></i>
                      &nbsp;Transfer GL Account
                    </button>
                  </>
                )}
              </ModalFooter>
            </form>
          </Modal>
          {/* MODAL FLOW */}
          <Modal
            dialogClassName="modal-flow"
            containerClassName="modal-flow"
            show={showModalFlow}
            backdrop="static"
            onHide={this.onModalFlowHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Flow Purchase Request</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col>Submitted</Col>
                    <Col>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col>Approval Supervisor {this.state.department}</Col>
                    <Col>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col>Approval Manager {this.state.department}</Col>
                    <Col>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col>Approval Checker</Col>
                    <Col>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col>Input Nominal by PRC</Col>
                    <Col>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col>Approval by PRC Manager</Col>
                    <Col>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col>Done</Col>
                  </Row>
                </Container>
              </ModalBody>
            </form>
          </Modal>
          {/* MODAL REJECT */}
          <Modal
            show={showModalPurchaseRequestReject}
            size="md"
            backdrop="static"
            onHide={this.onModalPurchaseRequestRejectHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              autoComplete="off"
            >
              <ModalHeader closeButton className="bg-warning text-white">
                <ModalTitle>Input Reason</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={12}>
                      Over Budget :
                      <Table
                        bordered
                        hover
                        size="sm"
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        <thead>
                          <tr key="0">
                            <th
                              style={{
                                backgroundColor: "#17A2B8",
                                color: "white",
                              }}
                            >
                              {" "}
                              No.{" "}
                            </th>
                            <th
                              style={{
                                backgroundColor: "#17A2B8",
                                color: "white",
                              }}
                            >
                              {" "}
                              GL Account{" "}
                            </th>

                            <th
                              style={{
                                backgroundColor: "#17A2B8",
                                color: "white",
                              }}
                            >
                              {" "}
                              Over{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {details &&
                            details.map((x, i) => (
                              <tr key={i + 1}>
                                <td key={`td-2-${i + 1}`}>{i + 1}.</td>
                                <td key={`td-4-${i + 1}`}>
                                  {x?.service_number_label}
                                </td>
                                <td key={`td-5-${i + 1}`}>
                                  {addSeparatorsNF(
                                    Math.abs(x.remain_payment)
                                      ? Math.abs(x.remain_payment)
                                      : 0,
                                    ".",
                                    ".",
                                    ","
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs={12} md={12}>
                      <div className="form-group">
                        <label htmlFor="reason">Reason *</label>
                        <textarea
                          id="reason"
                          className="form-control form-control-sm"
                          name="reason"
                          placeholder="Reason"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={reason}
                          rows="5"
                        />

                        {errors.reason && (
                          <div className="invalid-feedback">
                            {errors.reason}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="success"
                  onClick={() => this.extendBudget(id)}
                  className="btn btn-success btn-sm ml-2 mb-2 ts-bottom"
                >
                  <i className="fas fa-save" /> Extend
                </Button>
              </ModalFooter>
            </form>
          </Modal>
          <Modal
            show={showModalTransfer}
            size="xl"
            backdrop="static"
            onHide={this.onModalTransferHide}
            dialogClassName="full-modal-dialog"
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>
                  {is_nominal_approval
                    ? "Nominal Approval"
                    : "Transfer GL Account"}{" "}
                  on PR Service
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="document_type">Document Type *</label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="document_type"
                        defaultValue={document_type_label}
                        readOnly={true}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="requisitioner">Requisitioner *</label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="requisitioner"
                        defaultValue={requisitioner_label}
                        readOnly={true}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="plant">Plant *</label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="plant"
                        defaultValue={plant_label}
                        readOnly={true}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="cost_center">Cost Center *</label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="cost_center"
                        defaultValue={cost_center_label}
                        readOnly={true}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="account_assignment_cat">
                        Account Assignment Cat. *
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="account_assignment_cat"
                        defaultValue={account_assignment_cat_label}
                        readOnly={true}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="item_cat">Item Cat. *</label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="item_cat"
                        defaultValue={item_cat_label}
                        readOnly={true}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="purchase_group">Purchase Group *</label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="purchase_group"
                        defaultValue={purchase_group_label}
                        readOnly={true}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="document_number">Document Number *</label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="Document Number"
                        defaultValue={document_number}
                        readOnly={true}
                      />
                    </div>
                  </Col>
                  {created_at && (
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label>Created At</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          defaultValue={moment(created_at).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
                <Col xs={12} md={12}>
                  <DynamicTable
                    data={details}
                    columns={
                      is_nominal_approval
                        ? columns_service_nominal_approval
                        : columns_service_transfer
                    }
                    materials={select_items_material}
                    uom={select_items_uom}
                    gl_account={select_items_glaccount}
                    currency={select_items_currency}
                    service_number={select_items_service_number}
                    loadServiceNumber={loadServiceNumber}
                    props={this.props}
                    inputNominal={true}
                    onChange={(data) => {
                      this.setState(
                        {
                          details: [...data],
                        },
                        () => {
                          //call new function if needed
                        }
                      );
                    }}
                    onChangeRemaining={(data) => {
                      this.setState(
                        {
                          details: [...data],
                          query_get_remaining_by_month: is_nominal_approval
                            ? false
                            : true,
                        },
                        () => {
                          this.getRemaining();
                        }
                      );
                    }}
                    readOnly={true}
                    isTransfer={
                      is_nominal_approval ? is_nominal_approval : true
                    }
                    active_exchange_rate={active_exchange_rate}
                  />
                </Col>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="success"
                  onClick={
                    is_nominal_approval
                      ? this.handleSubmitNominalApproval
                      : this.handleSubmitTransfer
                  }
                >
                  {is_nominal_approval ? (
                    <>
                      <i className="fas fa-check-square" />{" "}
                      {"Ready for Nominal Approval"}
                    </>
                  ) : (
                    <>
                      <i className="fas fa-save" /> {"Submit"}
                    </>
                  )}
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">Purchase Request Service</h3>
                        <div className="card-tools float-right">
                          <Button color="warning" onClick={this.toggle}>
                            <i className="fas fa-plus-square" /> New
                          </Button>
                        </div>
                      </div>
                      <div className="card-body">
                        <TableCustom
                          data={list_data}
                          totalRows={count_list_data}
                          loading={fetchLoading}
                          action={this.GetActionFormat}
                          toggleStatus={this.toggleFlowPurchaseRequest}
                          fetchData={this.fetchPurchaseRequest}
                          handleSearch={this.handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { read_purchase_request } = state.purchase_request;
  const { read_department } = state.department;
  const { read_uom } = state.uom;
  const { read_material } = state.material;
  const { read_supplier } = state.supplier;
  const { read_glaccount } = state.glaccount;
  const { read_costcenter } = state.costcenter;
  const { read_document_type } = state.document_type;
  const { read_requisitioner } = state.requisitioner;
  const { read_plant } = state.plant;
  const { read_purch_group } = state.purch_group;
  const { read_account_assignment_cat } = state.account_assignment_cat;
  const { read_service_number } = state.service_number;
  const { read_budget_by_gl_account } = state.budget;
  const { read_current_exchange_rate, read_active_exchange_rate } =
    state.exchange_rate;

  const { message } = state.message;
  return {
    message,
    read_purchase_request,
    user,
    read_department,
    read_uom,
    read_material,
    read_supplier,
    read_glaccount,
    read_costcenter,
    read_document_type,
    read_requisitioner,
    read_plant,
    read_purch_group,
    read_account_assignment_cat,
    read_service_number,
    read_budget_by_gl_account,
    read_current_exchange_rate,
    read_active_exchange_rate,
  };
}

export default connect(mapStateToProps)(PurchaseRequest);
