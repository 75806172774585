import {
  CREATE_DOCUMENT_TYPE_SUCCESS,
  CREATE_DOCUMENT_TYPE_FAIL,
  SET_MESSAGE,
  READ_DOCUMENT_TYPE_SUCCESS,
  READ_DOCUMENT_TYPE_FAIL,
  EDIT_DOCUMENT_TYPE_SUCCESS,
  EDIT_DOCUMENT_TYPE_FAIL,
  DELETE_DOCUMENT_TYPE_SUCCESS,
  DELETE_DOCUMENT_TYPE_FAIL,
} from "./types";
import DocumentTypeService from "../services/document_type.service";

export const addDocumentType = (payload) => (dispatch) => {
  return DocumentTypeService.create(payload).then(
    (response) => {
      dispatch({
        type: CREATE_DOCUMENT_TYPE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_DOCUMENT_TYPE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editDocumentType = (payload) => (dispatch) => {
  return DocumentTypeService.edit(payload).then(
    (response) => {
      dispatch({
        type: EDIT_DOCUMENT_TYPE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_DOCUMENT_TYPE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deleteDocumentType = (payload) => (dispatch) => {
  return DocumentTypeService.delete(payload).then(
    (response) => {
      dispatch({
        type: DELETE_DOCUMENT_TYPE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_DOCUMENT_TYPE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readDocumentType = () => (dispatch) => {
  return DocumentTypeService.readDocumentType().then(
    (response) => {
      dispatch({
        type: READ_DOCUMENT_TYPE_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_DOCUMENT_TYPE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
