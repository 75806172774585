import {
  CREATE_BUDGET_SUCCESS,
  CREATE_BUDGET_FAIL,
  READ_BUDGET_SUCCESS,
  READ_BUDGET_FAIL,
  EDIT_BUDGET_SUCCESS,
  EDIT_BUDGET_FAIL,
  DELETE_BUDGET_SUCCESS,
  DELETE_BUDGET_FAIL,
  READY_FOR_UPPER_APPROVAL_BUDGET_SUCCESS,
  READY_FOR_UPPER_APPROVAL_BUDGET_FAIL,
  READ_BUDGET_GL_ACCOUT_SUCCESS,
  READ_BUDGET_GL_ACCOUT_FAIL,
  READ_REMAINING_BUDGET_ALL_MONTH_SUCCESS,
  READ_REMAINING_BUDGET_ALL_MONTH_FAIL,
} from "../actions/types";

const initialState = {
  read_budget: null,
};

export default function budget(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_BUDGET_SUCCESS:
      return {
        ...state,
      };
    case CREATE_BUDGET_FAIL:
      return {
        ...state,
      };

    case READ_BUDGET_GL_ACCOUT_SUCCESS:
      return {
        ...state,
        read_budget_by_gl_account: payload,
      };
    case READ_BUDGET_GL_ACCOUT_FAIL:
      return {
        ...state,
        read_budget_by_gl_account: null,
      };

    case EDIT_BUDGET_SUCCESS:
      return {
        ...state,
      };
    case EDIT_BUDGET_FAIL:
      return {
        ...state,
      };
    case READ_BUDGET_SUCCESS:
      return {
        ...state,
        read_budget: payload,
      };
    case READ_BUDGET_FAIL:
      return {
        ...state,
        read_budget: null,
      };
    case DELETE_BUDGET_SUCCESS:
      return {
        ...state,
      };
    case DELETE_BUDGET_FAIL:
      return {
        ...state,
      };
    case READY_FOR_UPPER_APPROVAL_BUDGET_SUCCESS:
      return {
        ...state,
      };
    case READY_FOR_UPPER_APPROVAL_BUDGET_FAIL:
      return {
        ...state,
      };
    case READ_REMAINING_BUDGET_ALL_MONTH_SUCCESS:
      return {
        ...state,
        read_remaining_budget: payload,
      };
    case READ_REMAINING_BUDGET_ALL_MONTH_FAIL:
      return {
        ...state,
        read_remaining_budget: null,
      };
    default:
      return state;
  }
}
