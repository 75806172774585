import {   
  CREATE_CURRENCY_SUCCESS,
  CREATE_CURRENCY_FAIL,
  SET_MESSAGE, 
  READ_CURRENCY_SUCCESS,
  READ_CURRENCY_FAIL,
  EDIT_CURRENCY_SUCCESS,
  EDIT_CURRENCY_FAIL,
  DELETE_CURRENCY_SUCCESS,
  DELETE_CURRENCY_FAIL, 
  READ_CURRENCY_WITHOUT_RP_SUCCESS,
  READ_CURRENCY_WITHOUT_RP_FAIL,
 } from "./types";
import CurrencyService from "../services/currency.service";
  
export const addCurrency = (payload) => (dispatch) => {
  return CurrencyService.create(payload).then(
    (response) => {
      dispatch({
        type: CREATE_CURRENCY_SUCCESS,
        payload:response.data
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_CURRENCY_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editCurrency = (payload) => (dispatch) => {
  return CurrencyService.edit(payload).then(
    (response) => {
      dispatch({
        type: EDIT_CURRENCY_SUCCESS,
        payload:response.data
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_CURRENCY_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deleteCurrency = (payload) => (dispatch) => {
  return CurrencyService.delete(payload).then(
    (response) => {
      dispatch({
        type: DELETE_CURRENCY_SUCCESS,
        payload:response.data
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_CURRENCY_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
}; 

export const readCurrency = () => (dispatch) => {
  return CurrencyService.readCurrency().then(
    (response) => { 
      dispatch({
        type: READ_CURRENCY_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_CURRENCY_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
}; 

 
export const readCurrencyWithoutRP = () => (dispatch) => {
  return CurrencyService.readCurrencyWithoutRP().then(
    (response) => { 
      dispatch({
        type: READ_CURRENCY_WITHOUT_RP_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_CURRENCY_WITHOUT_RP_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
}; 