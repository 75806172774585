import {
  CREATE_MATERIAL_SUCCESS,
  CREATE_MATERIAL_FAIL,
  READ_MATERIAL_SUCCESS,
  READ_MATERIAL_FAIL,
  READ_MATERIAL_UOM_SUCCESS,
  READ_MATERIAL_UOM_FAIL,
  EDIT_MATERIAL_SUCCESS,
  EDIT_MATERIAL_FAIL,
  DELETE_MATERIAL_SUCCESS,
  DELETE_MATERIAL_FAIL,
} from "../actions/types";

const initialState = {
  read_material: null,
};

export default function material(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case CREATE_MATERIAL_SUCCESS:
      return {
        ...state,
      };
    case CREATE_MATERIAL_FAIL:
      return {
        ...state,
      };

    case EDIT_MATERIAL_SUCCESS:
      return {
        ...state,
      };
    case EDIT_MATERIAL_FAIL:
      return {
        ...state,
      };
    case READ_MATERIAL_SUCCESS:
      return {
        ...state,
        read_material: payload,
      };
    case READ_MATERIAL_FAIL:
      return {
        ...state,
        read_material: null,
      };
    case READ_MATERIAL_UOM_SUCCESS:
      return {
        ...state,
        read_material_uom: payload,
      };
    case READ_MATERIAL_UOM_FAIL:
      return {
        ...state,
        read_material_uom: null,
      };
    case DELETE_MATERIAL_SUCCESS:
      return {
        ...state,
      };
    case DELETE_MATERIAL_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
