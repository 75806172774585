import React, { Component, useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import RemoteTable from "../components/RemoteTable";
import PropTypes from "prop-types";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import addSeparatorsNF from "../helpers/thousanddelimiter";
import Select from "react-select";
import { connect } from "react-redux";
import {
  addPurchaseRequest,
  readPurchaseRequest,
  editPurchaseRequest,
  deletePurchaseRequest,
  upperApprovalPurchaseRequest,
  transferPurchaseRequest,
  nominalApprovalPurchaseRequest,
} from "../actions/purchase_request";
import { readBudgetByGlAccount } from "../actions/budget";
import { readUOM } from "../actions/uom";
import { readCurrency } from "../actions/currency";
import {
  readCurrentExchangeRate,
  readActiveExchangeRate,
} from "../actions/exchange_rate";
import { readMATERIAL } from "../actions/material";
import { readSUPPLIER } from "../actions/supplier";
import { readRequisitioner } from "../actions/requisitioner";
import { readGLAccount } from "../actions/glaccount";
import { clearMessage } from "../actions/message";
import { readCostCenter } from "../actions/costcenter";
import { readDocumentType } from "../actions/document_type";
import { readPlant } from "../actions/plant";
import { readPurchGroup } from "../actions/purch_group";
import { readAccountAssignmentCat } from "../actions/account_assignment_cat";
import DynamicTable from "../components/DynamicTable";
import Table from "react-bootstrap/Table";
import moment from "moment";

const TableCustom = ({
  data,
  totalRows,
  action,
  toggleStatus,
  fetchData,
  handleSearch,
  loading,
}) => {
  const [perPage, setPerPage] = useState(10);
  const handleChange = (e) => {
    handleSearch({
      [e.target.name]: e.target.value,
    });
  };

  const columns = useMemo(() => {
    return [
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Requester"
                onChange={handleChange}
                name="requester"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => `${row.name_requester} (${row.requester})`,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Document Number"
                onChange={handleChange}
                name="document_number"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => row.document_number,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Summary"
                onChange={handleChange}
                name="summary"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        cell: (row) => {
          const summary = (row?.details || [])
            .map((e) => e?.short_text)
            .join(", ");
          return summary;
        },
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Status"
                onChange={handleChange}
                name="status"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        cell: (row) => {
          let message_reject = "";
          if (row.isRejected === true) {
            message_reject = row.input_approval_l1.reason
              ? row.input_approval_l1.reason
              : row.input_approval_l2.reason;
          }
          return (
            <div>
              <button
                type="button"
                className="btn btn-sm ml-2 mb-2 ts-buttom"
                size="sm"
                onClick={toggleStatus}
              >
                <i className="fas fa-search-location"></i>
                &nbsp;{row.status}
                <br></br>
                {message_reject ? `Reason : ${message_reject}` : ""}
              </button>
            </div>
          );
        },
        sortable: true,
      },
      {
        name: "Action",
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: action,
      },
    ];
  }, []);

  const handlePageChange = async (page) => {
    await fetchData({
      page,
      sizePerPage: perPage,
    });
  };

  const handlePerPageChange = async (newPerPage, page) => {
    await fetchData({
      page,
      sizePerPage: newPerPage,
    });

    setPerPage(newPerPage);
  };

  return (
    <DataTable
      persistTableHead={true}
      columns={columns}
      data={data}
      pagination
      paginationServer
      paginationPerPage={perPage}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      paginationTotalRows={totalRows}
      progressPending={loading}
    />
  );
};

TableCustom.propTypes = {
  data: PropTypes.instanceOf(Array),
  totalRows: PropTypes.number,
  action: PropTypes.func,
  toggleStatus: PropTypes.func,
  fetchData: PropTypes.func,
  handleSearch: PropTypes.func,
  loading: PropTypes.bool,
};

TableCustom.defaultProps = {
  data: [],
  totalRows: 0,
  loading: false,
};

class PurchaseRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClearable: true,
      isValid: true,
      isValidGLAccount: true,
      isValidDate: true,
      isValidMaterial: true,
      isValidSupplier: true,
      isValidCostCenter: true,
      isValidDetails: false,
      loading: false,
      fetchLoading: false,
      is_nominal_approval: false,
      query_get_remaining_by_month: false,
      extend: false,
      submitted: false,
      showModal: false,
      showModalTransfer: false,
      showModalPurchaseRequest: false,
      showModalPurchaseRequestReject: false,
      showModalFlow: false,
      readOnly: false,
      collection: [],
      select_items_cost_center: [],
      select_items_glaccount: [],
      select_items_source_determination: [],
      select_items_material: [],
      select_items_requisitioner: [],
      select_items_uom: [],
      select_items_document_type: [],
      select_items_plant: [],
      select_items_purch_group: [],
      select_items_account_assignment_cat: [],
      select_items_currency: [],
      list_data: [],
      count_list_data: 0,
      errors: {},
      active_exchange_rate: null,
      exchange_rate_to_rp: "1",
      id: null,
      reason: null,

      requester: null,
      email_requester: null,
      department: null,
      id_department: null,

      document_type: null,
      source_determination: null,
      document_number: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      remark: null,
      account_assignment_cat: null,
      item_cat: null,

      g_l_account: null,
      cost_center: null,
      ready_for_approval: false,
      ready_for_approval_l1: false,
      ready_for_approval_l2: false,
      type: null,
      created_at: null,
      details: [
        {
          material_code: "",
          material_name: "",
          short_text: "",
          glaccount: "",
          gl_account_code: "",
          qty: "",
          uom: "",
          delivery_date: "",
          currency: "",
          nominal: "",
          remaining: "",
        },
      ],
      details_validity: [
        {
          material_code: true,
          material_name: true,
        },
      ],
      columns: [
        {
          dataField: "requester",
          text: "Requester",
          filter: textFilter(),
          formatter: this.GetRequesterFormat,
          sort: true,
        },
        {
          dataField: "document_number",
          text: "Document Number",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "details",
          text: "Summary",
          filter: textFilter(),
          formatter: this.GetDetailItems,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "status",
          text: "Status",
          filter: textFilter(),
          formatter: this.GetStatusFormat,
          classes: "text-center",
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      columns_inventory: [
        {
          text: "Material",
          type: "material",
          width: "20%",
        },
        {
          text: "Long Text",
          type: "text",
        },
        {
          text: "GL Account",
          type: "glaccount",
          width: "20%",
        },
        {
          text: "Qty",
          type: "numeric",
        },
        {
          text: "UOM",
          type: "uom",
        },
        {
          text: "Delivery Date",
          type: "date",
        },
        {
          text: "Budget Remaining",
          type: "remaining",
        },
      ],
      columns_inventory_transfer: [
        {
          text: "Material",
          type: "material",
          width: "10%",
        },
        {
          text: "Long Text",
          type: "text",
          width: "10%",
        },
        {
          text: "Qty",
          type: "numeric",
        },
        {
          text: "UOM",
          type: "uom",
        },
        {
          text: "Delivery Date",
          type: "date",
          width: "5%",
        },
        {
          text: "Prev GL Account",
          type: "prev_glaccount",
        },
        {
          text: "Nominal",
          type: "after_input_nominal",
        },
        {
          text: "Exchange Rate",
          type: "exchange_rate",
        },
        {
          text: "Prev Budget Remaining",
          type: "prev_remaining",
        },
        {
          text: "Over Budget",
          type: "over",
        },
        {
          text: "Transfer GL Account",
          type: "glaccount",
          width: "20%",
        },
        {
          text: "Transfer Budget Remaining",
          type: "remaining",
          width: "10%",
        },
      ],
      defaultSorted: [
        {
          dataField: "requester",
          order: "asc",
        },
      ],
      columns_inventory_nominal_approval: [
        {
          text: "Material",
          type: "material",
          width: "10%",
        },
        {
          text: "Long Text",
          type: "text",
          width: "10%",
        },
        {
          text: "Qty",
          type: "numeric",
          width: "5%",
        },
        {
          text: "UOM",
          type: "uom",
          width: "5%",
        },
        {
          text: "Delivery Date",
          type: "date",
          width: "5%",
        },
        {
          text: "GL Account",
          type: "prev_glaccount",
          width: "10%",
        },
        {
          text: "Nominal",
          type: "after_input_nominal",
          width: "10%",
        },
        {
          text: "Exchange Rate",
          type: "exchange_rate",
          width: "10%",
        },
        {
          text: "Budget Remaining",
          type: "prev_remaining",
          width: "10%",
        },
      ],
      cellEditProps: {},
      requesterFilter: "",
      documentNumberFilter: "",
      summaryFilter: "",
      statusFilter: "",
      search: {},
    };
  }

  fetchPurchaseRequest = (additionalParams = { page: 1, sizePerPage: 10 }) => {
    const { dispatch, user } = this.props;
    this.setState({
      fetchLoading: true,
    });

    dispatch(
      readPurchaseRequest(user.username, "requester", {
        ...additionalParams,
        search: this.state.search,
        pr_type: "inventory",
      })
    ).then((response) => {
      this.setState({
        list_data: this.props.read_purchase_request.data.foundData,
        count_list_data: this.props.read_purchase_request.data.countData,
        fetchLoading: false,
      });
    });
  };

  componentDidMount() {
    const { dispatch } = this.props;
    this.reloadDataBackend();
    dispatch(readActiveExchangeRate()).then(() => {
      this.setState({
        active_exchange_rate: this.props.read_active_exchange_rate.data,
      });
    });
  }

  reloadDataBackend() {
    const { dispatch, user } = this.props;
    this.fetchPurchaseRequest();

    dispatch(readRequisitioner()).then(() => {
      this.setState({
        select_items_requisitioner: this.props.read_requisitioner.data,
      });
    });

    dispatch(readUOM()).then(() => {
      this.setState({ select_items_uom: this.props.read_uom.data });
    });
    dispatch(readMATERIAL({ size: 1, sizePerPage: 1 })).then(() => {
      this.setState({ select_items_material: this.props.read_material.data });
    });
    dispatch(readSUPPLIER({ size: 1, sizePerPage: 1 })).then(() => {
      this.setState({
        select_items_source_determination: this.props.read_supplier.data,
      });
    });

    dispatch(readGLAccount({ size: 1, sizePerPage: 1 })).then(() => {
      this.setState({ select_items_glaccount: this.props.read_glaccount.data });
    });
    dispatch(
      readCostCenter(user.details.hris_org_tree.current_person.nama_department)
    ).then(() => {
      this.setState({
        select_items_cost_center: this.props.read_costcenter.data,
      });
    });

    dispatch(readDocumentType()).then(() => {
      this.setState({
        select_items_document_type: this.props.read_document_type.data,
      });
    });
    dispatch(readPlant()).then(() => {
      this.setState({ select_items_plant: this.props.read_plant.data });
    });
    dispatch(readPurchGroup()).then(() => {
      this.setState({
        select_items_purch_group: this.props.read_purch_group.data,
      });
    });
    dispatch(readAccountAssignmentCat()).then(() => {
      this.setState({
        select_items_account_assignment_cat:
          this.props.read_account_assignment_cat.data,
      });
    });
    dispatch(readCurrency()).then(() => {
      this.setState({ select_items_currency: this.props.read_currency.data });
    });

    this.setState({
      details: [
        {
          material_code: "",
          material_name: "",
          short_text: "",
          glaccount: "",
          gl_account_code: "",
          qty: "",
          uom: "",
          delivery_date: "",
          currency: "",
          nominal: "",
          remaining: "",
        },
      ],
      requester: user.username,
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      email_requester: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.email
        : null,
      name_requester: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.person_name
        : null,
      id_department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.department_id
        : null,
      is_nominal_approval: false,
      ready_for_approval: false,
      extend: false,
      showModalPurchaseRequest: false,
    });
  }

  handleSearch = (data) => {
    this.setState((prevState) => ({
      search: { ...prevState.search, ...data },
    }));

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fetchPurchaseRequest();
    }, 250);
  };

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      document_type: null,
      source_determination: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      g_l_account: null,
      showModal: !this.state.showModal,
      readOnly: false,
      isValid: true,
      isValidDate: true,
      isValidGLAccount: true,
      reason: null,
      cost_center: null,
      remark: null,
      account_assignment_cat: null,
      type: null,
      details: [
        {
          material_code: "",
          material_name: "",
          short_text: "",
          gl_account_code: "",
          qty: "",
          uom: "",
          delivery_date: "",
          currency: "",
          nominal: "",
          remaining: "",
        },
      ],
      details_validity: [
        {
          material_code: false,
          material_name: false,
        },
      ],
    });
  };
  onModalPurchaseRequestHide = () => {
    this.setState({
      id: null,
      submitted: false,

      document_type: null,
      source_determination: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      g_l_account: null,

      showModalPurchaseRequest: !this.state.showModalPurchaseRequest,
      readOnly: false,
      ready_for_approval: false,
      reason: null,
      cost_center: null,
      remark: null,
      account_assignment_cat: null,
      details: [
        {
          material_code: "",
          material_name: "",
          short_text: "",
          qty: "",
          uom: "",
          delivery_date: "",
          currency: "",
          nominal: "",
          remaining: "",
        },
      ],
      details_validity: [
        {
          material_code: false,
          material_name: false,
          short_text: false,
          qty: false,
          uom: false,
          delivery_date: false,
        },
      ],
    });
  };

  onModalPurchaseRequestRejectHide = () => {
    this.setState({
      id: null,
      submitted: false,
      showModalPurchaseRequestReject:
        !this.state.showModalPurchaseRequestReject,
      readOnly: true,
      reason: null,
    });
  };

  onModalTransferHide = () => {
    this.setState({
      id: null,
      submittedTransfer: false,
      showModalTransfer: !this.state.showModalTransfer,
      document_type: null,
      source_determination: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      g_l_account: null,
      readOnly: false,
      ready_for_approval: false,
      reason: null,
      cost_center: null,
      remark: null,
      account_assignment_cat: null,
      details: [
        {
          material_code: "",
          material_name: "",
          short_text: "",
          qty: "",
          uom: "",
          delivery_date: "",
          currency: "",
          nominal: "",
          remaining: "",
          exchange_rate: "",
        },
      ],
      details_validity: [
        {
          material_code: false,
          material_name: false,
          short_text: false,
          qty: false,
          uom: false,
          delivery_date: false,
        },
      ],
      query_get_remaining_by_month: false,
    });
  };
  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  togglePurchaseRequest = () => {
    this.setState({
      showModalPurchaseRequest: !this.state.showModalPurchaseRequest,
    });
  };

  toggleFlowPurchaseRequest = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };
  onModalFlowHide = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };
  toDetail = (
    id,
    document_type,
    source_determination,
    material,
    quantity,
    unit_of_measure,
    delivery_date,
    plant,
    purchase_group,
    requisitioner,
    status,
    g_l_account,
    cost_center,
    remark,
    account_assignment_cat,
    details,
    created_at
  ) => {
    this.setState(
      {
        id: id,
        document_type: document_type,
        source_determination: source_determination,
        material: material,
        quantity: quantity,
        unit_of_measure: unit_of_measure,
        delivery_date: delivery_date,
        plant: plant,
        purchase_group: purchase_group,
        requisitioner: requisitioner,
        status: status,
        g_l_account: g_l_account,
        cost_center: cost_center,
        remark: remark,
        account_assignment_cat: account_assignment_cat,
        details: details,
        created_at: created_at,
      },
      () => {
        this.toggle();
        this.getRemaining();
      }
    );
  };
  toDetailPurchaseRequest = (
    id,
    document_type,
    source_determination,
    material,
    quantity,
    unit_of_measure,
    delivery_date,
    plant,
    purchase_group,
    requisitioner,
    status,
    ready_for_approval,
    g_l_account,
    extend,
    cost_center,
    remark,
    account_assignment_cat,
    details,
    document_number,
    is_nominal_approval = false,
    created_at
  ) => {
    const { dispatch } = this.props;
    if (status === "Submitted") {
      return this.setState(
        {
          id: id,
          document_type: document_type,
          source_determination: source_determination,
          material: material,
          quantity: quantity,
          unit_of_measure: unit_of_measure,
          delivery_date: delivery_date,
          plant: plant,
          purchase_group: purchase_group,
          requisitioner: requisitioner,
          status: status,
          readOnly: true,
          ready_for_approval: ready_for_approval,
          g_l_account: g_l_account,
          extend: extend,
          cost_center: cost_center,
          remark: remark,
          account_assignment_cat: account_assignment_cat,
          details: details,
          document_number: document_number,
          created_at: created_at,
        },
        () => {
          this.togglePurchaseRequest();
        }
      );
    }
    return this.setState(
      {
        id: id,
        document_type: document_type,
        source_determination: source_determination,
        material: material,
        quantity: quantity,
        unit_of_measure: unit_of_measure,
        delivery_date: delivery_date,
        plant: plant,
        purchase_group: purchase_group,
        requisitioner: requisitioner,
        status: status,
        readOnly: true,
        g_l_account: g_l_account,
        extend: extend,
        cost_center: cost_center,
        remark: remark,
        account_assignment_cat: account_assignment_cat,
        document_number: document_number,
        details: details,
        is_nominal_approval: is_nominal_approval,
        created_at: created_at,
      },
      () => {
        if (is_nominal_approval && status === "Need Nominal Approval") {
          return this.showTransfer(id);
        }
        this.togglePurchaseRequest();
      }
    );
  };

  GetRequesterFormat = (cell, row) => {
    return (
      <div>
        {row.name_requester} ({row.requester})
      </div>
    );
  };
  GetQTYFormat = (cell, row) => {
    return (
      <div>
        {addSeparatorsNF(row.quantity, ".", ".", ",")} &nbsp;
        {row.unit_of_measure}
      </div>
    );
  };
  GetDetailItems = (cell, row) => {
    const items = (row?.details || []).map((e) => e?.short_text).join(", ");
    return <div>{items}</div>;
  };
  GetStatusFormat = (cell, row) => {
    let message_reject = "";
    if (row.isRejected === true) {
      message_reject = row.input_approval_l1.reason
        ? row.input_approval_l1.reason
        : row.input_approval_l2.reason;
    }
    return (
      <div>
        <button
          type="button"
          className="btn btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => this.toggleFlowPurchaseRequest()}
        >
          <i className="fas fa-search-location"></i>
          &nbsp;{row.status}
          <br></br>
          {message_reject ? `Reason : ${message_reject}` : ""}
        </button>
      </div>
    );
  };
  GetActionFormat = (row) => {
    let date = row.delivery_date.split("-");
    let date_baru = new Date(date[0], date[1] - 1, date[2]);
    let extend = false;
    if (row.overbudget) {
      if (row.overbudget.isOverBudget) {
        extend = true;
      }
    }
    if (row.status === "Submitted") {
      return (
        <div>
          <button
            type="button"
            className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() => {
              this.toDetailPurchaseRequest(
                row._id,
                row.document_type,
                row.source_determination,
                row.material,
                row.quantity,
                row.unit_of_measure,
                row.delivery_date,
                row.plant,
                row.purchase_group,
                row.requisitioner,
                row.status,
                true,
                row.g_l_account,
                extend,
                row.cost_center,
                row.remark,
                row.account_assignment_cat,
                row.details,
                row.document_number,
                false,
                row.created_at
              );
            }}
          >
            <i className="fas fa-th"></i>
            &nbsp;Detail
          </button>
          <button
            type="button"
            className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() =>
              this.toDetail(
                row._id,
                row.document_type,
                row.source_determination,
                row.material,
                row.quantity,
                row.unit_of_measure,
                date_baru,
                row.plant,
                row.purchase_group,
                row.requisitioner,
                row.status,
                row.g_l_account,
                row.cost_center,
                row.remark,
                row.account_assignment_cat,
                row.details,
                row.created_at
              )
            }
          >
            <i className="fas fa-edit"></i>
            &nbsp;Edit
          </button>
          <button
            type="button"
            className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            data-id={row._id}
            onClick={() => this.deleteData(row._id)}
          >
            <i className="fas fa-trash"></i>
            &nbsp;Delete
          </button>
        </div>
      );
    }
    if (row.status.includes("Rejected")) {
      return (
        <div>
          <button
            type="button"
            className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() =>
              this.toDetailPurchaseRequest(
                row._id,
                row.document_type,
                row.source_determination,
                row.material,
                row.quantity,
                row.unit_of_measure,
                row.delivery_date,
                row.plant,
                row.purchase_group,
                row.requisitioner,
                row.status,
                false,
                row.g_l_account,
                extend,
                row.cost_center,
                row.remark,
                row.account_assignment_cat,
                row.details,
                row.document_number,
                false,
                row.created_at
              )
            }
          >
            <i className="fas fa-th"></i>
            &nbsp;Detail
          </button>
          <button
            type="button"
            className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() =>
              this.toDetail(
                row._id,
                row.document_type,
                row.source_determination,
                row.material,
                row.quantity,
                row.unit_of_measure,
                date_baru,
                row.plant,
                row.purchase_group,
                row.requisitioner,
                row.status,
                row.g_l_account,
                row.cost_center,
                row.remark,
                row.account_assignment_cat,
                row.details
              )
            }
          >
            <i className="fas fa-edit"></i>
            &nbsp;Edit
          </button>
        </div>
      );
    }
    return (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetailPurchaseRequest(
              row._id,
              row.document_type,
              row.source_determination,
              row.material,
              row.quantity,
              row.unit_of_measure,
              row.delivery_date,
              row.plant,
              row.purchase_group,
              row.requisitioner,
              row.status,
              false,
              row.g_l_account,
              extend,
              row.cost_center,
              row.remark,
              row.account_assignment_cat,
              row.details,
              row.document_number,
              row.is_nominal_approval,
              row.created_at
            )
          }
        >
          <i className="fas fa-th"></i>
          &nbsp;Detail
        </button>
      </div>
    );
  };
  isEmptyObject = (obj) => {
    return obj === null || !Object.keys(obj).length;
  };
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  handleSubmit = () => {
    this.setState({ submitted: true });
    const { dispatch, history } = this.props;
    const {
      id,
      requester,
      name_requester,
      department,
      id_department,
      email_requester,
      document_type,
      source_determination,
      plant,
      purchase_group,
      requisitioner,
      details,
    } = this.state;
    if (
      !this.validateForm(
        document_type,
        plant,
        purchase_group,
        source_determination,
        details
      )
    ) {
      return;
    }
    let collection = {
      _id: id,
      requester: requester,
      name_requester: name_requester,
      department: department,
      id_department: id_department,
      email_requester: email_requester,
      document_type: document_type,
      source_determination: source_determination,
      plant: plant,
      purchase_group: purchase_group,
      requisitioner: requisitioner !== null ? requisitioner : department,
      pr_type: "inventory",
      details: details,
    };
    for (let obj = 0; obj < details.length; obj++) {
      if (parseInt(details[obj].qty) < 0) {
        Swal.fire({
          title: "Warning?",
          text: "Qty can't minus",
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }
    }
    if (
      requester &&
      email_requester &&
      department &&
      document_type &&
      plant &&
      purchase_group
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          dispatch(addPurchaseRequest(collection))
            .then(() => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                history.push("/input/purchase-request-inventory");
                this.setState({
                  loading: false,
                });
                this.onModalHide();
                this.reloadDataBackend();
              });
            })
            .catch(() => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  handleSubmitTransfer = () => {
    this.setState({ submittedTransfer: true });
    const {
      id,
      requester,
      department,
      email_requester,
      name_requester,
      details,
      status,
    } = this.state;

    const { dispatch } = this.props;
    let collection = {
      _id: id,
      requester: requester,
      department: department,
      name_requester: name_requester,
      email: email_requester,
      source_determination: null,
      details: details,
      status: status,
    };

    const checkErate = [...details]
      .map((item) => {
        return item.exchange_rate && item.exchange_rate.error ? 1 : 0;
      })
      .reduce((acc, cur) => {
        return acc + cur;
      }, 0);
    const checkDetail = [...details]
      .map((item) => {
        return parseFloat(item.remaining) - parseFloat(item.over) < 0 ? 1 : 0;
      })
      .reduce((acc, val) => {
        return acc + val;
      }, 0);

    if (
      requester &&
      email_requester &&
      department &&
      checkDetail === 0 &&
      checkErate === 0
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, submit entries",
      }).then((result) => {
        if (result.value) {
          dispatch(transferPurchaseRequest(collection))
            .then(() => {
              toast.success("Data has been submitted successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-trasnfer-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been submitted successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.setState({
                  loading: false,
                });
                this.onModalTransferHide();
                this.reloadDataBackend();
              });
            })
            .catch(() => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-trasnfer-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  handleSubmitEdit = () => {
    this.setState({ submitted: true });
    const {
      id,
      requester,
      department,
      email_requester,
      document_type,
      source_determination,
      plant,
      purchase_group,
      requisitioner,
      details,
    } = this.state;

    if (
      !this.validateForm(
        document_type,
        plant,
        purchase_group,
        source_determination,
        details
      )
    ) {
      return;
    }
    const { dispatch } = this.props;
    let collection = {
      _id: id,
      requester: requester,
      document_type: document_type,
      source_determination: source_determination,
      plant: plant,
      purchase_group: purchase_group,
      requisitioner: requisitioner,
      pr_type: "inventory",
      details: details,
    };
    for (let obj = 0; obj < details.length; obj++) {
      if (parseInt(details[obj].qty) < 0) {
        Swal.fire({
          title: "Warning?",
          text: "Qty can't minus",
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }
    }
    if (
      requester &&
      email_requester &&
      department &&
      document_type &&
      plant &&
      purchase_group &&
      requisitioner
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          dispatch(editPurchaseRequest(collection))
            .then(() => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-edit-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.setState({
                  loading: false,
                });
                this.onModalHide();
                this.reloadDataBackend();
              });
            })
            .catch(() => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-edit-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  deleteData = (id) => {
    const { dispatch, history } = this.props;
    const { requester } = this.state;
    let collection = { _id: id, requester: requester };
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, delete entries",
      }).then((result) => {
        if (result.value) {
          dispatch(deletePurchaseRequest(collection))
            .then(() => {
              toast.success("Data has been deleted successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been deleted successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                history.push("/input/purchase-request-inventory");
                this.setState({
                  loading: false,
                });
                this.reloadDataBackend();
              });
            })
            .catch(() => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  approveData = (id) => {
    const { dispatch, history } = this.props;
    const { email_requester, requester, name_requester } = this.state;
    let collection = {
      _id: id,
      email: email_requester,
      requester: requester,
      name_requester: name_requester,
    };

    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, submit for approval",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(upperApprovalPurchaseRequest(collection, "Approval L1"))
            .then(() => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been ready for upper approval successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                history.push("/input/purchase-request-inventory");
                this.setState({
                  loading: false,
                });
                this.togglePurchaseRequest();
                this.reloadDataBackend();
              });
            })
            .catch(() => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  extendBudget = (id) => {
    const { dispatch, history } = this.props;
    const { email_requester, requester, name_requester, status, reason } =
      this.state;
    let collection = {
      _id: id,
      email: email_requester,
      requester: requester,
      name_requester: name_requester,
      status: status,
    };

    if (!this.validateRejectForm(reason)) {
      return;
    }
    if (reason && requester) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, extend budget",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(
            upperApprovalPurchaseRequest(collection, status, "approved", reason)
          )
            .then(() => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-extend-budget-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been ready for extend budget successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                history.push("/input/purchase-request-inventory");

                this.setState({
                  loading: false,
                  showModalPurchaseRequestReject: false,
                });
                this.reloadDataBackend();
              });
            })
            .catch(() => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-extend-budget-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  donotextendBudget = (id) => {
    const { dispatch, history } = this.props;
    const { email_requester, requester, name_requester, status } = this.state;
    let collection = {
      _id: id,
      email: email_requester,
      requester: requester,
      name_requester: name_requester,
      status: status,
    };

    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "No, Don't extend budget",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(upperApprovalPurchaseRequest(collection, status, "rejected"))
            .then(() => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId:
                  "customId-donot-extend-budget-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                history.push("/input/purchase-request-inventory");
                this.reloadDataBackend();
                this.setState({ loading: false });
              });
            })
            .catch(() => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-donot-extend-budget-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.value,
      });
      if (name === "requisitioner") {
        this.setState({
          isValid: true,
        });
      }
      if (name === "g_l_account") {
        this.setState({
          isValidGLAccount: true,
        });
      }
      if (name === "material") {
        this.setState({
          isValidMaterial: true,
        });
      }
      if (name === "source_determination") {
        this.setState({
          isValidSupplier: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
      });

      if (name === "requisitioner") {
        this.setState({
          isValid: false,
        });
      }
      if (name === "g_l_account") {
        this.setState({
          isValidGLAccount: false,
        });
      }
      if (name === "material") {
        this.setState({
          isValidMaterial: false,
        });
      }
    }
  };

  handleDate = (date) => {
    this.setState({
      delivery_date: date,
      isValidDate: true,
    });
  };

  showinputReason = () => {
    this.setState({
      showModalPurchaseRequestReject:
        !this.state.showModalPurchaseRequestReject,
    });
  };
  showTransfer = async () => {
    const { details, exchange_rate_to_rp, active_exchange_rate } = this.state;
    const { dispatch } = this.props;
    const cloneDetail = [...details];
    const mapDetail = await Promise.all(
      cloneDetail.map(async (item) => {
        item.over =
          parseFloat(item.remain_payment) < 0
            ? Math.abs(item.remain_payment)
            : 0;
        item.prev_remaining = item.remaining;
        item.prev_glaccount_code = item.gl_account_code;
        item.prev_glaccount_name = item.gl_account_name;
        const result = await dispatch(
          readCurrentExchangeRate(item.currency, "last=true")
        )
          .then(() => {
            const a = this.props.read_current_exchange_rate?.data[
              active_exchange_rate?.value
            ]
              ? this.props.read_current_exchange_rate?.data[
                  active_exchange_rate?.value
                ]
              : exchange_rate_to_rp;
            let obj = {
              error: false,
              data: a,
              number: a,
              created_at:
                this.props.read_current_exchange_rate?.data?.created_at,
            };
            return obj;
          })
          .catch((err) => {
            let obj = { error: true, data: err, number: 0, created_at: null };
            return obj;
          });
        item.exchange_rate = result;
        return item;
      })
    );
    this.setState(
      {
        details: mapDetail,
        isTransfer: true,
      },
      () => {
        this.getRemaining();
        this.setState({
          showModalTransfer: !this.state.showModalTransfer,
          showModalPurchaseRequest: false,
        });
      }
    );
  };

  handleSubmitNominalApproval = () => {
    this.setState({ submittedTransfer: true });
    const {
      id,
      requester,
      department,
      email_requester,
      details,
      status,
      name_requester,
    } = this.state;

    const { dispatch } = this.props;
    let collection = {
      _id: id,
      requester: requester,
      name_requester: name_requester,
      email: email_requester,
      details: details,
      status: status,
      approval: {
        result: "approved",
      },
    };

    const checkErate = [...details]
      .map((item) => {
        return item.exchange_rate && item.exchange_rate.error ? 1 : 0;
      })
      .reduce((acc, cur) => {
        return acc + cur;
      }, 0);
    if (requester && email_requester && department && checkErate === 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, ready for nominal approval",
      }).then((result) => {
        if (result.value) {
          dispatch(nominalApprovalPurchaseRequest(collection, status))
            .then(() => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-nominal-approval-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.setState({
                  loading: false,
                });
                this.onModalTransferHide();
                this.reloadDataBackend();
              });
            })
            .catch(() => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-nominal-approval-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  validateForm = (document_type, plant, purchase_group, details) => {
    let errors = this.state.errors;
    if (!document_type) errors.document_type = "This field is required";
    if (!plant) errors.plant = "This field is required";
    if (!purchase_group) errors.purchase_group = "This field is required";
    if (!details) errors.details = "This field is required";
    return errors;
  };

  validateRejectForm = (reason) => {
    let errors = this.state.errors;
    if (!reason) {
      errors.reason = "This field is required";
    }
    return errors;
  };

  getRemaining = () => {
    const { dispatch } = this.props;
    const { details, department } = this.state;

    details.map((obj, idx) => {
      let query = `department=${department}&gl_account=${obj.gl_account_code}&delivery_date=${obj.delivery_date}`;
      if (obj.gl_account_code !== "" && obj.delivery_date !== "") {
        dispatch(readBudgetByGlAccount(query)).then(() => {
          details[idx] = {
            ...details[idx],
            remaining: this.props.read_budget_by_gl_account.data
              ? this.props.read_budget_by_gl_account.data.budget_per_quartal
              : "0",
          };
          details[idx] = {
            ...details[idx],
            budget_interval:
              this.props.read_budget_by_gl_account.data.budget_interval ?? [],
          };
          this.setState({
            details: details,
            budget_interval:
              this.props.read_budget_by_gl_account.data.budget_interval ?? [],
          });
        });
      }
    });
  };

  render() {
    let {
      columns_inventory,
      showModal,
      showModalFlow,
      showModalPurchaseRequestReject,
      select_items_glaccount,
      select_items_material,
      select_items_requisitioner,
      select_items_uom,
      department,
      select_items_document_type,
      select_items_plant,
      select_items_purch_group,
      select_items_currency,
      list_data,
      count_list_data,
      id,
      status,
      document_type,
      document_number,
      plant,
      purchase_group,
      requisitioner,
      errors,
      submitted,
      readOnly,
      showModalPurchaseRequest,
      ready_for_approval,
      isClearable,
      loading,
      fetchLoading,
      isValid,
      extend,
      reason,
      details,
      showModalTransfer,
      columns_inventory_transfer,
      active_exchange_rate,
      is_nominal_approval,
      columns_inventory_nominal_approval,
      created_at,
    } = this.state;

    const customStyles = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "green",
        },
      }),
    };
    const { dispatch } = this.props;
    const loadMaterial = (value) => {
      return new Promise((resolve, reject) => {
        const query = {
          search: value,
          size: 1,
          sizePerPage: 10,
        };
        // using setTimeout to emulate a call to server
        dispatch(readMATERIAL(query)).then(() => {
          resolve(this.props.read_material.data);
        });
      });
    };

    const loadGLAccount = (value) => {
      return new Promise((resolve, reject) => {
        const query = {
          search: value,
          size: 1,
          sizePerPage: 10,
        };
        // using setTimeout to emulate a call to server
        dispatch(readGLAccount(query)).then(() => {
          resolve(this.props.read_glaccount.data);
        });
      });
    };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          {/* MODAL LOADING */}
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <ToastContainer />
          {/* MODAL EDIT */}
          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
            style
            dialogClassName="my-modal"
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
              autoComplete="off"
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>
                  {id ? "Update" : "Create"} Purchase Request Inventory
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xs={12} md={6}>
                    <div
                      className={
                        errors.document_type
                          ? "form-group"
                          : "form-group has-error has-feedback"
                      }
                    >
                      <label htmlFor="document_type">Document Type *</label>

                      <select
                        className={
                          errors.document_type !== "This field is required"
                            ? "form-control form-control-md "
                            : "form-control form-control-md  has-error has-feedback"
                        }
                        name="document_type"
                        onChange={this.onChangeValue}
                        style={{ padding: 0 }}
                        defaultValue={document_type}
                        required
                      >
                        <option value="">Choose</option>
                        {select_items_document_type &&
                          select_items_document_type.map(function (item, i) {
                            return (
                              <option value={item.value} key={i}>
                                {item.label}
                              </option>
                            );
                          })}
                      </select>

                      {errors.document_type === "This field is required" && (
                        <div className="invalid-feedback">
                          {errors.document_type}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="requisitioner">Requisitioner *</label>
                      <Select
                        styles={customStyles}
                        onChange={this.handleChange.bind(this, "requisitioner")}
                        options={select_items_requisitioner}
                        defaultValue={select_items_requisitioner.filter(
                          (e) => e.value === (id ? requisitioner : department)
                        )}
                        isClearable={isClearable}
                      />

                      {!isValid && (
                        <div
                          style={{
                            width: "100%",
                            marginTop: ".25rem",
                            fontSize: "80%",
                            color: " #dc3545",
                          }}
                        >
                          {errors.requisitioner}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <div
                      className={
                        errors.plant !== "This field is required"
                          ? "form-group"
                          : "form-group has-error has-feedback"
                      }
                    >
                      <label htmlFor="plant">Plant *</label>
                      <select
                        className={
                          errors.plant !== "This field is required"
                            ? "form-control form-control-md "
                            : "form-control form-control-md  has-error has-feedback"
                        }
                        name="plant"
                        onChange={this.onChangeValue}
                        style={{ padding: 0 }}
                        defaultValue={plant}
                        required
                      >
                        <option value="">Choose</option>
                        {select_items_plant &&
                          select_items_plant.map(function (item, i) {
                            return (
                              <option value={item.value} key={i}>
                                {item.label}
                              </option>
                            );
                          })}
                      </select>

                      {errors.plant === "This field is required" && (
                        <div className="invalid-feedback">{errors.plant}</div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div
                      className={
                        errors.purchase_group !== "This field is required"
                          ? "form-group"
                          : "form-group has-error has-feedback"
                      }
                    >
                      <label htmlFor="purchase_group">Purchase Group *</label>
                      <select
                        className={
                          errors.purchase_group !== "This field is required"
                            ? "form-control form-control-md "
                            : "form-control form-control-md  has-error has-feedback"
                        }
                        name="purchase_group"
                        onChange={this.onChangeValue}
                        style={{ padding: 0 }}
                        defaultValue={purchase_group}
                        required
                      >
                        <option value="">Choose</option>
                        {select_items_purch_group &&
                          select_items_purch_group.map(function (item, i) {
                            return (
                              <option value={item.value} key={i}>
                                {item.label}
                              </option>
                            );
                          })}
                      </select>

                      {errors.purchase_group === "This field is required" && (
                        <div className="invalid-feedback">
                          {errors.purchase_group}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  {created_at && (
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label>Created At</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          defaultValue={moment(created_at).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  )}
                  <Col xs={12} md={6}>
                    <div
                      className={
                        errors.purchase_group !== "This field is required"
                          ? "form-group"
                          : "form-group has-error has-feedback"
                      }
                    >
                      <label htmlFor="account_assignment_cat">
                        Account Assignment Cat *
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="account_assignment_cat"
                        disabled
                      ></input>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="purchase_group">Item Cat *</label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="item_cat"
                        disabled
                      ></input>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={12}>
                    <DynamicTable
                      data={details}
                      columns={columns_inventory}
                      materials={select_items_material}
                      uom={select_items_uom}
                      gl_account={select_items_glaccount}
                      currency={select_items_currency}
                      loadMaterial={loadMaterial}
                      loadGLAccount={loadGLAccount}
                      props={this.props}
                      inputNominal={true}
                      isTransfer={
                        is_nominal_approval ? is_nominal_approval : true
                      }
                      onChange={(data) => {
                        this.setState(
                          {
                            details: [...data],
                          },
                          () => {
                            //call new function if needed
                          }
                        );
                      }}
                      onChangeRemaining={(data) => {
                        this.setState(
                          {
                            details: [...data],
                          },
                          () => {
                            this.getRemaining();
                          }
                        );
                      }}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="justify-content-between">
                <Button variant="danger" onClick={this.toggle}>
                  <i className="fas fa-times" /> Cancel
                </Button>
                {id ? (
                  status.includes("Rejected") ? (
                    <Button variant="success" onClick={this.handleSubmit}>
                      <i className="fas fa-save" /> Update and Submit
                    </Button>
                  ) : (
                    <Button variant="success" onClick={this.handleSubmitEdit}>
                      <i className="fas fa-save" /> Update
                    </Button>
                  )
                ) : (
                  <Button variant="success" onClick={this.handleSubmit}>
                    <i className="fas fa-save" /> Submit
                  </Button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          {/* MODAL FLOW */}
          <Modal
            dialogClassName="modal-flow"
            containerClassName="modal-flow"
            show={showModalFlow}
            backdrop="static"
            onHide={this.onModalFlowHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Flow Purchase Request</ModalTitle>
              </ModalHeader>
              <ModalBody className="modal-flow">
                <div className="row">
                  <Col>Submitted</Col>
                  <Col>
                    <i className="fas fa-arrow-right" />
                  </Col>
                  <Col>Approval Supervisor {this.state.department}</Col>
                  <Col>
                    <i className="fas fa-arrow-right" />
                  </Col>
                  <Col>Approval Manager {this.state.department}</Col>
                  <Col>
                    <i className="fas fa-arrow-right" />
                  </Col>
                  <Col>Approval Checker</Col>
                  <Col>
                    <i className="fas fa-arrow-right" />
                  </Col>
                  <Col>Input Nominal by PRC</Col>
                  <Col>
                    <i className="fas fa-arrow-right" />
                  </Col>
                  <Col>Approval by PRC Manager</Col>
                  <Col>
                    <i className="fas fa-arrow-right" />
                  </Col>
                  <Col>Done</Col>
                </div>
              </ModalBody>
            </form>
          </Modal>
          {/* MODAL DETAIL */}
          <Modal
            show={showModalPurchaseRequest}
            size="xl"
            backdrop="static"
            onHide={this.onModalPurchaseRequestHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Detail Purchase Request Inventory</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="document_type">Document Type *</label>

                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="document_type"
                          placeholder="Document Type"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={document_type}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="requisitioner">Requisitioner *</label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="requisitioner"
                          placeholder="Requisitioner"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={requisitioner}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="plant">Plant *</label>
                        <input
                          type="number"
                          className="form-control form-control-md"
                          name="plant"
                          min="0"
                          placeholder="Plant"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={plant}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="purchase_group">Purchase Group *</label>
                        <input
                          type="number"
                          className="form-control form-control-md"
                          name="purchase_group"
                          min="0"
                          placeholder="Purchase Group"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={purchase_group}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="account_assignment_cat">
                          Account Assignment Cat{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="account_assignment_cat"
                          disabled
                        ></input>
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="purchase_group">Item Cat *</label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="item_cat"
                          disabled
                        ></input>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label>Created At</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          defaultValue={
                            created_at
                              ? moment(created_at).format("DD-MM-YYYY HH:mm:ss")
                              : ""
                          }
                          readOnly={true}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="Document Number">
                          Document Number *
                        </label>

                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="document_number"
                          placeholder="Document Number"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={document_number}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Table
                    bordered
                    hover
                    size="sm"
                    style={{ textAlign: "center", justifyContent: "center" }}
                  >
                    <thead>
                      <tr key="0">
                        <th
                          style={{ backgroundColor: "#17A2B8", color: "white" }}
                        >
                          {" "}
                          No.{" "}
                        </th>
                        <th
                          style={{ backgroundColor: "#17A2B8", color: "white" }}
                        >
                          {" "}
                          Material{" "}
                        </th>
                        <th
                          style={{ backgroundColor: "#17A2B8", color: "white" }}
                        >
                          {" "}
                          Long Text{" "}
                        </th>
                        <th
                          style={{ backgroundColor: "#17A2B8", color: "white" }}
                        >
                          {" "}
                          GL Account{" "}
                        </th>
                        <th
                          style={{ backgroundColor: "#17A2B8", color: "white" }}
                        >
                          {" "}
                          Qty{" "}
                        </th>
                        <th
                          style={{ backgroundColor: "#17A2B8", color: "white" }}
                        >
                          {" "}
                          UOM{" "}
                        </th>
                        <th
                          style={{ backgroundColor: "#17A2B8", color: "white" }}
                        >
                          {" "}
                          Delivery Date{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {details &&
                        details.map((x, i) => (
                          <tr key={i + 1}>
                            <td key={`td-2-${i + 1}`}>{i + 1}.</td>
                            <td key={`td-3-${i + 1}`}>
                              {x.material_name ? x.material_name : ""}
                            </td>
                            <td key={`td-35-${i + 1}`}>
                              {x.short_text ? x.short_text : ""}
                            </td>
                            <td key={`td-4-${i + 1}`}>
                              {x.gl_account_name ? x.gl_account_name : ""}
                            </td>
                            <td key={`td-5-${i + 1}`}>{x.qty}</td>
                            <td key={`td-6-${i + 1}`}>{x.uom}</td>
                            <td key={`td-7-${i + 1}`}>{x.delivery_date}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Container>
              </ModalBody>
              <ModalFooter className={extend ? "justify-content-between" : ""}>
                {ready_for_approval && status !== "Done" && (
                  <button
                    type="button"
                    className="btn btn-success btn-sm ml-2 mb-2 ts-bottom float-right"
                    size="sm"
                    data-id={id}
                    onClick={() => this.approveData(id)}
                  >
                    <i className="fas fa-check-square"></i>
                    &nbsp;Submit for Approval
                  </button>
                )}
                {extend && status !== "Done" && (
                  <button
                    type="button"
                    className="btn btn-warning btn-sm ml-2 mb-2 ts-bottom"
                    size="sm"
                    data-id={id}
                    onClick={() => this.donotextendBudget(id)}
                  >
                    <i className="fas fa-times"></i>
                    &nbsp;Dont Extend and Transfer GL Account
                  </button>
                )}
                {extend && status !== "Done" && (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm ml-2 mb-2 ts-bottom "
                      size="sm"
                      data-id={id}
                      onClick={() => this.showinputReason(id)}
                    >
                      <i className="fas fa-check-square"></i>
                      &nbsp;Extend Budget
                    </button>
                    <button
                      type="button"
                      className="btn btn-success btn-sm ml-2 mb-2 ts-bottom float-right"
                      size="sm"
                      data-id={id}
                      onClick={() => this.showTransfer(id)}
                    >
                      <i className="fas fa-check-square"></i>
                      &nbsp;Transfer GL Account
                    </button>
                  </>
                )}
              </ModalFooter>
            </form>
          </Modal>
          {/* MODAL REJECT */}
          <Modal
            show={showModalPurchaseRequestReject}
            size="md"
            backdrop="static"
            onHide={this.onModalPurchaseRequestRejectHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              autoComplete="off"
            >
              <ModalHeader closeButton className="bg-warning text-white">
                <ModalTitle>Input Reason for Extend Budget</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={12}>
                      <Col xs={12} md={12}>
                        Over Budget :
                        <Table
                          bordered
                          hover
                          size="sm"
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <thead>
                            <tr key="0">
                              <th
                                style={{
                                  backgroundColor: "#17A2B8",
                                  color: "white",
                                }}
                              >
                                {" "}
                                No.{" "}
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#17A2B8",
                                  color: "white",
                                }}
                              >
                                {" "}
                                GL Account{" "}
                              </th>

                              <th
                                style={{
                                  backgroundColor: "#17A2B8",
                                  color: "white",
                                }}
                              >
                                {" "}
                                Over{" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {details &&
                              details.map((x, i) => (
                                <tr key={i + 1}>
                                  <td key={`td-2-${i + 1}`}>{i + 1}.</td>
                                  <td key={`td-4-${i + 1}`}>
                                    {x.gl_account_name ? x.gl_account_name : ""}
                                  </td>
                                  <td key={`td-5-${i + 1}`}>
                                    {addSeparatorsNF(
                                      Math.abs(x.remain_payment),
                                      ".",
                                      ".",
                                      ","
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Col>
                      <div className="form-group">
                        <label htmlFor="reason">Reason *</label>
                        <textarea
                          id="reason"
                          className="form-control form-control-sm"
                          name="reason"
                          placeholder="Reason"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={reason}
                          rows="5"
                        />

                        {errors.reason && (
                          <div className="invalid-feedback">
                            {errors.reason}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="success"
                  onClick={() => this.extendBudget(id)}
                  className="btn btn-success btn-sm ml-2 mb-2 ts-bottom"
                >
                  <i className="fas fa-save" /> Extend
                </Button>
              </ModalFooter>
            </form>
          </Modal>
          <Modal
            show={showModalTransfer}
            size="xl"
            backdrop="static"
            onHide={this.onModalTransferHide}
            dialogClassName="full-modal-dialog"
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>
                  {is_nominal_approval
                    ? "Nominal Approval"
                    : "Transfer GL Account"}{" "}
                  on PR Inventory
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="document_type">Document Type *</label>

                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="document_type"
                        placeholder="Document Type"
                        required="required"
                        onChange={this.onChangeValue}
                        defaultValue={document_type}
                        readOnly={readOnly}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="requisitioner">Requisitioner *</label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="requisitioner"
                        placeholder="Requisitioner"
                        required="required"
                        onChange={this.onChangeValue}
                        defaultValue={requisitioner}
                        readOnly={readOnly}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="plant">Plant *</label>
                      <input
                        type="number"
                        className="form-control form-control-md"
                        name="plant"
                        min="0"
                        placeholder="Plant"
                        required="required"
                        onChange={this.onChangeValue}
                        defaultValue={plant}
                        readOnly={readOnly}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="purchase_group">Purchase Group *</label>
                      <input
                        type="number"
                        className="form-control form-control-md"
                        name="purchase_group"
                        min="0"
                        placeholder="Purchase Group"
                        required="required"
                        onChange={this.onChangeValue}
                        defaultValue={purchase_group}
                        readOnly={readOnly}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="account_assignment_cat">
                        Account Assignment Cat{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="account_assignment_cat"
                        disabled
                      ></input>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="purchase_group">Item Cat *</label>
                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="item_cat"
                        disabled
                      ></input>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <label htmlFor="Document Number">Document Number *</label>

                      <input
                        type="text"
                        className="form-control form-control-md"
                        name="document_number"
                        placeholder="Document Number"
                        required="required"
                        onChange={this.onChangeValue}
                        defaultValue={document_number}
                        readOnly={readOnly}
                      />
                    </div>
                  </Col>
                </Row>
                <Col xs={12} md={12}>
                  <DynamicTable
                    data={details}
                    columns={
                      is_nominal_approval
                        ? columns_inventory_nominal_approval
                        : columns_inventory_transfer
                    }
                    materials={select_items_material}
                    uom={select_items_uom}
                    gl_account={select_items_glaccount}
                    currency={select_items_currency}
                    loadMaterial={loadMaterial}
                    loadGLAccount={loadGLAccount}
                    props={this.props}
                    inputNominal={true}
                    onChange={(data) => {
                      this.setState(
                        {
                          details: [...data],
                        },
                        () => {
                          //call new function if needed
                        }
                      );
                    }}
                    onChangeRemaining={(data) => {
                      this.setState(
                        {
                          details: [...data],
                          query_get_remaining_by_month: is_nominal_approval
                            ? false
                            : true,
                        },
                        () => {
                          this.getRemaining();
                        }
                      );
                    }}
                    readOnly={true}
                    isTransfer={
                      is_nominal_approval ? is_nominal_approval : true
                    }
                    active_exchange_rate={active_exchange_rate}
                  />
                </Col>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="success"
                  onClick={
                    is_nominal_approval
                      ? this.handleSubmitNominalApproval
                      : this.handleSubmitTransfer
                  }
                >
                  <i className="fas fa-save" />{" "}
                  {is_nominal_approval
                    ? "Ready for Nominal Approval"
                    : "Submit"}
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">
                          Purchase Request Inventory
                        </h3>
                        <div className="card-tools float-right">
                          <Button color="warning" onClick={this.toggle}>
                            <i className="fas fa-plus-square" /> New
                          </Button>
                        </div>
                      </div>
                      <div className="card-body">
                        <TableCustom
                          data={list_data}
                          totalRows={count_list_data}
                          loading={fetchLoading}
                          action={this.GetActionFormat}
                          toggleStatus={this.toggleFlowPurchaseRequest}
                          fetchData={this.fetchPurchaseRequest}
                          handleSearch={this.handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { read_purchase_request } = state.purchase_request;
  const { read_department } = state.department;
  const { read_uom } = state.uom;
  const { read_material } = state.material;
  const { read_supplier } = state.supplier;
  const { read_glaccount } = state.glaccount;
  const { read_costcenter } = state.costcenter;
  const { read_document_type } = state.document_type;
  const { read_requisitioner } = state.requisitioner;
  const { read_plant } = state.plant;
  const { read_purch_group } = state.purch_group;
  const { read_account_assignment_cat } = state.account_assignment_cat;
  const { read_currency } = state.currency;
  const { read_budget_by_gl_account } = state.budget;

  const { read_current_exchange_rate, read_active_exchange_rate } =
    state.exchange_rate;

  const { message } = state.message;
  return {
    message,
    read_purchase_request,
    user,
    read_department,
    read_uom,
    read_material,
    read_supplier,
    read_glaccount,
    read_costcenter,
    read_document_type,
    read_requisitioner,
    read_plant,
    read_purch_group,
    read_account_assignment_cat,
    read_currency,
    read_budget_by_gl_account,
    read_current_exchange_rate,
    read_active_exchange_rate,
  };
}

export default connect(mapStateToProps)(PurchaseRequest);
