import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class AdvanceService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "advance", payload, {
      headers: authHeader(),
    });
  }

  claimAdvance(payload) {
    return axios.post(API_URL_DEFAULT + "claim_advance", payload, {
      headers: authHeader(),
    });
  }

  fileAdvance(payload) {
    return axios.get(API_URL_DEFAULT + "file/advance?filename=" + payload, {
      headers: authHeader(),
      responseType: "blob",
    });
  }
  confirmAdvance(payload) {
    return axios.put(API_URL_DEFAULT + "claim_advance", payload, {
      headers: authHeader(),
    });
  }
  readAdvance(username, type) {
    return axios.get(API_URL_DEFAULT + "advance/?" + type + "=" + username, {
      headers: authHeader(),
    });
  }
  readClaimAdvance(department) {
    return axios.get(
      API_URL_DEFAULT + "claim_advance/?department=" + department,
      { headers: authHeader() }
    );
  }
  edit(payload) {
    return axios.put(API_URL_DEFAULT + "advance", payload, {
      headers: authHeader(),
    });
  }

  editRealisasi(payload) {
    return axios.put(API_URL_DEFAULT + "advance/realisasi", payload, {
      headers: authHeader(),
    });
  }

  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "advance", {
      data: payload,
      headers: authHeader(),
    });
  }
  upperApproval(payload, status, approval, reason) {
    if (status === "need_upper_approval") {
      return axios.put(API_URL_DEFAULT + "advance/?status=" + status, payload, {
        headers: authHeader(),
      });
    }
    return axios.put(
      API_URL_DEFAULT +
        "advance/?status=" +
        status +
        "&approval=" +
        approval +
        "&reason=" +
        reason,
      payload,
      { headers: authHeader() }
    );
  }

  approvalSettlement(payload) {
    return axios.put(API_URL_DEFAULT + "advance/settlement", payload, {
      headers: authHeader(),
    });
  }
}

export default new AdvanceService();
