import {
  CREATE_GLACCOUNT_SUCCESS,
  CREATE_GLACCOUNT_FAIL,
  READ_GLACCOUNT_SUCCESS,
  READ_GLACCOUNT_FAIL,
  EDIT_GLACCOUNT_SUCCESS,
  EDIT_GLACCOUNT_FAIL,
  DELETE_GLACCOUNT_SUCCESS,
  DELETE_GLACCOUNT_FAIL,
} from "../actions/types";

const initialState = {
  read_glaccount: null,
};

export default function glaccount(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_GLACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case CREATE_GLACCOUNT_FAIL:
      return {
        ...state,
      };

    case EDIT_GLACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case EDIT_GLACCOUNT_FAIL:
      return {
        ...state,
      };
    case READ_GLACCOUNT_SUCCESS:
      return {
        ...state,
        read_glaccount: payload,
      };
    case READ_GLACCOUNT_FAIL:
      return {
        ...state,
        read_glaccount: null,
      };
    case DELETE_GLACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case DELETE_GLACCOUNT_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
