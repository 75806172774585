import React, { Component } from "react";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import NavSideLayout from "../components/NavSideLayout";
import { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Modal from "../components/Modal"; 
import Swal from "sweetalert2";   

export default class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleModal:"Extra Large",
      showModal:false, 
      collection:[],
      bodyModal:[
        {name:'name',placeholder:'Name',required:true,type:'text'},
        {name:'icon',placeholder:'Icon',required:true,type:'text'}, 
      ],
      text:'text',
      title:'title',
      icon:'info',
      products: [
        { id: 1, name: "a", price: 100000 },
        { id: 2, name: "b", price: 200000 },
        { id: 3, name: "c", price: 300000 },
        { id: 4, name: "d", price: 400000 },
        { id: 5, name: "e", price: 500000 },
        { id: 6, name: "f", price: 600000 },
        { id: 7, name: "g", price: 700000 },
        { id: 8, name: "h", price: 800000 },
        { id: 9, name: "i", price: 900000 },
        { id: 10, name: "j", price: 1000000 },
        { id: 11, name: "k", price: 1100000 },
      ],
      columns: [
        {
          dataField: "id",
          text: "Product ID",
          sort: true,
        },
        {
          dataField: "name",
          text: "Product Name",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "price",
          text: "Product Price",
          filter: textFilter(),
          sort: true,
        },
      ],
      defaultSorted: [
        {
          dataField: "name",
          order: "asc",
        },
      ],
      cellEditProps: {
        mode: "click",
      },
    }; 
  }
  CustomSwal = ({title,text,icon})=>{ 
    const data={
      title:'Information !',
      status:'success',
      text:'Show swal'
    }
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: true,
        confirmButtonText: 'Yes',
     }).then((result) => {
       if (result.value) {   
        Swal.fire(
          {
            icon: data.status,
            title:data.title,
            text:data.message,
            showConfirmButton: false,
            timer: 2000
          });
       } else if (result.dismiss === Swal.DismissReason.cancel) {
         Swal.fire(
           'Cancelled',
           'Your data is safe :)',
           'error'
         )
       }
     });  
  }; 
  render() {
    const { products, columns, defaultSorted, cellEditProps,titleModal,showModal,bodyModal,title,icon,text } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <NavSideLayout />
          <Modal 
            id="modal-xl"
            size="xl"
            title={titleModal}
            show={showModal}
            body={bodyModal}
          />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">Checkout</h3>
                        <div className="card-tools float-right">
                          <a
                            href="#!"
                            type="button"
                            className="btn btn-xs  btn-primary"
                            data-toggle="modal"
                            data-target="#modal-xl"
                           
                          >
                            <i className="fas fa-th" /> Modal Large
                          </a>
                          <button
                            className="btn btn-xs  btn-success"
                           onClick={()=> this.CustomSwal({title,text,icon})}
                          > <i className="fas fa-check-square" />
                            Swal
                          </button>
                        </div>
                      
                      </div>
                      <div className="card-body">
                        <RemoteTable
                          data={products}
                          columns={columns}
                          defaultSorted={defaultSorted}
                          cellEditProps={cellEditProps}
                        /> 
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}
