import React, { Component } from "react";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import dateFormat from "dateformat";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  addAdvance,
  readAdvance,
  editAdvance,
  deleteAdvance,
  upperApprovalAdvance,
  confirmAdvance,
  fileAdvance,
} from "../actions/advance";
import { readCurrency } from "../actions/currency";
import { readGLAccount } from "../actions/glaccount";
import { readCostCenter } from "../actions/costcenter";
import {
  readCurrentExchangeRate,
  readActiveExchangeRate,
} from "../actions/exchange_rate";
import { clearMessage } from "../actions/message";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import addSeparatorsNF from "../helpers/thousanddelimiter";
import Select from "react-select";

class HistoryAdvance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
      isValidCostCenter: true,
      loading: false,
      submitted: false,
      showModal: false,
      showModalAdvance: false,
      showModalFlow: false,
      showModalPhoto: false,
      readOnly: false,
      collection: [],
      select_items: [],
      select_items_glaccount: [],
      select_items_costcenter: [],
      advance: [],
      errors: {},
      id: null,
      selectedFile: null,
      requester: null,
      email_requester: null,
      department: null,
      keperluan_advance: null,
      nilai_pengajuan: null,
      nama_pemilik_rekening: null,
      nama_bank: null,
      nomor_rekening: null,
      g_l_account: null,
      cost_center: null,
      created_at: null,
      currency: null,
      grand_total: null,
      exchange_rate_to_rp: "1",
      ready_for_approval: false,
      error_message_exchange_rate: null,
      active_exchange_rate: null,
      confirmation: false,
      showPhoto: [],
      photo: null,
      isClearable: true,
      no_advance: null,
      columns: [
        {
          dataField: "no_advance",
          text: "No. Advance",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "requester",
          text: "Requester",
          filter: textFilter(),
          formatter: this.GetRequesterFormat,
          sort: true,
          headerClasses: "still",
        },
        {
          dataField: "departement",
          text: "Departement",
          filter: textFilter(),
          sort: true,
          headerClasses: "still",
        },
        {
          dataField: "keperluan_advance",
          text: "Purpose of Advance",
          filter: textFilter(),
          sort: true,
          headerClasses: "still",
        },
        {
          dataField: "currency",
          text: "Currency",
          filter: textFilter(),
          sort: true,
          headerClasses: "still",
        },
        {
          dataField: "nilai_pengajuan",
          text: "Proposal Nominal",
          filter: textFilter(),
          formatter: this.GetNominalFormat,
          sort: true,
          headerClasses: "still",
        },
        {
          dataField: "nama_pemilik_rekening",
          text: "Name of the Account Holder",
          filter: textFilter(),
          sort: true,
          headerClasses: "still",
        },
        {
          dataField: "nama_bank",
          text: "Bank Name",
          filter: textFilter(),
          sort: true,
          headerClasses: "still",
        },
        {
          dataField: "nomor_rekening",
          text: "Account Number",
          filter: textFilter(),
          sort: true,
          headerClasses: "still",
        },
        {
          dataField: "g_l_account",
          text: "GL Account",
          filter: textFilter(),
          sort: true,
          headerClasses: "still",
        },
        {
          dataField: "status",
          text: "Status",
          filter: textFilter(),
          formatter: this.GetStatusFormat,
          classes: "text-center",
          sort: true,
          headerClasses: "still",
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
          headerClasses: "still",
        },
      ],
      defaultSorted: [
        {
          dataField: "requester",
          order: "asc",
        },
      ],
      cellEditProps: {},
    };
  }
  componentDidMount() {
    const { dispatch, user } = this.props;
    dispatch(
      readAdvance(
        user.username +
          "&departemen=" +
          user.details.hris_org_tree.current_person.nama_department +
          "&posisi_id=" +
          user.details.details[0].hris.posisi_id,
        "history"
      )
    ).then((response) => {
      this.setState({
        advance: this.props.read_advance.data,
        requester: user.username,
        department: user.details.hris_org_tree.current_person.nama_department,
        email_requester: user.details.hris_org_tree.current_person.email,
        name_requester: user.details.hris_org_tree.current_person.person_name,
      });
    });
    dispatch(readActiveExchangeRate()).then((response) => {
      this.setState({
        active_exchange_rate: this.props.read_active_exchange_rate.data,
      });
    });

    dispatch(readCurrency()).then((response) => {
      this.setState({ select_items: this.props.read_currency.data });
    });

    dispatch(readGLAccount()).then((response) => {
      this.setState({ select_items_glaccount: this.props.read_glaccount.data });
    });

    dispatch(readCostCenter()).then((response) => {
      this.setState({
        select_items_costcenter: this.props.read_costcenter.data,
      });
    });
  }
  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      selectedFile: null,
      keperluan_advance: null,
      nilai_pengajuan: null,
      nama_pemilik_rekening: null,
      nama_bank: null,
      nomor_rekening: null,
      g_l_account: null,
      cost_center: null,
      currency: null,
      exchange_rate_to_rp: "1",
      showModal: !this.state.showModal,
      readOnly: false,
      isValid: true,
      isValidCostCenter: true,
      created_at: null,
    });
  };
  onModalPhotoHide = () => {
    this.setState({
      showModalPhoto: !this.state.showModalPhoto,
    });
  };
  onModalFlowHide = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };
  onModalAdvanceHide = () => {
    this.setState({
      id: null,
      submitted: false,
      selectedFile: null,
      keperluan_advance: null,
      nilai_pengajuan: null,
      nama_pemilik_rekening: null,
      nama_bank: null,
      nomor_rekening: null,
      g_l_account: null,
      cost_center: null,
      currency: null,
      exchange_rate_to_rp: "1",
      showModalAdvance: !this.state.showModalAdvance,
      readOnly: false,
      ready_for_approval: false,
      error_message_exchange_rate: null,
      confirmation: false,
      created_at: null,
    });
  };
  toggle = () => {
    let datenow = new Date();
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  toggleFlowAdvance = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };
  toggleAdvance = () => {
    this.setState({
      showModalAdvance: !this.state.showModalAdvance,
    });
  };

  toDetail = (
    id,
    keperluan_advance,
    nilai_pengajuan,
    nama_pemilik_rekening,
    nama_bank,
    nomor_rekening,
    g_l_account,
    cost_center,
    currency,
    exchange_rate_to_rp,
    created_at,
    no_advance
  ) => {
    this.setState(
      {
        id: id,
        keperluan_advance: keperluan_advance,
        nilai_pengajuan: nilai_pengajuan,
        nama_pemilik_rekening: nama_pemilik_rekening,
        nama_bank: nama_bank,
        nomor_rekening: nomor_rekening,
        g_l_account: g_l_account,
        cost_center: cost_center,
        currency: currency,
        exchange_rate_to_rp: exchange_rate_to_rp,
        created_at: created_at,
        no_advance: no_advance,
      },
      () => {
        this.toggle();
      }
    );
  };
  toDetailAdvance = (
    no_advance,
    id,
    keperluan_advance,
    nilai_pengajuan,
    nama_pemilik_rekening,
    nama_bank,
    nomor_rekening,
    g_l_account,
    cost_center,
    currency,
    exchange_rate_to_rp,
    ready_for_approval,
    status,
    confirmation,
    grand_total,
    files,
    created_at
  ) => {
    const { dispatch } = this.props;
    const { active_exchange_rate } = this.state;
    if (status === "Submitted" && currency !== "Rp") {
      return dispatch(readCurrentExchangeRate(currency))
        .then((response) => {
          let current_rate = this.props.read_current_exchange_rate.data[
            active_exchange_rate.value
          ]
            ? this.props.read_current_exchange_rate.data[
                active_exchange_rate.value
              ]
            : exchange_rate_to_rp;

          let cek_ready = false;
          if (currency !== "Rp" && current_rate !== "1") {
            cek_ready = true;
          }

          if (currency === "Rp") {
            cek_ready = true;
          }

          this.setState(
            {
              id: id,
              keperluan_advance: keperluan_advance,
              nilai_pengajuan: nilai_pengajuan,
              nama_pemilik_rekening: nama_pemilik_rekening,
              nama_bank: nama_bank,
              nomor_rekening: nomor_rekening,
              g_l_account: g_l_account,
              cost_center: cost_center,
              currency: currency,
              exchange_rate_to_rp: current_rate,
              readOnly: true,
              ready_for_approval: cek_ready,
              confirmation: confirmation,
              created_at: created_at,
              no_advance: no_advance,
            },
            () => {
              this.toggleAdvance();
            }
          );
        })
        .catch((err) => {
          this.setState(
            {
              id: id,
              keperluan_advance: keperluan_advance,
              nilai_pengajuan: nilai_pengajuan,
              nama_pemilik_rekening: nama_pemilik_rekening,
              nama_bank: nama_bank,
              nomor_rekening: nomor_rekening,
              g_l_account: g_l_account,
              cost_center: cost_center,
              currency: currency,
              exchange_rate_to_rp: exchange_rate_to_rp,
              readOnly: true,
              ready_for_approval: false,
              error_message_exchange_rate: this.props.message,
              confirmation: false,
              grand_total: null,
              created_at,
              no_advance: no_advance,
            },
            () => {
              this.toggleAdvance();
            }
          );
          toast.error(this.props.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: "customId-read-current-exchange-rate-failed",
            onClose: () => dispatch(clearMessage()),
          });
          this.setState({
            loading: false,
          });
        });
    }

    if (confirmation && files.length > 0) {
      files.map((e) => {
        dispatch(fileAdvance(e)).then((response) => {
          let showPhoto = [...this.state.showPhoto];
          showPhoto.push(this.props.read_file_advance);
          this.setState({
            showPhoto: showPhoto,
          });
        });
      });
    }
    return this.setState(
      {
        id: id,
        keperluan_advance: keperluan_advance,
        nilai_pengajuan: nilai_pengajuan,
        nama_pemilik_rekening: nama_pemilik_rekening,
        nama_bank: nama_bank,
        nomor_rekening: nomor_rekening,
        g_l_account: g_l_account,
        cost_center: cost_center,
        currency: currency,
        exchange_rate_to_rp: exchange_rate_to_rp,
        readOnly: true,
        ready_for_approval: ready_for_approval,
        confirmation: confirmation,
        grand_total: grand_total,
        created_at: created_at,
        no_advance: no_advance,
      },
      () => {
        this.toggleAdvance();
      }
    );
  };

  GetRequesterFormat = (cell, row) => {
    return (
      <div>
        {row.name_requester} ({row.requester})
      </div>
    );
  };
  GetNominalFormat = (cell, row) => {
    return <div>{addSeparatorsNF(row.nilai_pengajuan, ".", ".", ",")}</div>;
  };
  GetStatusFormat = (cell, row) => {
    return (
      <div>
        <button
          type="button"
          className="btn btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => this.toggleFlowAdvance()}
        >
          <i className="fas fa-search-location"></i>
          &nbsp;{row.status}
        </button>
      </div>
    );
  };
  GetActionFormat = (cell, row) => {
    let ready_for_approval = false;
    if (
      row.status === "Submitted" &&
      row.currency !== "Rp" &&
      row.exchange_rate_to_rp === "1"
    ) {
      ready_for_approval = false;
    }
    if (
      row.status === "Submitted" &&
      row.currency === "Rp" &&
      row.exchange_rate_to_rp === "1"
    ) {
      ready_for_approval = true;
    }
    if (
      row.status === "Submitted" &&
      row.currency !== "Rp" &&
      row.exchange_rate_to_rp !== "1"
    ) {
      ready_for_approval = true;
    }
    let confirmation = false;
    let grand_total = null;
    if (row.isClaimed) {
      confirmation = true;
      if (row.isConfirmClaim) {
        confirmation = false;
      }
      grand_total = row.grand_total;
    }
    let files = row.upload_claim ? row.upload_claim : [];

    return row.status === "Submitted" ? (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetailAdvance(
              row.no_advance,
              row._id,
              row.keperluan_advance,
              row.nilai_pengajuan,
              row.nama_pemilik_rekening,
              row.nama_bank,
              row.nomor_rekening,
              row.g_l_account,
              row.cost_center,
              row.currency,
              row.exchange_rate_to_rp,
              ready_for_approval,
              row.status,
              confirmation,
              grand_total,
              files,
              row.created_at
            )
          }
        >
          <i className="fas fa-th"></i>
          &nbsp;Detail
        </button>
      </div>
    ) : (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetailAdvance(
              row.no_advance,
              row._id,
              row.keperluan_advance,
              row.nilai_pengajuan,
              row.nama_pemilik_rekening,
              row.nama_bank,
              row.nomor_rekening,
              row.g_l_account,
              row.cost_center,
              row.currency,
              row.exchange_rate_to_rp,
              ready_for_approval,
              row.status,
              confirmation,
              grand_total,
              files,
              dateFormat(row.created_at, "yyyy-mm-dd HH:MM:ss")
            )
          }
        >
          <i className="fas fa-th"></i>
          &nbsp;Detail
        </button>
      </div>
    );
  };
  isEmptyObject = (obj) => {
    return obj == null || !Object.keys(obj).length;
  };
  onChangeHandlerFile = (event) => {
    this.setState({
      selectedFile: event.target.files,
    });
  };
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { dispatch, history } = this.props;
    const {
      requester,
      name_requester,
      department,
      keperluan_advance,
      nilai_pengajuan,
      nama_pemilik_rekening,
      nama_bank,
      nomor_rekening,
      g_l_account,
      cost_center,
      currency,
      exchange_rate_to_rp,
      selectedFile,
      email_requester,
    } = this.state;
    if (
      !this.validateForm(
        keperluan_advance,
        nilai_pengajuan,
        nama_pemilik_rekening,
        nama_bank,
        nomor_rekening,
        g_l_account,
        cost_center,
        currency,
        exchange_rate_to_rp
      )
    ) {
      return;
    }
    const data = new FormData();
    if (selectedFile) {
      for (var x = 0; x < selectedFile.length; x++) {
        data.append("upload_data", selectedFile[x]);
      }
    }
    data.append("requester", requester);
    data.append("email_requester", email_requester);
    data.append("name_requester", name_requester);
    data.append("departement", department);
    data.append("keperluan_advance", keperluan_advance);
    data.append("nilai_pengajuan", nilai_pengajuan);
    data.append("nama_pemilik_rekening", nama_pemilik_rekening);
    data.append("nama_bank", nama_bank);
    data.append("nomor_rekening", nomor_rekening);
    data.append("g_l_account", g_l_account);
    data.append("cost_center", cost_center);
    data.append("currency", currency);
    data.append("exchange_rate_to_rp", exchange_rate_to_rp);
    if (
      requester &&
      email_requester &&
      department &&
      keperluan_advance &&
      nilai_pengajuan &&
      nama_pemilik_rekening &&
      nama_bank &&
      nomor_rekening &&
      g_l_account &&
      cost_center &&
      currency &&
      exchange_rate_to_rp
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          dispatch(addAdvance(data))
            .then((response) => {
              this.toggle();
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-advance-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/advance");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-advance-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  handleSubmitEdit = () => {
    this.setState({ submitted: true });
    const {
      id,
      requester,
      keperluan_advance,
      nilai_pengajuan,
      nama_pemilik_rekening,
      nama_bank,
      nomor_rekening,
      g_l_account,
      cost_center,
      currency,
      exchange_rate_to_rp,
    } = this.state;

    if (
      !this.validateForm(
        keperluan_advance,
        nilai_pengajuan,
        nama_pemilik_rekening,
        nama_bank,
        nomor_rekening,
        g_l_account,
        cost_center,
        currency,
        exchange_rate_to_rp
      )
    ) {
      return;
    }
    const { dispatch, history } = this.props;
    let collection = {
      _id: id,
      requester: requester,
      keperluan_advance: keperluan_advance,
      nilai_pengajuan: nilai_pengajuan,
      nama_pemilik_rekening: nama_pemilik_rekening,
      nama_bank: nama_bank,
      nomor_rekening: nomor_rekening,
      g_l_account: g_l_account,
      cost_center: cost_center,
      currency: currency,
      exchange_rate_to_rp: exchange_rate_to_rp,
    };
    if (
      id &&
      requester &&
      keperluan_advance &&
      nilai_pengajuan &&
      nama_pemilik_rekening &&
      nama_bank &&
      nomor_rekening &&
      g_l_account &&
      cost_center &&
      currency &&
      exchange_rate_to_rp
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          dispatch(editAdvance(collection))
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-edit-advance-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/advance");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-edit-advance-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  deleteData = (id) => {
    const { dispatch, history } = this.props;
    const { requester } = this.state;
    let collection = { _id: id, requester: requester };
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, delete entries",
      }).then((result) => {
        if (result.value) {
          dispatch(deleteAdvance(collection))
            .then((response) => {
              toast.success("Data has been deleted successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-advance-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been deleted successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/advance");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-advance-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  approveData = (id) => {
    const { dispatch, history } = this.props;
    const { exchange_rate_to_rp, email_requester, requester, name_requester } =
      this.state;
    let collection = {
      _id: id,
      exchange_rate_to_rp: exchange_rate_to_rp,
      email: email_requester,
      requester: requester,
      name_requester: name_requester,
    };

    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, ready for upper approval",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(upperApprovalAdvance(collection, "need_upper_approval"))
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-advance-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been ready for upper approval successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/advance");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-advance-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.value,
      });
      if (name == "g_l_account") {
        this.setState({
          isValid: true,
        });
      }
      if (name == "cost_center") {
        this.setState({
          isValidCostCenter: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
      });

      if (name == "g_l_account") {
        this.setState({
          isValid: false,
        });
      }
      if (name == "cost_center") {
        this.setState({
          isValidCostCenter: false,
        });
      }
    }
  };
  confirmData = (id) => {
    const { dispatch, history } = this.props;
    const { email_requester, requester, name_requester, department } =
      this.state;
    let collection = {
      _id: id,
      username: requester,
      email: email_requester,
      name: name_requester,
      department: department,
    };

    if (collection) {
      Swal.fire({
        title: "Are you sure to confirm this received?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, confirm",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(confirmAdvance(collection))
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-confirm-advance-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/advance");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-confirm-advance-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  toggleShowPhoto = (item) => {
    this.setState({
      photo: item,
      showModalPhoto: true,
    });
  };
  validateForm = (
    keperluan_advance,
    nilai_pengajuan,
    nama_pemilik_rekening,
    nama_bank,
    nomor_rekening,
    g_l_account,
    cost_center,
    currency,
    exchange_rate_to_rp
  ) => {
    let errors = this.state.errors;
    if (!keperluan_advance) {
      errors.keperluan_advance = "This field is required";
    }
    if (!nilai_pengajuan) {
      errors.nilai_pengajuan = "This field is required";
    }
    if (!nama_pemilik_rekening) {
      errors.nama_pemilik_rekening = "This field is required";
    }
    if (!nama_bank) {
      errors.nama_bank = "This field is required";
    }
    if (!nomor_rekening) {
      errors.nomor_rekening = "This field is required";
    }
    if (!g_l_account) {
      this.setState({ isValid: false });
      errors.g_l_account = "This field is required";
    }
    if (!cost_center) {
      this.setState({ isValidCostCenter: false });
      errors.cost_center = "This field is required";
    }
    if (!currency || currency == "" || currency == null) {
      errors.currency = "This field is required";
    }
    if (!exchange_rate_to_rp) {
      errors.exchange_rate_to_rp = "This field is required";
    }

    return errors;
  };
  render() {
    let {
      columns,
      defaultSorted,
      showModal,
      showModalPhoto,
      cellEditProps,
      select_items,
      select_items_glaccount,
      select_items_costcenter,
      advance,
      id,
      keperluan_advance,
      nilai_pengajuan,
      nama_pemilik_rekening,
      nama_bank,
      nomor_rekening,
      g_l_account,
      cost_center,
      currency,
      exchange_rate_to_rp,
      errors,
      submitted,
      readOnly,
      showModalAdvance,
      ready_for_approval,
      error_message_exchange_rate,
      showModalFlow,
      loading,
      confirmation,
      grand_total,
      showPhoto,
      photo,
      isValid,
      isValidCostCenter,
      isClearable,
      status,
      created_at,
      no_advance,
    } = this.state;
    const customStyles = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "green",
        },
      }),
    };
    const customStylesCostCenter = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidCostCenter
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidCostCenter
            ? "#ddd"
            : "green",
        },
      }),
    };
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModalPhoto}
            size="xl"
            backdrop="static"
            onHide={this.onModalPhotoHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader
              closeButton
              className="bg-info text-white"
            ></ModalHeader>
            <ModalBody>
              <Container>
                <iframe src={photo} width="99%" height="680" />
              </Container>
            </ModalBody>
          </Modal>

          <ToastContainer />
          <Modal
            show={showModalFlow}
            size="xl"
            backdrop="static"
            onHide={this.onModalFlowHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Flow Advance</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={1}>Submitted</Col>
                    <Col xs={0}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={1}>Approval</Col>
                    <Col xs={0}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2}>Disbursement of Funds</Col>
                    <Col xs={0}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={1}>Confirm</Col>
                    <Col xs={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={1}>Settlement</Col>
                    <Col xs={0}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2}>Settlement Activity</Col>
                    <Col xs={0}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={1}>Done</Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <img src="./images/flow_advance.png" width="90%" alt="" />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </form>
          </Modal>

          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
              autoComplete="off"
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>{id ? "Update" : "Create"} Advance</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      <div
                        className={
                          errors.keperluan_advance
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="keperluan_advance">
                          Purpose of Advance *
                        </label>
                        <textarea
                          id="keperluan_advance"
                          className="form-control form-control-sm"
                          name="keperluan_advance"
                          placeholder="Purpose of Advance"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={keperluan_advance}
                          rows="5"
                        />
                        {errors.keperluan_advance && (
                          <div className="invalid-feedback">
                            {errors.keperluan_advance}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div
                        className={
                          errors.currency != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="nominal_pengajuan">
                          Proposal Nominal *
                        </label>
                        <div className="input-group mb-3">
                          <div className="col-md-3">
                            <select
                              className={
                                errors.currency != "This field is required"
                                  ? "form-control form-control-sm "
                                  : "form-control form-control-sm  has-error has-feedback"
                              }
                              name="currency"
                              onChange={this.onChangeValue}
                              style={{ padding: 0 }}
                              defaultValue={currency}
                              required
                            >
                              <option value="">Choose</option>
                              {select_items &&
                                select_items.map(function (item, i) {
                                  return (
                                    <option value={item.value} key={i}>
                                      {item.value}
                                    </option>
                                  );
                                })}
                            </select>

                            {errors.currency == "This field is required" && (
                              <div className="invalid-feedback">
                                {errors.currency}
                              </div>
                            )}
                          </div>
                          <div className="col-md-9">
                            <div
                              className={
                                errors.nilai_pengajuan
                                  ? "input-group-append"
                                  : "input-group-append has-error has-feedback"
                              }
                            >
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                name="nilai_pengajuan"
                                step="0.01"
                                min="0"
                                placeholder="Proposal Nominal"
                                required="required"
                                onChange={this.onChangeValue}
                                defaultValue={nilai_pengajuan}
                              />

                              {errors.nilai_pengajuan && (
                                <div className="invalid-feedback">
                                  {errors.nilai_pengajuan}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="nama_pemilik_rekening">
                          Name of Account Holder *
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="nama_pemilik_rekening"
                          placeholder="Name of Account Holder"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={nama_pemilik_rekening}
                        />

                        {errors.nama_pemilik_rekening && (
                          <div className="invalid-feedback">
                            {errors.nama_pemilik_rekening}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="nama_bank">Bank *</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="nama_bank"
                          placeholder="Bank"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={nama_bank}
                        />
                        {errors.nama_bank && (
                          <div className="invalid-feedback">
                            {errors.nama_bank}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="nomor_rekening">Account Number *</label>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          name="nomor_rekening"
                          placeholder="Account Number"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={nomor_rekening}
                          min="0"
                        />

                        {errors.nomor_rekening && (
                          <div className="invalid-feedback">
                            {errors.nomor_rekening}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="cost_center">Cost Center *</label>
                        <Select
                          styles={customStylesCostCenter}
                          onChange={this.handleChange.bind(this, "cost_center")}
                          options={select_items_costcenter}
                          defaultValue={select_items_costcenter.filter(
                            (e) => e.value === cost_center
                          )}
                          isClearable={isClearable}
                        />

                        {!isValidCostCenter && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.cost_center}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="g_l_account">GL Account *</label>
                        <Select
                          styles={customStyles}
                          onChange={this.handleChange.bind(this, "g_l_account")}
                          options={select_items_glaccount}
                          defaultValue={select_items_glaccount.filter(
                            (e) => e.value === g_l_account
                          )}
                          isClearable={isClearable}
                        />

                        {!isValid && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.g_l_account}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      {!id && (
                        <div className="form-group">
                          <label htmlFor="upload_data">File </label>
                          <input
                            type="file"
                            className="form-control form-control-sm"
                            name="upload_data"
                            placeholder="File"
                            multiple
                            onChange={this.onChangeHandlerFile}
                          />
                        </div>
                      )}
                      <div className="form-group">
                        <label htmlFor="created_at">Created at</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="created_at"
                          placeholder="Created at"
                          defaultValue={created_at}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter className="justify-content-between">
                <Button variant="danger" onClick={this.toggle}>
                  <i className="fas fa-times" /> Cancel
                </Button>
                {id ? (
                  <Button variant="success" onClick={this.handleSubmitEdit}>
                    <i className="fas fa-save" /> Update
                  </Button>
                ) : (
                  <Button variant="success" onClick={this.handleSubmit}>
                    <i className="fas fa-save" /> Submit
                  </Button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Modal
            show={showModalAdvance}
            size="xl"
            backdrop="static"
            onHide={this.onModalAdvanceHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Detail Advance</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="keperluan_advance">
                          Purpose of Advance *
                        </label>
                        <textarea
                          id="keperluan_advance"
                          className="form-control form-control-sm"
                          name="keperluan_advance"
                          placeholder="Purpose of Advance"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={keperluan_advance}
                          rows="5"
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="nominal_pengajuan">
                          Proposal Nominal *
                        </label>
                        <div className="input-group mb-3">
                          <div className="col-md-3">
                            <select
                              className="form-control form-control-sm "
                              name="currency"
                              onChange={this.onChangeValue}
                              style={{ padding: 0 }}
                              defaultValue={currency}
                              readOnly={readOnly}
                            >
                              <option value="">Choose</option>
                              {select_items &&
                                select_items.map(function (item, i) {
                                  return (
                                    <option value={item.value} key={i}>
                                      {item.value}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="col-md-9">
                            <div className="input-group-append">
                              <span
                                style={{
                                  backgroundColor: "rgb(233 236 239)",
                                  padding: ".25rem .5rem",
                                  fontSize: ".875rem",
                                  lineHeight: "1.5",
                                  borderRadius: ".2rem",
                                }}
                              >
                                {addSeparatorsNF(
                                  nilai_pengajuan,
                                  ".",
                                  ".",
                                  ","
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group input-group-sm mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text input-group-text"
                              id="exchange_rate_to_rp"
                            >
                              Exchange Rate to Rp.
                            </span>
                          </div>
                          <span
                            style={{
                              backgroundColor: "rgb(233 236 239)",
                              padding: ".25rem .5rem",
                              fontSize: ".875rem",
                              lineHeight: "1.5",
                              borderRadius: ".2rem",
                            }}
                          >
                            {addSeparatorsNF(
                              exchange_rate_to_rp,
                              ".",
                              ".",
                              ","
                            )}
                          </span>
                        </div>
                        {error_message_exchange_rate && (
                          <em className="error-message">
                            {error_message_exchange_rate}
                          </em>
                        )}
                      </div>
                      {confirmation && (
                        <div className="form-group">
                          <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text input-group-text"
                                id="grand_total"
                              >
                                Grand Total (Rp)
                              </span>
                            </div>
                            <span
                              style={{
                                backgroundColor: "rgb(233 236 239)",
                                padding: ".25rem .5rem",
                                fontSize: ".875rem",
                                lineHeight: "1.5",
                                borderRadius: ".2rem",
                              }}
                            >
                              {addSeparatorsNF(grand_total, ".", ".", ",")}
                            </span>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="nama_pemilik_rekening">
                          Name of Account Holder *
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="nama_pemilik_rekening"
                          placeholder="Name of Account Holder"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={nama_pemilik_rekening}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="nama_bank">Bank *</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="nama_bank"
                          placeholder="Bank"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={nama_bank}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="nomor_rekening">Account Number *</label>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          name="nomor_rekening"
                          placeholder="Account Number"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={nomor_rekening}
                          readOnly={readOnly}
                          min="0"
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="cost_center">Cost Center *</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="cost_center"
                          placeholder="Cost Center"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={cost_center}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="g_l_account">GL Account *</label>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          name="g_l_account"
                          placeholder="GL Account"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={g_l_account}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    {confirmation && showPhoto.length > 0 && (
                      <Col xs={12} md={6}>
                        <div className="form-group">
                          <label htmlFor="upload_data">
                            {" "}
                            Proof of Transfer
                          </label>
                        </div>
                        {showPhoto &&
                          showPhoto.map((item) => {
                            return (
                              <Button
                                color="info"
                                className="btn btn-xs"
                                onClick={() => this.toggleShowPhoto(item)}
                              >
                                <i className="fas fa-file" /> Show&nbsp;
                              </Button>
                            );
                          })}
                      </Col>
                    )}

                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="created_at">Created at</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="created_at"
                          placeholder="Created at"
                          defaultValue={created_at}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="no_advance">No Advance</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="no_advance"
                          placeholder="No Advance"
                          defaultValue={no_advance}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter className="justify-content-between">
                <Button variant="danger" onClick={this.toggleAdvance}>
                  <i className="fas fa-times" /> Close
                </Button>
                {ready_for_approval && (
                  <button
                    type="button"
                    className="btn btn-success btn-sm ml-2 mb-2 ts-buttom"
                    size="sm"
                    data-id={id}
                    onClick={() => this.approveData(id)}
                  >
                    <i className="fas fa-check-square"></i>
                    &nbsp;Ready for Approval
                  </button>
                )}
                {confirmation && (
                  <button
                    type="button"
                    className="btn btn-success btn-sm ml-2 mb-2 ts-buttom"
                    size="sm"
                    data-id={id}
                    onClick={() => this.confirmData(id)}
                  >
                    <i className="fas fa-check-square"></i>
                    &nbsp;Confirm
                  </button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">History Advance</h3>
                        <div className="card-tools float-right"></div>
                      </div>
                      <div className="card-body">
                        <RemoteTable
                          data={advance}
                          columns={columns}
                          defaultSorted={defaultSorted}
                          cellEditProps={cellEditProps}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { read_advance, read_file_advance } = state.advance;
  const { read_currency } = state.currency;
  const { read_glaccount } = state.glaccount;
  const { read_costcenter } = state.costcenter;

  const { read_current_exchange_rate, read_active_exchange_rate } =
    state.exchange_rate;

  const { message } = state.message;
  return {
    message,
    read_advance,
    read_file_advance,
    read_currency,
    read_current_exchange_rate,
    read_active_exchange_rate,
    user,
    read_glaccount,
    read_costcenter,
  };
}

export default connect(mapStateToProps)(HistoryAdvance);
