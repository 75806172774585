import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../actions/auth";
import { getMenu } from "../actions/menu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.state = {
      username: "",
      password: "",
      loading: false,
      submitted: false,
    };
  }
  componentDidMount() {
    const message = localStorage.getItem("message");
    if (message) {
      Swal.fire({
        icon: "warning",
        title: "Information",
        text: message,
        showConfirmButton: true,
      }).then(() => {
        localStorage.clear();
      });
    }
  }
  handleLogin(e) {
    e.preventDefault();
    this.setState({
      loading: true,
      submitted: true,
    });
    const { dispatch, history } = this.props;
    const { username, password } = this.state;

    if (username && password) {
      dispatch(login(username, password))
        .then((response) => {
          const user = JSON.parse(localStorage.getItem("user"));
          let role = "guest";
          if (user.details.hris_org_tree.current_person) {
            role = `${user.details.hris_org_tree.current_person.nama_department} ${user.details.hris_org_tree.current_person.nama_posisi}`;
          }
          //getmenu from backend
          dispatch(getMenu(role))
            .then((response) => {
              history.push("/home");
              window.location.reload();
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-login-failed",
                onClose: () => this.setState({ submitted: false }),
              });
              this.setState({
                loading: false,
              });
            });

          Swal.fire({
            icon: "success",
            title: "Information",
            text: "Login Success",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((err) => {
          toast.error(this.props.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: "customId-login-failed",
            onClose: () => this.setState({ submitted: false }),
          });
          this.setState({
            loading: false,
          });

          Swal.fire({
            icon: "warning",
            title: "Information",
            text: this.props.message,
            showConfirmButton: false,
            timer: 3000,
          });
          history.push("/login");
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value.toUpperCase(),
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  render() {
    const { isLoggedIn } = this.props;
    const { username, password, submitted } = this.state;
    if (isLoggedIn) {
      return <Redirect to="/" />;
    }
    return (
      <div className="hold-transition login-page">
        <div className="login-box">
          {/* /.login-logo */}
          <ToastContainer />
          <div className="card card-outline card-primary">
            <div className="login-logo">
              <a href="#!">
                <b />
                Login
              </a>
            </div>
            <div className="card-body login-card-body">
              <p className="login-box-msg">Sign in to start your session</p>
              <form
                method="POST"
                autoComplete="off"
                onSubmit={this.handleLogin}
                ref={(c) => {
                  this.form = c;
                }}
              >
                <div className="input-group mb-3">
                  <input
                    placeholder="Username"
                    id="username"
                    type="text"
                    className={
                      submitted && !username
                        ? " is-invalid form-control uppercase"
                        : "form-control uppercase"
                    }
                    name="username"
                    autoFocus
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                  />
                  {submitted && !username && (
                    <div className="invalid-feedback order-last ">
                      Username is required
                    </div>
                  )}
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-user" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    placeholder="Password"
                    id="password"
                    type="password"
                    className={
                      submitted && !password
                        ? " is-invalid form-control"
                        : "form-control"
                    }
                    name="password"
                    value={this.state.password}
                    required
                    onChange={this.onChangePassword}
                  />
                  {submitted && !password && (
                    <div className="invalid-feedback order-last ">
                      Password is required
                    </div>
                  )}
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* /.col */}
                  <div className="col-12">
                    {submitted && (
                      <button
                        disabled
                        className="btn btn-primary btn-block"
                        id="submit"
                      >
                        <Spinner animation="border" size="sm" role="status" Color="white" />
                        &nbsp; Loading...

                      </button>
                    )}
                    {!submitted && (
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        id="submit"
                      >
                        Sign In
                      </button>
                    )}
                  </div>
                  {/* /.col */}
                </div>
              </form>
            </div>
            {/* /.login-card-body */}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(Login);
