import {
  READ_SERVICE_NUMBER_SUCCESS,
  READ_SERVICE_NUMBER_FAIL,
} from "../actions/types";

const initialState = {
  read_service_number: null,
};

export default function account_assignment_cat(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_SERVICE_NUMBER_SUCCESS:
      return {
        ...state,
        read_service_number: payload,
      };
    case READ_SERVICE_NUMBER_FAIL:
      return {
        ...state,
        read_service_number: null,
      };
    default:
      return state;
  }
}
