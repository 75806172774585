import React, { Component, useState, useMemo } from "react";
import PropTypes from "prop-types";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import { connect } from "react-redux";
import {
  readInputNominalPurchaseRequest,
  upperApprovalPurchaseRequest,
  checkBudgetInputNominal,
} from "../actions/purchase_request";
import { readUOM } from "../actions/uom";
import { readCurrency } from "../actions/currency";
import { readMATERIAL } from "../actions/material";
import { readSUPPLIER } from "../actions/supplier";
import { readDepartment } from "../actions/department";
import { clearMessage } from "../actions/message";
import { readGLAccount } from "../actions/glaccount";
import { readServiceNumber } from "../actions/servicenumber";
import DynamicTable from "../components/DynamicTable";
import DynamicTablePrExpense from "../components/DynamicTablePrExpense";
import DataTable from "react-data-table-component";
import moment from "moment";

const TableCustom = ({
  data,
  totalRows,
  action,
  toggleStatus,
  fetchData,
  handleSearch,
  loading,
}) => {
  const [perPage, setPerPage] = useState(10);
  const handleChange = (e) => {
    handleSearch({
      [e.target.name]: e.target.value,
    });
  };

  const columns = useMemo(() => {
    return [
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Requester"
                onChange={handleChange}
                name="requester"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => `${row.name_requester} (${row.requester})`,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Document Number"
                onChange={handleChange}
                name="document_number"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => row.document_number,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Summary"
                onChange={handleChange}
                name="summary"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        cell: (row) => {
          let items = "";
          if (row.pr_type === "inventory") {
            items = (row?.details || []).map((e) => e?.short_text).join(", ");
          } else {
            items = (row?.details || []).map((e) => e?.item_text).join(", ");
          }

          return items;
        },
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="PR Type"
                onChange={handleChange}
                name="pr_type"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => row.pr_type,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Status"
                onChange={handleChange}
                name="status"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        cell: (row) => {
          return (
            <div>
              <button
                type="button"
                className="btn btn-sm ml-2 mb-2 ts-buttom"
                size="sm"
                onClick={toggleStatus}
              >
                <i className="fas fa-search-location"></i>
                &nbsp;{row.status}
              </button>
            </div>
          );
        },
        sortable: true,
      },
      {
        name: "Action",
        maxWidth: "max-content",
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: action,
      },
    ];
  }, []);

  const handlePageChange = async (page) => {
    await fetchData({
      page,
      sizePerPage: perPage,
    });
  };

  const handlePerPageChange = async (newPerPage, page) => {
    await fetchData({
      page,
      sizePerPage: newPerPage,
    });

    setPerPage(newPerPage);
  };

  return (
    <DataTable
      persistTableHead={true}
      columns={columns}
      data={data}
      pagination
      paginationServer
      paginationPerPage={perPage}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      paginationTotalRows={totalRows}
      progressPending={loading}
    />
  );
};

TableCustom.propTypes = {
  data: PropTypes.instanceOf(Array),
  totalRows: PropTypes.number,
  action: PropTypes.func,
  toggleStatus: PropTypes.func,
  fetchData: PropTypes.func,
  handleSearch: PropTypes.func,
  loading: PropTypes.bool,
};

TableCustom.defaultProps = {
  data: [],
  totalRows: 0,
  loading: false,
};

class InputNominalPR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requesterFilter: null,
      documentNumberFilter: null,
      summaryFilter: null,
      prTypeFilter: null,
      statusFilter: null,
      isClearable: true,
      isValid: true,
      isValidDate: true,
      isValidMaterial: true,
      isValidSupplier: true,
      loading: false,
      fetchLoading: false,
      submitted: false,
      showModal: false,
      showModalPurchaseRequest: false,
      showModalFlow: false,
      readOnly: false,
      collection: [],
      select_items_source_determination: [],
      select_items_material: [],
      select_items_requisitioner: [],
      select_items_uom: [],
      select_items_service_number: [],
      select_items_account_assignment_cat: [
        { value: "K", label: "(K) Cost Center" },
      ],
      exchange_rate_recent: [],
      select_items_item_cat: [{ value: "D", label: "(D) Service" }],
      select_items_currency: [],
      list_data: [],
      count_list_data: 0,
      errors: {},
      id: null,

      requester: null,
      email_requester: null,
      department: null,

      document_type: null,
      created_at: null,
      source_determination: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      g_l_account: null,

      cost_center: null,
      remark: null,
      account_assignment_cat: null,

      ready_for_approval: false,
      details: null,
      details_inventory: [
        {
          material_code: "",
          material_name: "",
          short_text: "",
          po_text: "",
          qty: "",
          uom: "",
          delivery_date: "",
        },
      ],
      details_expense: [
        {
          material_code: "",
          material_name: "",
          item_text: "",
          short_text: "",
          qty: "",
          uom: "",
          delivery_date: "",
        },
      ],
      details_service: [
        {
          short_text: "",
          item_text: "",
          service_number: "",
          qty: "",
          uom: "",
          delivery_date: "",
        },
      ],
      columns_expense: [
        {
          text: "Sort Text",
          type: "text",
        },
        {
          text: "Item Text",
          type: "text2",
        },
        {
          text: "GL Account",
          type: "glaccount",
        },
        {
          text: "Qty",
          type: "numeric",
        },
        {
          text: "UOM",
          type: "uom",
        },
        {
          text: "Delivery Date",
          type: "date",
        },
        {
          text: "Currency",
          type: "currency",
        },
        {
          text: "Nominal",
          type: "nominal",
        },
      ],
      columns_service: [
        {
          text: "Short Text",
          type: "text",
        },
        {
          text: "Item Text",
          type: "item_text",
        },
        {
          text: "Service Number",
          type: "service_number",
        },
        {
          text: "Qty",
          type: "numeric",
        },
        {
          text: "UOM",
          type: "uom",
        },
        {
          text: "Delivery Date",
          type: "date",
        },
        {
          text: "Currency",
          type: "currency",
        },
        {
          text: "Nominal",
          type: "nominal",
        },
      ],
      columns_inventory: [
        {
          text: "Material",
          type: "material",
          width: "15%",
        },
        {
          text: "Long Text",
          type: "text",
        },
        {
          text: "GL Account",
          type: "glaccount",
          width: "30%",
        },
        {
          text: "Qty",
          type: "numeric",
        },
        {
          text: "UOM",
          type: "uom",
        },
        {
          text: "Delivery Date",
          type: "date",
        },
        {
          text: "Currency",
          type: "currency",
        },
        {
          text: "Nominal",
          type: "nominal",
        },
      ],
      columns_new: [
        {
          dataField: "requester",
          text: "Requester",
          filter: textFilter(),
          formatter: this.GetRequesterFormat,
          sort: true,
        },
        {
          dataField: "document_number",
          text: "Document Number",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "details",
          text: "Summary",
          filter: textFilter(),
          formatter: this.GetDetailItems,
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "pr_type",
          text: "PR Type",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "status",
          text: "Status",
          filter: textFilter(),
          formatter: this.GetStatusFormat,
          classes: "text-center",
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      cellEditProps: {},
      search: {},
    };
    this.timer = null;
  }

  fetchInputNominal(additionalParams = { page: 1, sizePerPage: 10 }) {
    const { dispatch, user } = this.props;
    this.setState({
      fetchLoading: true,
    });

    dispatch(
      readInputNominalPurchaseRequest({
        search: this.state.search,
        ...additionalParams,
        approval: user.username,
      })
    ).then((response) => {
      this.setState({
        list_data: this.props.read_purchase_request.data.foundData,
        count_list_data: this.props.read_purchase_request.data.countData,
        fetchLoading: false,
      });
    });
  }

  componentDidMount() {
    const { dispatch, user } = this.props;
    this.fetchInputNominal();
    dispatch(readDepartment()).then((response) => {
      const { read_department } = this.props;
      let o = [];
      read_department.data.map((e) => {
        o.push({ value: e.name, label: e.name });
        return;
      });
      this.setState({
        select_items_requisitioner: o,
      });
    });
    dispatch(readGLAccount({ size: 1, sizePerPage: 10 })).then((response) => {
      if (this.props.read_glaccount) {
        this.setState({
          select_items_glaccount: this.props.read_glaccount.data,
        });
      }
    });

    dispatch(readUOM()).then((response) => {
      this.setState({ select_items_uom: this.props.read_uom.data });
    });
    dispatch(readMATERIAL({ size: 1, sizePerPage: 10 })).then((response) => {
      this.setState({ select_items_material: this.props.read_material.data });
    });
    dispatch(readCurrency()).then((response) => {
      this.setState({ select_items_currency: this.props.read_currency.data });
    });
    dispatch(readServiceNumber()).then((response) => {
      this.setState({
        select_items_service_number: this.props.read_service_number.data,
      });
    });
    dispatch(readSUPPLIER({ size: 1, sizePerPage: 10 })).then((response) => {
      this.setState({
        select_items_source_determination: this.props.read_supplier.data,
      });
    });

    this.setState({
      requester: user.username,
      department: user.details.hris_org_tree.current_person.nama_department,
      email_requester: user.details.hris_org_tree.current_person.email,
      name_requester: user.details.hris_org_tree.current_person.person_name,
    });
  }
  reloadDataBackend = () => {
    const { dispatch } = this.props;

    this.fetchInputNominal();
    dispatch(readGLAccount({ size: 1, sizePerPage: 10 })).then((response) => {
      if (this.props.read_glaccount) {
        this.setState({
          select_items_glaccount: this.props.read_glaccount.data,
        });
      }
    });

    dispatch(readUOM()).then((response) => {
      this.setState({ select_items_uom: this.props.read_uom.data });
    });
    dispatch(readMATERIAL({ size: 1, sizePerPage: 10 })).then((response) => {
      this.setState({ select_items_material: this.props.read_material.data });
    });
    dispatch(readCurrency()).then((response) => {
      this.setState({ select_items_currency: this.props.read_currency.data });
    });
    dispatch(readServiceNumber()).then((response) => {
      this.setState({
        select_items_service_number: this.props.read_service_number.data,
      });
    });
    dispatch(readSUPPLIER({ size: 1, sizePerPage: 10 })).then((response) => {
      this.setState({
        select_items_source_determination: this.props.read_supplier.data,
      });
    });

    this.setState({
      submitted: false,
      document_type: null,
      created_at: null,
      source_determination: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      g_l_account: null,
      errors: {},
      cost_center: null,
      remark: null,
      account_assignment_cat: null,

      ready_for_approval: false,
      details: [],
      created_at: null,

      details_inventory: [
        {
          material_code: "",
          material_name: "",
          short_text: "",
          po_text: "",
          qty: "",
          uom: "",
          delivery_date: "",
        },
      ],
      details_expense: [
        {
          material_code: "",
          material_name: "",
          item_text: "",
          short_text: "",
          qty: "",
          uom: "",
          delivery_date: "",
        },
      ],
      details_service: [
        {
          short_text: "",
          item_text: "",
          service_number: "",
          qty: "",
          uom: "",
          delivery_date: "",
        },
      ],
    });
  };
  handleSearch = (data) => {
    this.setState((prevState) => ({
      search: { ...prevState.search, ...data },
    }));

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fetchInputNominal();
    }, 250);
  };

  onModalPurchaseRequestHide = () => {
    this.setState({
      id: null,
      submitted: false,

      document_type: null,
      source_determination: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      g_l_account: null,
      nominal: null,

      showModalPurchaseRequest: !this.state.showModalPurchaseRequest,
      readOnly: false,
      ready_for_approval: false,

      cost_center: null,
      remark: null,
      account_assignment_cat: null,
      details: [],
      created_at: null,

      details_inventory: [
        {
          material_code: "",
          material_name: "",
          short_text: "",
          po_text: "",
          qty: "",
          uom: "",
          delivery_date: "",
        },
      ],
      details_expense: [
        {
          material_code: "",
          material_name: "",
          item_text: "",
          short_text: "",
          qty: "",
          uom: "",
          delivery_date: "",
        },
      ],
      details_service: [
        {
          short_text: "",
          item_text: "",
          service_number: "",
          qty: "",
          uom: "",
          delivery_date: "",
        },
      ],
    });
  };
  togglePurchaseRequest = () => {
    this.setState({
      showModalPurchaseRequest: !this.state.showModalPurchaseRequest,
    });
  };

  toggleFlowPurchaseRequest = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };
  onModalFlowHide = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };
  toDetailPurchaseRequest = (
    id,
    document_type,
    source_determination,
    material,
    quantity,
    unit_of_measure,
    delivery_date,
    plant,
    purchase_group,
    requisitioner,
    status,
    ready_for_approval,
    g_l_account,
    cost_center,
    remark,
    account_assignment_cat,
    pr_type,
    details,
    document_number,
    created_at
  ) => {
    const { dispatch } = this.props;
    if (status === "Submitted") {
      if (pr_type == "inventory") {
        return this.setState(
          {
            id: id,
            document_type: document_type,
            source_determination: source_determination,
            material: material,
            quantity: quantity,
            unit_of_measure: unit_of_measure,
            delivery_date: delivery_date,
            plant: plant,
            purchase_group: purchase_group,
            requisitioner: requisitioner,
            status: status,
            readOnly: true,
            ready_for_approval: ready_for_approval,
            g_l_account: g_l_account,
            cost_center: cost_center,
            remark: remark,
            account_assignment_cat: account_assignment_cat,
            pr_type: pr_type,
            details_inventory: details,
            details: details,
            document_number: document_number,
            created_at: created_at,
          },
          () => {
            this.togglePurchaseRequest();
          }
        );
      }
      if (pr_type == "expense") {
        return this.setState(
          {
            id: id,
            document_type: document_type,
            source_determination: source_determination,
            material: material,
            quantity: quantity,
            unit_of_measure: unit_of_measure,
            delivery_date: delivery_date,
            plant: plant,
            purchase_group: purchase_group,
            requisitioner: requisitioner,
            status: status,
            readOnly: true,
            ready_for_approval: ready_for_approval,
            g_l_account: g_l_account,
            cost_center: cost_center,
            remark: remark,
            account_assignment_cat: account_assignment_cat,
            pr_type: pr_type,
            details_expense: details,
            details: details,
            document_number: document_number,
            created_at: created_at,
          },
          () => {
            this.togglePurchaseRequest();
          }
        );
      }
      if (pr_type == "service") {
        return this.setState(
          {
            id: id,
            document_type: document_type,
            source_determination: source_determination,
            material: material,
            quantity: quantity,
            unit_of_measure: unit_of_measure,
            delivery_date: delivery_date,
            plant: plant,
            purchase_group: purchase_group,
            requisitioner: requisitioner,
            status: status,
            readOnly: true,
            ready_for_approval: ready_for_approval,
            g_l_account: g_l_account,
            cost_center: cost_center,
            remark: remark,
            account_assignment_cat: account_assignment_cat,
            pr_type: pr_type,
            details_service: details,
            details: details,
            document_number: document_number,
            created_at: created_at,
          },
          () => {
            this.togglePurchaseRequest();
          }
        );
      }
    }
    if (pr_type == "inventory") {
      return this.setState(
        {
          id: id,
          document_type: document_type,
          source_determination: source_determination,
          material: material,
          quantity: quantity,
          unit_of_measure: unit_of_measure,
          delivery_date: delivery_date,
          plant: plant,
          purchase_group: purchase_group,
          requisitioner: requisitioner,
          status: status,
          readOnly: true,
          ready_for_approval: ready_for_approval,
          g_l_account: g_l_account,
          cost_center: cost_center,
          remark: remark,
          account_assignment_cat: account_assignment_cat,
          pr_type: pr_type,
          details_inventory: details,
          details: details,
          document_number: document_number,
          created_at: created_at,
        },
        () => {
          this.togglePurchaseRequest();
        }
      );
    }
    if (pr_type == "expense") {
      return this.setState(
        {
          id: id,
          document_type: document_type,
          source_determination: source_determination,
          material: material,
          quantity: quantity,
          unit_of_measure: unit_of_measure,
          delivery_date: delivery_date,
          plant: plant,
          purchase_group: purchase_group,
          requisitioner: requisitioner,
          status: status,
          readOnly: true,
          ready_for_approval: ready_for_approval,
          g_l_account: g_l_account,
          cost_center: cost_center,
          remark: remark,
          account_assignment_cat: account_assignment_cat,
          pr_type: pr_type,
          details_expense: details,
          details: details,
          document_number: document_number,
          created_at: created_at,
        },
        () => {
          this.togglePurchaseRequest();
        }
      );
    }
    if (pr_type == "service") {
      return this.setState(
        {
          id: id,
          document_type: document_type,
          source_determination: source_determination,
          material: material,
          quantity: quantity,
          unit_of_measure: unit_of_measure,
          delivery_date: delivery_date,
          plant: plant,
          purchase_group: purchase_group,
          requisitioner: requisitioner,
          status: status,
          readOnly: true,
          ready_for_approval: ready_for_approval,
          g_l_account: g_l_account,
          cost_center: cost_center,
          remark: remark,
          account_assignment_cat: account_assignment_cat,
          pr_type: pr_type,
          details_service: details,
          details: details,
          document_number: document_number,
          created_at: created_at,
        },
        () => {
          this.togglePurchaseRequest();
        }
      );
    }
  };
  GetActionFormat = (row) => {
    let ready = row.isConfirm ? false : true;
    return (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetailPurchaseRequest(
              row._id,
              row.document_type,
              row.source_determination,
              row.material,
              row.quantity,
              row.unit_of_measure,
              row.delivery_date,
              row.plant,
              row.purchase_group,
              row.requisitioner,
              row.status,
              ready,
              row.g_l_account,
              row.cost_center,
              row.remark,
              row.account_assignment_cat,
              row.pr_type,
              row.details,
              row.document_number,
              row.created_at
            )
          }
        >
          <i className="fas fa-th"></i>
          &nbsp;Detail
        </button>
      </div>
    );
  };
  isEmptyObject = (obj) => {
    return obj == null || !Object.keys(obj).length;
  };
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  validateForm = (source_determination) => {
    let errors = this.state.errors;
    if (!source_determination) {
      errors.source_determination = "This field is required";
      this.setState({
        isValidSupplier: false,
      });
    }

    return errors;
  };

  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.value,
      });
      if (name == "source_determination") {
        this.setState({
          isValidSupplier: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
      });

      if (name == "source_determination") {
        this.setState({
          isValidSupplier: false,
        });
      }
    }
  };

  approveData = (id) => {
    this.setState({ submitted: true });
    const { dispatch, history } = this.props;
    const {
      requester,
      status,
      email_requester,
      name_requester,
      source_determination,
      details,
    } = this.state;

    let collection = {
      _id: id,
      requester: requester,
      email: email_requester,
      name_requester: name_requester,
      source_determination: source_determination,
      details: details,
    };

    let collection_check = {
      _id: id,
      requester: requester,
      email: email_requester,
      name_requester: name_requester,
      source_determination: source_determination,
      details: details,
      check_budget: true,
      status: status,
    };

    if (!this.validateForm(source_determination)) {
      return;
    }

    dispatch(checkBudgetInputNominal(collection_check))
      .then((response) => {
        Swal.fire({
          title: "Are you sure to submit this?",
          text: "Please check your entries",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Yes, submit this",
        }).then((result) => {
          if (result.value) {
            this.setState({ loading: true });
            dispatch(
              upperApprovalPurchaseRequest(collection, status, "nominal")
            )
              .then((response) => {
                toast.success("Data has been submitted successfully", {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  toastId: "customId-input-purchase-request-success",
                  onClose: () => dispatch(clearMessage()),
                });
                Swal.fire({
                  title: "Information",
                  icon: "success",
                  text: "Data has been submitted successfully",
                  showConfirmButton: false,
                  timer: 2000,
                }).then((result) => {
                  history.push("/input/nominal-purchase-request");
                  this.reloadDataBackend();

                  this.setState({
                    loading: false,
                    showModalPurchaseRequest: false,
                  });
                });
              })
              .catch((err) => {
                toast.error(this.props.message, {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  toastId: "customId-input-purchase-request-failed",
                  onClose: () => dispatch(clearMessage()),
                });
                this.setState({
                  loading: false,
                });
              });
          }
        });
      })
      .catch((err) => {
        if (this.props.message == "Cannot Input Nominal PR") {
          return Swal.fire({
            title: "Information!",
            icon: "warning",
            html: this.props.message,
          });
        }
        if (this.props.message == "Currency and nominal cannot be empty") {
          return Swal.fire({
            title: "Information!",
            icon: "warning",
            html: this.props.message,
          });
        }
        if (
          this.props.message ==
          " is not found in exchange rate. Please assign it first"
        ) {
          return Swal.fire({
            title: "Information!",
            icon: "warning",
            html: `Currency ${this.props.message}`,
          });
        }

        if (
          this.props.message ==
          "Exchange Rate for this month is not found. Please assign it first"
        ) {
          return Swal.fire({
            title: "Information!",
            icon: "warning",
            html: this.props.message,
          });
        }

        if (
          this.props.message !== "Currency and nominal cannot be empty" &&
          this.props.message !==
            " is not found in exchange rate. Please assign it first"
        ) {
          Swal.fire({
            title: "Over Budget",
            icon: "warning",
            html: this.props.message,
            showCancelButton: true,
            confirmButtonText: "Yes, continue",
          }).then((result) => {
            if (result.value) {
              this.setState({ loading: true });
              dispatch(
                upperApprovalPurchaseRequest(collection, status, "nominal")
              )
                .then((response) => {
                  toast.success("Data has been submitted successfully", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: "customId-input-purchase-request-success",
                    onClose: () => dispatch(clearMessage()),
                  });
                  Swal.fire({
                    title: "Information",
                    icon: "success",
                    text: "Data has been submitted successfully",
                    showConfirmButton: false,
                    timer: 2000,
                  }).then((result) => {
                    history.push("/input/nominal-purchase-request");
                    this.reloadDataBackend();

                    this.setState({
                      loading: false,
                      showModalPurchaseRequest: false,
                    });
                  });
                })
                .catch((err) => {
                  toast.error(this.props.message, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: "customId-input-purchase-request-failed",
                    onClose: () => dispatch(clearMessage()),
                  });
                  this.setState({
                    loading: false,
                  });
                });
            }
          });
        }
      });
  };

  renderDetail = (loadGLAccount) => {
    const {
      select_items_material,
      select_items_uom,
      select_items_glaccount,
      select_items_currency,
      select_items_service_number,
      columns_inventory,
      columns_expense,
      columns_service,
      pr_type,
      document_type,
      requisitioner,
      plant,
      purchase_group,
      document_number,
      details_inventory,
      details_expense,
      details_service,
      account_assignment_cat,
      cost_center,
      item_cat,
      isValidSupplier,
      created_at,
    } = this.state;
    if (pr_type == "inventory") {
      return (
        <div>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="document_type">Document Type *</label>

                <input
                  type="text"
                  className="form-control form-control-md"
                  name="document_type"
                  placeholder="Document Type"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={document_type}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="requisitioner">Requisitioner *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="requisitioner"
                  placeholder="Requisitioner"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={requisitioner}
                  readOnly={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="plant">Plant *</label>
                <input
                  type="number"
                  className="form-control form-control-md"
                  name="plant"
                  min="0"
                  placeholder="Plant"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={plant}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="purchase_group">Purchase Group *</label>
                <input
                  type="number"
                  className="form-control form-control-md"
                  name="purchase_group"
                  min="0"
                  placeholder="Purchase Group"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={purchase_group}
                  readOnly={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="account_assignment_cat">
                  Account Assignment Cat{" "}
                </label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="account_assignment_cat"
                  disabled
                ></input>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="purchase_group">Item Cat *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="item_cat"
                  disabled
                ></input>
              </div>
            </Col>
          </Row>

          <Row>
            {created_at && (
              <Col xs={12} md={6}>
                <div className="form-group">
                  <label>Created At</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    defaultValue={moment(created_at).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}
                    readOnly={true}
                  />
                </div>
              </Col>
            )}
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="Document Number">Document Number *</label>

                <input
                  type="text"
                  className="form-control form-control-md"
                  name="document_number"
                  placeholder="Document Number"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={document_number}
                  readOnly={true}
                />
              </div>
            </Col>
          </Row>
          <Col xs={12} md={12}>
            <DynamicTable
              data={details_inventory}
              columns={columns_inventory}
              materials={select_items_material}
              uom={select_items_uom}
              gl_account={select_items_glaccount}
              loadGLAccount={loadGLAccount}
              currency={select_items_currency}
              props={this.props}
              readOnly={true}
              onChange={(data) => {
                this.setState({
                  details: [...data],
                  details_inventory: [...data],
                });
              }}
              onChangeRemaining={(data) => {
                this.setState({
                  details: [...data],
                });
              }}
            />
          </Col>
        </div>
      );
    }
    if (pr_type == "expense") {
      return (
        <div>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="document_type">Document Type *</label>

                <input
                  type="text"
                  className="form-control form-control-md"
                  name="document_type"
                  placeholder="Document Type"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={document_type}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="requisitioner">Requisitioner *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="requisitioner"
                  placeholder="Requisitioner"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={requisitioner}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="plant">Plant *</label>
                <input
                  type="number"
                  className="form-control form-control-md"
                  name="plant"
                  min="0"
                  placeholder="Plant"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={plant}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="purchase_group">Purchase Group *</label>
                <input
                  type="number"
                  className="form-control form-control-md"
                  name="purchase_group"
                  min="0"
                  placeholder="Purchase Group"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={purchase_group}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="account_assignment_cat">
                  Account Assignment Cat{" "}
                </label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="account_assignment_cat"
                  placeholder="Account Assignment Cat"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={account_assignment_cat}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="purchase_group">Item Cat *</label>
                <select
                  className="form-control form-control-md "
                  name="item_cat"
                  style={{ padding: 0 }}
                  readOnly={true}
                >
                  <option value="">Choose</option>
                </select>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="cost_center">Cost Center *</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="cost_center"
                  placeholder="Cost Center"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={cost_center}
                  readOnly={true}
                />
              </div>
            </Col>

            {created_at && (
              <Col xs={12} md={6}>
                <div className="form-group">
                  <label>Created At</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    defaultValue={moment(created_at).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}
                    readOnly={true}
                  />
                </div>
              </Col>
            )}
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="Document Number">Document Number *</label>

                <input
                  type="text"
                  className="form-control form-control-md"
                  name="document_number"
                  placeholder="Document Number"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={document_number}
                  readOnly={true}
                />
              </div>
            </Col>
          </Row>
          <Col xs={12} md={12}>
            <DynamicTablePrExpense
              data={details_expense}
              columns={columns_expense}
              materials={select_items_material}
              uom={select_items_uom}
              gl_account={select_items_glaccount}
              currency={select_items_currency}
              props={this.props}
              readOnly={true}
              onChange={(data) => {
                this.setState({
                  details: [...data],
                  details_expense: [...data],
                });
              }}
            />
          </Col>
          <Row></Row>
        </div>
      );
    }
    if (pr_type == "service") {
      return (
        <div>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="document_type">Document Type *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="document_type"
                  defaultValue={document_type}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="requisitioner">Requisitioner *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="requisitioner"
                  defaultValue={requisitioner}
                  readOnly={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="plant">Plant *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="plant"
                  defaultValue={plant}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="cost_center">Cost Center *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="cost_center"
                  defaultValue={cost_center}
                  readOnly={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="account_assignment_cat">
                  Account Assignment Cat. *
                </label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="account_assignment_cat"
                  defaultValue={account_assignment_cat}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="item_cat">Item Cat. *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="item_cat"
                  defaultValue={item_cat}
                  readOnly={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="purchase_group">Purchase Group *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="purchase_group"
                  defaultValue={purchase_group}
                  readOnly={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            {created_at && (
              <Col xs={12} md={6}>
                <div className="form-group">
                  <label>Created At</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    defaultValue={moment(created_at).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}
                    readOnly={true}
                  />
                </div>
              </Col>
            )}
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="document_number">Document Number *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="document_number"
                  defaultValue={document_number}
                  readOnly={true}
                />
              </div>
            </Col>
          </Row>
          <Col xs={12} md={12}>
            <DynamicTable
              data={details_service}
              columns={columns_service}
              materials={select_items_material}
              uom={select_items_uom}
              gl_account={select_items_glaccount}
              currency={select_items_currency}
              service_number={select_items_service_number}
              props={this.props}
              readOnly={true}
              onChange={(data) => {
                this.setState({
                  details: [...data],
                  details_service: [...data],
                });
              }}
            />
          </Col>
        </div>
      );
    }
  };

  render() {
    let {
      showModalFlow,
      list_data,
      count_list_data,
      id,
      submitted,
      showModalPurchaseRequest,
      ready_for_approval,
      loading,
      fetchLoading,
    } = this.state;

    const { dispatch } = this.props;
    const loadGLAccount = (value) => {
      return new Promise((resolve, reject) => {
        const query = {
          search: value,
        };
        // using setTimeout to emulate a call to server
        dispatch(readGLAccount(query)).then(() => {
          resolve(this.props.read_glaccount.data);
        });
      });
    };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <ToastContainer />
          <Modal
            show={showModalFlow}
            size="xl"
            backdrop="static"
            onHide={this.onModalFlowHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Flow Purchase Request</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={2} md={2}>
                      Submitted
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      Input Nominal by PRC
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      *Approval <br />
                      <small>if OverBudget</small>
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      Done
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </form>
          </Modal>
          <Modal
            show={showModalPurchaseRequest}
            size="xl"
            backdrop="static"
            onHide={this.onModalPurchaseRequestHide}
            dialogClassName="my-modal"
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Detail Purchase Request</ModalTitle>
              </ModalHeader>
              <ModalBody>{this.renderDetail(loadGLAccount)}</ModalBody>
              <ModalFooter className="justify-content-between">
                <Button variant="danger" onClick={this.togglePurchaseRequest}>
                  <i className="fas fa-times" /> Close
                </Button>
                {ready_for_approval && (
                  <button
                    type="button"
                    className="btn btn-success btn-sm ml-2 mb-2 ts-buttom"
                    size="sm"
                    data-id={id}
                    onClick={() => this.approveData(id)}
                  >
                    <i className="fas fa-check-square"></i>
                    &nbsp;Submit
                  </button>
                )}
              </ModalFooter>
            </form>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">Input Nominal PR</h3>
                        <div className="card-tools float-right"></div>
                      </div>
                      <div className="card-body">
                        <TableCustom
                          data={list_data}
                          totalRows={count_list_data}
                          loading={fetchLoading}
                          action={this.GetActionFormat}
                          toggleStatus={this.toggleFlowPurchaseRequest}
                          fetchData={this.fetchInputNominal}
                          handleSearch={this.handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { read_purchase_request } = state.purchase_request;
  const { read_department } = state.department;
  const { read_uom } = state.uom;
  const { read_material } = state.material;
  const { read_supplier } = state.supplier;
  const { read_currency } = state.currency;
  const { read_glaccount } = state.glaccount;
  const { read_service_number } = state.service_number;
  const { read_recent_exchange_rate } = state.exchange_rate;

  const { message } = state.message;
  return {
    message,
    read_purchase_request,
    user,
    read_department,
    read_uom,
    read_material,
    read_supplier,
    read_currency,
    read_glaccount,
    read_service_number,
    read_recent_exchange_rate,
  };
}

export default connect(mapStateToProps)(InputNominalPR);
