import { 
  CREATE_CURRENCY_SUCCESS,
  CREATE_CURRENCY_FAIL,
  READ_CURRENCY_SUCCESS,
  READ_CURRENCY_FAIL,
  EDIT_CURRENCY_SUCCESS,
  EDIT_CURRENCY_FAIL,
  DELETE_CURRENCY_SUCCESS,
  DELETE_CURRENCY_FAIL, 
  READ_CURRENCY_WITHOUT_RP_SUCCESS,
  READ_CURRENCY_WITHOUT_RP_FAIL
} from "../actions/types";
 
const initialState = { 
  read_currency: null,
  read_currency_without_rp:null
};

export default function currency(state = initialState, action) {
  const { type, payload } = action;

  switch (type) { 

    case CREATE_CURRENCY_SUCCESS:
      return {
        ...state,
      };
    case CREATE_CURRENCY_FAIL:
      return {
        ...state,
      };

    case EDIT_CURRENCY_SUCCESS:
      return {
        ...state,
      };
    case EDIT_CURRENCY_FAIL:
      return {
        ...state,
      };
    case READ_CURRENCY_SUCCESS:  
      return {
        ...state,
        read_currency: payload,
      };
    case READ_CURRENCY_FAIL:
      return {
        ...state,
        read_currency: null,
      };
    case READ_CURRENCY_WITHOUT_RP_SUCCESS:  
      return {
        ...state,
        read_currency_without_rp: payload,
      };
    case READ_CURRENCY_WITHOUT_RP_FAIL:
      return {
        ...state,
        read_currency_without_rp: null,
      };
    case DELETE_CURRENCY_SUCCESS:
      return {
        ...state,
      };
    case DELETE_CURRENCY_FAIL:
      return {
        ...state,
      }; 
    default:
      return state;
  }
}
