import {
  CREATE_EXCHANGE_RATE_SUCCESS,
  CREATE_EXCHANGE_RATE_FAIL,
  SET_MESSAGE,
  READ_EXCHANGE_RATE_SUCCESS,
  READ_EXCHANGE_RATE_FAIL,
  EDIT_EXCHANGE_RATE_SUCCESS,
  EDIT_EXCHANGE_RATE_FAIL,
  DELETE_EXCHANGE_RATE_SUCCESS,
  DELETE_EXCHANGE_RATE_FAIL,
  READ_CURRENT_EXCHANGE_RATE_SUCCESS,
  READ_CURRENT_EXCHANGE_RATE_FAIL,
  CLEAR_CURRENT_EXCHANGE_RATE,
  READ_ACTIVE_EXCHANGE_RATE_SUCCESS,
  READ_ACTIVE_EXCHANGE_RATE_FAIL,
  READ_RECENT_EXCHANGE_RATE_SUCCESS,
  READ_RECENT_EXCHANGE_RATE_FAIL,
} from "./types";
import ExchangeRateService from "../services/exchange_rate.service";

export const addExchangeRate = (payload) => (dispatch) => {
  return ExchangeRateService.create(payload).then(
    (response) => {
      dispatch({
        type: CREATE_EXCHANGE_RATE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_EXCHANGE_RATE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editExchangeRate = (payload) => (dispatch) => {
  return ExchangeRateService.edit(payload).then(
    (response) => {
      dispatch({
        type: EDIT_EXCHANGE_RATE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_EXCHANGE_RATE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deleteExchangeRate = (payload) => (dispatch) => {
  return ExchangeRateService.delete(payload).then(
    (response) => {
      dispatch({
        type: DELETE_EXCHANGE_RATE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_EXCHANGE_RATE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readExchangeRate = (query) => (dispatch) => {
  return ExchangeRateService.readExchangeRate(query).then(
    (response) => {
      dispatch({
        type: READ_EXCHANGE_RATE_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_EXCHANGE_RATE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readCurrentExchangeRate = (currency, last) => (dispatch) => {
  return ExchangeRateService.readCurrentExchangeRate(currency, last).then(
    (response) => {
      dispatch({
        type: READ_CURRENT_EXCHANGE_RATE_SUCCESS,
        payload: response,
      });

      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_CURRENT_EXCHANGE_RATE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    }
  );
};

export const readActiveExchangeRate = () => (dispatch) => {
  return ExchangeRateService.readActiveExchangeRate().then(
    (response) => {
      dispatch({
        type: READ_ACTIVE_EXCHANGE_RATE_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_ACTIVE_EXCHANGE_RATE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readRecentExchangeRate = () => (dispatch) => {
  return ExchangeRateService.readRecentExchangeRate().then(
    (response) => {
      dispatch({
        type: READ_RECENT_EXCHANGE_RATE_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_RECENT_EXCHANGE_RATE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const clearCurrentExchangeRate = () => ({
  type: CLEAR_CURRENT_EXCHANGE_RATE,
});
