import {
  CREATE_PLANT_SUCCESS,
  CREATE_PLANT_FAIL,
  READ_PLANT_SUCCESS,
  READ_PLANT_FAIL,
  EDIT_PLANT_SUCCESS,
  EDIT_PLANT_FAIL,
  DELETE_PLANT_SUCCESS,
  DELETE_PLANT_FAIL,
} from "../actions/types";

const initialState = {
  read_plant: null,
};

export default function plant(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PLANT_SUCCESS:
      return {
        ...state,
      };
    case CREATE_PLANT_FAIL:
      return {
        ...state,
      };

    case EDIT_PLANT_SUCCESS:
      return {
        ...state,
      };
    case EDIT_PLANT_FAIL:
      return {
        ...state,
      };
    case READ_PLANT_SUCCESS:
      return {
        ...state,
        read_plant: payload,
      };
    case READ_PLANT_FAIL:
      return {
        ...state,
        read_plant: null,
      };
    case DELETE_PLANT_SUCCESS:
      return {
        ...state,
      };
    case DELETE_PLANT_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
