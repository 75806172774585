import React, { Component, useMemo, useState } from "react";
import DataTable from 'react-data-table-component';
import PropTypes from "prop-types";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { readBudget, upperApprovalBudget } from "../actions/budget";
import { readCurrency } from "../actions/currency";
import { clearMessage } from "../actions/message";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import OutTable from "../components/OutTable";
import { CSVLink } from "react-csv";

const TableCustom = ({
  data,
  totalRows,
  action,
  toggleStatus,
  fetchData,
  handleSearch,
  loading
}) => {
  const [perPage, setPerPage] = useState(10);
  const handleChange = (e) => {
    handleSearch({
      [e.target.name]: e.target.value
    });
  }

  const columns = useMemo(() => {
    return [
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input placeholder="Creator" onChange={handleChange} name="creator" type="search" className="form-control" autoComplete="off" />
            </div>
          </div>
        ),
        selector: (row) => (row.creator),
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input placeholder="Year" onChange={handleChange} name="year" type="search" className="form-control" autoComplete="off" />
            </div>
          </div>
          ),
        selector: (row) => (row.year),
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input placeholder="Department" onChange={handleChange} name="department" type="search" className="form-control" autoComplete="off" />
            </div>
          </div>
          ),
        selector: (row) => (row.department),
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input placeholder="Excel" onChange={handleChange} name="excel" type="search" className="form-control" autoComplete="off" />
            </div>
          </div>
        ),
        cell: (row) => (row.excel.filename),
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input placeholder="Currency" onChange={handleChange} name="currency" type="search" className="form-control" autoComplete="off" />
            </div>
          </div>
        ),
        cell: (row) => (row.currency),
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input placeholder="Status" onChange={handleChange} name="status" type="search" className="form-control" autoComplete="off" />
            </div>
          </div>
        ),
        cell: (row) => {
          return (
            <div>
              <button
                type="button"
                className="btn btn-sm ml-2 mb-2 ts-buttom"
                size="sm"
                onClick={toggleStatus}
              >
                <i className="fas fa-search-location"></i>
                &nbsp;
                {row.status === "Need Approval Finance Staff"
                  ? "Need Approval FAC Controller"
                  : row.status}
              </button>
            </div>
          );
        },
        sortable: true,
      },
      {
        name: "Action",
        maxWidth: 'max-content',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: action,
      },
    ]
  }, []);

  const handlePageChange = async (page) => {
    await fetchData({
      page, 
      sizePerPage: perPage,
    });
  }

  const handlePerPageChange = async (newPerPage, page) => {
    await fetchData({
      page, 
      sizePerPage: newPerPage 
    })

    setPerPage(newPerPage);
  }

  return (
    <DataTable
      persistTableHead={true}
      columns={columns}
      data={data}
      pagination
      paginationServer
      paginationPerPage={perPage}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      paginationTotalRows={totalRows}
      progressPending={loading}
    />
  );
}

TableCustom.propTypes = {
  data: PropTypes.instanceOf(Array),
  totalRows: PropTypes.number,
  action: PropTypes.func,
  toggleStatus: PropTypes.func,
  fetchData: PropTypes.func,
  handleSearch: PropTypes.func,
  loading: PropTypes.bool,
};

TableCustom.defaultProps = {
  data: [],
  totalRows: 0,
  loading: false,
};

class ApprovalBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      submitted: false,
      showModal: false,
      showModalBudget: false,
      showModalBudgetReject: false,
      showModalFlow: false,
      reason: null,
      readOnly: false,
      collection: [],
      select_items: [],
      list_data: [],
      count_list_data: 0,
      data: [],
      data_formatted: [],
      filename: null,
      cols: [],
      errors: {},
      id: null,
      selectedFile: null,
      creator: null,
      name_creator: null,
      email: null,
      department: null,
      excel: null,
      currency: null,
      exchange_rate_to_rp: "1",
      status: null,
      ready_for_approval: false,
      error_message_exchange_rate: null,
      defaultSorted: [
        {
          dataField: "creator",
          order: "asc",
        },
      ],
      cellEditProps: {},
      fetchLoading: false,
      search: {},
    };
    this.timer = null
  }

  fetchBudget = (additionalParams = {page: 1, sizePerPage: 10}) => {
    const { dispatch, user } = this.props;
    const query = {
      ...additionalParams,
      search: this.state.search,
      "approval": user.username,
    }

    this.setState({
      fetchLoading: true,
    });
    
    dispatch(readBudget(query)).then((response) => {
      this.setState({
        list_data: this.props.read_budget.data.foundData,
        count_list_data: this.props.read_budget.data.countData,
        fetchLoading: false,
      });
    });
  }

  handleSearch = (data) => {
    this.setState(prevState => ({search: {...prevState.search, ...data}}))

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fetchBudget()
    }, 250);
  }

  componentDidMount() {
    const { dispatch, user } = this.props;
    this.fetchBudget();

    dispatch(readCurrency()).then((response) => {
      this.setState({ select_items: this.props.read_currency.data });
    });

    this.setState({
      creator: user.username,
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      email: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.email
        : null,
      name_creator: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.person_name
        : null,
    });    
  }
  onModalBudgetHide = () => {
    this.setState({
      id: null,
      submitted: false,
      excel: null,
      data: null,
      cols: null,
      currency: null,
      exchange_rate_to_rp: "1",
      showModalBudget: !this.state.showModalBudget,
      readOnly: true,
      ready_for_approval: false,
      error_message_exchange_rate: null,
    });
  };
  onModalBudgetRejectHide = () => {
    this.setState({
      id: null,
      submitted: false,
      showModalBudgetReject: !this.state.showModalBudgetReject,
      readOnly: true,
      error_message_exchange_rate: null,
      reason: null,
    });
  };

  onModalLoadingHide = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };
  onModalFlowHide = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };

  toggleFlowBudget = (row) => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
      department: row.department,
    });
  };

  GetStatusFormat = (cell, row) => {
    return (
      <div>
        <button
          type="button"
          className="btn btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => this.toggleFlowBudget(row)}
        >
          <i className="fas fa-search-location"></i>
          &nbsp;
          {row.status == "Need Approval Finance Staff"
            ? "Need Approval FAC Controller"
            : row.status}
        </button>
      </div>
    );
  };
  GetStatusFormat = (cell, row) => {
    return (
      <div>
        <button
          type="button"
          className="btn btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => this.toggleFlowBudget(row)}
        >
          <i className="fas fa-search-location"></i>
          &nbsp;
          {row.status == "Need Approval Finance Staff"
            ? "Need Approval FAC Controller"
            : row.status}
        </button>
      </div>
    );
  };
  toggleBudget = () => {
    this.setState({
      showModalBudget: !this.state.showModalBudget,
    });
  };

  toDetailBudget = (
    id,
    excel,
    currency,
    exchange_rate_to_rp,
    ready_for_approval,
    status
  ) => {
    let arrayHeader = [];
    excel.cols.map((item) => {
      arrayHeader.push(item["name"]);
    });
    let dataFormatted = [arrayHeader, ...excel.data];
    this.setState({
      data_formatted: dataFormatted,
      filename: excel.filename.split(".")[0],
    });

    return this.setState(
      {
        id: id,
        excel: excel,
        data: excel.data,
        cols: excel.cols,
        currency: currency,
        exchange_rate_to_rp: exchange_rate_to_rp,
        readOnly: true,
        ready_for_approval: ready_for_approval,
        status: status,
      },
      () => {
        this.toggleBudget();
      }
    );
  };
  GetActionFormat = (row) => {
    return (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetailBudget(
              row._id,
              row.excel,
              row.currency,
              row.exchange_rate_to_rp,
              true,
              row.status
            )
          }
        >
          <i className="fas fa-th"></i>
          &nbsp;Detail
        </button>
      </div>
    );
  };
  isEmptyObject = (obj) => {
    return obj == null || !Object.keys(obj).length;
  };
  approveData = (id) => {
    const { dispatch, history } = this.props;
    const { creator, status, email, name_creator } = this.state;
    let collection = {
      _id: id,
      creator: creator,
      email: email,
      name_creator: name_creator,
    };

    if (collection) {
      Swal.fire({
        title: "Are you sure to approve this?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, approve",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(upperApprovalBudget(collection, status, "approved"))
            .then((response) => {
              toast.success("Data has been approved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-budget-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been approved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/approval/budget");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-budget-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  showinputReason = (id) => {
    this.setState({
      showModalBudgetReject: !this.state.showModalBudgetReject,
    });
  };
  rejectData = (id) => {
    this.setState({ submitted: true });
    const { dispatch, history } = this.props;
    let { creator, status, reason, email, name_creator } = this.state;
    let collection = {
      _id: id,
      creator: creator,
      email: email,
      name_creator: name_creator,
    };
    if (!this.validateRejectForm(reason)) {
      return;
    }

    if (reason && creator) {
      Swal.fire({
        title: "Are you sure to reject this?",
        text: "Please check your entries",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reject it",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(upperApprovalBudget(collection, status, "rejected", reason))
            .then((response) => {
              toast.success("Data has been rejected successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-budget-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been rejected successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/approval/budget");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-budget-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  validateRejectForm = (reason) => {
    let errors = this.state.errors;
    if (!reason) {
      errors.keperluan_budget = "This field is required";
    }
    return errors;
  };
  validateForm = (currency) => {
    let errors = this.state.errors;
    if (!currency) {
      errors.currency = "This field is required";
    }

    return errors;
  };
  render() {
    let {
      columns,
      defaultSorted,
      cellEditProps,
      select_items,
      data,
      cols,
      list_data,
      count_list_data,
      id,
      currency,
      exchange_rate_to_rp,
      readOnly,
      showModalBudget,
      ready_for_approval,
      showModalBudgetReject,
      submitted,
      reason,
      errors,
      showModalFlow,
      loading,
      fetchLoading,
    } = this.state;
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <ToastContainer />
          <Modal
            show={showModalFlow}
            size="xl"
            backdrop="static"
            onHide={this.onModalFlowHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Flow Budget</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={2} md={2}>
                      Creator Submitted
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      Approval Manager {this.state.department}
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      Approval FAC Controller
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      Approval FAC Manager
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </form>
          </Modal>
          <Modal
            show={showModalBudget}
            size="xl"
            backdrop="static"
            onHide={this.onModalBudgetHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Detail Budget</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={4}>
                      <div
                        className={
                          errors.currency
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="currency">Currency *</label>
                        <div className="input-group mb-3">
                          <div className="col-md-3">
                            <select
                              className="form-control form-control-sm "
                              name="currency"
                              onChange={this.onChangeValue}
                              style={{ padding: 0 }}
                              defaultValue={currency}
                              required
                              readOnly={readOnly}
                            >
                              <option value="">Choose</option>
                              {select_items &&
                                select_items.map(function (item, i) {
                                  return (
                                    <option value={item.value} key={i}>
                                      {item.value}
                                    </option>
                                  );
                                })}
                            </select>

                            {errors.currency && (
                              <div className="invalid-feedback">
                                {errors.currency}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={8}>
                      <div className="form-group">
                        <div className="input-group input-group-sm mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text input-group-text"
                              id="exchange_rate_to_rp"
                            >
                              Exchange Rate to Rp.
                            </span>
                          </div>
                          <input
                            type="number"
                            className="form-control form-control-sm"
                            name="exchange_rate_to_rp"
                            min="0"
                            step="0.01"
                            required="required"
                            onChange={this.onChangeValue}
                            defaultValue={exchange_rate_to_rp}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <OutTable data={data} cols={cols} />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter className="justify-content-between">
                <Button
                  variant="danger"
                  onClick={() => this.showinputReason(id)}
                  className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
                >
                  <i className="fas fa-times" /> Reject
                </Button>
                <CSVLink
                  data={this.state.data_formatted}
                  filename={this.state.filename + ".csv"}
                  className="btn btn-info"
                >
                  <i className="fas fa-file-download" /> Download
                </CSVLink>
                {ready_for_approval && (
                  <button
                    type="button"
                    className="btn btn-success btn-sm ml-2 mb-2 ts-buttom"
                    size="sm"
                    data-id={id}
                    onClick={() => this.approveData(id)}
                  >
                    <i className="fas fa-check-square"></i>
                    &nbsp;Approve
                  </button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Modal
            show={showModalBudgetReject}
            size="md"
            backdrop="static"
            onHide={this.onModalBudgetRejectHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              autoComplete="off"
            >
              <ModalHeader closeButton className="bg-warning text-white">
                <ModalTitle>Input Reason</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="form-group">
                        <label htmlFor="reason">Reason *</label>
                        <textarea
                          id="reason"
                          className="form-control form-control-sm"
                          name="reason"
                          placeholder="Reason"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={reason}
                          rows="5"
                        />

                        {errors.reason && (
                          <div className="invalid-feedback">
                            {errors.reason}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="danger"
                  onClick={() => this.rejectData(id)}
                  className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
                >
                  <i className="fas fa-times" /> Reject
                </Button>
              </ModalFooter>
            </form>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">Approval Budget</h3>
                      </div>
                      <div className="card-body">
                        <TableCustom 
                          data={list_data}
                          totalRows={count_list_data}
                          loading={fetchLoading}
                          action={this.GetActionFormat}
                          toggleStatus={this.toggleFlowBudget}
                          fetchData={this.fetchBudget}
                          handleSearch={this.handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { read_budget } = state.budget;
  const { read_currency } = state.currency;
  const { read_current_exchange_rate } = state.exchange_rate;

  const { message } = state.message;
  return {
    message,
    read_budget,
    read_currency,
    read_current_exchange_rate,
    user,
  };
}

export default connect(mapStateToProps)(ApprovalBudget);
