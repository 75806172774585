import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class SUPPLIERService {

  create(payload) {
    return axios
      .post(API_URL_DEFAULT + "supplier", payload, { headers: authHeader() });
  }
  readSUPPLIER(query) {
    return axios
      .get(API_URL_DEFAULT + "supplier/", { headers: authHeader(), params: query });
  }
  edit(payload) {
    return axios
      .put(API_URL_DEFAULT + "supplier", payload, { headers: authHeader() });
  }

  delete(payload) {
    return axios
      .delete(API_URL_DEFAULT + "supplier", { data: payload, headers: authHeader() });
  }

}

export default new SUPPLIERService();