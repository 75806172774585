import {  
  READ_DEPARTMENT_SUCCESS, 
  READ_DEPARTMENT_FAIL,
} from "../actions/types";
 
const initialState = { 
  read_department: null
};

export default function department(state = initialState, action) {
  const { type, payload } = action;

  switch (type) { 
 
    case READ_DEPARTMENT_SUCCESS:  
      return {
        ...state,
        read_department: payload,
      };
    case READ_DEPARTMENT_FAIL:
      return {
        ...state,
        read_department: null,
      }; 
    default:
      return state;
  }
}
