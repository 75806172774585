import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class BudgetService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "budget", payload, {
      headers: authHeader(),
    });
  }
  readBudget(query) {
    return axios.get(API_URL_DEFAULT + "budget/", {
      headers: authHeader(),
      params: query
    });
  }
  readBudgetByGlAccount(query) {
    return axios.get(
      API_URL_DEFAULT + "budget/remaining/byQuartalandAccount?" + query,
      {
        headers: authHeader(),
      }
    );
  }
  readRemainingBudgetAllMonth(query_string) {
    return axios.get(
      API_URL_DEFAULT + "budget/get/remaining-budget/?" + query_string,
      {
        headers: authHeader(),
      }
    );
  }
  edit(payload) {
    return axios.put(API_URL_DEFAULT + "budget", payload, {
      headers: authHeader(),
    });
  }

  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "budget", {
      data: payload,
      headers: authHeader(),
    });
  }
  upperApproval(payload, status, approval, reason) {
    if (status === "need_upper_approval") {
      return axios.put(API_URL_DEFAULT + "budget/?status=" + status, payload, {
        headers: authHeader(),
      });
    }
    return axios.put(
      API_URL_DEFAULT +
      "budget/?status=" +
      status +
      "&approval=" +
      approval +
      "&reason=" +
      reason,
      payload,
      { headers: authHeader() }
    );
  }
}

export default new BudgetService();
