import {
  CREATE_BUDGET_SUCCESS,
  CREATE_BUDGET_FAIL,
  SET_MESSAGE,
  READ_BUDGET_SUCCESS,
  READ_BUDGET_FAIL,
  READ_BUDGET_GL_ACCOUT_SUCCESS,
  READ_BUDGET_GL_ACCOUT_FAIL,
  EDIT_BUDGET_SUCCESS,
  EDIT_BUDGET_FAIL,
  DELETE_BUDGET_SUCCESS,
  DELETE_BUDGET_FAIL,
  READY_FOR_UPPER_APPROVAL_BUDGET_SUCCESS,
  READY_FOR_UPPER_APPROVAL_BUDGET_FAIL,
  READ_REMAINING_BUDGET_ALL_MONTH_SUCCESS,
  READ_REMAINING_BUDGET_ALL_MONTH_FAIL,
} from "./types";
import BudgetService from "../services/budget.service";

export const addBudget = (payload) => (dispatch) => {
  return BudgetService.create(payload).then(
    (response) => {
      dispatch({
        type: CREATE_BUDGET_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_BUDGET_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editBudget = (payload) => (dispatch) => {
  return BudgetService.edit(payload).then(
    (response) => {
      dispatch({
        type: EDIT_BUDGET_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_BUDGET_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deleteBudget = (payload) => (dispatch) => {
  return BudgetService.delete(payload).then(
    (response) => {
      dispatch({
        type: DELETE_BUDGET_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_BUDGET_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readBudget = (query) => (dispatch) => {
  return BudgetService.readBudget(query).then(
    (data) => {
      dispatch({
        type: READ_BUDGET_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_BUDGET_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readBudgetByGlAccount = (query) => (dispatch) => {
  return BudgetService.readBudgetByGlAccount(query).then(
    (data) => {
      dispatch({
        type: READ_BUDGET_GL_ACCOUT_SUCCESS,
        payload: data,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({ type: READ_BUDGET_GL_ACCOUT_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readRemainingBudgetAllMonth = (queryString) => (dispatch) => {
  return BudgetService.readRemainingBudgetAllMonth(queryString).then(
    (data) => {
      dispatch({
        type: READ_REMAINING_BUDGET_ALL_MONTH_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_REMAINING_BUDGET_ALL_MONTH_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const upperApprovalBudget =
  (payload, status, approval, reason) => (dispatch) => {
    return BudgetService.upperApproval(payload, status, approval, reason).then(
      (response) => {
        dispatch({
          type: READY_FOR_UPPER_APPROVAL_BUDGET_SUCCESS,
          payload: response.data,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: READY_FOR_UPPER_APPROVAL_BUDGET_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };
