import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class GLAccountService {

  create(payload) {
    return axios
      .post(API_URL_DEFAULT + "glaccount", payload, { headers: authHeader() });
  }
  readGLAccount(query) {
    return axios
      .get(API_URL_DEFAULT + "glaccount/", { headers: authHeader(), params: query });
  }
  readGLAccountByCostCenter(cost_center) {
    return axios
      .get(API_URL_DEFAULT + "glaccount/read-by-cost-center/?cost_center=" + cost_center, { headers: authHeader() });
  }
  edit(payload) {
    return axios
      .put(API_URL_DEFAULT + "glaccount", payload, { headers: authHeader() });
  }

  delete(payload) {
    return axios
      .delete(API_URL_DEFAULT + "glaccount", { data: payload, headers: authHeader() });
  }

}

export default new GLAccountService();