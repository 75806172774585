import React from "react";
import Table from "react-bootstrap/Table";
import addSeparatorsNF from "../helpers/thousanddelimiter";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";
import MATERIALService from "../services/material.service";
import BudgetRemaining from "./BudgetRemaining";
import CurrencyInput from "react-currency-input-field";

const DynamicTable = ({
  data, //data that is inputted by user in each row
  columns, //column from the parent, each type of PR has its own column
  onChange, //to return data to parrent
  onChangeRemaining,
  materials, // list materials to be picked by user
  uom, //list uom to be picked user
  currency,
  gl_account, //list gl account to be picked by user
  service_number,
  readOnly,
  loadMaterial,
  loadServiceNumber,
  loadGLAccount,
  isTransfer = false,
  active_exchange_rate = null,
}) => {
  let refUnit = null;
  let refCurrency = null;
  const readMaterialUomByCode = (code) => {
    return MATERIALService.readMATERIALbyUOM(code).then(
      (response) => {
        return Promise.resolve(response.data, response.data.message);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        return Promise.reject(message);
      }
    );
  };
  //state to do some validation for the Select type
  const headerStyle = { backgroundColor: "#17A2B8", color: "white" };

  const handleTableRowAdd = () => {
    // make new object based on the data sent and empty it
    let data_template = { ...data[0] };
    Object.keys(data_template).forEach((key) => (data_template[key] = ""));

    data.push(data_template);
    onChange(data);
  };

  const handleTableRowRemove = () => {
    if (data.length > 1) {
      data.pop();
      onChange(data);
    }
  };

  const handleDateChange = (val, idx) => {
    data[idx] = {
      ...data[idx],
      delivery_date: dateFormat(val, "yyyy-mm-dd"),
      remaining: "",
    };
    onChange(data);
    onChangeRemaining(data);
  };

  const handleSelectChange = (val, type, idx) => {
    if (type === "material") {
      if (val.label !== "") {
        readMaterialUomByCode(val.value).then((response) => {
          if (response) {
            refUnit.value = response[0].value;
            data[idx] = {
              ...data[idx],
              material_name: val.label,
              material_code: val.value,
              uom: response[0].value,
            };
            onChange(data);
          }
        });
      } else {
        data[idx] = {
          ...data[idx],
          material_name: "",
          material_code: "",
          uom: "",
        };

        onChange(data);
      }
    }
    if (type === "service_number") {
      data[idx] = {
        ...data[idx],
        service_number: val.value,
        service_number_label: val.label,
      };
      onChange(data);
      onChangeRemaining(data);
    }
    if (type === "glaccount") {
      if (val.label !== "") {
        data[idx] = {
          ...data[idx],
          gl_account_name: val.label,
          gl_account_code: val.value,
          remaining: "",
        };

        onChange(data);
        onChangeRemaining(data);
      }
    }
    if (type === "uom") {
      data[idx] = {
        ...data[idx],
        uom: val,
      };

      onChange(data);
    }
    if (type === "currency") {
      data[idx] = {
        ...data[idx],
        currency: val,
      };

      onChange(data);
    }
  };

  const handleTextChange = (val, type, idx) => {
    if (type === "short_text") {
      data[idx] = {
        ...data[idx],
        short_text: val,
      };
    }
    if (type === "item_text") {
      data[idx] = {
        ...data[idx],
        item_text: val,
      };
    }
    if (type === "po_text") {
      data[idx] = {
        ...data[idx],
        po_text: val,
      };
    }
    if (type === "qty") {
      data[idx] = {
        ...data[idx],
        qty: val,
      };
    }
    if (type === "nominal") {
      data[idx] = {
        ...data[idx],
        nominal: val,
      };
    }
    onChange(data);
  };

  return (
    <div>
      <Table
        bordered
        hover
        size="md"
        style={{ textAlign: "center", justifyContent: "center" }}
      >
        <thead>
          <tr>
            <th style={Object.assign(headerStyle, { width: "2%" })} key="abc1">
              {" "}
              No.{" "}
            </th>
            {columns.map((item, kl) => (
              <th
                style={
                  item.width
                    ? {
                        backgroundColor: "#17A2B8",
                        color: "white",
                        width: item.width,
                      }
                    : {
                        backgroundColor: "#17A2B8",
                        color: "white",
                      }
                }
                key={"th-" + kl}
              >
                {item.text}{" "}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((itemData, idxData) => (
              <tr key={"tr-" + idxData}>
                <td
                  style={{ fontSize: "0.64rem", width: "40px" }}
                  key={idxData}
                  className="text-center"
                >
                  {" "}
                  {idxData + 1}{" "}
                </td>
                {columns.map((item, i) => {
                  if (item.type === "text") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-2 text-center"
                        }
                        key={i}
                      >
                        {readOnly ? (
                          <span>{itemData.short_text}</span>
                        ) : (
                          <input
                            type="text"
                            className="form-control form-control-md"
                            placeholder={item.text}
                            required="required"
                            defaultValue={itemData.short_text}
                            onChange={(val) =>
                              handleTextChange(
                                val.target.value,
                                "short_text",
                                idxData
                              )
                            }
                            readOnly={readOnly}
                          />
                        )}
                      </td>
                    );
                  }

                  if (item.type === "item_text") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-2 text-center"
                        }
                        key={i}
                      >
                        {readOnly ? (
                          <span>{itemData.item_text}</span>
                        ) : (
                          <input
                            type="text"
                            className="form-control form-control-md"
                            placeholder={item.text}
                            required="required"
                            defaultValue={itemData.item_text}
                            onChange={(val) =>
                              handleTextChange(
                                val.target.value,
                                "item_text",
                                idxData
                              )
                            }
                            readOnly={readOnly}
                          />
                        )}
                      </td>
                    );
                  }

                  if (item.type === "po_text") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-2 text-center"
                        }
                        key={i}
                      >
                        {readOnly ? (
                          <span>{itemData.po_text}</span>
                        ) : (
                          <input
                            type="text"
                            className="form-control form-control-md"
                            placeholder={item.text}
                            required="required"
                            defaultValue={itemData.po_text}
                            onChange={(val) =>
                              handleTextChange(
                                val.target.value,
                                "po_text",
                                idxData
                              )
                            }
                            readOnly={readOnly}
                          />
                        )}
                      </td>
                    );
                  }

                  if (item.type === "service_number") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-2 text-center"
                        }
                        key={i}
                      >
                        {readOnly && !isTransfer ? (
                          <span>{itemData?.service_number_label}</span>
                        ) : (
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            onChange={(val) =>
                              handleSelectChange(val, "service_number", idxData)
                            }
                            loadOptions={loadServiceNumber}
                            isDisabled={readOnly && !isTransfer}
                            defaultValue={{
                              label: itemData?.service_number,
                              value: itemData.service_number_label,
                            }}
                          />
                        )}
                      </td>
                    );
                  }
                  if (item.type === "prev_service_number") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-2 text-center"
                        }
                        key={i}
                      >
                        <span>{itemData?.prev_service_number_label}</span>
                      </td>
                    );
                  }
                  if (item.type === "numeric") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-1 text-center"
                        }
                        style={{ maxWidth: "5%" }}
                        key={i}
                      >
                        {readOnly ? (
                          <span>
                            {itemData?.qty
                              ? addSeparatorsNF(itemData?.qty, ".", ".", ",")
                              : ""}
                          </span>
                        ) : (
                          <input
                            type="number"
                            min="0"
                            step="0.1"
                            className="form-control form-control-md"
                            placeholder={item.text}
                            required="required"
                            onChange={(val) =>
                              handleTextChange(val.target.value, "qty", idxData)
                            }
                            defaultValue={itemData.qty}
                            readOnly={readOnly}
                          />
                        )}
                      </td>
                    );
                  }
                  if (item.type === "material") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-2 text-center"
                        }
                        key={i}
                      >
                        {readOnly ? (
                          <span>{itemData?.material_name}</span>
                        ) : (
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            onChange={(val) =>
                              handleSelectChange(val, "material", idxData)
                            }
                            loadOptions={loadMaterial}
                            isDisabled={readOnly}
                            defaultValue={{
                              label: itemData?.material_name,
                              value: itemData.material_code,
                            }}
                          />
                        )}
                      </td>
                    );
                  }
                  if (item.type === "glaccount") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-3 text-center"
                        }
                        key={i}
                      >
                        {" "}
                        {readOnly && !isTransfer ? (
                          <span>{itemData.gl_account_name}</span>
                        ) : (
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            onChange={(val) =>
                              handleSelectChange(val, "glaccount", idxData)
                            }
                            loadOptions={loadGLAccount}
                            defaultValue={{
                              label: itemData?.gl_account_name,
                              value: itemData.gl_account_code,
                            }}
                            isDisabled={readOnly && !isTransfer}
                          />
                        )}
                      </td>
                    );
                  }
                  if (item.type === "prev_glaccount") {
                    return (
                      <td className={"text-center"} key={i}>
                        {<span>{itemData.prev_glaccount_name}</span>}
                      </td>
                    );
                  }
                  if (item.type === "uom") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-1 text-center"
                        }
                        style={{ maxWidth: "6%" }}
                        key={i}
                      >
                        {readOnly ? (
                          <span>{itemData.uom}</span>
                        ) : (
                          <select
                            className="form-control form-control-md"
                            name="uom"
                            onChange={(val) =>
                              handleSelectChange(
                                val.target.value,
                                "uom",
                                idxData
                              )
                            }
                            defaultValue={itemData.uom}
                            required="required"
                            readOnly={readOnly}
                            ref={(ref) => (refUnit = ref)}
                          >
                            <option value="">Choose</option>
                            {uom &&
                              uom.map(function (item, i) {
                                return (
                                  <option value={item.value} key={i}>
                                    {item.value}
                                  </option>
                                );
                              })}
                          </select>
                        )}
                      </td>
                    );
                  }
                  if (item.type === "date") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-1 text-center"
                        }
                        key={i}
                      >
                        {readOnly ? (
                          <span>{itemData.delivery_date}</span>
                        ) : (
                          <DatePicker
                            selected={
                              itemData.delivery_date === ""
                                ? null
                                : new Date(itemData.delivery_date)
                            }
                            disabledKeyboardNavigation
                            onChange={(val) => handleDateChange(val, idxData)}
                            name="date"
                            required="required"
                            placeholderText="Delivery Date"
                            dateFormat="dd MMMM yyyy"
                            className="form-control form-control-md"
                            readOnly={readOnly}
                            minDate={new Date()}
                          />
                        )}
                      </td>
                    );
                  }
                  if (item.type == "currency") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-1 text-center"
                        }
                        key={i}
                      >
                        {readOnly && itemData.currency & isTransfer ? (
                          <span>{itemData.currency}</span>
                        ) : (
                          <select
                            className="form-control form-control-md Input-number-hide-step"
                            name="currency"
                            onChange={(val) =>
                              handleSelectChange(
                                val.target.value,
                                "currency",
                                idxData
                              )
                            }
                            defaultValue={itemData.currency}
                            required="required"
                            ref={(ref) => (refCurrency = ref)}
                          >
                            <option value="">Choose</option>
                            {currency &&
                              currency.map(function (item, i) {
                                return (
                                  <option value={item.value} key={i}>
                                    {item.value}
                                  </option>
                                );
                              })}
                          </select>
                        )}
                      </td>
                    );
                  }

                  if (item.type == "over") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-1 text-center"
                        }
                        key={`td-currency-${idxData}`}
                      >
                        {itemData.currency +
                          " " +
                          addSeparatorsNF(
                            itemData.over >= 0 ? itemData.over : 0,
                            ".",
                            ".",
                            ","
                          )}
                      </td>
                    );
                  }

                  if (item.type == "remaining" && !itemData.over) {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-1 text-center"
                        }
                        key={i}
                      >
                        {readOnly ? (
                          <>
                            <span
                              style={
                                parseFloat(itemData.remaining) -
                                  parseFloat(itemData.nominal) <
                                0
                                  ? { border: "1px solid red" }
                                  : {}
                              }
                            >
                              {!itemData.remaining || itemData.remaining == 0
                                ? itemData.currency + " " + itemData.remaining
                                : itemData.currency +
                                  " " +
                                  addSeparatorsNF(
                                    itemData.remaining,
                                    ".",
                                    ".",
                                    ","
                                  )}
                            </span>
                            {parseFloat(itemData.remaining) -
                              parseFloat(itemData.nominal) <
                            0 ? (
                              <div
                                style={{
                                  width: "100%",
                                  marginTop: ".25rem",
                                  fontSize: "80%",
                                  color: " #dc3545",
                                }}
                              >
                                Please select other GL Account
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <BudgetRemaining itemData={itemData} key={i} />
                        )}
                      </td>
                    );
                  }
                  if (item.type == "remaining" && itemData?.over) {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-1 text-center"
                        }
                        key={i}
                      >
                        {readOnly ? (
                          <>
                            <span
                              style={
                                parseFloat(itemData.remaining) -
                                  parseFloat(itemData.over) <
                                0
                                  ? { border: "1px solid red" }
                                  : {}
                              }
                            >
                              {!itemData.remaining || itemData.remaining == 0
                                ? itemData.currency + " " + itemData.remaining
                                : itemData.currency +
                                  " " +
                                  addSeparatorsNF(
                                    itemData.remaining,
                                    ".",
                                    ".",
                                    ","
                                  )}
                            </span>
                            {parseFloat(itemData.remaining) -
                              parseFloat(itemData.over) <
                            0 ? (
                              <div
                                style={{
                                  width: "100%",
                                  marginTop: ".25rem",
                                  fontSize: "80%",
                                  color: " #dc3545",
                                }}
                              >
                                Please select other GL Account
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <BudgetRemaining itemData={itemData} key={i} />
                        )}
                      </td>
                    );
                  }

                  if (item.type == "prev_remaining") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-1 text-center"
                        }
                        key={i}
                      >
                        {
                          <span>
                            {itemData.prev_remaining == 0
                              ? itemData.currency +
                                " " +
                                itemData.prev_remaining
                              : itemData.currency +
                                " " +
                                addSeparatorsNF(
                                  itemData.prev_remaining,
                                  ".",
                                  ".",
                                  ","
                                )}
                          </span>
                        }
                      </td>
                    );
                  }
                  if (item.type == "exchange_rate") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-1 text-center"
                        }
                        key={i}
                      >
                        {
                          <>
                            {itemData?.exchange_rate &&
                            itemData?.exchange_rate?.error ? (
                              <small style={{ color: "red" }}>
                                {itemData?.exchange_rate?.data}
                              </small>
                            ) : (
                              <>
                                {itemData?.exchange_rate?.number == 0
                                  ? itemData?.exchange_rate?.number
                                  : addSeparatorsNF(
                                      itemData?.exchange_rate?.number,
                                      ".",
                                      ".",
                                      ","
                                    )}
                                <br />
                                <small style={{ fontSize: "0.63rem" }}>
                                  last updated:
                                  {dateFormat(
                                    itemData?.exchange_rate?.created_at,
                                    "yyyy-mm-dd HH:MM"
                                  )}
                                </small>
                              </>
                            )}
                          </>
                        }
                      </td>
                    );
                  }

                  if (item.type == "nominal") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-2 text-center"
                        }
                        key={i}
                      >
                        <CurrencyInput
                          name="nominal"
                          placeholder={item.nominal}
                          value={item.nominal}
                          defaultValue={item.nominal}
                          decimalsLimit={2}
                          onValueChange={(value, name) =>
                            handleTextChange(value, name, idxData)
                          }
                          className="form-control form-control-md"
                        />
                      </td>
                    );
                  }
                  if (item.type == "after_input_nominal") {
                    return (
                      <td
                        className={
                          readOnly ? "text-center" : "col-sm-2 text-center"
                        }
                        key={i}
                      >
                        {readOnly ? (
                          <span>
                            {itemData.currency +
                              " " +
                              addSeparatorsNF(itemData.nominal, ".", ".", ",")}
                          </span>
                        ) : (
                          <input
                            type="number"
                            min="0"
                            step="0.01"
                            className="form-control form-control-md"
                            placeholder={item.nominal}
                            required="required"
                            onChange={(val) =>
                              handleTextChange(
                                val.target.value,
                                "nominal",
                                idxData
                              )
                            }
                            defaultValue={itemData.nominal}
                            readOnly={true}
                          />
                        )}
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
        </tbody>
      </Table>

      {!readOnly && !isTransfer && (
        <div>
          <i
            className="fas fa-plus"
            style={{
              backgroundColor: "green",
              color: "white",
              padding: 5,
              fontWeight: "bold",
              borderRadius: 3,
            }}
            onClick={() => handleTableRowAdd()}
          />

          <i
            className="fas fa-minus"
            style={{
              backgroundColor: "red",
              color: "white",
              padding: 5,
              fontWeight: "bold",
              borderRadius: 3,
            }}
            onClick={() => handleTableRowRemove()}
          />
        </div>
      )}
    </div>
  );
};

export default DynamicTable;
