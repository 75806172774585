export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_SSO_SUCCESS = "LOGIN_SSO_SUCCESS";

export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SSO_FAIL = "LOGIN_SSO_FAIL";

export const MENU_SUCCESS = "MENU_SUCCESS";
export const MENU_FAIL = "MENU_FAIL";
export const LOGOUT = "LOGOUT";

export const GO_ONELNK = "GO_ONELNK";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";
export const CLEAR_ACTIVE_MENU = "CLEAR_ACTIVE_MENU";
export const SET_ACTIVE_DASHBOARD = "SET_ACTIVE_DASHBOARD";

export const CREATE_MENU_SUCCESS = "CREATE_MENU_SUCCESS";
export const CREATE_MENU_FAIL = "CREATE_MENU_FAIL";

export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_MENU_FAIL = "GET_MENU_FAIL";

export const READ_MENU_SUCCESS = "READ_MENU_SUCCESS";
export const READ_MENU_FAIL = "READ_MENU_FAIL";

export const EDIT_MENU_SUCCESS = "EDIT_MENU_SUCCESS";
export const EDIT_MENU_FAIL = "EDIT_MENU_FAIL";

export const DELETE_MENU_SUCCESS = "DELETE_MENU_SUCCESS";
export const DELETE_MENU_FAIL = "EDIT_MENU_FAIL";

export const CREATE_SUBMENU_SUCCESS = "CREATE_SUBMENU_SUCCESS";
export const CREATE_SUBMENU_FAIL = "CREATE_SUBMENU_FAIL";

export const EDIT_SUBMENU_SUCCESS = "EDIT_SUBMENU_SUCCESS";
export const EDIT_SUBMENU_FAIL = "EDIT_SUBMENU_FAIL";

export const DELETE_SUBMENU_SUCCESS = "DELETE_SUBMENU_SUCCESS";
export const DELETE_SUBMENU_FAIL = "DELETE_SUBMENU_FAIL";

export const READ_SUBMENU_SUCCESS = "READ_SUBMENU_SUCCESS";
export const READ_SUBMENU_FAIL = "READ_SUBMENU_FAIL";

//Advance
export const CREATE_ADVANCE_SUCCESS = "CREATE_ADVANCE_SUCCESS";
export const CREATE_ADVANCE_FAIL = "CREATE_ADVANCE_FAIL";

export const CLAIM_ADVANCE_SUCCESS = "CLAIM_ADVANCE_SUCCESS";
export const CLAIM_ADVANCE_FAIL = "CLAIM_ADVANCE_FAIL";

export const FILE_ADVANCE_SUCCESS = "FILE_ADVANCE_SUCCESS";
export const FILE_ADVANCE_FAIL = "FILE_ADVANCE_FAIL";

export const CONFIRM_ADVANCE_SUCCESS = "CONFIRM_ADVANCE_SUCCESS";
export const CONFIRM_ADVANCE_FAIL = "CONFIRM_ADVANCE_FAIL";

export const READ_ADVANCE_SUCCESS = "READ_ADVANCE_SUCCESS";
export const READ_ADVANCE_FAIL = "READ_ADVANCE_FAIL";

export const READ_CLAIM_ADVANCE_SUCCESS = "READ_CLAIM_ADVANCE_SUCCESS";
export const READ_CLAIM_ADVANCE_FAIL = "READ_CLAIM_ADVANCE_FAIL";

export const EDIT_ADVANCE_SUCCESS = "EDIT_ADVANCE_SUCCESS";
export const EDIT_ADVANCE_FAIL = "EDIT_ADVANCE_FAIL";

export const DELETE_ADVANCE_SUCCESS = "DELETE_ADVANCE_SUCCESS";
export const DELETE_ADVANCE_FAIL = "EDIT_ADVANCE_FAIL";

export const READY_FOR_UPPER_APPROVAL_ADVANCE_SUCCESS =
  "READY_FOR_UPPER_APPROVAL_ADVANCE_SUCCESS";
export const READY_FOR_UPPER_APPROVAL_ADVANCE_FAIL =
  "READY_FOR_UPPER_APPROVAL_ADVANCE_FAIL";

//Budget
export const CREATE_BUDGET_SUCCESS = "CREATE_BUDGET_SUCCESS";
export const CREATE_BUDGET_FAIL = "CREATE_BUDGET_FAIL";

export const READ_BUDGET_SUCCESS = "READ_BUDGET_SUCCESS";
export const READ_BUDGET_FAIL = "READ_BUDGET_FAIL";

export const READ_BUDGET_GL_ACCOUT_SUCCESS = "READ_BUDGET_GL_ACCOUT_SUCCESS";
export const READ_BUDGET_GL_ACCOUT_FAIL = "READ_BUDGET_GL_ACCOUT_FAIL";

export const EDIT_BUDGET_SUCCESS = "EDIT_BUDGET_SUCCESS";
export const EDIT_BUDGET_FAIL = "EDIT_BUDGET_FAIL";

export const DELETE_BUDGET_SUCCESS = "DELETE_BUDGET_SUCCESS";
export const DELETE_BUDGET_FAIL = "EDIT_BUDGET_FAIL";

export const READY_FOR_UPPER_APPROVAL_BUDGET_SUCCESS =
  "READY_FOR_UPPER_APPROVAL_BUDGET_SUCCESS";
export const READY_FOR_UPPER_APPROVAL_BUDGET_FAIL =
  "READY_FOR_UPPER_APPROVAL_BUDGET_FAIL";

//Curency
export const CREATE_CURRENCY_SUCCESS = "CREATE_CURRENCY_SUCCESS";
export const CREATE_CURRENCY_FAIL = "CREATE_CURRENCY_FAIL";

export const READ_CURRENCY_SUCCESS = "READ_CURRENCY_SUCCESS";
export const READ_CURRENCY_FAIL = "READ_CURRENCY_FAIL";

export const READ_CURRENCY_WITHOUT_RP_SUCCESS =
  "READ_CURRENCY_WITHOUT_RP_SUCCESS";
export const READ_CURRENCY_WITHOUT_RP_FAIL = "READ_CURRENCY_WITHOUT_RP_FAIL";

export const EDIT_CURRENCY_SUCCESS = "EDIT_CURRENCY_SUCCESS";
export const EDIT_CURRENCY_FAIL = "EDIT_CURRENCY_FAIL";

export const DELETE_CURRENCY_SUCCESS = "DELETE_CURRENCY_SUCCESS";
export const DELETE_CURRENCY_FAIL = "EDIT_CURRENCY_FAIL";

//GL Account
export const CREATE_GLACCOUNT_SUCCESS = "CREATE_GLACCOUNT_SUCCESS";
export const CREATE_GLACCOUNT_FAIL = "CREATE_GLACCOUNT_FAIL";

export const READ_GLACCOUNT_SUCCESS = "READ_GLACCOUNT_SUCCESS";
export const READ_GLACCOUNT_FAIL = "READ_GLACCOUNT_FAIL";

export const READ_GLACCOUNT_WITHOUT_RP_SUCCESS =
  "READ_GLACCOUNT_WITHOUT_RP_SUCCESS";
export const READ_GLACCOUNT_WITHOUT_RP_FAIL = "READ_GLACCOUNT_WITHOUT_RP_FAIL";

export const EDIT_GLACCOUNT_SUCCESS = "EDIT_GLACCOUNT_SUCCESS";
export const EDIT_GLACCOUNT_FAIL = "EDIT_GLACCOUNT_FAIL";

export const DELETE_GLACCOUNT_SUCCESS = "DELETE_GLACCOUNT_SUCCESS";
export const DELETE_GLACCOUNT_FAIL = "EDIT_GLACCOUNT_FAIL";

//ExchangeRate
export const CREATE_EXCHANGE_RATE_SUCCESS = "CREATE_EXCHANGE_RATE_SUCCESS";
export const CREATE_EXCHANGE_RATE_FAIL = "CREATE_EXCHANGE_RATE_FAIL";

export const READ_EXCHANGE_RATE_SUCCESS = "READ_EXCHANGE_RATE_SUCCESS";
export const READ_EXCHANGE_RATE_FAIL = "READ_EXCHANGE_RATE_FAIL";

export const READ_CURRENT_EXCHANGE_RATE_SUCCESS =
  "READ_CURRENT_EXCHANGE_RATE_SUCCESS";
export const READ_CURRENT_EXCHANGE_RATE_FAIL =
  "READ_CURRENT_EXCHANGE_RATE_FAIL";

export const CLEAR_CURRENT_EXCHANGE_RATE = "CLEAR_CURRENT_EXCHANGE_RATE";

export const EDIT_EXCHANGE_RATE_SUCCESS = "EDIT_EXCHANGE_RATE_SUCCESS";
export const EDIT_EXCHANGE_RATE_FAIL = "EDIT_EXCHANGE_RATE_FAIL";

export const DELETE_EXCHANGE_RATE_SUCCESS = "DELETE_EXCHANGE_RATE_SUCCESS";
export const DELETE_EXCHANGE_RATE_FAIL = "EDIT_EXCHANGE_RATE_FAIL";

export const READ_ACTIVE_EXCHANGE_RATE_SUCCESS =
  "READ_ACTIVE_EXCHANGE_RATE_SUCCESS";
export const READ_ACTIVE_EXCHANGE_RATE_FAIL = "READ_ACTIVE_EXCHANGE_RATE_FAIL";

export const READ_RECENT_EXCHANGE_RATE_SUCCESS =
  "READ_RECENT_EXCHANGE_RATE_SUCCESS";
export const READ_RECENT_EXCHANGE_RATE_FAIL = "READ_RECENT_EXCHANGE_RATE_FAIL";
//Department

export const READ_DEPARTMENT_SUCCESS = "READ_DEPARTMENT_SUCCESS";
export const READ_DEPARTMENT_FAIL = "READ_DEPARTMENT_FAIL";

//Purchase Request
export const CREATE_PURCHASE_REQUEST_SUCCESS =
  "CREATE_PURCHASE_REQUEST_SUCCESS";
export const CREATE_PURCHASE_REQUEST_FAIL = "CREATE_PURCHASE_REQUEST_FAIL";

export const READ_PURCHASE_REQUEST_SUCCESS = "READ_PURCHASE_REQUEST_SUCCESS";
export const READ_PURCHASE_REQUEST_FAIL = "READ_PURCHASE_REQUEST_FAIL";

export const EDIT_PURCHASE_REQUEST_SUCCESS = "EDIT_PURCHASE_REQUEST_SUCCESS";
export const EDIT_PURCHASE_REQUEST_FAIL = "EDIT_PURCHASE_REQUEST_FAIL";

export const DELETE_PURCHASE_REQUEST_SUCCESS =
  "DELETE_PURCHASE_REQUEST_SUCCESS";
export const DELETE_PURCHASE_REQUEST_FAIL = "EDIT_PURCHASE_REQUEST_FAIL";

export const READY_FOR_UPPER_APPROVAL_PURCHASE_REQUEST_SUCCESS =
  "READY_FOR_UPPER_APPROVAL_PURCHASE_REQUEST_SUCCESS";
export const READY_FOR_UPPER_APPROVAL_PURCHASE_REQUEST_FAIL =
  "READY_FOR_UPPER_APPROVAL_PURCHASE_REQUEST_FAIL";

//UOM
export const CREATE_UOM_SUCCESS = "CREATE_UOM_SUCCESS";
export const CREATE_UOM_FAIL = "CREATE_UOM_FAIL";

export const READ_UOM_SUCCESS = "READ_UOM_SUCCESS";
export const READ_UOM_FAIL = "READ_UOM_FAIL";

export const EDIT_UOM_SUCCESS = "EDIT_UOM_SUCCESS";
export const EDIT_UOM_FAIL = "EDIT_UOM_FAIL";

export const DELETE_UOM_SUCCESS = "DELETE_UOM_SUCCESS";
export const DELETE_UOM_FAIL = "EDIT_UOM_FAIL";
//MATERIAL
export const CREATE_MATERIAL_SUCCESS = "CREATE_MATERIAL_SUCCESS";
export const CREATE_MATERIAL_FAIL = "CREATE_MATERIAL_FAIL";

export const READ_MATERIAL_SUCCESS = "READ_MATERIAL_SUCCESS";
export const READ_MATERIAL_FAIL = "READ_MATERIAL_FAIL";

export const READ_MATERIAL_UOM_SUCCESS = "READ_MATERIAL_UOM_SUCCESS";
export const READ_MATERIAL_UOM_FAIL = "READ_MATERIAL_UOM_FAIL";

export const EDIT_MATERIAL_SUCCESS = "EDIT_MATERIAL_SUCCESS";
export const EDIT_MATERIAL_FAIL = "EDIT_MATERIAL_FAIL";

export const DELETE_MATERIAL_SUCCESS = "DELETE_MATERIAL_SUCCESS";
export const DELETE_MATERIAL_FAIL = "EDIT_MATERIAL_FAIL";
//SUPPLIER
export const CREATE_SUPPLIER_SUCCESS = "CREATE_SUPPLIER_SUCCESS";
export const CREATE_SUPPLIER_FAIL = "CREATE_SUPPLIER_FAIL";

export const READ_SUPPLIER_SUCCESS = "READ_SUPPLIER_SUCCESS";
export const READ_SUPPLIER_FAIL = "READ_SUPPLIER_FAIL";

export const EDIT_SUPPLIER_SUCCESS = "EDIT_SUPPLIER_SUCCESS";
export const EDIT_SUPPLIER_FAIL = "EDIT_SUPPLIER_FAIL";

export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS";
export const DELETE_SUPPLIER_FAIL = "EDIT_SUPPLIER_FAIL";

//COST CENTER
export const READ_COSTCENTER_SUCCESS = "READ_COSTCENTER_SUCCESS";
export const READ_COSTCENTER_FAIL = "READ_COSTCENTER_FAIL";

//READ ADVANCE HISTORY
export const READ_ADVANCE_HISTORY_SUCCESS = "READ_ADVANCE_HISTORY_SUCCESS";
export const READ_ADVANCE_HISTORY_FAIL = "READ_ADVANCE_HISTORY_FAIL";

//EDIT REALISASI
export const EDIT_REALISASI_SUCCESS = "EDIT_REALISASI_SUCCESS";
export const EDIT_REALISASI_FAIL = "EDIT_REALISASI_FAIL";

//APPROVAL SETTLEMENT ADVANCE
export const APPROVAL_SETTLEMENT_ADVANCE_SUCCESS =
  "APPROVAL_SETTLEMENT_ADVANCE_SUCCESS";
export const APPROVAL_SETTLEMENT_ADVANCE_FAIL =
  "APPROVAL_SETTLEMENT_ADVANCE_FAIL";

//DocumentType
export const CREATE_DOCUMENT_TYPE_SUCCESS = "CREATE_DOCUMENT_TYPE_SUCCESS";
export const CREATE_DOCUMENT_TYPE_FAIL = "CREATE_DOCUMENT_TYPE_FAIL";

export const READ_DOCUMENT_TYPE_SUCCESS = "READ_DOCUMENT_TYPE_SUCCESS";
export const READ_DOCUMENT_TYPE_FAIL = "READ_DOCUMENT_TYPE_FAIL";

export const EDIT_DOCUMENT_TYPE_SUCCESS = "EDIT_DOCUMENT_TYPE_SUCCESS";
export const EDIT_DOCUMENT_TYPE_FAIL = "EDIT_DOCUMENT_TYPE_FAIL";

export const DELETE_DOCUMENT_TYPE_SUCCESS = "DELETE_DOCUMENT_TYPE_SUCCESS";
export const DELETE_DOCUMENT_TYPE_FAIL = "DELETE_DOCUMENT_TYPE_FAIL";

//Requisitioner
export const CREATE_REQUISITIONER_SUCCESS = "CREATE_REQUISITIONER_SUCCESS";
export const CREATE_REQUISITIONER_FAIL = "CREATE_REQUISITIONER_FAIL";

export const READ_REQUISITIONER_SUCCESS = "READ_REQUISITIONER_SUCCESS";
export const READ_REQUISITIONER_FAIL = "READ_REQUISITIONER_FAIL";

export const EDIT_REQUISITIONER_SUCCESS = "EDIT_REQUISITIONER_SUCCESS";
export const EDIT_REQUISITIONER_FAIL = "EDIT_REQUISITIONER_FAIL";

export const DELETE_REQUISITIONER_SUCCESS = "DELETE_REQUISITIONER_SUCCESS";
export const DELETE_REQUISITIONER_FAIL = "DELETE_REQUISITIONER_FAIL";

//Plant
export const CREATE_PLANT_SUCCESS = "CREATE_PLANT_SUCCESS";
export const CREATE_PLANT_FAIL = "CREATE_PLANT_FAIL";

export const READ_PLANT_SUCCESS = "READ_PLANT_SUCCESS";
export const READ_PLANT_FAIL = "READ_PLANT_FAIL";

export const EDIT_PLANT_SUCCESS = "EDIT_PLANT_SUCCESS";
export const EDIT_PLANT_FAIL = "EDIT_PLANT_FAIL";

export const DELETE_PLANT_SUCCESS = "DELETE_PLANT_SUCCESS";
export const DELETE_PLANT_FAIL = "DELETE_PLANT_FAIL";
//Purch Group
export const CREATE_PURCH_GROUP_SUCCESS = "CREATE_PURCH_GROUP_SUCCESS";
export const CREATE_PURCH_GROUP_FAIL = "CREATE_PURCH_GROUP_FAIL";

export const READ_PURCH_GROUP_SUCCESS = "READ_PURCH_GROUP_SUCCESS";
export const READ_PURCH_GROUP_FAIL = "READ_PURCH_GROUP_FAIL";

export const EDIT_PURCH_GROUP_SUCCESS = "EDIT_PURCH_GROUP_SUCCESS";
export const EDIT_PURCH_GROUP_FAIL = "EDIT_PURCH_GROUP_FAIL";

export const DELETE_PURCH_GROUP_SUCCESS = "DELETE_PURCH_GROUP_SUCCESS";
export const DELETE_PURCH_GROUP_FAIL = "DELETE_PURCH_GROUP_FAIL";
//Account Assignment Cat
export const CREATE_ACCOUNT_ASSIGNMENT_CAT_SUCCESS =
  "CREATE_ACCOUNT_ASSIGNMENT_CAT_SUCCESS";
export const CREATE_ACCOUNT_ASSIGNMENT_CAT_FAIL =
  "CREATE_ACCOUNT_ASSIGNMENT_CAT_FAIL";

export const READ_ACCOUNT_ASSIGNMENT_CAT_SUCCESS =
  "READ_ACCOUNT_ASSIGNMENT_CAT_SUCCESS";
export const READ_ACCOUNT_ASSIGNMENT_CAT_FAIL =
  "READ_ACCOUNT_ASSIGNMENT_CAT_FAIL";

export const EDIT_ACCOUNT_ASSIGNMENT_CAT_SUCCESS =
  "EDIT_ACCOUNT_ASSIGNMENT_CAT_SUCCESS";
export const EDIT_ACCOUNT_ASSIGNMENT_CAT_FAIL =
  "EDIT_ACCOUNT_ASSIGNMENT_CAT_FAIL";

export const DELETE_ACCOUNT_ASSIGNMENT_CAT_SUCCESS =
  "DELETE_ACCOUNT_ASSIGNMENT_CAT_SUCCESS";
export const DELETE_ACCOUNT_ASSIGNMENT_CAT_FAIL =
  "DELETE_ACCOUNT_ASSIGNMENT_CAT_FAIL";

// Service Number
export const READ_SERVICE_NUMBER_SUCCESS = "READ_SERVICE_NUMBER_SUCCESS";
export const READ_SERVICE_NUMBER_FAIL = "READ_SERVICE_NUMBER_FAIL";
// Remaining Budget All Mont
export const READ_REMAINING_BUDGET_ALL_MONTH_SUCCESS =
  "READ_REMAINING_BUDGET_ALL_MONTH_SUCCESS";
export const READ_REMAINING_BUDGET_ALL_MONTH_FAIL =
  "READ_REMAINING_BUDGET_ALL_MONTH_FAIL";

export const TRANSFER_PURCHASE_REQUEST_SUCCESS =
  "TRANSFER_PURCHASE_REQUEST_SUCCESS";
export const TRANSFER_PURCHASE_REQUEST_FAIL = "TRANSFER_PURCHASE_REQUEST_FAIL";
//

export const NOMINAL_APPROVAL_PURCHASE_REQUEST_SUCCESS =
  "NOMINAL_APPROVAL_PURCHASE_REQUEST_SUCCESS";
export const NOMINAL_APPROVAL_PURCHASE_REQUEST_FAIL =
  "NOMINAL_APPROVAL_PURCHASE_REQUEST_FAIL";

export const CHECK_BUDGET_INPUT_NOMINAL_SUCCESS =
  "CHECK_BUDGET_INPUT_NOMINAL_SUCCESS";
export const CHECK_BUDGET_INPUT_NOMINAL_FAIL =
  "CHECK_BUDGET_INPUT_NOMINAL_FAIL";

export const UPDATE_BUDGET_OVERBUDGET_SUCCESS =
  "UPDATE_BUDGET_OVERBUDGET_SUCCESS";
export const UPDATE_BUDGET_OVERBUDGET_FAIL = "UPDATE_BUDGET_OVERBUDGET_FAIL";
