import {
  CREATE_ADVANCE_SUCCESS,
  CREATE_ADVANCE_FAIL,
  SET_MESSAGE,
  READ_ADVANCE_SUCCESS,
  READ_ADVANCE_FAIL,
  READ_ADVANCE_HISTORY_SUCCESS,
  READ_ADVANCE_HISTORY_FAIL,
  READ_CLAIM_ADVANCE_SUCCESS,
  READ_CLAIM_ADVANCE_FAIL,
  EDIT_ADVANCE_SUCCESS,
  EDIT_ADVANCE_FAIL,
  DELETE_ADVANCE_SUCCESS,
  DELETE_ADVANCE_FAIL,
  READY_FOR_UPPER_APPROVAL_ADVANCE_SUCCESS,
  READY_FOR_UPPER_APPROVAL_ADVANCE_FAIL,
  CLAIM_ADVANCE_SUCCESS,
  CLAIM_ADVANCE_FAIL,
  CONFIRM_ADVANCE_SUCCESS,
  CONFIRM_ADVANCE_FAIL,
  FILE_ADVANCE_SUCCESS,
  FILE_ADVANCE_FAIL,
  EDIT_REALISASI_SUCCESS,
  EDIT_REALISASI_FAIL,
  APPROVAL_SETTLEMENT_ADVANCE_SUCCESS,
  APPROVAL_SETTLEMENT_ADVANCE_FAIL,
} from "./types";
import AdvanceService from "../services/advance.service";

export const addAdvance = (payload) => (dispatch) => {
  return AdvanceService.create(payload).then(
    (response) => {
      dispatch({
        type: CREATE_ADVANCE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_ADVANCE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editAdvance = (payload) => (dispatch) => {
  return AdvanceService.edit(payload).then(
    (response) => {
      dispatch({
        type: EDIT_ADVANCE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_ADVANCE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const UpdateRealisai = (payload) => (dispatch) => {
  return AdvanceService.editRealisasi(payload).then(
    (response) => {
      dispatch({
        type: EDIT_REALISASI_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_REALISASI_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteAdvance = (payload) => (dispatch) => {
  return AdvanceService.delete(payload).then(
    (response) => {
      dispatch({
        type: DELETE_ADVANCE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_ADVANCE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readAdvance = (username, type) => (dispatch) => {
  return AdvanceService.readAdvance(username, type).then(
    (data) => {
      dispatch({
        type: READ_ADVANCE_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_ADVANCE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const upperApprovalAdvance =
  (payload, status, approval, reason) => (dispatch) => {
    return AdvanceService.upperApproval(payload, status, approval, reason).then(
      (response) => {
        dispatch({
          type: READY_FOR_UPPER_APPROVAL_ADVANCE_SUCCESS,
          payload: response.data,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: READY_FOR_UPPER_APPROVAL_ADVANCE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const approvalSettlement = (payload) => (dispatch) => {
  return AdvanceService.approvalSettlement(payload).then(
    (response) => {
      dispatch({
        type: APPROVAL_SETTLEMENT_ADVANCE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: APPROVAL_SETTLEMENT_ADVANCE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readClaimAdvance = (payload) => (dispatch) => {
  return AdvanceService.readClaimAdvance(payload).then(
    (response) => {
      dispatch({
        type: READ_CLAIM_ADVANCE_SUCCESS,
        payload: response,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_CLAIM_ADVANCE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const claimAdvance = (payload) => (dispatch) => {
  return AdvanceService.claimAdvance(payload).then(
    (response) => {
      dispatch({
        type: CLAIM_ADVANCE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CLAIM_ADVANCE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const confirmAdvance = (payload) => (dispatch) => {
  return AdvanceService.confirmAdvance(payload).then(
    (response) => {
      dispatch({
        type: CONFIRM_ADVANCE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CONFIRM_ADVANCE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const fileAdvance = (payload) => (dispatch) => {
  return AdvanceService.fileAdvance(payload).then(
    (response) => {
      const file = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileURL = URL.createObjectURL(file);
      dispatch({
        type: FILE_ADVANCE_SUCCESS,
        payload: fileURL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: FILE_ADVANCE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
