import React, { Component } from 'react'
import DefaultContent from '../components/templates/DefaultContent';
import Footer from '../components/templates/Footer'; 
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

export default class Product extends Component {
  render() {
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          
          <Header/>
          <SideMenu/> 
          
          <DefaultContent/>
          <Footer/>
        </div> 
      </div>
    )
  }
}
