import {
  SET_ACTIVE_MENU,
  CLEAR_ACTIVE_MENU,
  SET_ACTIVE_DASHBOARD,
  CREATE_MENU_SUCCESS,
  CREATE_MENU_FAIL,
  SET_MESSAGE,
  GET_MENU_SUCCESS,
  GET_MENU_FAIL,
  READ_MENU_SUCCESS,
  READ_MENU_FAIL,
  EDIT_MENU_SUCCESS,
  EDIT_MENU_FAIL,
  DELETE_MENU_SUCCESS,
  DELETE_MENU_FAIL,
  CREATE_SUBMENU_SUCCESS,
  CREATE_SUBMENU_FAIL,
  EDIT_SUBMENU_SUCCESS,
  EDIT_SUBMENU_FAIL,
  DELETE_SUBMENU_SUCCESS,
  DELETE_SUBMENU_FAIL,
  READ_SUBMENU_SUCCESS,
  READ_SUBMENU_FAIL,
} from "./types";
import MenuService from "../services/menu.service";

export const setActiveMenu = (menu) => (dispatch) => {
  localStorage.setItem("active_menu", JSON.stringify(menu));
  return {
    type: SET_ACTIVE_MENU,
    payload: menu,
  };
};

export const setActiveDashboard = () => ({
  type: SET_ACTIVE_DASHBOARD,
});

export const clearActiveMenu = () => ({
  type: CLEAR_ACTIVE_MENU,
});

export const addMenu = (payload) => (dispatch) => {
  return MenuService.create(payload).then(
    (response) => {
      dispatch({
        type: CREATE_MENU_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_MENU_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editMenu = (payload) => (dispatch) => {
  return MenuService.edit(payload).then(
    (response) => {
      dispatch({
        type: EDIT_MENU_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_MENU_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deleteMenu = (payload) => (dispatch) => {
  return MenuService.delete(payload).then(
    (response) => {
      dispatch({
        type: DELETE_MENU_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_MENU_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const getMenu = (role) => (dispatch) => {
  return MenuService.getMenu(role).then(
    (data) => {
      dispatch({
        type: GET_MENU_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_MENU_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readMenu = () => (dispatch) => {
  return MenuService.readMenu().then(
    (data) => {
      dispatch({
        type: READ_MENU_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_MENU_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const addSubmenu = (payload) => (dispatch) => {
  return MenuService.create_submenu(payload).then(
    (response) => {
      dispatch({
        type: CREATE_SUBMENU_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_SUBMENU_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editSubmenu = (payload) => (dispatch) => {
  return MenuService.edit_submenu(payload).then(
    (response) => {
      dispatch({
        type: EDIT_SUBMENU_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_SUBMENU_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deleteSubmenu = (payload) => (dispatch) => {
  return MenuService.delete_submenu(payload).then(
    (response) => {
      dispatch({
        type: DELETE_SUBMENU_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_SUBMENU_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readSubmenu = (payload) => (dispatch) => {
  return MenuService.readSubmenu(payload).then(
    (data) => {
      dispatch({
        type: READ_SUBMENU_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_SUBMENU_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
