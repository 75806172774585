import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import menu from "./menu";
import advance from "./advance";
import currency from "./currency";
import exchange_rate from "./exchange_rate";
import budget from "./budget";
import department from "./department";
import glaccount from "./glaccount";
import costcenter from "./costcenter";
import purchase_request from "./purchase_request";
import uom from "./uom";
import material from "./material";
import supplier from "./supplier";
import document_type from "./document_type";
import requisitioner from "./requisitioner";
import plant from "./plant";
import purch_group from "./purch_group";
import account_assignment_cat from "./account_assignment_cat";
import service_number from "./service_number";

export default combineReducers({
  auth,
  message,
  menu,
  advance,
  currency,
  exchange_rate,
  budget,
  department,
  glaccount,
  costcenter,
  purchase_request,
  uom,
  material,
  supplier,
  document_type,
  requisitioner,
  plant,
  purch_group,
  account_assignment_cat,
  service_number
});
