import {
  CREATE_MATERIAL_SUCCESS,
  CREATE_MATERIAL_FAIL,
  SET_MESSAGE,
  READ_MATERIAL_SUCCESS,
  READ_MATERIAL_FAIL,
  READ_MATERIAL_UOM_SUCCESS,
  READ_MATERIAL_UOM_FAIL,
  EDIT_MATERIAL_SUCCESS,
  EDIT_MATERIAL_FAIL,
  DELETE_MATERIAL_SUCCESS,
  DELETE_MATERIAL_FAIL,
} from "./types";
import MATERIALService from "../services/material.service";

export const addMATERIAL = (payload) => (dispatch) => {
  return MATERIALService.create(payload).then(
    (response) => {
      dispatch({
        type: CREATE_MATERIAL_SUCCESS,
        payload: response.data
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_MATERIAL_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editMATERIAL = (payload) => (dispatch) => {
  return MATERIALService.edit(payload).then(
    (response) => {
      dispatch({
        type: EDIT_MATERIAL_SUCCESS,
        payload: response.data
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_MATERIAL_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deleteMATERIAL = (payload) => (dispatch) => {
  return MATERIALService.delete(payload).then(
    (response) => {
      dispatch({
        type: DELETE_MATERIAL_SUCCESS,
        payload: response.data
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_MATERIAL_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readMATERIAL = (query) => (dispatch) => {
  return MATERIALService.readMATERIAL(query).then(
    (response) => {
      dispatch({
        type: READ_MATERIAL_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_MATERIAL_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readMATERIALbyUOM = (code) => (dispatch) => {
  return MATERIALService.readMATERIALbyUOM(code).then(
    (response) => {
      dispatch({
        type: READ_MATERIAL_UOM_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_MATERIAL_UOM_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
