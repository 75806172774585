import {
  SET_MESSAGE,
  CREATE_PURCHASE_REQUEST_SUCCESS,
  CREATE_PURCHASE_REQUEST_FAIL,
  READ_PURCHASE_REQUEST_SUCCESS,
  READ_PURCHASE_REQUEST_FAIL,
  EDIT_PURCHASE_REQUEST_SUCCESS,
  EDIT_PURCHASE_REQUEST_FAIL,
  DELETE_PURCHASE_REQUEST_SUCCESS,
  DELETE_PURCHASE_REQUEST_FAIL,
  READY_FOR_UPPER_APPROVAL_PURCHASE_REQUEST_SUCCESS,
  READY_FOR_UPPER_APPROVAL_PURCHASE_REQUEST_FAIL,
  TRANSFER_PURCHASE_REQUEST_SUCCESS,
  TRANSFER_PURCHASE_REQUEST_FAIL,
  NOMINAL_APPROVAL_PURCHASE_REQUEST_SUCCESS,
  NOMINAL_APPROVAL_PURCHASE_REQUEST_FAIL,
  CHECK_BUDGET_INPUT_NOMINAL_SUCCESS,
  CHECK_BUDGET_INPUT_NOMINAL_FAIL,
  UPDATE_BUDGET_OVERBUDGET_SUCCESS,
  UPDATE_BUDGET_OVERBUDGET_FAIL
} from "./types";
import PurchaseRequestService from "../services/purchase_request.service";

export const addPurchaseRequest = (payload) => (dispatch) => {
  return PurchaseRequestService.create(payload).then(
    (response) => {
      dispatch({
        type: CREATE_PURCHASE_REQUEST_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CREATE_PURCHASE_REQUEST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const editPurchaseRequest = (payload) => (dispatch) => {
  return PurchaseRequestService.edit(payload).then(
    (response) => {
      dispatch({
        type: EDIT_PURCHASE_REQUEST_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: EDIT_PURCHASE_REQUEST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const deletePurchaseRequest = (payload) => (dispatch) => {
  return PurchaseRequestService.delete(payload).then(
    (response) => {
      dispatch({
        type: DELETE_PURCHASE_REQUEST_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_PURCHASE_REQUEST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readInputNominalPurchaseRequest = (query) => (dispatch) => {
  return PurchaseRequestService.readInputNominal(query).then(
    (data) => {
      dispatch({
        type: READ_PURCHASE_REQUEST_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_PURCHASE_REQUEST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const readPurchaseRequest = (username, type, additionalParams) => (dispatch) => {
  return PurchaseRequestService.readPurchaseRequest(username, type, additionalParams).then(
    (data) => {
      dispatch({
        type: READ_PURCHASE_REQUEST_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_PURCHASE_REQUEST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const readAllPurchaseRequest = (username, pr_type) => (dispatch) => {
  return PurchaseRequestService.readAllPurchaseRequest(username, pr_type).then(
    (data) => {
      dispatch({
        type: READ_PURCHASE_REQUEST_SUCCESS,
        payload: data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_PURCHASE_REQUEST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const upperApprovalPurchaseRequest =
  (payload, status, approval, reason) => (dispatch) => {
    return PurchaseRequestService.upperApproval(
      payload,
      status,
      approval,
      reason
    ).then(
      (response) => {
        dispatch({
          type: READY_FOR_UPPER_APPROVAL_PURCHASE_REQUEST_SUCCESS,
          payload: response.data,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: READY_FOR_UPPER_APPROVAL_PURCHASE_REQUEST_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const transferPurchaseRequest = (payload) => (dispatch) => {
  return PurchaseRequestService.transfer(payload).then(
    (response) => {
      dispatch({
        type: TRANSFER_PURCHASE_REQUEST_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: TRANSFER_PURCHASE_REQUEST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const nominalApprovalPurchaseRequest =
  (payload, status, approval, reason) => (dispatch) => {
    return PurchaseRequestService.nominalApproval(
      payload,
      status,
      approval,
      reason
    ).then(
      (response) => {
        dispatch({
          type: NOMINAL_APPROVAL_PURCHASE_REQUEST_SUCCESS,
          payload: response.data,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: NOMINAL_APPROVAL_PURCHASE_REQUEST_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const checkBudgetInputNominal = (payload) => (dispatch) => {
  return PurchaseRequestService.checkBudget(payload).then(
    (response) => {
      dispatch({
        type: CHECK_BUDGET_INPUT_NOMINAL_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CHECK_BUDGET_INPUT_NOMINAL_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const updateOverbudget = (payload) => (dispatch) => {
  return PurchaseRequestService.updateOverbudget(payload).then(
    (response) => {
      dispatch({
        type: UPDATE_BUDGET_OVERBUDGET_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_BUDGET_OVERBUDGET_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};