import axios from "axios";
import authHeader from "./auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;

class ExchangeRateService {
  create(payload) {
    return axios.post(API_URL_DEFAULT + "exchange_rate", payload, {
      headers: authHeader(),
    });
  }
  readExchangeRate(query) {
    return axios.get(API_URL_DEFAULT + "exchange_rate/", {
      headers: authHeader(),
      params: query,
    });
  }
  readCurrentExchangeRate(currency, last) {
    return axios.get(
      API_URL_DEFAULT + "exchange_rate/?currency=" + currency + "&" + last,
      { headers: authHeader() }
    );
  }
  readActiveExchangeRate() {
    return axios.get(API_URL_DEFAULT + "exchange_rate/?active=active", {
      headers: authHeader(),
    });
  }
  readRecentExchangeRate() {
    return axios.get(API_URL_DEFAULT + "exchange_rate/?recent=recent", {
      headers: authHeader(),
    });
  }
  edit(payload) {
    return axios.put(API_URL_DEFAULT + "exchange_rate", payload, {
      headers: authHeader(),
    });
  }

  delete(payload) {
    return axios.delete(API_URL_DEFAULT + "exchange_rate", {
      data: payload,
      headers: authHeader(),
    });
  }
}

export default new ExchangeRateService();
