import axios from "axios";
import authHeader from "./auth-header"; 

const API_URL_DEFAULT = process.env.REACT_APP_API;

class MenuService {

  getMenu(role) {   
    return axios
      .get(API_URL_DEFAULT + "authorization/"+role,{ headers: authHeader()})
      .then((response) => { 
        localStorage.setItem("menu", JSON.stringify(response.data)); 
        return response.data;
      });
  }

  create(payload) {  
    return axios
      .post(API_URL_DEFAULT + "menu", payload,{ headers: authHeader()});
  }
  readMenu() {   
    return axios
      .get(API_URL_DEFAULT + "menu/",{ headers: authHeader()});
  }
  edit(payload) {  
    return axios
      .put(API_URL_DEFAULT + "menu", payload,{ headers: authHeader()});
  }

  delete(payload) {  
    return axios
      .delete(API_URL_DEFAULT + "menu", {data:payload, headers: authHeader()});
  }
 
  create_submenu(payload) {  
    return axios
      .post(API_URL_DEFAULT + "submenu", payload,{ headers: authHeader()});
  }
  readSubmenu(payload) {   
    let url =API_URL_DEFAULT + `submenu/${payload}`;
    return axios
      .get(url,{ headers: authHeader()});
  }
  edit_submenu(payload) {  
    return axios
      .put(API_URL_DEFAULT + "submenu", payload,{ headers: authHeader()});
  }

  delete_submenu(payload) {  
    return axios
      .delete(API_URL_DEFAULT + "submenu", {data:payload, headers: authHeader()});
  }
}

export default new MenuService();