import React, { Component } from "react";
import RemoteTable from "../components/RemoteTable";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from 'react-bootstrap/Dropdown'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import addSeparatorsNF from "../helpers/thousanddelimiter";
import Select from "react-select";
import { subDays } from "date-fns";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import {
  addPurchaseRequest,
  readPurchaseRequest,
  editPurchaseRequest,
  deletePurchaseRequest,
  upperApprovalPurchaseRequest,
} from "../actions/purchase_request";
import { readUOM } from "../actions/uom";
import { readMATERIAL } from "../actions/material";
import { readSUPPLIER } from "../actions/supplier";
import { readRequisitioner } from "../actions/requisitioner";
import { readGLAccount } from "../actions/glaccount";
import { clearMessage } from "../actions/message";
import { readCostCenter } from "../actions/costcenter";
import { readDocumentType } from "../actions/document_type";
import { readPlant } from "../actions/plant";
import { readPurchGroup } from "../actions/purch_group";
import { readAccountAssignmentCat } from "../actions/account_assignment_cat";

class PurchaseRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClearable: true,
      isValid: true,
      isValidGLAccount: true,
      isValidDate: true,
      isValidMaterial: true,
      isValidSupplier: true,
      isValidCostCenter: true,

      loading: false,
      extend: false,
      submitted: false,
      showModal: false,
      showModalPurchaseRequest: false,
      showModalPurchaseRequestReject: false,
      showModalFlow: false,
      readOnly: false,
      collection: [],
      select_items_cost_center: [],
      select_items_glaccount: [],
      select_items_source_determination: [],
      select_items_material: [],
      select_items_requisitioner: [],
      select_items_uom: [],
      select_items_document_type: [],
      select_items_plant: [],
      select_items_purch_group: [],
      select_items_account_assignment_cat: [],
      list_data: [],
      errors: {},
      id: null,
      reason: null,

      requester: null,
      email_requester: null,
      department: null,
      id_department: null,

      document_type: null,
      source_determination: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      remark: null,
      account_assignment_cat: null,

      g_l_account: null,
      cost_center: null,
      ready_for_approval: false,
      columns: [
        {
          dataField: "requester",
          text: "Requester",
          filter: textFilter(),
          formatter: this.GetRequesterFormat,
          sort: true,
        },
        {
          dataField: "document_type",
          text: "Document Type",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "source_determination",
          text: "Source Determination",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "g_l_account",
          text: "GL Account",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "cost_center",
          text: "Cost Center",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "material",
          text: "Material",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "quantity",
          text: "Quantity",
          filter: textFilter(),
          formatter: this.GetQTYFormat,
          sort: true,
        },
        {
          dataField: "delivery_date",
          text: "Delivery Date",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "plant",
          text: "Plant",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "purchase_group",
          text: "Purchase Group",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "requisitioner",
          text: "Requisitioner",
          filter: textFilter(),
          sort: true,
        },
        {
          dataField: "status",
          text: "Status",
          filter: textFilter(),
          formatter: this.GetStatusFormat,
          classes: "text-center",
          sort: true,
        },
        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      defaultSorted: [
        {
          dataField: "requester",
          order: "asc",
        },
      ],
      cellEditProps: {},
    };
  }
  componentDidMount() {
    const { dispatch, user } = this.props;
    dispatch(readPurchaseRequest(user.username, "requester")).then(
      (response) => {
        this.setState({
          list_data: this.props.read_purchase_request.data,
          requester: user.username,
          department: user.details.hris_org_tree.current_person
            ? user.details.hris_org_tree.current_person.nama_department
            : null,
          email_requester: user.details.hris_org_tree.current_person
            ? user.details.hris_org_tree.current_person.email
            : null,
          name_requester: user.details.hris_org_tree.current_person
            ? user.details.hris_org_tree.current_person.person_name
            : null,
          id_department: user.details.hris_org_tree.current_person
            ? user.details.hris_org_tree.current_person.department_id
            : null,
        });
      }
    );
    dispatch(readRequisitioner()).then((response) => {
      this.setState({
        select_items_requisitioner: this.props.read_requisitioner.data,
      });
    });

    dispatch(readUOM()).then((response) => {
      this.setState({ select_items_uom: this.props.read_uom.data });
    });
    dispatch(readMATERIAL()).then((response) => {
      this.setState({ select_items_material: this.props.read_material.data });
    });
    dispatch(readSUPPLIER()).then((response) => {
      this.setState({
        select_items_source_determination: this.props.read_supplier.data,
      });
    });

    dispatch(readGLAccount()).then((response) => {
      this.setState({ select_items_glaccount: this.props.read_glaccount.data });
    });
    dispatch(
      readCostCenter(user.details.hris_org_tree.current_person.nama_department)
    ).then((response) => {
      this.setState({
        select_items_cost_center: this.props.read_costcenter.data,
      });
    });

    dispatch(readDocumentType()).then((response) => {
      this.setState({
        select_items_document_type: this.props.read_document_type.data,
      });
    });
    dispatch(readPlant()).then((response) => {
      this.setState({ select_items_plant: this.props.read_plant.data });
    });
    dispatch(readPurchGroup()).then((response) => {
      this.setState({ select_items_purch_group: this.props.read_purch_group.data });
    });
    dispatch(readAccountAssignmentCat()).then((response) => {
      this.setState({ select_items_account_assignment_cat: this.props.read_account_assignment_cat.data });
    });

  }
  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      document_type: null,
      source_determination: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      g_l_account: null,
      showModal: !this.state.showModal,
      readOnly: false,
      isValid: true,
      isValidDate: true,
      isValidGLAccount: true,
      reason: null,
      cost_center: null,
      remark: null,
      account_assignment_cat: null
    });
  };
  onModalPurchaseRequestHide = () => {
    this.setState({
      id: null,
      submitted: false,

      document_type: null,
      source_determination: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      g_l_account: null,

      showModalPurchaseRequest: !this.state.showModalPurchaseRequest,
      readOnly: false,
      ready_for_approval: false,
      reason: null,
      cost_center: null,
      remark: null,
      account_assignment_cat: null
    });
  };

  onModalPurchaseRequestRejectHide = () => {
    this.setState({
      id: null,
      submitted: false,
      showModalPurchaseRequestReject:
        !this.state.showModalPurchaseRequestReject,
      readOnly: true,
      reason: null,
    });
  };
  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  togglePurchaseRequest = () => {
    this.setState({
      showModalPurchaseRequest: !this.state.showModalPurchaseRequest,
    });
  };

  toggleFlowPurchaseRequest = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };
  onModalFlowHide = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };
  toDetail = (
    id,
    document_type,
    source_determination,
    material,
    quantity,
    unit_of_measure,
    delivery_date,
    plant,
    purchase_group,
    requisitioner,
    status,
    g_l_account,
    cost_center,
    remark,
    account_assignment_cat
  ) => {
    this.setState(
      {
        id: id,
        document_type: document_type,
        source_determination: source_determination,
        material: material,
        quantity: quantity,
        unit_of_measure: unit_of_measure,
        delivery_date: delivery_date,
        plant: plant,
        purchase_group: purchase_group,
        requisitioner: requisitioner,
        status: status,
        g_l_account: g_l_account,
        cost_center: cost_center,
        remark: remark,
        account_assignment_cat: account_assignment_cat
      },
      () => {
        this.toggle();
      }
    );
  };
  toDetailPurchaseRequest = (
    id,
    document_type,
    source_determination,
    material,
    quantity,
    unit_of_measure,
    delivery_date,
    plant,
    purchase_group,
    requisitioner,
    status,
    ready_for_approval,
    g_l_account,
    extend,
    cost_center,
    remark,
    account_assignment_cat
  ) => {
    const { dispatch } = this.props;
    if (status === "Submitted") {
      return this.setState(
        {
          id: id,
          document_type: document_type,
          source_determination: source_determination,
          material: material,
          quantity: quantity,
          unit_of_measure: unit_of_measure,
          delivery_date: delivery_date,
          plant: plant,
          purchase_group: purchase_group,
          requisitioner: requisitioner,
          status: status,
          readOnly: true,
          ready_for_approval: ready_for_approval,
          g_l_account: g_l_account,
          extend: extend,
          cost_center: cost_center,
          remark: remark,
          account_assignment_cat: account_assignment_cat
        },
        () => {
          this.togglePurchaseRequest();
        }
      );
    }
    return this.setState(
      {
        id: id,
        document_type: document_type,
        source_determination: source_determination,
        material: material,
        quantity: quantity,
        unit_of_measure: unit_of_measure,
        delivery_date: delivery_date,
        plant: plant,
        purchase_group: purchase_group,
        requisitioner: requisitioner,
        status: status,
        readOnly: true,
        g_l_account: g_l_account,
        extend: extend,
        cost_center: cost_center,
        remark: remark,
        account_assignment_cat: account_assignment_cat

      },
      () => {
        this.togglePurchaseRequest();
      }
    );
  };

  GetRequesterFormat = (cell, row) => {
    return (
      <div>
        {row.name_requester} ({row.requester})
      </div>
    );
  };
  GetQTYFormat = (cell, row) => {
    return (
      <div>
        {addSeparatorsNF(row.quantity, ".", ".", ",")} &nbsp;
        {row.unit_of_measure}
      </div>
    );
  };
  GetStatusFormat = (cell, row) => {
    return (
      <div>
        <button
          type="button"
          className="btn btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() => this.toggleFlowPurchaseRequest()}
        >
          <i className="fas fa-search-location"></i>
          &nbsp;{row.status}
        </button>
      </div>
    );
  };
  GetActionFormat = (cell, row) => {
    let date = row.delivery_date.split("-");
    let date_baru = new Date(date[0], date[1] - 1, date[2]);
    let extend = false;
    if (row.overbudget) {
      if (row.overbudget.isOverBudget) {
        extend = true;
      }
    }
    return row.status === "Submitted" ? (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetailPurchaseRequest(
              row._id,
              row.document_type,
              row.source_determination,
              row.material,
              row.quantity,
              row.unit_of_measure,
              row.delivery_date,
              row.plant,
              row.purchase_group,
              row.requisitioner,
              row.status,
              true,
              row.g_l_account,
              extend,
              row.cost_center,
              row.remark,
              row.account_assignment_cat
            )
          }
        >
          <i className="fas fa-th"></i>
          &nbsp;Detail
        </button>
        <button
          type="button"
          className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetail(
              row._id,
              row.document_type,
              row.source_determination,
              row.material,
              row.quantity,
              row.unit_of_measure,
              date_baru,
              row.plant,
              row.purchase_group,
              row.requisitioner,
              row.status,
              row.g_l_account,
              row.cost_center,
              row.remark,
              row.account_assignment_cat
            )
          }
        >
          <i className="fas fa-edit"></i>
          &nbsp;Edit
        </button>
        <button
          type="button"
          className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          data-id={row._id}
          onClick={() => this.deleteData(row._id)}
        >
          <i className="fas fa-trash"></i>
          &nbsp;Delete
        </button>
      </div>
    ) : (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetailPurchaseRequest(
              row._id,
              row.document_type,
              row.source_determination,
              row.material,
              row.quantity,
              row.unit_of_measure,
              row.delivery_date,
              row.plant,
              row.purchase_group,
              row.requisitioner,
              row.status,
              false,
              row.g_l_account,
              extend,
              row.cost_center,
              row.remark,
              row.account_assignment_cat
            )
          }
        >
          <i className="fas fa-th"></i>
          &nbsp;Detail
        </button>
      </div>
    );
  };
  isEmptyObject = (obj) => {
    return obj == null || !Object.keys(obj).length;
  };
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };
  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { dispatch, history } = this.props;
    const {
      requester,
      name_requester,
      department,
      id_department,
      email_requester,
      document_type,
      source_determination,
      material,
      quantity,
      unit_of_measure,
      delivery_date,
      plant,
      purchase_group,
      requisitioner,
      g_l_account,
      cost_center,
      remark,
      account_assignment_cat
    } = this.state;
    if (
      !this.validateForm(
        document_type,
        source_determination,
        material,
        quantity,
        unit_of_measure,
        delivery_date,
        plant,
        purchase_group,
        requisitioner,
        g_l_account,
        cost_center,
        account_assignment_cat
      )
    ) {
      return;
    }
    let collection = {
      requester: requester,
      name_requester: name_requester,
      department: department,
      id_department: id_department,
      email_requester: email_requester,
      document_type: document_type,
      source_determination: source_determination,
      material: material,
      quantity: quantity,
      unit_of_measure: unit_of_measure,
      delivery_date: delivery_date,
      plant: plant,
      purchase_group: purchase_group,
      requisitioner: requisitioner,
      g_l_account: g_l_account,
      cost_center: cost_center,
      remark: remark,
      account_assignment_cat: account_assignment_cat
    };
    if (
      requester &&
      email_requester &&
      department &&
      document_type &&
      material &&
      quantity &&
      unit_of_measure &&
      delivery_date &&
      plant &&
      purchase_group &&
      requisitioner &&
      g_l_account &&
      cost_center &&
      account_assignment_cat
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          dispatch(addPurchaseRequest(collection))
            .then((response) => {
              this.toggle();
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/purchase-request");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  handleSubmitEdit = () => {
    this.setState({ submitted: true });
    const {
      id,
      requester,
      document_type,
      source_determination,
      material,
      quantity,
      unit_of_measure,
      delivery_date,
      plant,
      purchase_group,
      requisitioner,
      g_l_account,
      cost_center,
      remark,
      account_assignment_cat
    } = this.state;

    if (
      !this.validateForm(
        document_type,
        source_determination,
        material,
        quantity,
        unit_of_measure,
        delivery_date,
        plant,
        purchase_group,
        requisitioner,
        g_l_account,
        cost_center,
        account_assignment_cat
      )
    ) {
      return;
    }
    const { dispatch, history } = this.props;
    let collection = {
      _id: id,
      requester: requester,
      document_type: document_type,
      source_determination: source_determination,
      material: material,
      quantity: quantity,
      unit_of_measure: unit_of_measure,
      delivery_date: delivery_date,
      plant: plant,
      purchase_group: purchase_group,
      requisitioner: requisitioner,
      g_l_account: g_l_account,
      cost_center: cost_center,
      remark: remark,
      account_assignment_cat: account_assignment_cat
    };
    if (
      id &&
      requester &&
      document_type &&
      material &&
      quantity &&
      unit_of_measure &&
      delivery_date &&
      plant &&
      purchase_group &&
      requisitioner &&
      g_l_account &&
      cost_center &&
      account_assignment_cat
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          dispatch(editPurchaseRequest(collection))
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-edit-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/purchase-request");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-edit-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  deleteData = (id) => {
    const { dispatch, history } = this.props;
    const { requester } = this.state;
    let collection = { _id: id, requester: requester };
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, delete entries",
      }).then((result) => {
        if (result.value) {
          dispatch(deletePurchaseRequest(collection))
            .then((response) => {
              toast.success("Data has been deleted successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been deleted successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/purchase-request");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  approveData = (id) => {
    const { dispatch, history } = this.props;
    const { email_requester, requester, name_requester } = this.state;
    let collection = {
      _id: id,
      email: email_requester,
      requester: requester,
      name_requester: name_requester,
    };

    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, ready for input nominal",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(
            upperApprovalPurchaseRequest(collection, "need_upper_approval")
          )
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been ready for upper approval successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/purchase-request");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  extendBudget = (id) => {
    const { dispatch, history } = this.props;
    const { email_requester, requester, name_requester, status, reason } =
      this.state;
    let collection = {
      _id: id,
      email: email_requester,
      requester: requester,
      name_requester: name_requester,
      status: status,
    };

    if (!this.validateRejectForm(reason)) {
      return;
    }
    if (reason && requester) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, extend budget",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(
            upperApprovalPurchaseRequest(collection, status, "approved", reason)
          )
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-extend-budget-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been ready for extend budget successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/purchase-request");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-extend-budget-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  donotextendBudget = (id) => {
    const { dispatch, history } = this.props;
    const { email_requester, requester, name_requester, status } = this.state;
    let collection = {
      _id: id,
      email: email_requester,
      requester: requester,
      name_requester: name_requester,
      status: status,
    };

    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "No, Don't extend budget",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(upperApprovalPurchaseRequest(collection, status, "rejected"))
            .then((response) => {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId:
                  "customId-donot-extend-budget-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then((result) => {
                history.push("/input/purchase-request");
                window.location.reload();
              });
            })
            .catch((err) => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-donot-extend-budget-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.value,
      });
      if (name == "requisitioner") {
        this.setState({
          isValid: true,
        });
      }
      if (name == "g_l_account") {
        this.setState({
          isValidGLAccount: true,
        });
      }
      if (name == "material") {
        this.setState({
          isValidMaterial: true,
        });
      }
      if (name == "source_determination") {
        this.setState({
          isValidSupplier: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
      });

      if (name == "requisitioner") {
        this.setState({
          isValid: false,
        });
      }
      if (name == "g_l_account") {
        this.setState({
          isValidGLAccount: false,
        });
      }
      if (name == "material") {
        this.setState({
          isValidMaterial: false,
        });
      }
    }
  };

  handleDate = (date) => {
    this.setState({
      delivery_date: date,
      isValidDate: true,
    });
  };

  showinputReason = (id) => {
    this.setState({
      showModalPurchaseRequestReject:
        !this.state.showModalPurchaseRequestReject,
    });
  };
  validateForm = (
    document_type,
    source_determination,
    material,
    quantity,
    unit_of_measure,
    delivery_date,
    plant,
    purchase_group,
    requisitioner,
    g_l_account,
    cost_center,
    account_assignment_cat
  ) => {
    let errors = this.state.errors;
    if (!g_l_account) {
      errors.g_l_account = "This field is required";
      this.setState({
        isValidGLAccount: false,
      });
    }
    if (!cost_center) {
      errors.cost_center = "This field is required";
      this.setState({
        isValidCostCenter: false,
      });
    }
    if (!document_type) errors.document_type = "This field is required";

    if (!material) {
      errors.material = "This field is required";
      this.setState({
        isValidMaterial: false,
      });
    }
    if (!quantity) errors.quantity = "This field is required";
    if (!unit_of_measure) errors.unit_of_measure = "This field is required";
    if (!delivery_date) {
      errors.delivery_date = "This field is required";
      this.setState({
        isValidDate: false,
      });
    }
    if (!plant) errors.plant = "This field is required";
    if (!purchase_group) errors.purchase_group = "This field is required";
    if (!requisitioner) {
      this.setState({ isValid: false });
      errors.requisitioner = "This field is required";
    }
    if (!account_assignment_cat) errors.account_assignment_cat = "This field is required";

    return errors;
  };

  validateRejectForm = (reason) => {
    let errors = this.state.errors;
    if (!reason) {
      errors.reason = "This field is required";
    }
    return errors;
  };
  render() {
    let {
      columns,
      defaultSorted,
      showModal,
      showModalFlow,
      showModalPurchaseRequestReject,
      cellEditProps,
      select_items_glaccount,
      select_items_material,
      select_items_requisitioner,
      select_items_source_determination,
      select_items_uom,
      select_items_cost_center,
      select_items_document_type,
      select_items_plant,
      select_items_purch_group,
      select_items_account_assignment_cat,
      list_data,
      id,
      g_l_account,
      document_type,
      source_determination,
      material,
      quantity,
      unit_of_measure,
      delivery_date,
      plant,
      purchase_group,
      requisitioner,
      errors,
      submitted,
      readOnly,
      showModalPurchaseRequest,
      ready_for_approval,
      isClearable,
      loading,
      isValid,
      isValidDate,
      isValidMaterial,
      isValidSupplier,
      isValidGLAccount,
      isValidCostCenter,
      extend,
      reason,
      cost_center,
      remark,
      account_assignment_cat
    } = this.state;
    const customStyles = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused ? "#ddd" : isValid ? "#ddd" : "green",
        },
      }),
    };
    const customStylesCostCenter = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidCostCenter
            ? "#ddd"
            : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidCostCenter
              ? "#ddd"
              : "green",
        },
      }),
    };
    const customStylesGLAccount = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidGLAccount
            ? "#ddd"
            : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidGLAccount
              ? "#ddd"
              : "green",
        },
      }),
    };
    const customStylesSupplier = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidSupplier
            ? "#ddd"
            : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidSupplier
              ? "#ddd"
              : "green",
        },
      }),
    };
    const customStylesMaterial = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterial
            ? "#ddd"
            : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterial
              ? "#ddd"
              : "green",
        },
      }),
    };
    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <ToastContainer />
          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
              autoComplete="off"
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>
                  {id ? "Update" : "Create"} Purchase Request
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      <div
                        className={
                          errors.document_type
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="document_type">Document Type *</label>

                        <select
                          className={
                            errors.document_type != "This field is required"
                              ? "form-control form-control-md "
                              : "form-control form-control-md  has-error has-feedback"
                          }
                          name="document_type"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={document_type}
                          required
                        >
                          <option value="">Choose</option>
                          {select_items_document_type &&
                            select_items_document_type.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.label}
                                </option>
                              );
                            })}
                        </select>

                        {errors.document_type == "This field is required" && (
                          <div className="invalid-feedback">
                            {errors.document_type}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div
                        className={
                          errors.source_determination !=
                            "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="source_determination">
                          Source Determination
                        </label>
                        <Select
                          styles={customStylesSupplier}
                          onChange={this.handleChange.bind(
                            this,
                            "source_determination"
                          )}
                          options={select_items_source_determination}
                          defaultValue={select_items_source_determination.filter(
                            (e) => e.value === source_determination
                          )}
                          isClearable={isClearable}
                        />
                        {!isValidSupplier && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.source_determination}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div
                        className={
                          errors.material != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="material">Material *</label>
                        <Select
                          styles={customStylesMaterial}
                          onChange={this.handleChange.bind(this, "material")}
                          options={select_items_material}
                          defaultValue={select_items_material.filter(
                            (e) => e.value === material
                          )}
                          isClearable={isClearable}
                        />

                        {!isValidMaterial && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.material}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="g_l_account">GL Account *</label>
                        <Select
                          styles={customStylesGLAccount}
                          onChange={this.handleChange.bind(this, "g_l_account")}
                          options={select_items_glaccount}
                          defaultValue={select_items_glaccount.filter(
                            (e) => e.value === g_l_account
                          )}
                          isClearable={isClearable}
                        />

                        {!isValidGLAccount && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.g_l_account}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div
                        className={
                          errors.quantity != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="quantity">Quantity *</label>
                        <div className="input-group mb-3">
                          <div className="col-md-9">
                            <div
                              className={
                                errors.quantity
                                  ? "input-group-append"
                                  : "input-group-append has-error has-feedback"
                              }
                            >
                              <input
                                type="number"
                                className="form-control form-control-md"
                                name="quantity"
                                min="0"
                                placeholder="0"
                                required="required"
                                onChange={this.onChangeValue}
                                defaultValue={quantity}
                              />

                              {errors.quantity && (
                                <div className="invalid-feedback">
                                  {errors.quantity}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <select
                              className={
                                errors.unit_of_measure !=
                                  "This field is required"
                                  ? "form-control form-control-md "
                                  : "form-control form-control-md  has-error has-feedback"
                              }
                              name="unit_of_measure"
                              onChange={this.onChangeValue}
                              style={{ padding: 0 }}
                              defaultValue={unit_of_measure}
                              required
                            >
                              <option value="">Choose</option>
                              {select_items_uom &&
                                select_items_uom.map(function (item, i) {
                                  return (
                                    <option value={item.value} key={i}>
                                      {item.value}
                                    </option>
                                  );
                                })}
                            </select>

                            {errors.unit_of_measure ==
                              "This field is required" && (
                                <div className="invalid-feedback">
                                  {errors.unit_of_measure}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="cost_center">Cost Center *</label>
                        <Select
                          styles={customStylesCostCenter}
                          onChange={this.handleChange.bind(this, "cost_center")}
                          options={select_items_cost_center}
                          defaultValue={select_items_cost_center.filter(
                            (e) => e.value === cost_center
                          )}
                          isClearable={isClearable}
                        />

                        {!isValidCostCenter && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.cost_center}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={3}>
                      <div
                        className={
                          errors.purchase_group != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="purchase_group">Purchase Group *</label>
                        <select
                          className={
                            errors.purchase_group != "This field is required"
                              ? "form-control form-control-md "
                              : "form-control form-control-md  has-error has-feedback"
                          }
                          name="purchase_group"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={purchase_group}
                          required
                        >
                          <option value="">Choose</option>
                          {select_items_purch_group &&
                            select_items_purch_group.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.label}
                                </option>
                              );
                            })}
                        </select>

                        {errors.purchase_group == "This field is required" && (
                          <div className="invalid-feedback">
                            {errors.purchase_group}
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col xs={12} md={3}>
                      <div
                        className={
                          errors.plant != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="plant">Plant *</label>
                        <select
                          className={
                            errors.plant !=
                              "This field is required"
                              ? "form-control form-control-md "
                              : "form-control form-control-md  has-error has-feedback"
                          }
                          name="plant"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={plant}
                          required
                        >
                          <option value="">Choose</option>
                          {select_items_plant &&
                            select_items_plant.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.label}
                                </option>
                              );
                            })}
                        </select>

                        {errors.plant ==
                          "This field is required" && (
                            <div className="invalid-feedback">
                              {errors.plant}
                            </div>
                          )}
                      </div>
                    </Col>

                    <Col xs={12} md={3}>
                      <div
                        className={
                          errors.delivery_date != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="delivery_date">Delivery Date *</label>
                        <DatePicker
                          selected={delivery_date}
                          startDate={delivery_date}
                          minDate={
                            delivery_date
                              ? subDays(new Date(delivery_date), 0)
                              : subDays(new Date(), 0)
                          }
                          disabledKeyboardNavigation
                          onChange={this.handleDate}
                          name="delivery_date"
                          dateFormat="yyyy-MM-dd"
                          className="form-control form-control-md"
                          onFocus={(e) => e.target.blur()}
                          isClearable
                          required
                        />
                        {!isValidDate && (
                          <div style={{ fontSize: "12px", color: "red" }}>
                            {errors.delivery_date}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="requisitioner">Requisitioner *</label>
                        <Select
                          styles={customStyles}
                          onChange={this.handleChange.bind(
                            this,
                            "requisitioner"
                          )}
                          options={select_items_requisitioner}
                          defaultValue={select_items_requisitioner.filter(
                            (e) => e.value === requisitioner
                          )}
                          isClearable={isClearable}
                        />

                        {!isValid && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: " #dc3545",
                            }}
                          >
                            {errors.requisitioner}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div
                        className={
                          errors.purchase_group != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="purchase_group">Account Assignment Cat *</label>
                        <select
                          className={
                            errors.account_assignment_cat != "This field is required"
                              ? "form-control form-control-md "
                              : "form-control form-control-md  has-error has-feedback"
                          }
                          name="account_assignment_cat"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={account_assignment_cat}
                          required
                        >
                          <option value="">Choose</option>
                          {select_items_account_assignment_cat &&
                            select_items_account_assignment_cat.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.label}
                                </option>
                              );
                            })}
                        </select>

                        {errors.account_assignment_cat == "This field is required" && (
                          <div className="invalid-feedback">
                            {errors.account_assignment_cat}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div
                        className="form-group"
                      >
                        <label htmlFor="remark">Remark </label>
                        <textarea
                          type="text"
                          className="form-control form-control-md"
                          name="remark"
                          maxLength="40"
                          placeholder="Remark"
                          rows="5"
                          onChange={this.onChangeValue}
                          defaultValue={remark}
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter className="justify-content-between">
                <Button variant="danger" onClick={this.toggle}>
                  <i className="fas fa-times" /> Cancel
                </Button>
                {id ? (
                  <Button variant="success" onClick={this.handleSubmitEdit}>
                    <i className="fas fa-save" /> Update
                  </Button>
                ) : (
                  <Button variant="success" onClick={this.handleSubmit}>
                    <i className="fas fa-save" /> Submit
                  </Button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Modal
            show={showModalFlow}
            size="xl"
            backdrop="static"
            onHide={this.onModalFlowHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Flow Purchase Request</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={2} md={2}>
                      Submitted
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      Input Nominal by PRC
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      *Approval <br />
                      <small>if OverBudget</small>
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      Done
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </form>
          </Modal>
          <Modal
            show={showModalPurchaseRequest}
            size="xl"
            backdrop="static"
            onHide={this.onModalPurchaseRequestHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Detail Purchase Request</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="document_type">Document Type *</label>

                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="document_type"
                          placeholder="Document Type"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={document_type}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="source_determination">
                          Source Determination *
                        </label>
                        <input
                          type="number"
                          className="form-control form-control-md"
                          name="source_determination"
                          min="0"
                          placeholder="Source Determination"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={source_determination}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="material">Material *</label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="material"
                          placeholder="Material"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={material}
                          readOnly={readOnly}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="g_l_account">GL Account *</label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="g_l_account"
                          placeholder="GL Account"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={g_l_account}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="form-group">
                        <label htmlFor="quantity">Quantity *</label>
                        <div className="input-group mb-3">
                          <div className="col-md-12">
                            <div className="input-group-append">
                              <span
                                style={{
                                  backgroundColor: "rgb(233 236 239)",
                                  padding: ".25rem .5rem",
                                  fontSize: ".875rem",
                                  lineHeight: "1.5",
                                  borderRadius: ".2rem",
                                }}
                              >
                                {addSeparatorsNF(quantity, ".", ".", ",")}
                              </span>
                              <span
                                style={{
                                  backgroundColor: "rgb(233 236 239)",
                                  padding: ".25rem .5rem",
                                  fontSize: ".875rem",
                                  lineHeight: "1.5",
                                  borderRadius: ".2rem",
                                }}
                              >
                                {unit_of_measure}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="cost_center">Cost Center *</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="cost_center"
                          placeholder="Cost Center"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={cost_center}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="purchase_group">Purchase Group *</label>
                        <input
                          type="number"
                          className="form-control form-control-md"
                          name="purchase_group"
                          min="0"
                          placeholder="Purchase Group"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={purchase_group}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>

                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="plant">Plant *</label>
                        <input
                          type="number"
                          className="form-control form-control-md"
                          name="plant"
                          min="0"
                          placeholder="Plant"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={plant}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>

                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="delivery_date">Delivery Date *</label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="delivery_date"
                          placeholder="Delivery Date"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={delivery_date}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="form-group">
                        <label htmlFor="requisitioner">Requisitioner *</label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="requisitioner"
                          placeholder="Requisitioner"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={requisitioner}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div
                        className="form-group"
                      >
                        <label htmlFor="account_assignment_cat">Account Assignment Cat </label>
                        <input
                          type="text"
                          className="form-control form-control-md"
                          name="account_assignment_cat"
                          placeholder="Account Assignment Cat"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={account_assignment_cat}
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div
                        className="form-group"
                      >
                        <label htmlFor="remark">Remark </label>
                        <textarea
                          type="text"
                          className="form-control form-control-md"
                          name="remark"
                          maxLength="40"
                          placeholder="Remark"
                          onChange={this.onChangeValue}
                          defaultValue={remark}
                          rows="5"
                          readOnly={readOnly}
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter className={extend ? "justify-content-between" : ""}>
                {ready_for_approval && (
                  <button
                    type="button"
                    className="btn btn-success btn-sm ml-2 mb-2 ts-bottom float-right"
                    size="sm"
                    data-id={id}
                    onClick={() => this.approveData(id)}
                  >
                    <i className="fas fa-check-square"></i>
                    &nbsp;Ready for Input Nominal
                  </button>
                )}
                {extend && (
                  <button
                    type="button"
                    className="btn btn-warning btn-sm ml-2 mb-2 ts-bottom"
                    size="sm"
                    data-id={id}
                    onClick={() => this.donotextendBudget(id)}
                  >
                    <i className="fas fa-times"></i>
                    &nbsp;Dont Extend Budget
                  </button>
                )}
                {extend && (
                  <button
                    type="button"
                    className="btn btn-success btn-sm ml-2 mb-2 ts-bottom float-right"
                    size="sm"
                    data-id={id}
                    onClick={() => this.showinputReason(id)}
                  >
                    <i className="fas fa-check-square"></i>
                    &nbsp;Extend Budget
                  </button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Modal
            show={showModalPurchaseRequestReject}
            size="md"
            backdrop="static"
            onHide={this.onModalPurchaseRequestRejectHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              autoComplete="off"
            >
              <ModalHeader closeButton className="bg-warning text-white">
                <ModalTitle>Input Reason</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="form-group">
                        <label htmlFor="reason">Reason *</label>
                        <textarea
                          id="reason"
                          className="form-control form-control-sm"
                          name="reason"
                          placeholder="Reason"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={reason}
                          rows="5"
                        />

                        {errors.reason && (
                          <div className="invalid-feedback">
                            {errors.reason}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="success"
                  onClick={() => this.extendBudget(id)}
                  className="btn btn-success btn-sm ml-2 mb-2 ts-bottom"
                >
                  <i className="fas fa-save" /> Extend
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">Purchase Request</h3>
                        <div className="card-tools float-right">
                          <DropdownButton
                            title={<span> <i className="fas fa-plus-square" id="dropdown-create-pr" /> Purchase
                              Request</span>}
                          >
                            <Dropdown.Item
                            >Expense</Dropdown.Item>
                            <Dropdown.Item
                            >Service</Dropdown.Item>
                            <Dropdown.Item
                            >
                              <div onClick={() => {
                                this.setState({
                                  type: 'Inventory'
                                }, () => this.toggle())
                              }}>
                                Inventory
                              </div>
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                      </div>
                      <div className="card-body">
                        <RemoteTable
                          data={list_data}
                          columns={columns}
                          defaultSorted={defaultSorted}
                          cellEditProps={cellEditProps}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { read_purchase_request } = state.purchase_request;
  const { read_department } = state.department;
  const { read_uom } = state.uom;
  const { read_material } = state.material;
  const { read_supplier } = state.supplier;
  const { read_glaccount } = state.glaccount;
  const { read_costcenter } = state.costcenter;
  const { read_document_type } = state.document_type;
  const { read_requisitioner } = state.requisitioner;
  const { read_plant } = state.plant;
  const { read_purch_group } = state.purch_group;
  const { read_account_assignment_cat } = state.account_assignment_cat;

  const { message } = state.message;
  return {
    message,
    read_purchase_request,
    user,
    read_department,
    read_uom,
    read_material,
    read_supplier,
    read_glaccount,
    read_costcenter,
    read_document_type,
    read_requisitioner,
    read_plant,
    read_purch_group,
    read_account_assignment_cat,
  };
}

export default connect(mapStateToProps)(PurchaseRequest);
