import addSeparatorsNF from "../helpers/thousanddelimiter";

const OutTable = ({ data, cols }) => {
  return (
    <div className="table-responsive" style={{ height: "500px" }}>
      <table className="tableFreezeHeader table table-striped table-compact">
        <thead>
          <tr>
            {cols &&
              cols.map((c) => (
                <th key={c.key} style={{ fontSize: 12 }}>
                  {c.name}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((r, i) => (
              <tr key={i}>
                {cols &&
                  cols.map((c) => (
                    <td
                      key={c.key}
                      style={{ fontSize: 12 }}
                      className={`${
                        !isNaN(r[c.key]) &&
                        c.key !== 0 &&
                        c.key !== 1 &&
                        c.key !== 15
                          ? "text-right"
                          : ""
                      }`}
                    >
                      {!isNaN(r[c.key]) && c.key !== 0 && c.key !== 1
                        ? r[c.key] === null
                          ? ""
                          : addSeparatorsNF(r[c.key], ".", ".", ",")
                        : r[c.key]}
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default OutTable;
