import React, { Component } from "react";
import { connect } from "react-redux";

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: {},
    };
  }

  componentDidMount() {
    const user = this.props.user;

    if (user) {
      this.setState({
        currentUser: user,
      });
    }
  }
  render() {
    const { currentUser } = this.state;
    return (
      <div>
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6"></div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </div>
          {/* /.content-header */}
          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="info-box bg-gradient-info">
                    <div className="info-box-content">
                      <center>
                        <h2>
                          Welcome{" "}
                          {currentUser && currentUser.details
                            ? currentUser.details.hris_org_tree.current_person
                                .person_name
                            : ""}
                        </h2>
                        <p>Simplicity your work by using our apps</p>
                      </center>
                    </div>
                    {/* /.info-box-content */}
                  </div>
                  {/* /.info-box */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Content);
