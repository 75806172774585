import React, { Component, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import PropTypes from "prop-types";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import addSeparatorsNF from "../helpers/thousanddelimiter";
import { connect } from "react-redux";
import {
  readPurchaseRequest,
  upperApprovalPurchaseRequest,
  nominalApprovalPurchaseRequest,
  updateOverbudget,
  checkBudgetInputNominal,
} from "../actions/purchase_request";
import {
  readCurrentExchangeRate,
  readActiveExchangeRate,
} from "../actions/exchange_rate";
import { readBudgetByGlAccount } from "../actions/budget";
import { readUOM } from "../actions/uom";
import { readMATERIAL } from "../actions/material";
import { readSUPPLIER } from "../actions/supplier";
import { readDepartment } from "../actions/department";
import { readGLAccount } from "../actions/glaccount";
import { clearMessage } from "../actions/message";
import Table from "react-bootstrap/Table";
import dateFormat from "dateformat";
import BudgetRemaining from "../../src/components/BudgetRemaining";
import moment from "moment";

const TableCustom = ({
  data,
  totalRows,
  action,
  toggleStatus,
  fetchData,
  handleSearch,
  loading,
}) => {
  const [perPage, setPerPage] = useState(10);
  const handleChange = (e) => {
    handleSearch({
      [e.target.name]: e.target.value,
    });
  };

  const columns = useMemo(() => {
    return [
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Requester"
                onChange={handleChange}
                name="requester"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => `${row.name_requester} (${row.requester})`,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Document Number"
                onChange={handleChange}
                name="document_number"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => row.document_number,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Summary"
                onChange={handleChange}
                name="summary"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        cell: (row) => {
          let items = "";
          if (row.pr_type === "inventory") {
            items = (row?.details || []).map((e) => e?.short_text).join(", ");
          } else {
            items = (row?.details || []).map((e) => e?.item_text).join(", ");
          }

          return items;
        },
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="PR Type"
                onChange={handleChange}
                name="pr_type"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        selector: (row) => row.pr_type,
        sortable: true,
      },
      {
        name: (
          <div className="form-row align-items-center px-2">
            <div className="col">
              <input
                placeholder="Status"
                onChange={handleChange}
                name="status"
                type="search"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        ),
        cell: (row) => {
          return (
            <div>
              <button
                type="button"
                className="btn btn-sm ml-2 mb-2 ts-buttom"
                size="sm"
                onClick={toggleStatus}
              >
                <i className="fas fa-search-location"></i>
                &nbsp;{row.status}
              </button>
            </div>
          );
        },
        sortable: true,
      },
      {
        name: "Action",
        maxWidth: "max-content",
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: action,
      },
    ];
  }, []);

  const handlePageChange = async (page) => {
    await fetchData({
      page,
      sizePerPage: perPage,
    });
  };

  const handlePerPageChange = async (newPerPage, page) => {
    await fetchData({
      page,
      sizePerPage: newPerPage,
    });

    setPerPage(newPerPage);
  };

  return (
    <DataTable
      persistTableHead={true}
      columns={columns}
      data={data}
      pagination
      paginationServer
      paginationPerPage={perPage}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      paginationTotalRows={totalRows}
      progressPending={loading}
    />
  );
};

TableCustom.propTypes = {
  data: PropTypes.instanceOf(Array),
  totalRows: PropTypes.number,
  action: PropTypes.func,
  toggleStatus: PropTypes.func,
  fetchData: PropTypes.func,
  handleSearch: PropTypes.func,
  loading: PropTypes.bool,
};

TableCustom.defaultProps = {
  data: [],
  totalRows: 0,
  loading: false,
};

class ApprovalPurchaseRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClearable: true,
      isValid: true,
      isValidGLAccount: true,
      isValidDate: true,
      isValidMaterial: true,
      isValidSupplier: true,
      is_nominal_approval: false,
      loading: false,
      fetchLoading: false,
      extend: false,
      submitted: false,
      showModal: false,
      showModalPurchaseRequest: false,
      showModalPurchaseRequestReject: false,
      showModalFlow: false,
      readOnly: false,
      collection: [],
      select_items_glaccount: [],
      select_items_source_determination: [],
      select_items_material: [],
      select_items_requisitioner: [],
      select_items_uom: [],
      select_items_account_assignment_cat: [
        { value: "K", label: "(K) Cost Center" },
      ],
      select_items_item_cat: [{ value: "D", label: "(D) Service" }],
      list_data: [],
      count_list_data: 0,
      errors: {},
      id: null,
      reason: null,

      requester: null,
      email_requester: null,
      department: null,
      id_department: null,
      department_approval: null,
      document_type: null,
      source_determination: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      remark: null,
      cost_center: null,
      account_assignment_cat: null,
      document_number: null,
      ready_for_approval: false,
      item_cat: null,
      details_inventory: [
        {
          material_code: "",
          material_name: "",
          short_text: "",
          po_text: "",
          qty: "",
          uom: "",
          delivery_date: "",
          remaining: "",
        },
      ],
      details_expense: [
        {
          material_code: "",
          material_name: "",
          item_text: "",
          short_text: "",
          qty: "",
          uom: "",
          delivery_date: "",
          remaining: "",
        },
      ],
      details_service: [
        {
          short_text: "",
          item_text: "",
          service_number: "",
          qty: "",
          uom: "",
          delivery_date: "",
          remaining: "",
        },
      ],
      columns_service: [
        {
          text: "Short Text",
          type: "text",
        },
        {
          text: "Item Text",
          type: "item_text",
        },
        {
          text: "Service Number",
          type: "service_number",
        },
        {
          text: "Qty",
          type: "numeric",
        },
        {
          text: "UOM",
          type: "uom",
        },
        {
          text: "Delivery Date",
          type: "date",
        },
        {
          text: "Budget Remaining",
          type: "text",
        },
      ],
      columns_inventory: [
        {
          text: "Material",
          type: "material",
        },
        {
          text: "Long Text",
          type: "text",
        },
        {
          text: "GL Account",
          type: "po_text",
        },
        {
          text: "Qty",
          type: "numeric",
        },
        {
          text: "UOM",
          type: "uom",
        },
        {
          text: "Delivery Date",
          type: "date",
        },
        {
          text: "Budget Remaining",
          type: "text",
        },
      ],
      defaultSorted: [
        {
          dataField: "requester",
          order: "asc",
        },
      ],
      cellEditProps: {},
      active_exchange_rate: null,
      exchange_rate_to_rp: "1",
      search: {},
      created_at: null,
    };
    this.timer = null;
  }

  fetchApprovalPurchaseRequest = (
    additionalParams = { page: 1, sizePerPage: 10 }
  ) => {
    const { dispatch, user } = this.props;
    this.setState({
      fetchLoading: true,
    });

    dispatch(
      readPurchaseRequest(user.username, "approval", {
        ...additionalParams,
        search: this.state.search,
      })
    ).then(() => {
      this.setState({
        list_data: this.props.read_purchase_request.data.foundData,
        count_list_data: this.props.read_purchase_request.data.countData,
        fetchLoading: false,
      });
    });
  };

  reloadDataBackend() {
    const { dispatch, user } = this.props;
    this.fetchApprovalPurchaseRequest();

    dispatch(readDepartment()).then(() => {
      const { read_department } = this.props;
      let o = [];
      read_department.data.map((e) => {
        o.push({ value: e.name, label: e.name });
        return;
      });
      this.setState({
        select_items_requisitioner: o,
      });
    });

    dispatch(readUOM()).then(() => {
      this.setState({ select_items_uom: this.props.read_uom.data });
    });

    dispatch(readMATERIAL({ size: 1, sizePerPage: 1 })).then(() => {
      this.setState({ select_items_material: this.props.read_material.data });
    });

    dispatch(readSUPPLIER({ size: 1, sizePerPage: 1 })).then(() => {
      this.setState({
        select_items_source_determination: this.props.read_supplier.data,
      });
    });

    dispatch(readGLAccount({ size: 1, sizePerPage: 1 })).then(() => {
      this.setState({ select_items_glaccount: this.props.read_glaccount.data });
    });

    this.setState({
      created_at: null,
      requester: user.username,
      department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.nama_department
        : null,
      email_requester: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.email
        : null,
      name_requester: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.person_name
        : null,
      id_department: user.details.hris_org_tree.current_person
        ? user.details.hris_org_tree.current_person.department_id
        : null,
    });
  }

  handleSearch = (data) => {
    this.setState((prevState) => ({
      search: { ...prevState.search, ...data },
    }));

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fetchApprovalPurchaseRequest();
    }, 250);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    this.reloadDataBackend();

    dispatch(readActiveExchangeRate()).then(() => {
      this.setState({
        active_exchange_rate: this.props.read_active_exchange_rate.data,
      });
    });

    dispatch(readActiveExchangeRate()).then(() => {
      this.setState({
        active_exchange_rate: this.props.read_active_exchange_rate.data,
      });
    });
  }

  onModalPurchaseRequestHide = () => {
    this.setState({
      id: null,
      submitted: false,

      document_type: null,
      source_determination: null,
      material: null,
      quantity: null,
      unit_of_measure: null,
      delivery_date: null,
      plant: null,
      purchase_group: null,
      requisitioner: null,
      g_l_account: null,

      showModalPurchaseRequest: !this.state.showModalPurchaseRequest,
      readOnly: false,
      ready_for_approval: false,
      reason: null,
      remark: null,
      cost_center: null,
      account_assignment_cat: null,
      department_approval: null,
    });
  };

  onModalPurchaseRequestRejectHide = () => {
    this.setState({
      id: null,
      submitted: false,
      showModalPurchaseRequestReject:
        !this.state.showModalPurchaseRequestReject,
      readOnly: true,
      reason: null,
    });
  };
  onModalFlowHide = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };
  togglePurchaseRequest = () => {
    this.setState({
      showModalPurchaseRequest: !this.state.showModalPurchaseRequest,
    });
  };

  toggleFlowPurchaseRequest = () => {
    this.setState({
      showModalFlow: !this.state.showModalFlow,
    });
  };
  toDetailPurchaseRequest = async (
    id,
    document_type,
    source_determination,
    material,
    quantity,
    unit_of_measure,
    delivery_date,
    plant,
    purchase_group,
    requisitioner,
    status,
    ready_for_approval,
    g_l_account,
    extend,
    cost_center,
    remark,
    account_assignment_cat,
    document_number,
    details,
    pr_type,
    item_cat,
    is_transfer,
    is_nominal_approval = false,
    department_approval,
    is_extend = false,
    extend_reason = "",
    created_at
  ) => {
    const { exchange_rate_to_rp, active_exchange_rate } = this.state;
    const { dispatch } = this.props;
    const cloneDetail = [...details];
    const mapDetail = await Promise.all(
      cloneDetail.map(async (item) => {
        item.over =
          parseFloat(item.remain_payment) < 0
            ? Math.abs(item.remain_payment)
            : 0;
        if (item.exchange_rate) {
          item.prev_exchange_rate = { ...item.exchange_rate };
        }
        if (is_transfer || is_extend) {
          item.prev_remaining = "0";
        }
        if (is_extend) {
          item.remaining = "0";
        }
        if (item.currency) {
          const result = await dispatch(
            readCurrentExchangeRate(item.currency, "last=true")
          )
            .then((res) => {
              const a = this.props.read_current_exchange_rate?.data[
                active_exchange_rate?.value
              ]
                ? this.props.read_current_exchange_rate?.data[
                    active_exchange_rate?.value
                  ]
                : exchange_rate_to_rp;
              let obj = {
                error: false,
                data: a,
                number: a,
                created_at:
                  this.props.read_current_exchange_rate?.data?.created_at,
              };
              return obj;
            })
            .catch((err) => {
              let obj = { error: true, data: err, number: 0, created_at: null };
              return obj;
            });

          item.exchange_rate = result;
        }
        return item;
      })
    );
    if (pr_type === "inventory") {
      return this.setState(
        {
          id: id,
          document_type: document_type,
          source_determination: source_determination,
          material: material,
          quantity: quantity,
          unit_of_measure: unit_of_measure,
          delivery_date: delivery_date,
          plant: plant,
          purchase_group: purchase_group,
          requisitioner: requisitioner,
          status: status,
          readOnly: true,
          ready_for_approval: ready_for_approval,
          g_l_account: g_l_account,
          extend: extend,
          cost_center: cost_center,
          remark: remark,
          account_assignment_cat: account_assignment_cat,
          document_number: document_number,
          details_inventory: mapDetail,
          details: mapDetail,
          pr_type: pr_type,
          item_cat: item_cat,
          is_transfer: is_transfer,
          is_nominal_approval: is_nominal_approval,
          department_approval: department_approval,
          is_extend: is_extend,
          extend_reason: extend_reason,
          created_at: created_at,
        },
        () => {
          this.getRemaining("inventory");
          return this.togglePurchaseRequest();
        }
      );
    }
    if (pr_type === "expense") {
      return this.setState(
        {
          id: id,
          document_type: document_type,
          source_determination: source_determination,
          material: material,
          quantity: quantity,
          unit_of_measure: unit_of_measure,
          delivery_date: delivery_date,
          plant: plant,
          purchase_group: purchase_group,
          requisitioner: requisitioner,
          status: status,
          readOnly: true,
          ready_for_approval: ready_for_approval,
          g_l_account: g_l_account,
          extend: extend,
          cost_center: cost_center,
          remark: remark,
          account_assignment_cat: account_assignment_cat,
          document_number: document_number,
          details_expense: mapDetail,
          details: mapDetail,
          pr_type: pr_type,
          item_cat: item_cat,
          is_transfer: is_transfer,
          is_nominal_approval: is_nominal_approval,
          department_approval: department_approval,
          is_extend: is_extend,
          extend_reason: extend_reason,
          created_at: created_at,
        },
        () => {
          this.getRemaining("expense");
          return this.togglePurchaseRequest();
        }
      );
    }
    if (pr_type === "service") {
      return this.setState(
        {
          id: id,
          document_type: document_type,
          source_determination: source_determination,
          material: material,
          quantity: quantity,
          unit_of_measure: unit_of_measure,
          delivery_date: delivery_date,
          plant: plant,
          purchase_group: purchase_group,
          requisitioner: requisitioner,
          status: status,
          readOnly: true,
          ready_for_approval: ready_for_approval,
          g_l_account: g_l_account,
          extend: extend,
          cost_center: cost_center,
          remark: remark,
          account_assignment_cat: account_assignment_cat,
          document_number: document_number,
          details_service: mapDetail,
          details: mapDetail,
          pr_type: pr_type,
          item_cat: item_cat,
          is_transfer: is_transfer,
          is_nominal_approval: is_nominal_approval,
          department_approval: department_approval,
          is_extend: is_extend,
          extend_reason: extend_reason,
          created_at: created_at,
        },
        () => {
          this.getRemaining("service");
          return this.togglePurchaseRequest();
        }
      );
    }
  };

  GetActionFormat = (row) => {
    let extend = false;
    if (row.overbudget) {
      if (row.overbudget.isOverBudget) {
        extend = true;
      }
    }
    return (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ml-2 mb-2 ts-buttom"
          size="sm"
          onClick={() =>
            this.toDetailPurchaseRequest(
              row._id,
              row.document_type,
              row.source_determination,
              row.material,
              row.quantity,
              row.unit_of_measure,
              row.delivery_date,
              row.plant,
              row.purchase_group,
              row.requisitioner,
              row.status,
              true,
              row.g_l_account,
              extend,
              row.cost_center,
              row.remark,
              row.account_assignment_cat,
              row.document_number,
              row.details,
              row.pr_type,
              row.item_cat,
              row.is_transfer,
              row.is_nominal_approval,
              row.department,
              row.is_extend,
              row?.overbudget?.reason_user,
              row.created_at
            )
          }
        >
          <i className="fas fa-th"></i>
          &nbsp;Detail
        </button>
      </div>
    );
  };
  isEmptyObject = (obj) => {
    return obj === null || !Object.keys(obj).length;
  };
  onChangeValue = (any) => {
    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  handleSubmitNominalApproval = () => {
    this.setState({ submittedTransfer: true });
    const {
      id,
      requester,
      department,
      email_requester,
      details,
      status,
      name_requester,
    } = this.state;

    const { dispatch } = this.props;
    let collection = {
      _id: id,
      requester: requester,
      name_requester: name_requester,
      email: email_requester,
      details: details,
      status: status,
      approval: {
        result: "approved",
      },
    };
    let collectionOverbudget = {
      _id: id,
      requester: requester,
      email: email_requester,
      name_requester: name_requester,
      source_determination: null,
      status: status,
      details: details,
      approval: {
        result: "approved",
      },
    };

    let collection_check = {
      _id: id,
      requester: requester,
      email: email_requester,
      name_requester: name_requester,
      details: details,
      check_budget: true,
      status: status,
      source_determination: null,
    };

    const checkErate = [...details]
      .map((item) => {
        return item.exchange_rate && item.exchange_rate.error ? 1 : 0;
      })
      .reduce((acc, cur) => {
        return acc + cur;
      }, 0);
    if (requester && email_requester && department && checkErate === 0) {
      dispatch(checkBudgetInputNominal(collection_check))
        .then((response) => {
          Swal.fire({
            title: "Are you sure?",
            text: "Please check your entries",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Yes, approve entries",
          }).then((result) => {
            if (result.value) {
              this.setState({
                loading: true,
              });
              dispatch(nominalApprovalPurchaseRequest(collection, status))
                .then(() => {
                  toast.success("Data has been approved successfully", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId:
                      "customId-nominal-approval-purchase-request-success",
                    onClose: () => dispatch(clearMessage()),
                  });
                  Swal.fire({
                    title: "Information",
                    icon: "success",
                    text: "Data has been approved successfully",
                    showConfirmButton: false,
                    timer: 2000,
                  }).then(() => {
                    this.setState({
                      loading: false,
                    });
                    this.onModalPurchaseRequestHide();
                    this.reloadDataBackend();
                  });
                })
                .catch(() => {
                  toast.error(this.props.message, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId:
                      "customId-nominal-approval-purchase-request-failed",
                    onClose: () => dispatch(clearMessage()),
                  });
                  this.setState({
                    loading: false,
                  });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelled", "Your data is safe :)", "error");
            }
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "Over Budget",
            icon: "warning",
            html: this.props.message,
            showCancelButton: true,
            confirmButtonText: "Yes, continue",
          }).then((result) => {
            if (result.value) {
              this.setState({ loading: true });
              dispatch(updateOverbudget(collectionOverbudget))
                .then(() => {
                  toast.success("Data has been submitted successfully", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: "customId-over-purchase-request-success",
                    onClose: () => dispatch(clearMessage()),
                  });
                  Swal.fire({
                    title: "Information",
                    icon: "success",
                    text: "Data has been submitted successfully",
                    showConfirmButton: false,
                    timer: 2000,
                  }).then(() => {
                    this.setState({
                      loading: false,
                    });
                    this.onModalPurchaseRequestHide();
                    this.reloadDataBackend();
                  });
                })
                .catch((err) => {
                  Swal.fire({
                    title: "Information",
                    icon: "warning",
                    text: this.props.message,
                    showConfirmButton: false,
                    timer: 2000,
                  }).then(() => {
                    dispatch(clearMessage());
                  });
                  this.setState({
                    loading: false,
                  });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelled", "Your data is safe :)", "error");
            }
          });
        });
    }
  };
  approveData = (id) => {
    const { dispatch, history } = this.props;
    const {
      email_requester,
      requester,
      name_requester,
      status,
      details_inventory,
      details_expense,
      details_service,
      pr_type,
    } = this.state;
    let details;
    if (pr_type === "inventory") {
      details = details_inventory;
    }
    if (pr_type === "service") {
      details = details_service;
    }
    if (pr_type === "expense") {
      details = details_expense;
    }
    let collection = {
      _id: id,
      email: email_requester,
      requester: requester,
      name_requester: name_requester,
      details: details,
    };
    if (collection) {
      Swal.fire({
        title: "Are you sure to approve this?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, approve",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(upperApprovalPurchaseRequest(collection, status, "approved"))
            .then(() => {
              toast.success("Data has been approved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been approved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                history.push("/approval/purchase-request");
                this.setState({
                  loading: false,
                });
                this.togglePurchaseRequest();
                this.reloadDataBackend();
              });
            })
            .catch(() => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  rejectData = (id) => {
    this.setState({ submitted: true });
    const { dispatch, history } = this.props;
    const {
      email_requester,
      requester,
      name_requester,
      status,
      reason,
      details_inventory,
      details_expense,
      details_service,
      pr_type,
    } = this.state;
    let details;
    if (pr_type === "inventory") {
      details = details_inventory;
    }
    if (pr_type === "service") {
      details = details_service;
    }
    if (pr_type === "expense") {
      details = details_expense;
    }
    let collection = {
      _id: id,
      email: email_requester,
      requester: requester,
      name_requester: name_requester,
      details: details,
    };
    if (!this.validateRejectForm(reason)) {
      return;
    }

    if (reason && requester) {
      Swal.fire({
        title: "Are you sure to reject this?",
        text: "Please check your entries",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reject it",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          dispatch(
            upperApprovalPurchaseRequest(collection, status, "rejected", reason)
          )
            .then(() => {
              toast.success("Data has been rejected successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-purchase-request-success",
                onClose: () => dispatch(clearMessage()),
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been rejected successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                history.push("/approval/purchase-request");
                this.setState({
                  loading: false,
                });
                this.showinputReason();
                this.togglePurchaseRequest();
                this.reloadDataBackend();
              });
            })
            .catch(() => {
              toast.error(this.props.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-upper-approval-purchase-request-failed",
                onClose: () => dispatch(clearMessage()),
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState({
        [name]: newValue.value,
      });
      if (name === "requisitioner") {
        this.setState({
          isValid: true,
        });
      }
      if (name === "g_l_account") {
        this.setState({
          isValidGLAccount: true,
        });
      }
      if (name === "material") {
        this.setState({
          isValidMaterial: true,
        });
      }
      if (name === "source_determination") {
        this.setState({
          isValidSupplier: true,
        });
      }
    } else {
      this.setState({
        [name]: null,
      });

      if (name === "requisitioner") {
        this.setState({
          isValid: false,
        });
      }
      if (name === "g_l_account") {
        this.setState({
          isValidGLAccount: false,
        });
      }
      if (name === "material") {
        this.setState({
          isValidMaterial: false,
        });
      }
      if (name === "source_determination") {
        this.setState({
          isValidSupplier: false,
        });
      }
    }
  };

  handleDate = (date) => {
    this.setState({
      delivery_date: date,
      isValidDate: true,
    });
  };

  showinputReason = () => {
    this.setState({
      showModalPurchaseRequestReject:
        !this.state.showModalPurchaseRequestReject,
    });
  };
  validateForm = (
    document_type,
    source_determination,
    material,
    quantity,
    unit_of_measure,
    delivery_date,
    plant,
    purchase_group,
    requisitioner,
    g_l_account
  ) => {
    let errors = this.state.errors;
    if (!g_l_account) {
      errors.g_l_account = "This field is required";
      this.setState({
        isValidGLAccount: false,
      });
    }
    if (!document_type) errors.document_type = "This field is required";
    if (!source_determination) {
      errors.source_determination = "This field is required";
      this.setState({
        isValidSupplier: false,
      });
    }
    if (!material) {
      errors.material = "This field is required";
      this.setState({
        isValidMaterial: false,
      });
    }
    if (!quantity) errors.quantity = "This field is required";
    if (!unit_of_measure) errors.unit_of_measure = "This field is required";
    if (!delivery_date) {
      errors.delivery_date = "This field is required";
      this.setState({
        isValidDate: false,
      });
    }
    if (!plant) errors.plant = "This field is required";
    if (!purchase_group) errors.purchase_group = "This field is required";
    if (!requisitioner) {
      this.setState({ isValid: false });
      errors.requisitioner = "This field is required";
    }

    return errors;
  };

  validateRejectForm = (reason) => {
    let errors = this.state.errors;
    if (!reason) {
      errors.reason = "This field is required";
    }
    return errors;
  };
  renderDetail = () => {
    const {
      pr_type,
      document_type,
      requisitioner,
      plant,
      purchase_group,
      document_number,
      details_inventory,
      extend_reason,
      details_expense,
      details_service,
      account_assignment_cat,
      cost_center,
      item_cat,
      is_transfer,
      is_nominal_approval,
      is_extend,
      created_at,
    } = this.state;

    if (pr_type === "inventory") {
      return (
        <>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="document_type">Document Type *</label>

                <input
                  type="text"
                  className="form-control form-control-md"
                  name="document_type"
                  placeholder="Document Type"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={document_type}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="requisitioner">Requisitioner *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="requisitioner"
                  placeholder="Requisitioner"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={requisitioner}
                  readOnly={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="plant">Plant *</label>
                <input
                  type="number"
                  className="form-control form-control-md"
                  name="plant"
                  min="0"
                  placeholder="Plant"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={plant}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="purchase_group">Purchase Group *</label>
                <input
                  type="number"
                  className="form-control form-control-md"
                  name="purchase_group"
                  min="0"
                  placeholder="Purchase Group"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={purchase_group}
                  readOnly={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="account_assignment_cat">
                  Account Assignment Cat{" "}
                </label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="account_assignment_cat"
                  disabled
                ></input>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="purchase_group">Item Cat *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="item_cat"
                  disabled
                ></input>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="Document Number">Document Number *</label>

                <input
                  type="text"
                  className="form-control form-control-md"
                  name="document_number"
                  placeholder="Document Number"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={document_number}
                  readOnly={true}
                />
              </div>
            </Col>
            {created_at && (
              <Col xs={12} md={6}>
                <div className="form-group">
                  <label>Created At</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    defaultValue={moment(created_at).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}
                    readOnly={true}
                  />
                </div>
              </Col>
            )}
          </Row>
          {is_extend && (
            <Row>
              <Col xs={12} md={6}>
                <div className="form-group">
                  <label htmlFor="extend_reason">Remark Extend</label>

                  <input
                    type="text"
                    className="form-control form-control-md"
                    name="document_number"
                    placeholder="extend_reason"
                    onChange={this.onChangeValue}
                    defaultValue={extend_reason}
                    readOnly={true}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Table
            bordered
            hover
            size="sm"
            style={{ textAlign: "center", justifyContent: "center" }}
          >
            <thead>
              <tr key="0">
                <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                  {" "}
                  No.{" "}
                </th>
                <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                  {" "}
                  Material{" "}
                </th>
                <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                  {" "}
                  Long Text{" "}
                </th>
                <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                  {" "}
                  Qty{" "}
                </th>
                <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                  {" "}
                  UOM{" "}
                </th>
                <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                  {" "}
                  Delivery Date{" "}
                </th>
                {is_transfer ? (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Prev GL Account{" "}
                  </th>
                ) : (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    GL Account{" "}
                  </th>
                )}
                {is_transfer || is_nominal_approval ? (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Nominal{" "}
                  </th>
                ) : (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Budget Remaining{" "}
                  </th>
                )}
                {is_transfer && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    Over{" "}
                  </th>
                )}
                {(is_transfer || is_nominal_approval) && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Exchange Rate on propose approval
                  </th>
                )}
                {(is_transfer || is_nominal_approval) && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Current Exchange Rate
                  </th>
                )}
                {is_transfer && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    Prev Budget Remaining
                  </th>
                )}
                {is_nominal_approval && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    Budget Remaining
                  </th>
                )}
                {is_transfer && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    Transfer GL Account
                  </th>
                )}
                {is_transfer && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    Transfer Budget Remaining
                  </th>
                )}

                {is_extend && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    Nominal PR
                  </th>
                )}
                {is_extend && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    Nominal Extend
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {details_inventory &&
                details_inventory.map((x, i) => (
                  <tr key={i + 1}>
                    <td key={`td-2-${i + 1}`}>{i + 1}.</td>
                    <td key={`td-3-${i + 1}`}>
                      {x.material_name ? x.material_name : ""}
                    </td>
                    <td key={`td-35-${i + 1}`}>
                      {x.short_text ? x.short_text : ""}
                    </td>
                    <td key={`td-5-${i + 1}`}>
                      {" "}
                      {addSeparatorsNF(x.qty, ".", ".", ",")}{" "}
                    </td>
                    <td key={`td-6-${i + 1}`}>{x.uom}</td>
                    <td key={`td-7-${i + 1}`}>{x.delivery_date}</td>
                    {is_transfer ? (
                      <td key={`td-4-${i + 1}`}>
                        {x.prev_glaccount_name ? x.prev_glaccount_name : ""}
                      </td>
                    ) : (
                      <td key={`td-4-${i + 1}`}>
                        {x.gl_account_name ? x.gl_account_name : ""}
                      </td>
                    )}
                    {is_transfer || is_nominal_approval ? (
                      <td key={`td-8-${i + 1}`}>
                        {x.currency} {addSeparatorsNF(x.nominal, ".", ".", ",")}
                      </td>
                    ) : (
                      <td key={`td-8-${i + 1}`}>
                        <BudgetRemaining itemData={x} key={`br-1-${i + 1}`} />
                      </td>
                    )}
                    {is_transfer && (
                      <td key={`td-899-${i + 1}`}>
                        {x.currency} {addSeparatorsNF(x.over, ".", ".", ",")}
                      </td>
                    )}
                    {(is_transfer || is_nominal_approval) && (
                      <td key={`td-10-${i + 1}`}>
                        <>
                          {x.prev_exchange_rate.number
                            ? addSeparatorsNF(
                                x.prev_exchange_rate.number,
                                ".",
                                ".",
                                ","
                              )
                            : ""}
                          <br />
                          <small>
                            last updated at:&nbsp;
                            <br />
                            {dateFormat(
                              x.prev_exchange_rate.created_at,
                              "yyyy-mm-dd HH:MM"
                            )}
                          </small>
                        </>
                      </td>
                    )}
                    {(is_transfer || is_nominal_approval) && (
                      <td key={`td-101-${i + 1}`}>
                        <>
                          {x.exchange_rate.number
                            ? addSeparatorsNF(
                                x.exchange_rate.number,
                                ".",
                                ".",
                                ","
                              )
                            : ""}
                          <br />
                          <small>
                            last updated at:&nbsp;
                            <br />
                            {dateFormat(
                              x.exchange_rate.created_at,
                              "yyyy-mm-dd HH:MM"
                            )}
                          </small>
                        </>
                      </td>
                    )}
                    {is_transfer && (
                      <td key={`td-11-${i + 1}`}>
                        {x.currency}{" "}
                        {x.prev_remaining
                          ? addSeparatorsNF(x.prev_remaining, ".", ".", ",")
                          : ""}
                      </td>
                    )}
                    {is_nominal_approval && (
                      <td key={`td-833-${i + 1}`}>
                        <BudgetRemaining itemData={x} key={`br-1-${i + 1}`} />
                      </td>
                    )}
                    {is_transfer && (
                      <td key={`td-41-${i + 1}`}>
                        {x.gl_account_name ? x.gl_account_name : ""}
                      </td>
                    )}
                    {is_transfer && (
                      <td key={`td-9-${i + 1}`}>
                        <BudgetRemaining itemData={x} key={`br-1-${i + 1}`} />
                      </td>
                    )}
                    {is_extend && (
                      <td key={`td-9-${i + 1}`}>
                        {x.currency}{" "}
                        {x.nominal
                          ? addSeparatorsNF(x.nominal, ".", ".", ",")
                          : ""}
                      </td>
                    )}
                    {is_extend && (
                      <td key={`td-93-${i + 1}`}>
                        {x.currency}{" "}
                        {x.over ? addSeparatorsNF(x.over, ".", ".", ",") : ""}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      );
    }
    if (pr_type === "expense") {
      return (
        <>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="document_type">Document Type * </label>

                <input
                  type="text"
                  className="form-control form-control-md"
                  name="document_type"
                  placeholder="Document Type"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={document_type}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="requisitioner">Requisitioner *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="requisitioner"
                  placeholder="Requisitioner"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={requisitioner}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="plant">Plant *</label>
                <input
                  type="number"
                  className="form-control form-control-md"
                  name="plant"
                  min="0"
                  placeholder="Plant"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={plant}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="purchase_group">Purchase Group *</label>
                <input
                  type="number"
                  className="form-control form-control-md"
                  name="purchase_group"
                  min="0"
                  placeholder="Purchase Group"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={purchase_group}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="account_assignment_cat">
                  Account Assignment Cat{" "}
                </label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="account_assignment_cat"
                  placeholder="Account Assignment Cat"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={account_assignment_cat}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="purchase_group">Item Cat *</label>
                <select
                  className="form-control form-control-md "
                  name="item_cat"
                  style={{ padding: 0 }}
                  readOnly={true}
                >
                  <option value="">Choose</option>
                </select>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="cost_center">Cost Center *</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="cost_center"
                  placeholder="Cost Center"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={cost_center}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="Document Number">Document Number *</label>

                <input
                  type="text"
                  className="form-control form-control-md"
                  name="document_number"
                  placeholder="Document Number"
                  required="required"
                  onChange={this.onChangeValue}
                  defaultValue={document_number}
                  readOnly={true}
                />
              </div>
            </Col>

            {created_at && (
              <Col xs={12} md={6}>
                <div className="form-group">
                  <label>Created At</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    defaultValue={moment(created_at).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}
                    readOnly={true}
                  />
                </div>
              </Col>
            )}
          </Row>

          {is_extend && (
            <Row>
              <Col xs={12} md={6}>
                <div className="form-group">
                  <label htmlFor="extend_reason">Remark Extend</label>

                  <input
                    type="text"
                    className="form-control form-control-md"
                    name="document_number"
                    placeholder="extend_reason"
                    onChange={this.onChangeValue}
                    defaultValue={extend_reason}
                    readOnly={true}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Table
            bordered
            hover
            size="sm"
            style={{ textAlign: "center", justifyContent: "center" }}
          >
            <thead>
              <tr key="0">
                <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                  {" "}
                  No.{" "}
                </th>
                <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                  {" "}
                  Sort Text{" "}
                </th>
                <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                  {" "}
                  Item Text{" "}
                </th>
                <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                  {" "}
                  Qty{" "}
                </th>
                <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                  {" "}
                  UOM{" "}
                </th>
                <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                  {" "}
                  Delivery Date{" "}
                </th>

                {is_transfer ? (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Prev GL Account{" "}
                  </th>
                ) : (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    GL Account{" "}
                  </th>
                )}
                {is_transfer || is_nominal_approval ? (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Nominal{" "}
                  </th>
                ) : (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Budget Remaining
                  </th>
                )}
                {is_transfer && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Over{" "}
                  </th>
                )}
                {(is_transfer || is_nominal_approval) && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Exchange Rate on propose approval
                  </th>
                )}
                {(is_transfer || is_nominal_approval) && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Current Exchange Rate
                  </th>
                )}
                {is_transfer && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    Prev Budget Remaining
                  </th>
                )}
                {is_nominal_approval && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    Budget Remaining
                  </th>
                )}
                {is_transfer && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    Transfer GL Account
                  </th>
                )}
                {is_transfer && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    Transfer Budget Remaining
                  </th>
                )}
                {is_extend && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    Nominal PR
                  </th>
                )}
                {is_extend && (
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    Nominal Extend
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {details_expense &&
                details_expense.map((x, i) => (
                  <tr key={i + 1}>
                    <td key={`td-2-${i + 1}`}>{i + 1}.</td>
                    <td key={`td-3-${i + 1}`}>{x.short_text}</td>
                    <td key={`td-35-${i + 1}`}>
                      {x.item_text ? x.item_text : ""}
                    </td>
                    <td key={`td-5-${i + 1}`}>
                      {addSeparatorsNF(x.qty, ".", ".", ",")}
                    </td>
                    <td key={`td-6-${i + 1}`}>{x.uom}</td>
                    <td key={`td-7-${i + 1}`}>{x.delivery_date}</td>
                    {is_transfer ? (
                      <td key={`td-4-${i + 1}`}>
                        {x.prev_glaccount_name ? x.prev_glaccount_name : ""}
                      </td>
                    ) : (
                      <td key={`td-4-${i + 1}`}>
                        {x.gl_account_name ? x.gl_account_name : ""}
                      </td>
                    )}
                    {is_transfer || is_nominal_approval ? (
                      <td key={`td-8-${i + 1}`}>
                        {x.currency}{" "}
                        {x.nominal
                          ? addSeparatorsNF(x.nominal, ".", ".", ",")
                          : "0"}
                      </td>
                    ) : (
                      <td key={`td-8-${i + 1}`}>
                        <BudgetRemaining itemData={x} />
                      </td>
                    )}
                    {is_transfer && (
                      <td key={`td-842-${i + 1}`}>
                        {x.currency}{" "}
                        {x.over ? addSeparatorsNF(x.over, ".", ".", ",") : "0"}
                      </td>
                    )}
                    {(is_transfer || is_nominal_approval) && (
                      <td key={`td-10-${i + 1}`}>
                        <>
                          {x.prev_exchange_rate.number
                            ? addSeparatorsNF(
                                x.prev_exchange_rate.number,
                                ".",
                                ".",
                                ","
                              )
                            : "0"}
                          <br />
                          <small>
                            last updated at:&nbsp;
                            <br />
                            {dateFormat(
                              x.prev_exchange_rate.created_at,
                              "yyyy-mm-dd HH:MM"
                            )}
                          </small>
                        </>
                      </td>
                    )}
                    {(is_transfer || is_nominal_approval) && (
                      <td key={`td-101-${i + 1}`}>
                        <>
                          {x.exchange_rate.number
                            ? addSeparatorsNF(
                                x.exchange_rate.number,
                                ".",
                                ".",
                                ","
                              )
                            : "0"}
                          <br />
                          <small>
                            last updated at:&nbsp;
                            <br />
                            {dateFormat(
                              x.exchange_rate.created_at,
                              "yyyy-mm-dd HH:MM"
                            )}
                          </small>
                        </>
                      </td>
                    )}
                    {is_transfer && (
                      <td key={`td-11-${i + 1}`}>
                        {x.currency}{" "}
                        {x.prev_remaining
                          ? addSeparatorsNF(x.prev_remaining, ".", ".", ",")
                          : "0"}
                      </td>
                    )}
                    {is_nominal_approval && (
                      <td key={`td-11-${i + 1}`}>
                        {x.currency}{" "}
                        {x.remaining
                          ? addSeparatorsNF(x.remaining, ".", ".", ",")
                          : "0"}
                      </td>
                    )}
                    {is_transfer && (
                      <td key={`td-41-${i + 1}`}>
                        {x.gl_account_name ? x.gl_account_name : ""}
                      </td>
                    )}
                    {is_transfer && (
                      <td key={`td-9-${i + 1}`}>
                        {x.currency}{" "}
                        {x.remaining
                          ? addSeparatorsNF(x.remaining, ".", ".", ",")
                          : "0"}
                      </td>
                    )}
                    {is_extend && (
                      <td key={`td-9-${i + 1}`}>
                        {x.currency}{" "}
                        {x.nominal
                          ? addSeparatorsNF(x.nominal, ".", ".", ",")
                          : "0"}
                      </td>
                    )}
                    {is_extend && (
                      <td key={`td-9333-${i + 1}`}>
                        {x.currency}{" "}
                        {x.over ? addSeparatorsNF(x.over, ".", ".", ",") : "0"}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
          <Row></Row>
        </>
      );
    }
    if (pr_type === "service") {
      return (
        <>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="document_type">Document Type *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="document_type"
                  defaultValue={document_type}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="requisitioner">Requisitioner *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="requisitioner"
                  defaultValue={requisitioner}
                  readOnly={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="plant">Plant *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="plant"
                  defaultValue={plant}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="cost_center">Cost Center *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="cost_center"
                  defaultValue={cost_center}
                  readOnly={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="account_assignment_cat">
                  Account Assignment Cat. *
                </label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="account_assignment_cat"
                  defaultValue={account_assignment_cat}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="item_cat">Item Cat. *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="item_cat"
                  defaultValue={item_cat}
                  readOnly={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="purchase_group">Purchase Group *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="purchase_group"
                  defaultValue={purchase_group}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="form-group">
                <label htmlFor="document_number">Document Number *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="document_number"
                  defaultValue={document_number}
                  readOnly={true}
                />
              </div>
            </Col>
            {created_at && (
              <Col xs={12} md={6}>
                <div className="form-group">
                  <label>Created At</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    defaultValue={moment(created_at).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}
                    readOnly={true}
                  />
                </div>
              </Col>
            )}
          </Row>

          {is_extend && (
            <Row>
              <Col xs={12} md={6}>
                <div className="form-group">
                  <label htmlFor="extend_reason">Remark Extend</label>

                  <input
                    type="text"
                    className="form-control form-control-md"
                    name="document_number"
                    placeholder="extend_reason"
                    onChange={this.onChangeValue}
                    defaultValue={extend_reason}
                    readOnly={true}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Col xs={12} md={12}>
            <Table
              bordered
              hover
              size="sm"
              style={{ textAlign: "center", justifyContent: "center" }}
            >
              <thead>
                <tr key="0">
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    No.{" "}
                  </th>
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Short Text{" "}
                  </th>
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Item Text{" "}
                  </th>
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Qty{" "}
                  </th>
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    UOM{" "}
                  </th>
                  <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                    {" "}
                    Delivery Date{" "}
                  </th>
                  {is_transfer ? (
                    <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                      {" "}
                      Prev Service Number{" "}
                    </th>
                  ) : (
                    <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                      {" "}
                      Service Number{" "}
                    </th>
                  )}
                  {is_transfer || is_nominal_approval ? (
                    <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                      {" "}
                      Nominal{" "}
                    </th>
                  ) : (
                    <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                      {" "}
                      Budget Remaining{" "}
                    </th>
                  )}
                  {is_transfer && (
                    <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                      {" "}
                      Over{" "}
                    </th>
                  )}
                  {(is_transfer || is_nominal_approval) && (
                    <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                      {" "}
                      Exchange Rate on propose approval
                    </th>
                  )}
                  {(is_transfer || is_nominal_approval) && (
                    <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                      {" "}
                      Current Exchange Rate
                    </th>
                  )}
                  {is_transfer && (
                    <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                      Prev Budget Remaining
                    </th>
                  )}
                  {is_nominal_approval && (
                    <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                      Budget Remaining
                    </th>
                  )}
                  {is_transfer && (
                    <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                      Transfer Service Number
                    </th>
                  )}
                  {is_transfer && (
                    <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                      Transfer Budget Remaining
                    </th>
                  )}
                  {is_extend && (
                    <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                      Nominal PR
                    </th>
                  )}
                  {is_extend && (
                    <th style={{ backgroundColor: "#17A2B8", color: "white" }}>
                      Nominal Extend
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {details_service &&
                  details_service.map((x, i) => (
                    <tr key={i + 1}>
                      <td key={`td-2-${i + 1}`}>{i + 1}.</td>
                      <td key={`td-3-${i + 1}`}>{x.short_text}</td>
                      <td key={`td-41-${i + 1}`}>{x.item_text}</td>
                      <td key={`td-5-${i + 1}`}>
                        {addSeparatorsNF(x.qty, ".", ".", ",")}
                      </td>
                      <td key={`td-6-${i + 1}`}>{x.uom}</td>
                      <td key={`td-7-${i + 1}`}>{x.delivery_date}</td>

                      {is_transfer ? (
                        <td key={`td-4-${i + 1}`}>
                          {x.prev_service_number_label
                            ? x.prev_service_number_label
                            : ""}
                        </td>
                      ) : (
                        <td key={`td-4-${i + 1}`}>
                          {x.service_number_label ? x.service_number_label : ""}
                        </td>
                      )}
                      {is_transfer || is_nominal_approval ? (
                        <td key={`td-8-${i + 1}`}>
                          {x.currency}{" "}
                          {x.nominal
                            ? addSeparatorsNF(x.nominal, ".", ".", ",")
                            : "0"}
                        </td>
                      ) : (
                        <td key={`td-8-${i + 1}`}>
                          <BudgetRemaining itemData={x} key={`br-1-${i + 1}`} />
                        </td>
                      )}
                      {is_transfer && (
                        <td key={`td-8-${i + 1}`}>
                          {x.currency}{" "}
                          {x.over
                            ? addSeparatorsNF(x.over, ".", ".", ",")
                            : "0"}
                        </td>
                      )}
                      {(is_transfer || is_nominal_approval) && (
                        <td key={`td-10-${i + 1}`}>
                          <>
                            {x.prev_exchange_rate.number
                              ? addSeparatorsNF(
                                  x.prev_exchange_rate.number,
                                  ".",
                                  ".",
                                  ","
                                )
                              : "0"}
                            <br />
                            <small>
                              last updated at:&nbsp;
                              <br />
                              {dateFormat(
                                x.prev_exchange_rate.created_at,
                                "yyyy-mm-dd HH:MM"
                              )}
                            </small>
                          </>
                        </td>
                      )}
                      {(is_transfer || is_nominal_approval) && (
                        <td key={`td-101-${i + 1}`}>
                          <>
                            {x.exchange_rate.number
                              ? addSeparatorsNF(
                                  x.exchange_rate.number,
                                  ".",
                                  ".",
                                  ","
                                )
                              : ""}
                            <br />
                            <small>
                              last updated at:&nbsp;
                              <br />
                              {dateFormat(
                                x.exchange_rate.created_at,
                                "yyyy-mm-dd HH:MM"
                              )}
                            </small>
                          </>
                        </td>
                      )}
                      {is_transfer && (
                        <td key={`td-11-${i + 1}`}>
                          {x.currency}{" "}
                          {x.prev_remaining
                            ? addSeparatorsNF(x.prev_remaining, ".", ".", ",")
                            : "0"}
                        </td>
                      )}
                      {is_nominal_approval && (
                        <td key={`td-8-${i + 1}`}>
                          <BudgetRemaining itemData={x} key={`br-1-${i + 1}`} />
                        </td>
                      )}
                      {is_transfer && (
                        <td key={`td-41-${i + 1}`}>
                          {x.service_number_label ? x.service_number_label : ""}
                        </td>
                      )}
                      {is_transfer && (
                        <td key={`td-9-${i + 1}`}>
                          <BudgetRemaining itemData={x} key={`br-1-${i + 1}`} />
                        </td>
                      )}
                      {is_extend && (
                        <td key={`td-9-${i + 1}`}>
                          {x.currency}{" "}
                          {x.nominal
                            ? addSeparatorsNF(x.nominal, ".", ".", ",")
                            : ""}
                        </td>
                      )}
                      {is_extend && (
                        <td key={`td-9333-${i + 1}`}>
                          {x.currency}{" "}
                          {x.over
                            ? addSeparatorsNF(x.over, ".", ".", ",")
                            : "0"}
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </>
      );
    }
  };

  getRemaining = (pr_type) => {
    const { dispatch } = this.props;
    const { department_approval } = this.state;
    let details = {};
    switch (pr_type) {
      case "inventory":
        details = this.state.details_inventory;
        break;
      case "expense":
        details = this.state.details_expense;
        break;
      case "service":
        details = this.state.details_service;
        break;
    }
    if (pr_type === "service") {
      details.map((obj, idx) => {
        let query = `department=${department_approval}&service_number=${obj.service_number}&delivery_date=${obj.delivery_date}`;

        if (obj.service_number !== "" && obj.delivery_date !== "") {
          dispatch(readBudgetByGlAccount(query)).then(() => {
            details[idx] = {
              ...details[idx],
              remaining: this.props.read_budget_by_gl_account.data
                ? this.props.read_budget_by_gl_account.data.sisa_budget
                : "0",
            };
            this.setState({
              details_service: details,
            });
          });
        }
      });
    } else {
      details.map((obj, idx) => {
        let query = `department=${department_approval}&gl_account=${obj.gl_account_code}&delivery_date=${obj.delivery_date}`;

        if (obj.gl_account_code !== "" && obj.delivery_date !== "") {
          dispatch(readBudgetByGlAccount(query)).then(() => {
            details[idx] = {
              ...details[idx],
              remaining: this.props.read_budget_by_gl_account.data
                ? this.props.read_budget_by_gl_account.data.sisa_budget
                : "0",
            };
            details[idx] = {
              ...details[idx],
              budget_interval:
                this.props.read_budget_by_gl_account.data.budget_interval ?? [],
            };
            if (pr_type == "expense") {
              this.setState({
                details_expense: details,
                budget_interval:
                  this.props.read_budget_by_gl_account.data.budget_interval ??
                  [],
              });
            }
            if (pr_type == "inventory") {
              this.setState({
                details_inventory: details,
                budget_interval:
                  this.props.read_budget_by_gl_account.data.budget_interval ??
                  [],
              });
            }
          });
        }
      });
    }
  };

  render() {
    let {
      defaultSorted,
      showModalFlow,
      showModalPurchaseRequestReject,
      cellEditProps,
      list_data,
      count_list_data,
      id,
      errors,
      submitted,
      showModalPurchaseRequest,
      ready_for_approval,
      loading,
      fetchLoading,
      reason,
      is_nominal_approval,
    } = this.state;

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <ToastContainer />
          <Modal
            show={showModalFlow}
            size="xl"
            backdrop="static"
            onHide={this.onModalFlowHide}
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Flow Purchase Request</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={2} md={2}>
                      Submitted
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      Input Nominal by PRC
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      *Approval <br />
                      <small>if OverBudget</small>
                    </Col>
                    <Col xs={1} md={1}>
                      <i className="fas fa-arrow-right" />
                    </Col>
                    <Col xs={2} md={2}>
                      Done
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </form>
          </Modal>
          <Modal
            show={showModalPurchaseRequest}
            size="xl"
            backdrop="static"
            onHide={this.onModalPurchaseRequestHide}
            dialogClassName="full-modal-dialog"
          >
            <form>
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>Detail Purchase Request</ModalTitle>
              </ModalHeader>
              <ModalBody>{this.renderDetail()}</ModalBody>
              <ModalFooter className="justify-content-between">
                <Button
                  variant="danger"
                  onClick={() => this.showinputReason(id)}
                  className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
                >
                  <i className="fas fa-times" /> Reject
                </Button>
                {ready_for_approval && (
                  <button
                    type="button"
                    className="btn btn-success btn-sm ml-2 mb-2 ts-buttom"
                    size="sm"
                    data-id={id}
                    onClick={() => {
                      is_nominal_approval
                        ? this.handleSubmitNominalApproval(id)
                        : this.approveData(id);
                    }}
                  >
                    <i className="fas fa-check-square"></i>
                    &nbsp;Approve
                  </button>
                )}
              </ModalFooter>
            </form>
          </Modal>
          <Modal
            show={showModalPurchaseRequestReject}
            size="md"
            backdrop="static"
            onHide={this.onModalPurchaseRequestRejectHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              autoComplete="off"
            >
              <ModalHeader closeButton className="bg-warning text-white">
                <ModalTitle>Input Reason</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="form-group">
                        <label htmlFor="reason">Reason *</label>
                        <textarea
                          id="reason"
                          className="form-control form-control-sm"
                          name="reason"
                          placeholder="Reason"
                          required="required"
                          onChange={this.onChangeValue}
                          defaultValue={reason}
                          rows="5"
                        />

                        {errors.reason && (
                          <div className="invalid-feedback">
                            {errors.reason}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="danger"
                  onClick={() => this.rejectData(id)}
                  className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
                >
                  <i className="fas fa-times" /> Reject
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="card card-info">
                      <div className="card-header ">
                        <h3 className="card-title">
                          Approval Purchase Request
                        </h3>
                        <div className="card-tools float-right"></div>
                      </div>
                      <div className="card-body">
                        <TableCustom
                          data={list_data}
                          totalRows={count_list_data}
                          loading={fetchLoading}
                          action={this.GetActionFormat}
                          toggleStatus={this.toggleFlowPurchaseRequest}
                          fetchData={this.fetchApprovalPurchaseRequest}
                          handleSearch={this.handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { read_purchase_request } = state.purchase_request;
  const { read_department } = state.department;
  const { read_uom } = state.uom;
  const { read_material } = state.material;
  const { read_supplier } = state.supplier;
  const { read_glaccount } = state.glaccount;
  const { read_budget_by_gl_account } = state.budget;

  const { message } = state.message;

  const { read_current_exchange_rate, read_active_exchange_rate } =
    state.exchange_rate;

  return {
    message,
    read_purchase_request,
    user,
    read_department,
    read_uom,
    read_material,
    read_supplier,
    read_glaccount,
    read_current_exchange_rate,
    read_active_exchange_rate,
    read_budget_by_gl_account,
  };
}

export default connect(mapStateToProps)(ApprovalPurchaseRequest);
