import axios from "axios";
import authHeader from "./auth-header"; 

const API_URL_DEFAULT = process.env.REACT_APP_API;

class DepartmentService { 
 
  readDepartment() {   
    return axios
      .get(API_URL_DEFAULT + "department/",{ headers: authHeader()});
  } 
  
}

export default new DepartmentService();