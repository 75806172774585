import {
  CREATE_ACCOUNT_ASSIGNMENT_CAT_SUCCESS,
  CREATE_ACCOUNT_ASSIGNMENT_CAT_FAIL,
  READ_ACCOUNT_ASSIGNMENT_CAT_SUCCESS,
  READ_ACCOUNT_ASSIGNMENT_CAT_FAIL,
  EDIT_ACCOUNT_ASSIGNMENT_CAT_SUCCESS,
  EDIT_ACCOUNT_ASSIGNMENT_CAT_FAIL,
  DELETE_ACCOUNT_ASSIGNMENT_CAT_SUCCESS,
  DELETE_ACCOUNT_ASSIGNMENT_CAT_FAIL,
} from "../actions/types";

const initialState = {
  read_account_assignment_cat: null,
};

export default function account_assignment_cat(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ACCOUNT_ASSIGNMENT_CAT_SUCCESS:
      return {
        ...state,
      };
    case CREATE_ACCOUNT_ASSIGNMENT_CAT_FAIL:
      return {
        ...state,
      };

    case EDIT_ACCOUNT_ASSIGNMENT_CAT_SUCCESS:
      return {
        ...state,
      };
    case EDIT_ACCOUNT_ASSIGNMENT_CAT_FAIL:
      return {
        ...state,
      };
    case READ_ACCOUNT_ASSIGNMENT_CAT_SUCCESS:
      return {
        ...state,
        read_account_assignment_cat: payload,
      };
    case READ_ACCOUNT_ASSIGNMENT_CAT_FAIL:
      return {
        ...state,
        read_account_assignment_cat: null,
      };
    case DELETE_ACCOUNT_ASSIGNMENT_CAT_SUCCESS:
      return {
        ...state,
      };
    case DELETE_ACCOUNT_ASSIGNMENT_CAT_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
