import {
  CREATE_PURCH_GROUP_SUCCESS,
  CREATE_PURCH_GROUP_FAIL,
  READ_PURCH_GROUP_SUCCESS,
  READ_PURCH_GROUP_FAIL,
  EDIT_PURCH_GROUP_SUCCESS,
  EDIT_PURCH_GROUP_FAIL,
  DELETE_PURCH_GROUP_SUCCESS,
  DELETE_PURCH_GROUP_FAIL,
} from "../actions/types";

const initialState = {
  read_purch_group: null,
};

export default function purch_group(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PURCH_GROUP_SUCCESS:
      return {
        ...state,
      };
    case CREATE_PURCH_GROUP_FAIL:
      return {
        ...state,
      };

    case EDIT_PURCH_GROUP_SUCCESS:
      return {
        ...state,
      };
    case EDIT_PURCH_GROUP_FAIL:
      return {
        ...state,
      };
    case READ_PURCH_GROUP_SUCCESS:
      return {
        ...state,
        read_purch_group: payload,
      };
    case READ_PURCH_GROUP_FAIL:
      return {
        ...state,
        read_purch_group: null,
      };
    case DELETE_PURCH_GROUP_SUCCESS:
      return {
        ...state,
      };
    case DELETE_PURCH_GROUP_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
