import {
  CREATE_COSTCENTER_SUCCESS,
  CREATE_COSTCENTER_FAIL,
  SET_MESSAGE,
  READ_COSTCENTER_SUCCESS,
  READ_COSTCENTER_FAIL,
  EDIT_COSTCENTER_SUCCESS,
  EDIT_COSTCENTER_FAIL,
  DELETE_COSTCENTER_SUCCESS,
  DELETE_COSTCENTER_FAIL,
} from "./types";
import CostCenterService from "../services/costcenter.service";

export const readCostCenter = (departemen) => (dispatch) => {
  return CostCenterService.readCostCenter(departemen).then(
    (response) => {
      dispatch({
        type: READ_COSTCENTER_SUCCESS,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: READ_COSTCENTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
